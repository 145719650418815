import type { CoverageRuleDisplay } from 'models/LoCo/Insurance/CoverageRule';
import type { CoverageInstance, SettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import type { VariantType } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

type ABTestType = {
    readonly variantName: string;
    readonly variantType: VariantType;
    readonly experimentName: string;
};

type CoverageRuleDisplayLogBase = CoverageRuleDisplay & { readonly filed: boolean };

export type CoverageRuleDisplayLog = CoverageRuleDisplayLogBase | (ABTestType & CoverageRuleDisplayLogBase);

export const isCoverageRuleDisplayLogABTest = (
    rule: CoverageRuleDisplayLog
): rule is ABTestType & CoverageRuleDisplayLogBase => 'variantName' in rule;

export interface CoverageRelatedSettingsAndRules {
    readonly rules: CoverageRuleDisplayLog[];
    readonly coverage: CoverageInstance;
    readonly relatedSettings: SettingInstance[];
}

export interface SettingsRelatedCoveragesAndRules {
    readonly rules: CoverageRuleDisplayLog[];
    readonly relatedCoverages: CoverageInstance[];
    readonly setting: SettingInstance;
}
