import { Flex, spacing, Spinner } from '@lemonade-hq/blender-ui';
import {
    SummaryInnerSectionItemKey as Key,
    SummarySectionContent,
    SummarySectionTitle,
    SummaryInnerSectionItemValue as Value,
} from '@lemonade-hq/bluis';
import { LinkComp } from '@lemonade-hq/bluiza';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { CopyableText } from '../components/Copyable/CopyButton';
import { getFormattedDate } from '../helpers/dateHelpers';
import { getEditionUrl, getProductUrl } from 'components/LoCo/common/urlBuilders';
import { StyledSection } from 'components/LoCo/LoCoPagesSharedStyles';
import { EditionStatus } from 'models/LoCo/Insurance/BaseEdition';
import type { Edition, EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { Product } from 'models/LoCo/Insurance/Product';
import { useGetEditionSummary } from 'queries/LoCo/Insurance/BaseEditionQueries';

function getEditionName(edition: Edition): string {
    return isDefined(edition.version) ? `${edition.friendlyName} (${edition.version})` : edition.friendlyName;
}

interface SummaryProps {
    readonly type: EditionType;
    readonly edition: Edition;
    readonly product: Product;
}

export const EditionSummarySection: React.FC<SummaryProps> = ({ type, edition, product }) => {
    const { data: baseEdition, isLoading: isLoadingBaseEdition } = useGetEditionSummary(
        edition.baseEditionCode ?? '',
        type
    );

    return (
        <StyledSection>
            <SummarySectionTitle>Edition Details</SummarySectionTitle>
            <SummarySectionContent>
                <Key>Product</Key>
                <Value>
                    <CopyableText
                        textToCopy={product.code}
                        textToDisplay={<LinkComp url={getProductUrl(product.code)}>{product.name}</LinkComp>}
                        visibleOnHover
                    />{' '}
                </Value>
                <Key>Edition Description</Key>
                <Value>{edition.description}</Value>
                <Key>Created By</Key>
                <Value>{edition.addedByName}</Value>
                <Key>Edition Code</Key>
                <Value>
                    <CopyableText textToCopy={edition.code} textToDisplay={edition.code} visibleOnHover />
                </Value>
                {edition.baseEditionCode != null && (
                    <>
                        <Key>Base Edition</Key>
                        <Value>
                            {isLoadingBaseEdition ? (
                                <Spinner size="md" />
                            ) : (
                                <CopyableText
                                    textToCopy={edition.baseEditionCode}
                                    textToDisplay={
                                        <Flex gap={spacing.s06}>
                                            <LinkComp url={getEditionUrl(product.code, edition.baseEditionCode, type)}>
                                                {isDefined(baseEdition)
                                                    ? getEditionName(baseEdition)
                                                    : edition.baseEditionCode}
                                            </LinkComp>
                                        </Flex>
                                    }
                                    visibleOnHover
                                />
                            )}
                        </Value>
                    </>
                )}
                {product.insurableEntities.length > 0 && (
                    <>
                        <Key>Insurable Entities</Key>
                        <Value>{product.insurableEntities.map(e => e.name).join(', ')}</Value>
                    </>
                )}
                {edition.status === EditionStatus.Approved && edition.approvedAt != null && (
                    <>
                        <Key>Approved At</Key>
                        <Value>{getFormattedDate(edition.approvedAt)}</Value>
                        <Key>Approved By</Key>
                        <Value>{edition.approvedByName}</Value>
                    </>
                )}
                {edition.archivedAt != null && (
                    <>
                        <Key>Archived At</Key>
                        <Value>{getFormattedDate(edition.archivedAt)}</Value>
                        <Key>Archived By</Key>
                        <Value>{edition.archivedByName}</Value>
                    </>
                )}
                {edition.publishedAt != null && (
                    <>
                        <Key>Published At</Key>
                        <Value>{getFormattedDate(edition.publishedAt)}</Value>
                        <Key>Published By</Key>
                        <Value>{edition.publishedByName}</Value>
                    </>
                )}
                {edition.earliestNewBusinessEffectiveDate != null && (
                    <>
                        <Key>New Business Effective date</Key>
                        <Value>{getFormattedDate(edition.earliestNewBusinessEffectiveDate)}</Value>
                    </>
                )}
                {edition.earliestRenewalEffectiveDate != null && (
                    <>
                        <Key>Renewal Effective date</Key>
                        <Value>{getFormattedDate(edition.earliestRenewalEffectiveDate)}</Value>
                    </>
                )}
                {edition.status === EditionStatus.Published && edition.version != null && (
                    <>
                        <Key>Version</Key>
                        <Value>{edition.version}</Value>
                    </>
                )}
            </SummarySectionContent>
        </StyledSection>
    );
};
