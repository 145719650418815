import type { UseBaseMutationResult, UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { blenderGeneral } from '../../../apiClients';
import type {
    AssignNextTicketPayload,
    AssignNextTicketResponseDTO,
    InterveneParams,
    ReviewParams,
    SupervisorMetadata,
    SupervisorMetadataResponse,
    SupervisorTicketFilters,
    TicketAiReview,
} from '../shared/types';
import { usePessimisticMutation } from 'queries/MutationHooks';

const BASE_URL = '/api/v1/ai_responses';

export enum SupervisorQueryKey {
    GetTicketAiReview = 'getTicketAiReview',
    GetMetadata = 'getMetadata',
}

export function useGetAiResponse(
    publicId: string,
    onSuccess: (data: TicketAiReview) => void
): UseQueryResult<TicketAiReview> {
    return useQuery<TicketAiReview>({
        queryKey: [SupervisorQueryKey.GetTicketAiReview, publicId],
        queryFn: async () => {
            const url = `${BASE_URL}/${publicId}`;

            const response = await blenderGeneral.get<{ data: TicketAiReview }>(url);

            onSuccess(response.data.data);
            return response.data.data;
        },
    });
}

export function useAssignNextAiResponse(): UseMutationResult<
    AssignNextTicketResponseDTO,
    unknown,
    AssignNextTicketPayload,
    null
> {
    return usePessimisticMutation({
        mutationFn: async (payload: AssignNextTicketPayload): Promise<AssignNextTicketResponseDTO> => {
            const url = `${BASE_URL}/assign`;
            const { intents, ...restFilters } = payload.filters;

            const response = await blenderGeneral.post<{ data: AssignNextTicketResponseDTO }>(url, {
                ...payload,
                filters: {
                    ...restFilters,
                    toolTypes: intents,
                },
            });

            return response.data.data;
        },
        invalidateKeys: [[SupervisorQueryKey.GetTicketAiReview]],
    });
}

export function useResponseTagReview(): UseMutationResult<TicketAiReview, unknown, ReviewParams, null> {
    return usePessimisticMutation({
        mutationFn: async (params: ReviewParams) => {
            const url = `${BASE_URL}/${params.ticketId}/review`;

            const response = await blenderGeneral.post<{ data: TicketAiReview }>(url, params);

            return response.data.data;
        },
        invalidateKeys: [[SupervisorQueryKey.GetTicketAiReview], [SupervisorQueryKey.GetMetadata]],
    });
}

export function useResetReview(): UseBaseMutationResult<TicketAiReview, unknown, string> {
    return usePessimisticMutation({
        mutationFn: async (ticketId: string) => {
            const url = `${BASE_URL}/${ticketId}/reset`;
            const response = await blenderGeneral.post<{ data: TicketAiReview }>(url);

            return response.data.data;
        },
        invalidateKeys: [[SupervisorQueryKey.GetTicketAiReview]],
    });
}

export function useInterveneAiResponse(): UseBaseMutationResult<void, unknown, InterveneParams> {
    return usePessimisticMutation({
        mutationFn: async ({ ticketId, intervenedResponse, tags, type }: InterveneParams) => {
            const url = `${BASE_URL}/${ticketId}/intervene`;

            const body = {
                response: intervenedResponse,
                tags,
                type,
            };

            await blenderGeneral.post(url, body);
        },
        invalidateKeys: [[SupervisorQueryKey.GetTicketAiReview], [SupervisorQueryKey.GetMetadata]],
    });
}

export function useUnassignTicket(): UseBaseMutationResult<void, unknown, string> {
    return usePessimisticMutation({
        mutationFn: async (ticketId: string) => {
            const url = `${BASE_URL}/${ticketId}/unassign`;

            await blenderGeneral.post<{ data: TicketAiReview }>(url);
        },
        invalidateKeys: [[SupervisorQueryKey.GetTicketAiReview]],
    });
}

export function useSkipTicket(): UseBaseMutationResult<void, unknown, string> {
    return usePessimisticMutation({
        mutationFn: async (ticketId: string) => {
            const url = `${BASE_URL}/${ticketId}/skip`;

            await blenderGeneral.post<{ data: TicketAiReview }>(url);
        },
        invalidateKeys: [[SupervisorQueryKey.GetTicketAiReview]],
    });
}

export function useReleaseTicketToZendesk(): UseBaseMutationResult<void, unknown, string> {
    return usePessimisticMutation({
        mutationFn: async (ticketId: string) => {
            const url = `${BASE_URL}/${ticketId}/release_to_zendesk`;

            await blenderGeneral.post(url);
        },
        invalidateKeys: [[SupervisorQueryKey.GetTicketAiReview], [SupervisorQueryKey.GetMetadata]],
    });
}

function serializeMetadataResponse(response: SupervisorMetadataResponse): SupervisorMetadata {
    const {
        availableProducts,
        pendingReviews,
        reviewedTodayCount,
        possibleTools,
        possibleNonToolsIntents,
        mediums,
        languages,
        regions,
    } = response;

    return {
        pendingTicketsCount: pendingReviews,
        products: availableProducts,
        intents: {
            tools: possibleTools,
            nonTools: possibleNonToolsIntents,
        },
        mediums,
        languages,
        reviewedTodayCount,
        regions,
    };
}

export function useSupervisorMetadata(filters: SupervisorTicketFilters): UseQueryResult<SupervisorMetadata> {
    return useQuery({
        queryKey: [SupervisorQueryKey.GetMetadata],
        queryFn: async () => {
            const url = `${BASE_URL}/ai_review_client/metadata`;

            const { intents, ...restFilters } = filters;

            const response = await blenderGeneral.post<{ data: SupervisorMetadataResponse }>(url, {
                ...restFilters,
                toolTypes: intents,
            });

            return serializeMetadataResponse(response.data.data);
        },
    });
}

export function useExpireTicket(): UseBaseMutationResult<void, unknown, string> {
    return usePessimisticMutation({
        mutationFn: async (ticketId: string) => {
            const url = `${BASE_URL}/${ticketId}/expire`;

            await blenderGeneral.post(url);
        },
        invalidateKeys: [[SupervisorQueryKey.GetTicketAiReview], [SupervisorQueryKey.GetMetadata]],
    });
}
