import type { AccordionProps } from '@lemonade-hq/blender-ui';
import { Accordion, Card, FormLayout } from '@lemonade-hq/blender-ui';
import type { FC, PropsWithChildren } from 'react';

type ToolEditorFormSectionProps = Pick<
    AccordionProps,
    'badges' | 'className' | 'hasAsteriskMark' | 'subTitle' | 'title'
>;

export const ToolEditorFormSection: FC<PropsWithChildren<ToolEditorFormSectionProps>> = ({
    children,
    title,
    className,
    badges,
    hasAsteriskMark,
    subTitle,
}) => {
    return (
        <Accordion
            badges={badges}
            className={className}
            hasAsteriskMark={hasAsteriskMark}
            isOpenByDefault
            subTitle={subTitle}
            title={title}
        >
            <Card borderRadius="sm" padding="1.2rem" variant="primary">
                <FormLayout>{children}</FormLayout>
            </Card>
        </Accordion>
    );
};
