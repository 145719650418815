import { spacing } from '@lemonade-hq/blender-ui';
import { GENERAL_ERROR_MSG } from '@lemonade-hq/bluis';
import { instanceOfAxiosError } from '@lemonade-hq/bluiza';
import { Flex, Text } from '@lemonade-hq/cdk';
import styled from 'styled-components';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { UnderwritingFiltersEditionViolationsPayload } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

type ViolationsPayloadForEditionType = {
    readonly [EditionType.Coverages]: string[];
    readonly [EditionType.UnderwritingFilters]: UnderwritingFiltersEditionViolationsPayload[];
    readonly [EditionType.DigitalAgent]: never;
    readonly [EditionType.Rating]: never;
};

export interface ViolationErrorData<TEditionType extends EditionType = EditionType> {
    readonly data: {
        readonly details?: {
            readonly editionCode?: string;
            readonly violations?: ViolationsPayloadForEditionType[TEditionType];
        };
    };
    readonly message: string;
}

const StyledUL = styled.ul`
    padding-left: 12px;
`;

const StyledContainer = styled(Flex)`
    flex-direction: column;
    gap: 3px;
    align-items: flex-start;
`;

export const ViolationList: React.FC<{
    readonly violations: string[] | UnderwritingFiltersEditionViolationsPayload[] | null;
    readonly editionType: EditionType;
}> = ({ violations, editionType }) => {
    const content =
        editionType === EditionType.Coverages
            ? (violations as string[]).map(message => <li key={message}>{message}</li>)
            : (violations as UnderwritingFiltersEditionViolationsPayload[]).map(violation => (
                  <li key={violation.explanationName}>
                      {`${violation.explanationName}: ${violation.locales.join(', ')}`}
                  </li>
              ));

    return <StyledUL>{content}</StyledUL>;
};

export const ViolationsErrorMessage: React.FC<{
    readonly message: string;
    readonly editionType: EditionType;
    readonly violations: string[] | UnderwritingFiltersEditionViolationsPayload[] | null;
}> = ({ message, violations, editionType }) => {
    return (
        <StyledContainer>
            <Text>{`${message}${violations ? ':' : ''}`}</Text>
            {violations && <ViolationList editionType={editionType} violations={violations} />}
        </StyledContainer>
    );
};

interface ViolationsWrapperProps {
    readonly error: unknown;
    readonly editionType: EditionType;
}

export const Violations: React.FC<ViolationsWrapperProps> = ({ error, editionType }) => {
    if (instanceOfAxiosError(error) && error.response?.status === 400) {
        const violationErrorData = error.response.data as ViolationErrorData;

        return (
            <ViolationsErrorMessage
                editionType={editionType}
                message={violationErrorData.message}
                violations={violationErrorData.data.details?.violations ?? null}
            />
        );
    }

    return GENERAL_ERROR_MSG;
};

export const GeneralViolations: React.FC<{
    readonly violations: string[];
    readonly title: string;
}> = ({ violations, title }) => {
    const content = violations.map(violation => <li key={violation}>{violation}</li>);

    return (
        <Flex flexDirection="column" gap={spacing.s10}>
            <Text>{title}</Text>
            <StyledUL>{content}</StyledUL>
        </Flex>
    );
};
