import { LIST_ITEM_MENU_COUNT_INTERPOLATION } from '@lemonade-hq/blender-ui';
import { toast } from '@lemonade-hq/bluis';
import { instanceOfAxiosError } from '@lemonade-hq/bluiza';
import { getCountryAlias, getCountryFlag } from '@lemonade-hq/lemonation';
import type { Infer, RecordPath } from '@lemonade-hq/maschema-schema';
import type {
    clientToolSchema,
    CustomerCardSectionName,
    SerializableTool as Tool,
    ToolInstruction,
    ToolParam,
    ToolRegion,
    ToolSchemaKey,
    SerializableToolsRevision as ToolsRevision,
    ToolSubclassifier,
} from '@lemonade-hq/persisted-tools';
import {
    instructionsToString,
    stringToStructuredInstructions,
    stringToStructuredSubclassifiers,
    subclassifiersToString,
    ToolChangeStatus,
    ToolMode,
    ToolParamName,
    ToolProduct,
    ToolSubclassifierActionType,
    ToolSubclassifierType,
    ToolType,
} from '@lemonade-hq/persisted-tools';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import capitalize from 'lodash/capitalize';
import sortBy from 'lodash/sortBy';

export function prettifyField(field: ToolSchemaKey): string {
    // eslint-disable-next-line default-case
    switch (field) {
        case 'name':
        case 'type':
        case 'description':
        case 'region':
        case 'questions':
        case 'macros':
        case 'topic':
        case 'subtopic':
        case 'products':
        case 'notes':
        case 'instructions':
            return capitalize(field);
        case 'params':
            return 'Required parameters';
        case 'userGroups':
            return 'User groups';
        case 'ticketsIdsExamples':
            return 'Tickets examples';
        case 'subClassifiers':
            return 'Sub-classifiers';
        case 'customerCardSections':
            return 'Customer card';
        case 'mode':
            return 'Status';
    }
}

export function prettifyToolMode(status: ToolMode): string {
    // eslint-disable-next-line default-case
    switch (status) {
        case ToolMode.Draft:
            return 'Draft';
        case ToolMode.InProgress:
            return 'Unindexed';
        case ToolMode.Shell:
            return 'Shell';
        case ToolMode.Public:
            return 'Public';
    }
}

export function prettifyToolType(type: ToolType): string {
    // eslint-disable-next-line default-case
    switch (type) {
        case ToolType.DIY:
            return 'DIY';
        case ToolType.GeneralKnowledge:
            return 'General Knowledge';
    }
}

export function prettifyToolProduct(product: ToolProduct): string {
    // eslint-disable-next-line default-case
    switch (product) {
        case ToolProduct.Car:
            return 'Car';
        case ToolProduct.Homeowners:
            return 'Homeowners';
        case ToolProduct.Life:
            return 'Life';
        case ToolProduct.Pet:
            return 'Pet';
        case ToolProduct.Renters:
            return 'Renters';
    }
}

export function prettifyToolParam(param: ToolParamName): string {
    // eslint-disable-next-line default-case
    switch (param) {
        case ToolParamName.PolicyId: {
            return 'Policy ID';
        }
    }
}

export function prettifyToolRegion(region?: ToolRegion): string {
    if (region == null) return '';

    return `${getCountryAlias(region) ?? region} ${getCountryFlag(region)}`;
}

export function prettifyString(val: string): string {
    return snakeCaseToReadable(val);
}

const SECTIONS_ORDER: ToolMode[] = [ToolMode.InProgress, ToolMode.Shell, ToolMode.Draft, ToolMode.Public];

interface ToolsSection {
    readonly label: string;
    readonly id: ToolMode;
    readonly sideLabel: string;
    readonly options: {
        readonly id: string;
        readonly label: string;
        readonly sideLabel?: string;
        readonly color?: 'error' | undefined;
    }[];
}

export function getToolSideLabel(changeStatus: ToolChangeStatus): string | undefined {
    // eslint-disable-next-line default-case
    switch (changeStatus) {
        case ToolChangeStatus.Deleted:
            return '(deleted)';
        case ToolChangeStatus.New:
            return '(new)';
        case ToolChangeStatus.Edited:
            return '(edited)';
        case ToolChangeStatus.Cloned:
            return '';
    }
}

export function sortToolsBySections(toolsRevision: ToolsRevision | undefined): ToolsSection[] {
    const sortedTools = sortBy(toolsRevision?.tools, 'name');
    const sectionsMap = new Map<string, ToolsSection>();
    sortedTools.forEach(tool => {
        const section = sectionsMap.get(tool.mode) ?? {
            label: prettifyToolMode(tool.mode),
            id: tool.mode,
            sideLabel: `${LIST_ITEM_MENU_COUNT_INTERPOLATION} tools`,
            options: [],
        };
        section.options.push({
            id: tool.name,
            label: tool.name,
            sideLabel: getToolSideLabel(tool.changeStatus),
            color:
                tool.changeStatus !== ToolChangeStatus.Deleted && tool.validStatus?.valid === false
                    ? 'error'
                    : undefined,
        });
        sectionsMap.set(tool.mode, section);
    });
    const nonSortedSections = Array.from(sectionsMap.values());
    const sortedSections = sortBy(nonSortedSections, section => SECTIONS_ORDER.indexOf(section.id));
    return sortedSections;
}

export function getToolsThatRerouteToTool(tool: Tool, toolsRevision?: ToolsRevision): Tool[] {
    return (
        toolsRevision?.tools.filter(
            t =>
                (tool.name !== t.name &&
                    t.subClassifiers?.some(
                        sc =>
                            sc.type === ToolSubclassifierType.Standard &&
                            sc.thenAction.type === ToolSubclassifierActionType.Reroute &&
                            sc.thenAction.toolName === tool.name
                    )) ??
                false
        ) ?? []
    );
}

// Updates the CCs schema value and converts it to an array of CC names
function generateUpdatedCcSections(
    key: string,
    value: boolean,
    customerCardSections: Infer<typeof clientToolSchema>['customerCardSections']
): CustomerCardSectionName[] {
    const sectionName = key.split('.')[1] as CustomerCardSectionName | undefined;
    if (sectionName == null) return [];

    const updatedCustomerCardSections = {
        ...customerCardSections,
        [sectionName]: value,
    } as Record<CustomerCardSectionName, boolean>;

    return Object.keys(updatedCustomerCardSections).filter(
        name => updatedCustomerCardSections[name as CustomerCardSectionName]
    ) as CustomerCardSectionName[];
}

export function transformSchemaValueChange<TKey extends RecordPath<typeof clientToolSchema>>(
    key: TKey,
    value: unknown,
    values: Infer<typeof clientToolSchema>
): Partial<Tool> {
    if (key.includes('customerCardSections')) {
        return {
            customerCardSections: generateUpdatedCcSections(key, value as boolean, values.customerCardSections),
        };
    }

    switch (key) {
        case 'params': {
            return value != null
                ? { params: [{ name: value as ToolParamName, description: '', required: true } satisfies ToolParam] }
                : { params: [] };
        }

        case 'ticketsIdsExamples': {
            return {
                [key]:
                    value != null
                        ? (value as string)
                              .split(',')
                              .map(v => v.trim())
                              .filter(Boolean)
                        : [],
            };
        }

        // in order to eliminate sending partial subclassifier data from another subclassifier type
        case 'subClassifiers': {
            return {
                [key]: stringToStructuredSubclassifiers(subclassifiersToString(value as ToolSubclassifier[])),
            };
        }

        // in order to eliminate sending partial instruction data from another instruction type
        case 'instructions': {
            return {
                [key]: stringToStructuredInstructions(instructionsToString(value as ToolInstruction[])),
            };
        }

        default: {
            return { [key]: value };
        }
    }
}

// deleted tools without a parent tool means they are just new in this tool revision, hence they are ignored rather than actually deleted
export function actuallyDeletedTool(tool: Tool): boolean {
    return tool.changeStatus === ToolChangeStatus.Deleted && tool.parentToolPublicId != null;
}

export function formatChangedTools(toolsRevision: ToolsRevision): string {
    return (
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions -- so empty array are displayed as '-'
        toolsRevision.tools
            .filter(t => t.changeStatus !== ToolChangeStatus.Cloned)
            .map(t => t.name)
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- so empty array are displayed as '-'
            .join(', ') || '-'
    );
}

export function displayError(messagePrefix: string) {
    return (e: unknown): void => {
        if (instanceOfAxiosError(e)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            toast.error(`${messagePrefix}: ${e.response?.data.data}`);
        } else {
            toast.error(`${messagePrefix}: ${e as string}`);
        }
    };
}

export function safeDisplayAs<T>(callback: (item: T) => string): (item: T) => string {
    return (item: T) => {
        try {
            return callback(item);
        } catch {
            return '<INVALID>';
        }
    };
}

export function getInvalidToolsNamesFromToolsRevision(toolsRevision: ToolsRevision | undefined): string[] {
    return (
        toolsRevision?.tools
            .filter(t => t.changeStatus !== ToolChangeStatus.Deleted && t.validStatus?.valid === false)
            .map(t => t.name) ?? []
    );
}
