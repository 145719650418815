import type { FC } from 'react';
import { useCallback } from 'react';
import { ManageABTestDialog } from '../../../common/components/ABTestDialogs/ManageABTestDialog';
import { RemoveABTestDialog } from '../../../common/components/ABTestDialogs/RemoveABTestDialog';
import { RenameVariantsDialog } from '../../../common/components/ABTestDialogs/RenameVariantsDialog';
import type { RenameVariantsValues } from '../../../common/components/ABTestDialogs/utils';
import { DialogType } from '../../../common/components/ABTestDialogs/utils';
import { isABTestRuleGroup } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import type { UnderwritingFlagRulesGroup } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import {
    useAddVariants,
    useAttachABTest,
    useRemoveVariants,
    useRenameVariants,
    useUnbindABTest,
} from 'queries/LoCo/Insurance/UnderwritingFiltersEditionQueries';

interface ABTestDialogsProps {
    readonly dialogType: DialogType;
    readonly editionCode: string;
    readonly rulesGroup: UnderwritingFlagRulesGroup;
    readonly flagCode: string;
    readonly onClose: () => void;
}

export const ABTestDialogs: FC<ABTestDialogsProps> = ({ dialogType, editionCode, rulesGroup, flagCode, onClose }) => {
    const { mutateAsync: attachABTest, isPending: isAttachPending } = useAttachABTest(editionCode);
    const { mutateAsync: addVariants, isPending: isAddPending } = useAddVariants(editionCode);
    const { mutateAsync: removeVariants, isPending: isRemovePending } = useRemoveVariants(editionCode);
    const { mutateAsync: renameVariants, isPending: isRenamePending } = useRenameVariants(editionCode);
    const { mutateAsync: unbindABTest, isPending: isUnbindPending } = useUnbindABTest(editionCode);

    const isPending = isAttachPending || isAddPending || isRemovePending || isRenamePending || isUnbindPending;

    const submitABTest = useCallback(
        async (values: Record<string, string[] | string>) => {
            let variantsToAdd: string[] = [],
                variantsToRemove: string[] = [];
            if (isABTestRuleGroup(rulesGroup)) {
                variantsToAdd = (values.variants as string[]).filter(
                    variant => !rulesGroup.variants.some(v => v.variantName === variant)
                );
                variantsToRemove = rulesGroup.variants
                    .filter(v => !values.variants.includes(v.variantName))
                    .map(v => v.variantName);
            }

            switch (dialogType) {
                case DialogType.AddABTest:
                    await attachABTest({
                        uwFiltersEditionCode: editionCode,
                        flagCode,
                        payload: {
                            experimentName: values.experimentName as string,
                            control: values.variants[0],
                            variants: (values.variants as string[]).slice(1),
                        },
                    });
                    break;
                case DialogType.AddTestGroups:
                    await addVariants({
                        uwFiltersEditionCode: editionCode,
                        flagCode,
                        payload: {
                            variantNames: variantsToAdd,
                        },
                    });
                    break;
                case DialogType.RemoveTestGroups:
                    await removeVariants({
                        uwFiltersEditionCode: editionCode,
                        flagCode,
                        payload: {
                            variantNames: variantsToRemove,
                        },
                    });
                    break;
                default:
            }
        },
        [addVariants, attachABTest, dialogType, editionCode, flagCode, removeVariants, rulesGroup]
    );

    const submitRenameVariants = useCallback(
        async (values: RenameVariantsValues) => {
            if (isABTestRuleGroup(rulesGroup)) {
                const payload = {
                    experimentName: values.experimentName,
                    variants: values.variants,
                };
                await renameVariants({
                    uwFiltersEditionCode: editionCode,
                    flagCode,
                    payload,
                });
            }
        },
        [editionCode, flagCode, renameVariants, rulesGroup]
    );

    const removeABTest = useCallback(
        async ({ variantToKeep }: { variantToKeep: string }) => {
            await unbindABTest({
                uwFiltersEditionCode: editionCode,
                flagCode,
                payload: { variantToKeep },
            });
        },
        [editionCode, flagCode, unbindABTest]
    );

    switch (dialogType) {
        case DialogType.RenameVariants:
            return (
                <RenameVariantsDialog
                    isLoading={isPending}
                    item={isABTestRuleGroup(rulesGroup) ? rulesGroup : undefined}
                    onClose={onClose}
                    onSubmit={submitRenameVariants}
                />
            );
        case DialogType.RemoveABTest:
            return (
                <RemoveABTestDialog
                    isLoading={isPending}
                    item={isABTestRuleGroup(rulesGroup) ? rulesGroup : undefined}
                    onClose={onClose}
                    onSubmit={removeABTest}
                />
            );
        default:
            return (
                <ManageABTestDialog
                    isLoading={isPending}
                    item={isABTestRuleGroup(rulesGroup) ? rulesGroup : undefined}
                    onClose={onClose}
                    onSubmit={submitABTest}
                    type={dialogType}
                />
            );
    }
};
