import type { CoreExpression, Expression, Identifier as JsepIdentifier } from 'jsep';
import jsep from 'jsep';
import { randomExpressionId } from './ExpressionSimpleEditorShared';
import type {
    ArrayExpressionNode,
    BinaryExpressionNode,
    CallExpressionNode,
    ExpressionNode,
    InputExpressionNode,
    LiteralNode,
    LogicalExpressionNode,
} from './expressionTypes';
import { ExpressionType, LogicalOperator } from './expressionTypes';
import { BOOLEAN_VALUES, COMPARE_OPERATORS, INPUT_OPERATORS } from './operators';

const SUPPORTED_FUNCTIONS = [...Object.values(COMPARE_OPERATORS).flatMap(Object.keys), ...Object.keys(INPUT_OPERATORS)];
const INPUT_FUNCTIONS = Object.keys(INPUT_OPERATORS);
const LOGICAL_EXPRESSION_VALID_CHILDREN = ['BinaryExpression', 'CallExpression', 'LogicalExpression'];

const Identifier = 'Identifier';

export function recordsToString(expressions: Record<string, ExpressionNode>, root: string): string {
    if (!root) return '';

    return astToString(recordsToAst(expressions, root));
}

export function astToString(node: Expression): string {
    const coreNode = node as CoreExpression;

    switch (coreNode.type) {
        case ExpressionType.Literal:
            return node.value as string;

        case ExpressionType.BinaryExpression:
            return `(${astToString(node.left as CoreExpression)} ${node.operator?.toString()} ${astToString(
                node.right as CoreExpression
            )})`;

        case ExpressionType.CallExpression:
            return `${astToString(coreNode.callee)}(${coreNode.arguments.map(arg => astToString(arg)).join(', ')})`;

        case ExpressionType.ArrayExpression:
            return `[${coreNode.elements.map(element => astToString(element)).join(', ')}]`;

        case Identifier:
            return coreNode.name;

        case 'Compound':
            return coreNode.body.map(astToString).join(' ');

        // Add cases for other types of expressions as needed
        default:
            throw new Error(`Unknown node type: ${node.type}`);
    }
}

export function recordsToAst(expressions: Record<string, ExpressionNode>, root: string): CoreExpression {
    switch (expressions[root].type) {
        case ExpressionType.LogicalExpression:
        case ExpressionType.BinaryExpression: {
            const node = expressions[root] as BinaryExpressionNode;

            return {
                type: 'BinaryExpression',
                operator: node.operator,
                left: recordsToAst(expressions, node.children[0]),
                right: recordsToAst(expressions, node.children[1]),
            };
        }

        case ExpressionType.CallExpression: {
            const node = expressions[root] as CallExpressionNode;

            return {
                type: 'CallExpression',
                callee: {
                    type: Identifier,
                    name: node.callee,
                },
                arguments: node.children.map(child => recordsToAst(expressions, child)),
            };
        }

        case ExpressionType.ArrayExpression: {
            const node = expressions[root] as ArrayExpressionNode;

            return {
                type: 'ArrayExpression',
                elements: node.children.map(child => recordsToAst(expressions, child)),
            };
        }

        case ExpressionType.InputExpression: {
            const node = expressions[root] as InputExpressionNode;

            return {
                type: 'CallExpression',
                callee: {
                    type: Identifier,
                    name: node.callee,
                },
                arguments: [
                    {
                        type: 'Literal',
                        value: `'${node.value}'`,
                        raw: node.value,
                    },
                ],
            };
        }

        case ExpressionType.Literal: {
            const node = expressions[root] as LiteralNode;

            const value =
                isNaN(Number(node.value)) && !BOOLEAN_VALUES.includes(node.value) ? `'${node.value}'` : node.value;

            return {
                type: 'Literal',
                value: value,
                raw: value.toString(),
            };
        }

        default:
            throw new Error(`Unknown node type`);
    }
}

function isInputFunction(callee: Expression, additionalFunctions: string[]): callee is JsepIdentifier {
    const allFunctions = [...INPUT_FUNCTIONS, ...additionalFunctions];
    return allFunctions.includes((callee as JsepIdentifier).name);
}

function isSupportedFunction(callee: Expression, supportedFunctions: string[] = []): callee is JsepIdentifier {
    const allFunctions = [...SUPPORTED_FUNCTIONS, ...supportedFunctions];
    return allFunctions.includes((callee as JsepIdentifier).name);
}

export function jsepToExpressionNodes(
    node: CoreExpression,
    id: string,
    additionalFunctions: string[] = [],
    parent?: string
): Record<string, ExpressionNode> {
    switch (node.type) {
        case ExpressionType.BinaryExpression: {
            const binaryNode = node;

            const firstChildId = randomExpressionId();
            const secondChildId = randomExpressionId();

            const children = {
                ...jsepToExpressionNodes(binaryNode.left as CoreExpression, firstChildId, additionalFunctions, id),
                ...jsepToExpressionNodes(binaryNode.right as CoreExpression, secondChildId, additionalFunctions, id),
            };

            const type = Object.values(LogicalOperator).includes(binaryNode.operator as LogicalOperator)
                ? ExpressionType.LogicalExpression
                : ExpressionType.BinaryExpression;

            return {
                [id]: {
                    type: type,
                    operator: binaryNode.operator as LogicalOperator,
                    children: [firstChildId, secondChildId],
                    parent,
                },
                ...children,
            };
        }

        case ExpressionType.CallExpression: {
            const callNode = node as CoreExpression & { callee: CoreExpression; arguments: CoreExpression[] };

            if (isInputFunction(callNode.callee, additionalFunctions)) {
                const value = callNode.arguments[0].value as string;
                return {
                    [id]: {
                        type: ExpressionType.InputExpression,
                        callee: callNode.callee.name,
                        value,
                        parent,
                    },
                };
            }

            const childrenIds: string[] = [];

            const children = callNode.arguments.reduce(
                (acc, arg) => {
                    const childId = randomExpressionId();
                    childrenIds.push(childId);
                    return { ...acc, ...jsepToExpressionNodes(arg, childId, additionalFunctions, id) };
                },
                {} as Record<string, ExpressionNode>
            );

            return {
                [id]: {
                    type: ExpressionType.CallExpression,
                    callee: (callNode.callee as CoreExpression).name as string,
                    children: childrenIds,
                    parent,
                },
                ...children,
            };
        }

        case ExpressionType.ArrayExpression: {
            const arrayNode = node as CoreExpression & { elements: CoreExpression[] };

            const childrenIds: string[] = [];

            const children = arrayNode.elements.reduce(
                (acc, arg) => {
                    const childId = randomExpressionId();
                    childrenIds.push(childId);
                    return { ...acc, ...jsepToExpressionNodes(arg, childId, additionalFunctions, id) };
                },
                {} as Record<string, ExpressionNode>
            );

            return {
                [id]: {
                    type: ExpressionType.ArrayExpression,
                    children: childrenIds,
                    parent,
                },
                ...children,
            };
        }

        case ExpressionType.Literal: {
            return {
                [id]: {
                    type: ExpressionType.Literal,
                    value: node.value as string,
                    parent,
                },
            };
        }

        default:
            throw new Error(`Unknown node type: ${node.type}`);
    }
}

interface ValidationResult {
    readonly valid: boolean;
    readonly message?: string;
}

function isInitialExpressionValid(expression: CoreExpression, additionalFunctions: string[]): ValidationResult {
    if (!['BinaryExpression', 'CallExpression'].includes(expression.type)) {
        return { valid: false, message: 'Expression must be a binary or call expression' };
    }

    if (
        expression.type === 'CallExpression' &&
        expression.callee.type === Identifier &&
        [...additionalFunctions, 'input'].includes(expression.callee.name as string)
    ) {
        return { valid: false, message: 'Function is not valid' };
    }

    return { valid: true };
}

export function validateSimpleTransition(expression: string, additionalFunctions: string[] = []): ValidationResult {
    let ast: CoreExpression;

    if (expression === '') return { valid: true };

    try {
        ast = jsep(expression) as CoreExpression;
    } catch (e) {
        return { valid: false, message: 'Could not parse expression' };
    }

    let logicalOperator: string | null = null;

    const initialValidation = isInitialExpressionValid(ast, additionalFunctions);
    if (!initialValidation.valid) return initialValidation;

    function validate(node: CoreExpression): string | true {
        switch (node.type) {
            case 'BinaryExpression': {
                if (Object.values(LogicalOperator).includes(node.operator as LogicalOperator)) {
                    if (logicalOperator === null) {
                        logicalOperator = node.operator;
                    } else if (logicalOperator !== node.operator) {
                        return 'Cannot have both And and Or operators in the same expression';
                    }

                    if (
                        !LOGICAL_EXPRESSION_VALID_CHILDREN.includes(node.left.type) &&
                        !LOGICAL_EXPRESSION_VALID_CHILDREN.includes(node.right.type)
                    ) {
                        return 'Both sides of logical expression must be boolean expressions';
                    }
                } else {
                    const validCompare = SUPPORTED_FUNCTIONS.includes(node.operator);
                    const validArithmetic = ['+', '-', '*', '/'].includes(node.operator);

                    if (!validCompare && !validArithmetic) return `operator ${node.operator} could not be parsed`;
                }

                const left = validate(node.left as CoreExpression);
                if (left !== true) return left;

                const right = validate(node.right as CoreExpression);
                if (right !== true) return right;

                return true;
            }

            case 'CallExpression': {
                const callee = node.callee as CoreExpression;

                if (callee.type !== Identifier) return 'Function is not valid';
                if (!isSupportedFunction(node.callee, additionalFunctions))
                    return `Cannot parse expression with function ${node.callee.name?.toString()}`;

                for (const arg of node.arguments) {
                    const result = validate(arg as CoreExpression);
                    if (result !== true) return result;
                }

                return true;
            }

            case 'ArrayExpression':
                for (const arg of node.elements) {
                    const result = validate(arg as CoreExpression);
                    if (result !== true) return result;
                }

                return true;

            case 'Literal':
                return true;

            default:
                return `Could not parse expression ${JSON.stringify(node)}`;
        }
    }

    const validationResult = validate(ast);

    if (validationResult === true) {
        return { valid: true };
    }

    return { valid: false, message: validationResult };
}

export function validateExpression(expressions: Record<string, ExpressionNode>, root: string): boolean {
    if (!root) return true;

    switch (expressions[root].type) {
        case ExpressionType.LogicalExpression:
        case ExpressionType.BinaryExpression: {
            const node = expressions[root] as BinaryExpressionNode | LogicalExpressionNode;

            if (!node.operator || !node.children.length) return false;
            if (node.type === ExpressionType.BinaryExpression && node.children.length < 2) return false;

            return node.children.every(child => validateExpression(expressions, child));
        }

        case ExpressionType.CallExpression: {
            const node = expressions[root] as CallExpressionNode;

            if (!node.callee || !node.children.length) return false;

            return node.children.every(child => validateExpression(expressions, child));
        }

        case ExpressionType.ArrayExpression: {
            const node = expressions[root] as ArrayExpressionNode;
            if (node.children.length === 0) return false;

            return node.children.every(child => validateExpression(expressions, child));
        }

        case ExpressionType.InputExpression: {
            const node = expressions[root] as InputExpressionNode;
            return Boolean(node.value);
        }

        case ExpressionType.Literal: {
            const node = expressions[root] as LiteralNode;

            return Boolean(node.value);
        }

        default:
            return false;
    }
}
