export enum LogicalOperator {
    AND = '&&',
    OR = '||',
}

interface ExpressionNodeBase {
    readonly type: ExpressionType;
    readonly parent?: string;
    readonly placeholder?: string;
}

export interface BinaryExpressionNode extends ExpressionNodeBase {
    readonly type: ExpressionType.BinaryExpression;
    readonly operator: string;
    readonly children: string[];
}

export interface LogicalExpressionNode extends ExpressionNodeBase {
    readonly type: ExpressionType.LogicalExpression;
    readonly operator: LogicalOperator;
    readonly children: string[];
}

export interface CallExpressionNode extends ExpressionNodeBase {
    readonly type: ExpressionType.CallExpression;
    readonly callee: string;
    readonly children: string[];
}

export interface InputExpressionNode extends ExpressionNodeBase {
    readonly type: ExpressionType.InputExpression;
    readonly callee: string;
    readonly value: string;
}

export interface ArrayExpressionNode extends ExpressionNodeBase {
    readonly type: ExpressionType.ArrayExpression;
    readonly children: string[];
}

export interface LiteralNode extends ExpressionNodeBase {
    readonly type: ExpressionType.Literal;
    readonly value: string;
}

export type ExpressionNode =
    | ArrayExpressionNode
    | BinaryExpressionNode
    | CallExpressionNode
    | InputExpressionNode
    | LiteralNode
    | LogicalExpressionNode;

export type ExpressionTree = Record<string, ExpressionNode>;

export enum ExpressionType {
    BinaryExpression = 'BinaryExpression',
    LogicalExpression = 'LogicalExpression',
    CallExpression = 'CallExpression',
    ArrayExpression = 'ArrayExpression',
    InputExpression = 'InputExpression',
    Literal = 'Literal',
}

export enum ArgumentType {
    Number = 'number',
    String = 'string',
    Unknown = 'null',
    Enum = 'enum',
    Boolean = 'boolean',
}

export type EnumArgument = {
    readonly type: ArgumentType.Enum;
    readonly symbols: { readonly label: string; readonly value: string }[];
    readonly enumName: string;
};

export type BooleanArgument = {
    readonly type: ArgumentType.Boolean;
};

export type NumberArgument = {
    readonly type: ArgumentType.Number;
};

export type StringArgument = {
    readonly type: ArgumentType.String;
};

export type UnknownArgument = {
    readonly type: ArgumentType.Unknown;
};

export type Argument = BooleanArgument | EnumArgument | NumberArgument | StringArgument | UnknownArgument;

export function nodeHasChildren(
    node: ExpressionNode
): node is BinaryExpressionNode | CallExpressionNode | LogicalExpressionNode {
    return 'children' in node;
}

export function isLogicalExpression(node: ExpressionNode): node is LogicalExpressionNode {
    return node.type === ExpressionType.LogicalExpression;
}

export function isCallOrBinaryExpression(node: ExpressionNode): node is BinaryExpressionNode | CallExpressionNode {
    return node.type === ExpressionType.CallExpression || node.type === ExpressionType.BinaryExpression;
}
