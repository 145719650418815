import type { RefObject } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useResizableStoredState } from './useResizableStoredState';

interface UseResizableWithCollapseProps {
  readonly minWidth?: number;
  readonly maxWidth?: number;
  readonly initialWidth: number;

  // collapse behavior
  readonly collapseThreshold?: number;
  readonly collapsedWidth?: number;

  // Persistence
  readonly storageKey?: string;
  // Required DOM references
  readonly containerRef: RefObject<HTMLElement>;
  readonly elementRef: RefObject<HTMLElement>;
  readonly handleRef: RefObject<HTMLElement>;
  // additional configurations
  readonly config?: {
    readonly transitionDuration?: number;
  };
}

interface UseResizableWithCollapseReturn {
  readonly isCollapsed: boolean;
  readonly open: () => void;
}

function calculateContainerRightEdge(containerRef: RefObject<HTMLElement>): number {
  if (!containerRef.current) return 0;

  const computedStyle = window.getComputedStyle(containerRef.current);
  const paddingRight = parseInt(computedStyle.paddingRight, 0);
  return containerRef.current.getBoundingClientRect().right - paddingRight;
}

function determineNewWidth(
  newWidth: number,
  minWidth: number,
  maxWidth: number,
  isCollapsable: boolean,
  collapseThreshold?: number,
  collapsedWidth = 0,
): number {
  // Collapse if below threshold and collapsing is possible
  if (isCollapsable && collapseThreshold != null && newWidth < collapseThreshold) {
    return collapsedWidth;
  }

  // Constrain width between min and max
  return Math.max(minWidth, Math.min(newWidth, maxWidth));
}

export const useResizableWithCollapse = ({
  minWidth = 0,
  maxWidth = Infinity,
  initialWidth,
  storageKey,
  collapsedWidth = 0,
  collapseThreshold,
  containerRef,
  elementRef,
  handleRef,
  config = {},
}: UseResizableWithCollapseProps): UseResizableWithCollapseReturn => {
  const { width, setWidth, resetWidth } = useResizableStoredState({
    initialWidth,
    minWidth,
    maxWidth,
    storageKey,
    collapsedWidth,
  });

  const isResizing = useRef(false);
  const isCollapsable = collapseThreshold != null;
  const { transitionDuration = 0.2 } = config;

  // Simplified resize handling
  const handleResize = useCallback((): void => {
    if (elementRef.current) {
      // Direct style manipulation
      elementRef.current.style.width = `${width}px`;
      elementRef.current.style.transition = width <= minWidth ? `width ${transitionDuration}s` : 'none';
    }
  }, [minWidth, width, elementRef, transitionDuration]);

  // Resize observer effect
  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [handleResize, elementRef]);

  // Mouse move handler
  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLElement>): void => {
      if (!isResizing.current || !containerRef.current) return;

      // Calculate new width based on container's right edge
      const containerOffsetRight = calculateContainerRightEdge(containerRef);
      const newWidth = containerOffsetRight - e.clientX;

      // Determine the appropriate width considering collapse and min/max constraints
      const newWidthToSet = determineNewWidth(
        newWidth,
        minWidth,
        maxWidth,
        isCollapsable,
        collapseThreshold,
        collapsedWidth,
      );

      setWidth(newWidthToSet);
    },
    [collapseThreshold, collapsedWidth, isCollapsable, maxWidth, minWidth, setWidth, containerRef],
  );

  // Mouse event handlers
  const handleMouseUp = useCallback((): void => {
    if (isResizing.current) {
      isResizing.current = false;
      document.body.style.cursor = '';
      document.body.style.userSelect = '';
      handleRef.current?.setAttribute('data-resizing', 'false');
    }

    document.removeEventListener('mousemove', handleMouseMove as unknown as EventListener);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove, handleRef]);

  useEffect(() => {
    const handleElement = handleRef.current;

    const startResize = (): void => {
      // Set mouse move and up events on document,
      document.addEventListener('mousemove', handleMouseMove as unknown as EventListener);
      document.addEventListener('mouseup', handleMouseUp);

      isResizing.current = true;
      document.body.style.cursor = 'col-resize';
      document.body.style.userSelect = 'none';
      handleRef.current?.setAttribute('data-resizing', 'true');
    };

    // Set mouse down event on draggable handle
    if (handleElement) {
      handleElement.addEventListener('mousedown', startResize);
    }

    return () => {
      if (handleElement) {
        handleElement.removeEventListener('mousedown', startResize);
      }

      document.removeEventListener('mousemove', handleMouseMove as unknown as EventListener);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleRef, handleMouseMove, handleMouseUp]);

  return {
    isCollapsed: width <= collapsedWidth,
    open: resetWidth,
  };
};
