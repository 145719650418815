import { Flex, Pagination, spacing, usePagination } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { useEffect } from 'react';
import type { PaginationStats } from './types';

export const GenericQueuePagination: FC<{
    readonly stats: PaginationStats;
    readonly setPage: (page: number) => void;
}> = ({ stats, setPage }) => {
    const { totalPages, currentPage } = stats;
    const { selectedPage, handleNextClick, handlePrevClick, handleFirstClick, handleLastClick } = usePagination({
        currentPage,
        totalPages,
    });

    useEffect(() => {
        if (selectedPage !== currentPage) {
            setPage(selectedPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPage]);

    return (
        <Flex padding={`${spacing.s12} ${spacing.s16}`}>
            <Pagination
                currentPage={selectedPage}
                onClickFirst={handleFirstClick}
                onClickLast={handleLastClick}
                onClickNext={handleNextClick}
                onClickPrev={handlePrevClick}
                totalPages={totalPages}
            />
        </Flex>
    );
};
