import {
    Accordion,
    Button,
    Flex,
    spacing,
    SummarySection,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from '@lemonade-hq/blender-ui';
import { Alert, AlertMode } from '@lemonade-hq/bluis';
import { useCallback, useMemo, useState } from 'react';
import { DialogType } from '../../common/components/ABTestDialogs/utils';
import { ABTestDialogs } from './dialogs/ABTestDialogs';
import type { RuleActionData } from './dialogs/UnderwritingRulesDialogs';
import { RulesTable } from './rules-group/RulesTable';
import * as styles from './rules-group/rulesTable.css';
import { UnderwritingDialogType } from './UnderwritingFiltersShared';
import { useOrderableTable } from 'components/LoCo/common/components/Table/useOrderableTable';
import { getOutcomeText } from 'components/LoCo/common/display-texts/underwriting-rules';
import { isABTestRuleGroup, VariantType } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import type {
    UnderwritingDecisionLifecycleContext,
    UnderwritingDeclineRule,
    UnderwritingFlagRulesGroup,
} from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import { useReorderUnderwritingFiltersEditionRule } from 'queries/LoCo/Insurance/UnderwritingFiltersEditionQueries';

interface PreviewEntityAccordionProps {
    readonly title: string;
    readonly description: string;
    readonly rulesGroup: UnderwritingDeclineRule[] | UnderwritingFlagRulesGroup;
    readonly editionCode: string;
    readonly readonly: boolean;
    readonly type: 'decline' | 'flag';
    readonly hideActions: boolean;
    readonly lifecycle?: UnderwritingDecisionLifecycleContext;
    readonly onAction: (actionData: RuleActionData) => void;
}

export const PreviewEntityAccordion: React.FC<PreviewEntityAccordionProps> = ({
    lifecycle,
    title,
    description,
    rulesGroup,
    editionCode,
    type,
    hideActions,
    readonly,
    onAction,
}) => {
    const lifecycleLabel = lifecycle != null ? getOutcomeText(lifecycle) : '';
    const [selectedVariantTab, setSelectedVariantTab] = useState(0);

    const { mutateAsync, isPending } = useReorderUnderwritingFiltersEditionRule(editionCode, 'decline');

    const [dialogType, setDialogType] = useState<DialogType>();

    const sortedVariants = useMemo(() => {
        if (Array.isArray(rulesGroup) || !isABTestRuleGroup(rulesGroup)) {
            return undefined;
        }

        return rulesGroup.variants.sort((a, _) => (a.variantType === VariantType.Control ? -1 : 1));
    }, [rulesGroup]);

    const selectedVariantIndex =
        sortedVariants != null && sortedVariants.length <= selectedVariantTab ? 0 : selectedVariantTab;

    const rules = useMemo(() => {
        if (Array.isArray(rulesGroup)) {
            return rulesGroup;
        }

        if (!isABTestRuleGroup(rulesGroup)) {
            return rulesGroup.rules;
        }

        return sortedVariants?.[selectedVariantIndex]?.rules ?? [];
    }, [rulesGroup, selectedVariantIndex, sortedVariants]);

    const { getClassNameForItem, handleReorder, isReorderMode, reorderButton } = useOrderableTable(
        rules.map(r => r.publicId),
        async (rulePublicId, order) => await mutateAsync({ rulePublicId, destination: order })
    );

    const onActionRequested = useCallback((): void => {
        if (type === 'decline' && lifecycle != null) {
            onAction({
                type: UnderwritingDialogType.AddDeclineRule,
                lifecycleContext: lifecycle,
            });
        } else {
            onAction({
                type: UnderwritingDialogType.AddFlagRule,
                data: {
                    flagCode: (rulesGroup as UnderwritingFlagRulesGroup).flagCode,
                    variantName: sortedVariants?.[selectedVariantIndex].variantName,
                },
            });
        }
    }, [lifecycle, onAction, rulesGroup, selectedVariantIndex, sortedVariants, type]);

    const groupActions = useMemo(() => {
        if (!readonly && !Array.isArray(rulesGroup)) {
            if (isABTestRuleGroup(rulesGroup)) {
                const actions = [{ label: 'Add test groups', onClick: () => setDialogType(DialogType.AddTestGroups) }];

                if (sortedVariants != null && sortedVariants.length > 2) {
                    actions.push({
                        label: 'Remove test groups',
                        onClick: () => setDialogType(DialogType.RemoveTestGroups),
                    });
                }

                actions.push({
                    label: 'Edit test identifiers',
                    onClick: () => setDialogType(DialogType.RenameVariants),
                });
                actions.push({ label: 'Remove A/B test', onClick: () => setDialogType(DialogType.RemoveABTest) });

                return actions;
            } else {
                return [{ label: 'Add A/B Test', onClick: () => setDialogType(DialogType.AddABTest) }];
            }
        }
    }, [readonly, rulesGroup, sortedVariants]);

    return (
        <>
            <Accordion
                badges={
                    !Array.isArray(rulesGroup) && isABTestRuleGroup(rulesGroup)
                        ? [
                              {
                                  label: 'A/B Test',
                                  tooltip: rulesGroup.experimentName,
                              },
                          ]
                        : undefined
                }
                title={title}
            >
                <Flex flexDirection="column" gap={spacing.s08} padding="1.2rem" width="100%">
                    <SummarySection title="Description">
                        <Text>{description}</Text>
                    </SummarySection>
                    <SummarySection
                        actions={groupActions}
                        badges={
                            !Array.isArray(rulesGroup) && isABTestRuleGroup(rulesGroup)
                                ? [
                                      {
                                          label: 'A/B Test',
                                          tooltipProps: {
                                              content: rulesGroup.experimentName,
                                          },
                                      },
                                  ]
                                : undefined
                        }
                        title="Rules"
                    >
                        <Flex flexDirection="column" gap={spacing.s08} width="100%">
                            {!Array.isArray(rulesGroup) && isABTestRuleGroup(rulesGroup) && !readonly && (
                                <Alert
                                    mode={AlertMode.Info}
                                    title="Adding, editing, removing, or reordering rules must be done separately for each variant"
                                />
                            )}
                            <Flex flexDirection="column" style={{ position: 'relative' }} width="100%">
                                <Flex
                                    flexDirection="row-reverse"
                                    gap={spacing.s12}
                                    style={{ position: 'absolute', zIndex: 1 }}
                                    width="100%"
                                >
                                    {!hideActions && (
                                        <Button
                                            label={type === 'flag' ? '+ Add Segment' : `+ Add ${lifecycleLabel} Rule`}
                                            onClick={onActionRequested}
                                            variant="secondary"
                                        />
                                    )}
                                    {!hideActions && type === 'decline' && reorderButton}
                                </Flex>
                                {Array.isArray(rulesGroup) ? (
                                    <Tabs variant="inline">
                                        <TabList>
                                            <Tab>All Rules</Tab>
                                        </TabList>
                                        <TabPanels>
                                            <TabPanel>
                                                <RulesTable
                                                    className={styles.tableWrapper}
                                                    editionCode={editionCode}
                                                    getReorderedRowClassName={getClassNameForItem}
                                                    handleReorder={handleReorder}
                                                    hideActions={hideActions}
                                                    isLoading={isPending}
                                                    isReorderMode={isReorderMode}
                                                    lifecycle={lifecycle}
                                                    onAction={onAction}
                                                    rules={rules}
                                                    type={type}
                                                />
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                ) : isABTestRuleGroup(rulesGroup) ? (
                                    <Tabs key={rulesGroup.variants.length} variant="inline">
                                        <TabList>
                                            {rulesGroup.variants.map((variant, index) => (
                                                <Tab
                                                    key={variant.variantName}
                                                    onClick={() => setSelectedVariantTab(index)}
                                                >
                                                    {variant.variantName}
                                                </Tab>
                                            ))}
                                        </TabList>
                                        <TabPanels>
                                            {rulesGroup.variants.map(variant => (
                                                <TabPanel key={variant.variantName}>
                                                    <RulesTable
                                                        className={styles.tableWrapper}
                                                        editionCode={editionCode}
                                                        getReorderedRowClassName={getClassNameForItem}
                                                        handleReorder={handleReorder}
                                                        hideActions={hideActions}
                                                        isLoading={isPending}
                                                        isReorderMode={isReorderMode}
                                                        onAction={onAction}
                                                        rules={rules}
                                                        type={type}
                                                        variantName={variant.variantName}
                                                    />
                                                </TabPanel>
                                            ))}
                                        </TabPanels>
                                    </Tabs>
                                ) : (
                                    <Tabs variant="inline">
                                        <TabList>
                                            <Tab>All Rules</Tab>
                                        </TabList>
                                        <TabPanels>
                                            <TabPanel>
                                                <RulesTable
                                                    className={styles.tableWrapper}
                                                    editionCode={editionCode}
                                                    getReorderedRowClassName={getClassNameForItem}
                                                    handleReorder={handleReorder}
                                                    hideActions={hideActions}
                                                    isLoading={isPending}
                                                    isReorderMode={isReorderMode}
                                                    onAction={onAction}
                                                    rules={rules}
                                                    type={type}
                                                />
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                )}
                            </Flex>
                        </Flex>
                    </SummarySection>
                </Flex>
            </Accordion>
            {dialogType != null && !Array.isArray(rulesGroup) && (
                <ABTestDialogs
                    dialogType={dialogType}
                    editionCode={editionCode}
                    flagCode={rulesGroup.flagCode}
                    onClose={() => setDialogType(undefined)}
                    rulesGroup={rulesGroup}
                />
            )}
        </>
    );
};
