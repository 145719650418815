import {
    generateAdditionalFunctions,
    generateInsuredEntitiesTypes,
    nameToTypescriptSafeName,
    schemaRecordTypeToTypescriptType,
} from '../../expressions-to-typescript/expressions-to-typescript';
import type { InputFunction } from '../ExpressionSimpleEditor/operators';
import type { InsuranceScope } from 'models/LoCo/Insurance/CoveragesEdition';
import { InsuranceScopeType } from 'models/LoCo/Insurance/CoveragesEdition';
import type { SchemaResponse } from 'models/LoCo/Insurance/Schema';

export function generateTypesLib(
    schema: SchemaResponse,
    additionalFunctions: Record<string, InputFunction>,
    coveragesTemplateCodes: string[],
    settingsTemplateCodes: string[],
    scope: InsuranceScope = { type: InsuranceScopeType.Policy }
): string {
    const sanitizedName = nameToTypescriptSafeName(schema.schema.name);

    const typesLib = `/**
* Check if a number is between two other numbers
* @param a The number to check
* @param b The lower bound
* @param c The upper bound
* @returns true if a is between b and c, false otherwise
* @example isBetween(5, 1, 10) // true
* @example isBetween(5, 10, 20) // false
*/
function isBetween(a: number, b: number, c: number): boolean { return true; };

/**
 * Get the maximum of two numbers
 * @param a The first number
 * @param b The second number
 * @returns The maximum of a and b
 * @example max(5, 10) // 10
 */
function max(a: number, b: number): number { return 0; };

/**
 * Get the minimum of two numbers
 * @param a The first number
 * @param b The second number
 * @returns The minimum of a and b
 * @example min(5, 10) // 5
 */
function min(a: number, b: number): number { return 0; };

/**
 * Check if a value is defined
 * @param a The value to check
 * @returns true if a is defined, false otherwise
 * @example isDefined(5) // true
 * @example isDefined(null) // false
 */
function isDefined(a: any): boolean { return true; };

/**
 * Check if value a is contained in array b
 * @param a The value to check
 * @param b The array to check
 * @returns true if a is contained in b, false otherwise
 * @example isContainedIn(5, [1, 2, 3, 4, 5]) // true
 * @example isContainedIn(6, [1, 2, 3, 4, 5]) // false
 */
function isContainedIn<T>(a: T, b: T[]): boolean { return true; };


/**
 * Check if any of the elements in an array satisfy a condition
 * @param arr The array to check
 * @param iterator The condition to check
 * @returns true if any element in arr satisfies the condition, false otherwise
 * @example anyOf([1, 2, 3, 4, 5], (x) => x > 3) // true
 * @example anyOf([1, 2, 3, 4, 5], (x) => x > 5) // false
 */
function anyOf<T>(arr: T[], iterator: (item: T) => boolean): boolean {
    return true;
}

/**
 * Check if all elements in an array satisfy a condition
 * @param arr The array to check
 * @param iterator The condition to check
 * @returns true if all elements in arr satisfy the condition, false otherwise
 * @example allOf([1, 2, 3, 4, 5], (x) => x > 0) // true
 * @example allOf([1, 2, 3, 4, 5], (x) => x > 3) // false
 */
function allOf<T>(arr: T[], iterator: (item: T) => boolean): boolean {
    return true;
}

${schemaRecordTypeToTypescriptType(schema.schema)}

function input<T extends keyof ${sanitizedName}>(i: T): ${sanitizedName}[T] {
    return (i as unknown) as unknown as ${sanitizedName}[T];
};

${generateAdditionalFunctions(additionalFunctions)}

${generateInsuredEntitiesTypes(schema, scope, coveragesTemplateCodes, settingsTemplateCodes)}
`;

    return typesLib;
}
