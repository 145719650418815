// eslint-disable-next-line @typescript-eslint/naming-convention
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { clsx } from 'clsx';
import type { FC, ReactNode } from 'react';
import { IconButton } from '../IconButton/IconButton';
import * as listItemStyles from '../ListItem/ListItem.css';
import { Tooltip } from '../Tooltip/Tooltip';
import * as styles from './ActionsMenu.css';

export interface ActionsMenuItem extends DropdownMenu.DropdownMenuItemProps {
  readonly label: string;
  readonly tooltip?: ReactNode;
}

export interface ActionsMenuProps {
  readonly items: ActionsMenuItem[];
  readonly align?: 'center' | 'end' | 'start';
  readonly side?: 'bottom' | 'left' | 'right' | 'top';
  readonly triggerIconSize?: 'lg' | 'md' | 'sm';
  readonly triggerElement?: JSX.Element;
  readonly className?: string;
}

export const ActionsMenu: FC<ActionsMenuProps> = ({
  items,
  align = 'end',
  triggerElement,
  triggerIconSize,
  className,
  ...rest
}) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        {triggerElement ?? (
          <IconButton color="textTertiary" icon="more-vertical" size={triggerIconSize} variant="inline" />
        )}
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content align={align} {...rest} className={clsx(styles.content, className)}>
          {items.map(({ id, label, tooltip, ...item }) => {
            return (
              <Tooltip content={tooltip} disabled={tooltip == null} key={id}>
                <DropdownMenu.DropdownMenuItem
                  className={clsx(listItemStyles.listItem, styles.item)}
                  key={id}
                  {...item}
                >
                  {label}
                </DropdownMenu.DropdownMenuItem>
              </Tooltip>
            );
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
