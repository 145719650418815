import type * as avro from 'avsc';
import type { EnumArgument } from '../components/ExpressionSimpleEditor/expressionTypes';
import { ArgumentType } from '../components/ExpressionSimpleEditor/expressionTypes';
import type { InputFunction } from '../components/ExpressionSimpleEditor/operators';
import { isPrimitiveType } from '../helpers/schemaHelpers';
import { InsuranceScopeType } from 'models/LoCo/Insurance/CoveragesEdition';
import type { InsuranceScope } from 'models/LoCo/Insurance/CoveragesEdition';
import type { SchemaResponse } from 'models/LoCo/Insurance/Schema';

export function schemaPrimitiveTypeToTypeScriptType(schemaType: avro.schema.PrimitiveType): string {
    switch (schemaType) {
        case 'boolean':
            return 'boolean';
        case 'int':
        case 'float':
            return 'number';
        case 'string':
            return 'string';
        default:
            throw new Error(`Unsupported schema type: ${schemaType}`);
    }
}

export function schemaEnumTypeToTypescriptType({ name, symbols }: avro.schema.EnumType): string {
    return `enum ${name} {
    ${symbols.map(value => `${value} = '${value}',`).join('\n    ')}
}`;
}

function isEnumType(type: avro.Schema): type is avro.schema.EnumType {
    return typeof type === 'object' && 'type' in type && type.type === 'enum';
}

function getTypeOfRecordField(fieldType: avro.Schema): string {
    if (isEnumType(fieldType)) return fieldType.name;
    else if (isPrimitiveType(fieldType)) return schemaPrimitiveTypeToTypeScriptType(fieldType);
    else return `Unsupported field type`;
}

export function nameToTypescriptSafeName(name: string): string {
    // eslint-disable-next-line unicorn/prefer-string-replace-all
    return name.replace(/[^a-zA-Z0-9]/g, '_');
}

export function schemaRecordTypeToTypescriptType({ name, fields }: avro.schema.RecordType): string {
    const enumDefinitions = fields
        .map(field => field.type)
        .filter(isEnumType)
        .map(e => schemaEnumTypeToTypescriptType(e))
        .join('\n');

    const t = `${enumDefinitions}

type ${nameToTypescriptSafeName(name)} = {
    ${fields.map(({ name: fieldName, type }) => `"${fieldName}": ${getTypeOfRecordField(type)};`).join('\n    ')}
}`;

    return t;
}

export function generateAdditionalFunctions(functions: Record<string, InputFunction>): string {
    const enums: Record<string, EnumArgument> = {};

    let functionsLib = '';

    Object.keys(functions).forEach(functionName => {
        const functionObject = functions[functionName];
        let returnType = functionObject.returnType.type as string;

        if (functionObject.returnType.type === ArgumentType.Enum) {
            returnType = functionObject.returnType.enumName;
            enums[functionObject.returnType.enumName] = functionObject.returnType;
        }

        const argument = functionObject.argument;

        if (argument) {
            enums[argument.enumName] = argument;

            functionsLib += `function ${functionName}(i: ${argument.enumName}): ${returnType} {
                return i as unknown as ${returnType};
            };`;
        } else {
            functionsLib += `function ${functionName}(): ${returnType} {
                return {} as ${returnType};
            };`;
        }
    });

    const enumsLib = generateEnums(enums);

    return `${enumsLib}\n${functionsLib}`;
}

function generateUnion(types: string[]): string {
    return types.map(t => `"${t}"`).join(' | ');
}

function generateEnums(enums: Record<string, EnumArgument>): string {
    return Object.entries(enums)
        .map(
            ([enumName, enumArgument]) =>
                `type ${enumName} = ${generateUnion(enumArgument.symbols.map(({ value }) => value))};`
        )
        .join('\n');
}

type Fields = avro.schema.RecordType['fields'];

export function getInsuredEntityFields(
    fields: Fields,
    insuredEntityName: string
): { readonly name: string; readonly fields: Fields } {
    const insuredEntity = fields.find(({ name }) => name === insuredEntityName);
    if (
        !(
            typeof insuredEntity?.type === 'object' &&
            'type' in insuredEntity.type &&
            insuredEntity.type.type === 'array' &&
            typeof insuredEntity.type.items === 'object' &&
            'type' in insuredEntity.type.items &&
            insuredEntity.type.items.type === 'record'
        )
    ) {
        throw new Error(`Invalid insured entity schema: ${insuredEntityName}`);
    }

    return { name: insuredEntity.type.items.name, fields: insuredEntity.type.items.fields };
}

export function getInsurableEntityType(fields: Fields, insurableEntityName: string): string {
    const insurableEntityFields = getInsuredEntityFields(fields, insurableEntityName);
    return `{
    ${insurableEntityFields.fields.map(({ name, type }) => `"${name}": ${getTypeOfRecordField(type)};`).join('\n    ')}
} & InsuredEntityBase`;
}

export function generateGetSelectedCoveragesAndSettingsFunction(
    coveragesTemplateCodes: string[],
    settingsTemplateCode: string[]
): string {
    return `type CoverageTemplateCode = ${generateUnion(coveragesTemplateCodes)};

type SettingTemplateCode = ${generateUnion(settingsTemplateCode)};

type CoverageState = "on" | "off";

type GetSelectedCoverage = (coverageTemplateCode: CoverageTemplateCode) => CoverageState;

type GetSelectedSetting = (settingTemplateCode: SettingTemplateCode) => number;
`;
}

export function getInsuredEntitiesType(schema: SchemaResponse): string {
    const typeMembers = Object.entries(schema.fieldsMetadata)
        .filter(([_, fieldMetadata]) => Boolean(fieldMetadata.isInsurableEntity))
        .map(
            ([fieldName, fieldMetadata]) =>
                `${fieldMetadata.insurableEntityCode}: ${getInsurableEntityType(schema.schema.fields, fieldName)};`
        )
        .join('\n');

    return `
type InsuredEntityBase = {
    publicId: string;
    selectedCoverage: GetSelectedCoverage;
    previousSelectedCoverage: GetSelectedCoverage;
    selectedSetting: GetSelectedSetting;
    previousSelectedSetting: GetSelectedSetting;
};

type InsuredEntities = {
    ${typeMembers}
}`;
}

export function generateInsuredEntitiesFunction(): string {
    return `function insuredEntities<TInsuredEntityTypeCode extends keyof InsuredEntities>(
    key: TInsuredEntityTypeCode
): InsuredEntities[TInsuredEntityTypeCode][];`;
}

export function generatedCurrentInsuredEntityFunction(scope: InsuranceScope): string {
    if (scope.type === InsuranceScopeType.Policy || scope.type === InsuranceScopeType.ExternalEntity) return '';

    const insuredEntityTypeCode = scope.insuredEntityCode;
    return `function currentInsuredEntity(): InsuredEntities["${insuredEntityTypeCode}"];`;
}

export function generateInsuredEntitiesTypes(
    schema: SchemaResponse,
    scope: InsuranceScope,
    coveragesTemplateCodes: string[],
    settingTemplateCodes: string[]
): string {
    return `${generateGetSelectedCoveragesAndSettingsFunction(coveragesTemplateCodes, settingTemplateCodes)}
    
${getInsuredEntitiesType(schema)}

${generateInsuredEntitiesFunction()}

${generatedCurrentInsuredEntityFunction(scope)}`;
}
