import { Card, Flex, pageWrapper, Select, spacing, Text } from '@lemonade-hq/blender-ui';
import { EntityTypes } from '@lemonade-hq/bluiza';
import { EntityDataProvider } from '@lemonade-hq/boutique';
import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AttachmentHub } from './AttachmentHub';

const getProductByEntity = (entityType: EntityTypes): 'car' | 'home' | 'pet' => {
    if (entityType.includes('pet')) {
        return 'pet';
    } else if (entityType.includes('car')) {
        return 'car';
    } else {
        return 'home';
    }
};

const ExampleContent: FC<{
    readonly entityType: EntityTypes;
    readonly entityId: string;
}> = ({ entityType, entityId }) => {
    return (
        <Flex className={pageWrapper} flexDirection="column" gap={spacing.s48}>
            <Text textAlign="center" type="h3">
                Attachments Hub
            </Text>
            <AttachmentHub entityId={entityId} entityType={entityType} />
        </Flex>
    );
};

export const ExamplePage: FC = () => {
    const navigate = useNavigate();
    const params = new URLSearchParams(document.location.search);
    const id = params.get('id');

    const [entityId] = useState(id ?? 'LC74DF0A4');
    const [entityType, setEntityType] = useState(EntityTypes.HomeClaim);

    useEffect(() => {
        navigate(`?id=${entityId}`, { replace: true });
    }, [entityId, navigate]);

    const handleEntityTypeChange = (value: string): void => setEntityType(value as EntityTypes);

    return (
        <Flex flexDirection="column" gap={spacing.s48} height="85vh">
            <Card margin="auto" p={spacing.s16} width="320px">
                <Flex flexDirection="column" gap={spacing.s24}>
                    <Flex alignItems="center" justifyContent="space-between">
                        <Text fontWeight="semi-bold" type="text-lg">
                            Attachment Data
                        </Text>
                    </Flex>
                    <Flex flexDirection="column" gap={spacing.s12}>
                        <Flex flexDirection="column" gap={spacing.s06}>
                            <Text color="primary" type="label-sm">
                                Entity id
                            </Text>
                            <Text fontWeight="semi-bold">{entityId}</Text>
                        </Flex>
                        <Flex flexDirection="column" gap={spacing.s06}>
                            <Text color="primary" type="label-sm">
                                Type
                                <Text as="span" color="error">
                                    &nbsp;*
                                </Text>
                            </Text>
                            <Flex flexDirection="column" gap={spacing.s06}>
                                <Select<never, { value: string; label: EntityTypes }>
                                    onChange={value => handleEntityTypeChange(value ?? '')}
                                    options={Object.values(EntityTypes).map(type => ({
                                        value: type,
                                        label: type,
                                    }))}
                                    placeholder="Select entity type"
                                    selectedKey={entityType}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Card>

            <EntityDataProvider
                entityId={entityId}
                entityType={entityType}
                productType={getProductByEntity(entityType)}
            >
                <ExampleContent entityId={entityId} entityType={entityType} />
            </EntityDataProvider>
        </Flex>
    );
};
