import type { BadgeProps } from '@lemonade-hq/blender-ui';
import { Badge, Text } from '@lemonade-hq/blender-ui';
import { createUserLink } from '@lemonade-hq/bluiza';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import { differenceInDays } from 'date-fns';
import type { ReactNode } from 'react';
import { AssigneeItem } from './AssigneeItem';

export interface RenderData {
    readonly value: string;
    readonly metadata?: Record<string, string>;
}

const statusToBadgeVariant: Record<string, BadgeProps['variant']> = {
    new: 'positive',
    in_progress: 'progress',
    pending: 'attention',
    expired: 'negative',
    completed: 'info',
};

export const columnRenders: Record<string, (renderData: RenderData) => ReactNode> = {
    string: ({ value }) => (
        <Text as="span" type="text-md">
            {value}
        </Text>
    ),
    assignee: ({ value, metadata }) =>
        value === '' ? '-' : <AssigneeItem fullName={value} photoUrl={metadata?.photoUrl} />,
    link: ({ value, metadata }) => <a href={metadata?.url}>{value}</a>,
    badge: ({ value }) => <Badge label={snakeCaseToReadable(value)} variant={statusToBadgeVariant[value]} />,
    dueDate: ({ value }) => (
        <Text as="span" type="text-md">
            {differenceInDays(new Date(value), new Date())}
        </Text>
    ),
    userUrl: ({ value, metadata }) => <a href={createUserLink(metadata?.userId)}>{value}</a>,
};
