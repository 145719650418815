import { Card, Flex, generateTypedFormComponents, spacing, Text } from '@lemonade-hq/blender-ui';
import type { clientToolSchema, CustomerCardSectionName, ToolSchemaValues } from '@lemonade-hq/persisted-tools';
import { prettifyField } from '../../shared/tool.helpers';
import * as styles from '../PlaygroundToolEditorMain.css';

interface CustomerCardSectionAccordionProps {
    readonly customerCardSections?: Record<CustomerCardSectionName, boolean>;
    readonly toolSchemaValues?: ToolSchemaValues;
}
const { Accordion, Checkbox } = generateTypedFormComponents<typeof clientToolSchema>();

export const CustomerCardSectionAccordion: React.FC<CustomerCardSectionAccordionProps> = ({
    customerCardSections,
    toolSchemaValues,
}) => {
    const checkedSectionsCount = Object.values(customerCardSections ?? {}).filter(Boolean).length;
    return (
        <Accordion
            badges={[{ label: checkedSectionsCount }]}
            hasAsteriskMark
            isOpenByDefault
            schemaKey="customerCardSections"
            subTitle="Select the customer info this tool needs to handle tickets properly"
            title={prettifyField('customerCardSections')}
        >
            <Card
                borderRadius="sm"
                display="flex"
                flexDirection="column"
                gap={spacing.s16}
                padding={spacing.s16}
                variant="primary"
            >
                {Object.entries(toolSchemaValues?.customerCardSections ?? {}).map(
                    ([sectionName, { label, description, required }]) => (
                        <Flex alignItems="flex-start" gap={spacing.s06} key={sectionName}>
                            <Checkbox
                                disabled={required}
                                schemaKey={`customerCardSections.${sectionName as CustomerCardSectionName}`}
                            />
                            <Flex flexDirection="column" gap={spacing.s04}>
                                <Text className={styles.sectionLabel}> {label}</Text>
                                <Text color="secondary">{description}</Text>
                            </Flex>
                        </Flex>
                    )
                )}
            </Card>
        </Accordion>
    );
};
