/* eslint-disable react/no-array-index-key */
import type { FC } from 'react';
import { useMemo } from 'react';
import type { ParsedPrompt } from '../types';

function createOrderedList(listItems: JSX.Element[]): JSX.Element {
    return (
        <ol key={`ol-${listItems[0].key}`} start={Number(listItems[0].key)}>
            {listItems}
        </ol>
    );
}

function replaceCodeInLine(line: string): JSX.Element[] {
    const pattern = /(\{[^}]*\})/g;
    const parts = line.split(pattern);
    return parts.map(part => {
        if (part.startsWith('{') && part.endsWith('}')) {
            const content = part.slice(1, -1);
            return <code key={content}>{`{ ${content.trim()} }`}</code>;
        } else {
            return <span key={part}>{part}</span>;
        }
    });
}

/**
 * input:
 * line A
 * line B
 * 1. line C
 * 2. line D
 *
 * output
 * <p>line A</p>
 * <p>line B</p>
 * <ol>
 *  <li>line C</li>
 *  <li>line D</li>
 * </ol>
 */
export function renderPromptContent(content: string): JSX.Element[] {
    const lines = content.split('\n');
    const elements = [];
    let listItems: JSX.Element[] = [];
    const numberedLinePrefix = /^\s*(\d+\.\s)/;
    lines.forEach((line, i) => {
        const isNumberedListItem = numberedLinePrefix.exec(line);
        if (isNumberedListItem) {
            const lineWithoutNumberPrefix = line.replace(numberedLinePrefix, '');
            listItems.push(
                <li key={parseInt(isNumberedListItem[1], 10)}>{replaceCodeInLine(lineWithoutNumberPrefix)}</li>
            );
        } else {
            if (listItems.length > 0) {
                elements.push(createOrderedList(listItems));
                listItems = [];
            }

            const lineToAdd = line.startsWith('API: ') ? <u>{line}</u> : replaceCodeInLine(line);
            if (line.length > 0) {
                elements.push(<p key={line}>{lineToAdd}</p>);
            } else {
                elements.push(<br key={`empty-${i}`} />);
            }
        }
    });

    if (listItems.length > 0) {
        elements.push(createOrderedList(listItems));
    }

    return elements;
}

export const PromptRenderer: FC<{ readonly prompt: ParsedPrompt }> = ({ prompt }) => {
    const renderedContent = useMemo(
        () => (prompt.mainSection != null ? renderPromptContent(prompt.mainSection) : null),
        [prompt]
    );

    return (
        <div>
            <h3>{prompt.title}</h3>
            {renderedContent}
            {prompt.subsections?.map(section => (
                <>
                    <h4>{section.title}</h4>
                    {renderPromptContent(section.text)}
                </>
            ))}
        </div>
    );
};

function renderRawOutput(content: string): JSX.Element[] {
    const cleanContent = content.replace(/^"|"$/g, '');
    const lines = cleanContent.split('\n');
    const elements: JSX.Element[] = [];
    let listItems: JSX.Element[] = [];
    const numberedLinePrefix = /^\s*(\d+\.\s)/;

    lines.forEach((line, i) => {
        const isNumberedListItem = numberedLinePrefix.exec(line);

        if (isNumberedListItem) {
            const lineWithoutNumberPrefix = line.replace(numberedLinePrefix, '');
            const formattedLine = lineWithoutNumberPrefix.replace(
                /(\{[^}]+\}|"api":\s*"[^"]+"|what_is_[a-z_]+)/g,
                match => `<code>${match}</code>`
            );

            const parts = formattedLine.split(/(<code>[^<]+<\/code>)/);
            listItems.push(
                <li key={parseInt(isNumberedListItem[1], 10)}>
                    {parts.map((part, j) =>
                        part.startsWith('<code>') ? (
                            <code key={j}>{part.replace(/<\/?code>/g, '')}</code>
                        ) : (
                            <span key={j}>{part}</span>
                        )
                    )}
                </li>
            );
        } else {
            if (listItems.length > 0) {
                elements.push(createOrderedList(listItems));
                listItems = [];
            }

            if (line.length > 0) {
                const formattedLine = line.replace(
                    /(\{[^}]+\}|"api":\s*"[^"]+"|what_is_[a-z_]+)/g,
                    match => `<code>${match}</code>`
                );

                const parts = formattedLine.split(/(<code>[^<]+<\/code>)/);
                elements.push(
                    <p key={`${line}-${i}`}>
                        {parts.map((part, j) =>
                            part.startsWith('<code>') ? (
                                <code key={j}>{part.replace(/<\/?code>/g, '')}</code>
                            ) : (
                                <span key={j}>{part}</span>
                            )
                        )}
                    </p>
                );
            } else {
                elements.push(<br key={`empty-${i}`} />);
            }
        }
    });

    if (listItems.length > 0) {
        elements.push(createOrderedList(listItems));
    }

    return elements;
}

export const RawOutputRenderer: FC<{ readonly rawOutput: string }> = ({ rawOutput }) => {
    const renderedContent = useMemo(() => renderRawOutput(rawOutput), [rawOutput]);

    return <div>{renderedContent}</div>;
};
