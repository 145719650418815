import type { RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import type { ReactNode } from 'react';
import type { AccordionProps } from '../../../Accordion/Accordion';
import { Accordion as BUIAccordion } from '../../../Accordion/Accordion';
import { useForm } from '../../FormContext';
import { isSchemaKeyRequired } from '../common';

export type AssertedAccordionProps<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
> = AccordionProps & {
  readonly schemaKey: TSchemaKey;
  readonly children: ReactNode;
};

export const Accordion = <TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>>(
  props,
): ReactNode => {
  const { schemaKey, children, ...restProps } = props as AssertedAccordionProps<TSchema, TSchemaKey>;
  const { schema, values } = useForm();
  const isRequired = isSchemaKeyRequired(schemaKey, schema, values);

  return (
    <BUIAccordion hasAsteriskMark={isRequired} {...restProps}>
      {children}
    </BUIAccordion>
  );
};
