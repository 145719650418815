import { Flex, spacing } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { assertDigitalAgentEditionIsNotNull, findCoverage } from './changes/common';
import { CoverageAdded } from './changes/CoverageAdded';
import { CoverageAttributesUpdated } from './changes/CoverageAttributesUpdated';
import { CoverageBindingChanged } from './changes/CoverageBindingChanged';
import { CoverageConditionStatus } from './changes/CoverageConditionStatus';
import { CoverageRemoved } from './changes/CoverageRemoved';
import { CoverageRuleABTestChanges } from './changes/CoverageRuleABTestChanges';
import { CoverageRuleABTestDetached } from './changes/CoverageRuleABTestDetached';
import { CoverageRulesUpdated } from './changes/CoverageRulesUpdated';
import { FlagABTestChanges } from './changes/FlagABTestChanges';
import { FlagABTestDetached } from './changes/FlagABTestDetached';
import { FlagAdded } from './changes/FlagAdded';
import { FlagRemoved } from './changes/FlagRemoved';
import { FlagRulesUpdated } from './changes/FlagRulesUpdated';
import { SchemaUpdated } from './changes/SchemaUpdated';
import { SettingAdded } from './changes/SettingAdded';
import { SettingAttributesUpdated } from './changes/SettingAttributesUpdated';
import { SettingCoupled } from './changes/SettingCoupled';
import { SettingDecoupled } from './changes/SettingDecoupled';
import { SettingRemoved } from './changes/SettingRemoved';
import { SettingValuesUpdated } from './changes/SettingValuesUpdated';
import { UnderwritingDecisionUpdated } from './changes/UnderwritingDecisionUpdated';
import type { Edition } from 'models/LoCo/Insurance/BaseEdition';
import { ChangeType } from 'models/LoCo/Insurance/ChangesLog';
import type { ChangesLogEntry } from 'models/LoCo/Insurance/ChangesLog';
import type { CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';
import type { DigitalAgentEdition } from 'models/LoCo/Insurance/DigitalAgentEdition';
import type { UnderwritingFiltersEdition } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

interface ChangesByEditionSetProps {
    readonly changes: ChangesLogEntry[];
    readonly coveragesEdition: CoveragesEdition | null;
    readonly baseCoveragesEdition: CoveragesEdition | null;
    readonly digitalAgentEdition: DigitalAgentEdition | null;
    readonly baseDigitalAgentEdition: DigitalAgentEdition | null;
    readonly underwritingFiltersEdition: UnderwritingFiltersEdition | null;
    readonly baseUnderwritingFiltersEdition: UnderwritingFiltersEdition | null;
    readonly platformSchemaRevision: number | null;
    readonly productSchemaRevision: number | null;
    readonly basePlatformSchemaRevision: number | null;
    readonly baseProductSchemaRevision: number | null;
}

export const ChangesSummaryContent: FC<ChangesByEditionSetProps> = ({
    changes,
    coveragesEdition,
    baseCoveragesEdition,
    digitalAgentEdition,
    underwritingFiltersEdition,
    baseUnderwritingFiltersEdition,
    platformSchemaRevision,
    productSchemaRevision,
    basePlatformSchemaRevision,
    baseProductSchemaRevision,
    baseDigitalAgentEdition,
}) => {
    return (
        <Flex flexDirection="column" gap={spacing.s08}>
            {changes.flatMap((change, i) => {
                const ref =
                    'referenceId' in change.metadata
                        ? change.metadata.referenceId
                        : 'entityCode' in change.metadata
                          ? change.metadata.entityCode
                          : i;
                const key = `${change.changeType}${ref}`;

                switch (change.changeType) {
                    case ChangeType.CoverageAdded:
                        assertEditionNotNull(coveragesEdition);

                        return (
                            <CoverageAdded
                                change={change}
                                coveragesEdition={coveragesEdition}
                                digitalAgentEdition={digitalAgentEdition}
                                key={key}
                            />
                        );
                    case ChangeType.CoverageRemoved: {
                        assertEditionNotNull(baseCoveragesEdition);

                        return (
                            <CoverageRemoved baseCoveragesEdition={baseCoveragesEdition} change={change} key={key} />
                        );
                    }

                    case ChangeType.CoverageAttributesUpdated: {
                        assertEditionNotNull(coveragesEdition);
                        assertEditionNotNull(baseCoveragesEdition);

                        return (
                            <CoverageAttributesUpdated
                                baseCoverageEdition={baseCoveragesEdition}
                                change={change}
                                coverageEdition={coveragesEdition}
                            />
                        );
                    }

                    case ChangeType.CoverageSetToRequired: {
                        assertEditionNotNull(baseCoveragesEdition);
                        return (
                            <CoverageConditionStatus
                                baseCoveragesEdition={baseCoveragesEdition}
                                change={change}
                                condition={'required'}
                                key={key}
                            />
                        );
                    }

                    case ChangeType.CoverageSetToOptional: {
                        assertEditionNotNull(coveragesEdition);
                        assertEditionNotNull(baseCoveragesEdition);

                        const changedCoverage = findCoverage(baseCoveragesEdition, change.metadata.referenceId);

                        return (
                            <CoverageAttributesUpdated
                                baseCoverageEdition={baseCoveragesEdition}
                                change={change}
                                coverageEdition={coveragesEdition}
                                title={`Coverage set to optional - ${changedCoverage.name}`}
                            />
                        );
                    }

                    case ChangeType.SettingAdded: {
                        assertEditionNotNull(coveragesEdition);

                        return (
                            <SettingAdded
                                change={change}
                                coveragesEdition={coveragesEdition}
                                digitalAgentEdition={digitalAgentEdition}
                                key={key}
                            />
                        );
                    }

                    case ChangeType.SettingRemoved: {
                        assertEditionNotNull(baseCoveragesEdition);

                        return <SettingRemoved baseCoveragesEdition={baseCoveragesEdition} change={change} key={key} />;
                    }

                    case ChangeType.SettingAttributesUpdated: {
                        assertEditionNotNull(coveragesEdition);
                        assertEditionNotNull(baseCoveragesEdition);

                        return (
                            <SettingAttributesUpdated
                                baseCoveragesEdition={baseCoveragesEdition}
                                change={change}
                                coveragesEdition={coveragesEdition}
                                key={key}
                            />
                        );
                    }

                    case ChangeType.SettingValuesUpdated: {
                        assertEditionNotNull(coveragesEdition);
                        assertEditionNotNull(baseCoveragesEdition);

                        return (
                            <SettingValuesUpdated
                                baseCoveragesEdition={baseCoveragesEdition}
                                change={change}
                                coveragesEdition={coveragesEdition}
                                key={key}
                            />
                        );
                    }

                    case ChangeType.CoverageBindingAdded:
                    case ChangeType.CoverageBindingRemoved: {
                        assertEditionNotNull(coveragesEdition);

                        return <CoverageBindingChanged change={change} coverageEdition={coveragesEdition} />;
                    }

                    case ChangeType.ProductSchemaUpdated: {
                        assertSchemaIsNotNull(productSchemaRevision);
                        assertSchemaIsNotNull(platformSchemaRevision);
                        return (
                            <SchemaUpdated
                                basePlatformSchemaRevision={basePlatformSchemaRevision}
                                baseProductSchemaRevision={baseProductSchemaRevision}
                                change={change}
                                key={key}
                                platformSchemaRevision={platformSchemaRevision}
                                productSchemaRevision={productSchemaRevision}
                            />
                        );
                    }

                    case ChangeType.PlatformSchemaUpdated: {
                        assertSchemaIsNotNull(productSchemaRevision);
                        assertSchemaIsNotNull(platformSchemaRevision);

                        return (
                            <SchemaUpdated
                                basePlatformSchemaRevision={null}
                                baseProductSchemaRevision={null}
                                change={change}
                                key={key}
                                platformSchemaRevision={platformSchemaRevision}
                                productSchemaRevision={productSchemaRevision}
                            />
                        );
                    }

                    case ChangeType.CoverageRulesUpdated: {
                        return (
                            <CoverageRulesUpdated
                                baseCoveragesEdition={baseCoveragesEdition}
                                baseDigitalAgentEdition={baseDigitalAgentEdition}
                                change={change}
                                coveragesEdition={coveragesEdition}
                                digitalAgentEdition={digitalAgentEdition}
                                key={key}
                            />
                        );
                    }

                    case ChangeType.DeclineRulesUpdated: {
                        assertEditionNotNull(underwritingFiltersEdition);

                        return (
                            <UnderwritingDecisionUpdated
                                baseUnderwritingFiltersEdition={baseUnderwritingFiltersEdition}
                                change={change}
                                underwritingFiltersEdition={underwritingFiltersEdition}
                            />
                        );
                    }

                    case ChangeType.FlagRulesUpdated: {
                        assertEditionNotNull(underwritingFiltersEdition);

                        return (
                            <FlagRulesUpdated
                                baseUnderwritingFiltersEdition={baseUnderwritingFiltersEdition}
                                key={key}
                                ruleStats={change.metadata}
                                underwritingFiltersEdition={underwritingFiltersEdition}
                            />
                        );
                    }

                    case ChangeType.FlagChanges: {
                        assertEditionNotNull(underwritingFiltersEdition);

                        return change.metadata.removed
                            .map((flagId: string) => {
                                assertBaseUnderwritingFiltersEditionNotNull(baseUnderwritingFiltersEdition);

                                return (
                                    <FlagRemoved
                                        baseUnderwritingFiltersEdition={baseUnderwritingFiltersEdition}
                                        flagId={flagId}
                                        key={key}
                                    />
                                );
                            })
                            .concat(
                                change.metadata.added.map((flagId: string) => (
                                    <FlagAdded
                                        flagId={flagId}
                                        key={key}
                                        underwritingFiltersEdition={underwritingFiltersEdition}
                                    />
                                ))
                            );
                    }

                    case ChangeType.CoupledSettingsAdded: {
                        assertEditionNotNull(coveragesEdition);
                        return <SettingCoupled change={change} coveragesEdition={coveragesEdition} />;
                    }

                    case ChangeType.CoupledSettingsRemoved: {
                        assertEditionNotNull(coveragesEdition);
                        return <SettingDecoupled change={change} coveragesEdition={coveragesEdition} />;
                    }

                    case ChangeType.CoverageRuleABTestAttached:
                    case ChangeType.CoverageRuleABTestVariantsUpdated:
                    case ChangeType.CoverageRuleABTestExperimentChanged: {
                        assertDigitalAgentEditionIsNotNull(digitalAgentEdition);

                        return (
                            <CoverageRuleABTestChanges
                                baseDigitalAgentEdition={baseDigitalAgentEdition}
                                change={change}
                                digitalAgentEdition={digitalAgentEdition}
                            />
                        );
                    }

                    case ChangeType.CoverageRuleABTestDetached: {
                        assertDigitalAgentEditionIsNotNull(digitalAgentEdition);
                        assertDigitalAgentEditionIsNotNull(baseDigitalAgentEdition);

                        return (
                            <CoverageRuleABTestDetached
                                baseDigitalAgentEdition={baseDigitalAgentEdition}
                                change={change}
                                digitalAgentEdition={digitalAgentEdition}
                            />
                        );
                    }

                    case ChangeType.FlagABTestAttached:
                    case ChangeType.FlagABTestVariantsUpdated:
                    case ChangeType.FlagABTestExperimentChanged: {
                        assertEditionNotNull(underwritingFiltersEdition);

                        return (
                            <FlagABTestChanges
                                baseUnderwritingFiltersEdition={baseUnderwritingFiltersEdition}
                                change={change}
                                underwritingFiltersEdition={underwritingFiltersEdition}
                            />
                        );
                    }

                    case ChangeType.FlagABTestDetached: {
                        assertEditionNotNull(underwritingFiltersEdition);
                        assertEditionNotNull(baseUnderwritingFiltersEdition);

                        return (
                            <FlagABTestDetached
                                baseUnderwritingFiltersEdition={baseUnderwritingFiltersEdition}
                                change={change}
                                underwritingFiltersEdition={underwritingFiltersEdition}
                            />
                        );
                    }

                    default:
                        throw new Error(`Unknown change type: ${(change as ChangesLogEntry).changeType}`);
                }
            })}
        </Flex>
    );
};

function assertEditionNotNull(edition: Edition | null): asserts edition {
    if (edition === null) {
        throw new Error('Coverage edition not found');
    }
}

function assertBaseUnderwritingFiltersEditionNotNull(
    baseUnderwritingFiltersEdition: UnderwritingFiltersEdition | null
): asserts baseUnderwritingFiltersEdition {
    if (baseUnderwritingFiltersEdition === null) {
        throw new Error('Base underwriting filters edition not found');
    }
}

function assertSchemaIsNotNull(schema: number | null): asserts schema {
    if (schema === null) {
        throw new Error('Schema not found');
    }
}
