import type { LabelValueItem } from '@lemonade-hq/blender-ui';
import { ChangeAccordion } from '../ChangeAccordion';
import { findCoverage } from './common';
import { getIsBenefit } from './coverage-attributes';
import { getScopeEntityName } from 'components/LoCo/common/display-texts/common';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import type { AttributesUpdatedEntry, ReferenceIdEntry } from 'models/LoCo/Insurance/ChangesLog';
import { InsuranceScopeType } from 'models/LoCo/Insurance/CoveragesEdition';
import type { CoverageInstance, CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';
import type { Product } from 'models/LoCo/Insurance/Product';

interface CoverageAttributesUpdatedProps {
    readonly title?: string;
    readonly baseCoverageEdition: CoveragesEdition;
    readonly coverageEdition: CoveragesEdition;
    readonly change: AttributesUpdatedEntry | ReferenceIdEntry;
}

export function getScope(coverage: CoverageInstance, product: Product): LabelValueItem {
    let insuredEntityName;

    if (coverage.scope.type === InsuranceScopeType.InsuredEntity) {
        const insuredEntityCode = coverage.scope.insuredEntityCode;

        insuredEntityName = product.insurableEntities.find(e => e.code === insuredEntityCode)?.name;
        if (insuredEntityName === undefined) {
            throw new Error(`Insurable entity with code ${insuredEntityCode} not found`);
        }
    }

    const value = getScopeEntityName(coverage, insuredEntityName);

    return { label: 'Scope', value };
}

export const CoverageAttributesUpdated: React.FC<CoverageAttributesUpdatedProps> = ({
    title,
    coverageEdition,
    baseCoverageEdition,
    change,
}) => {
    const product = useGetProductData();
    const coverageInstance = findCoverage(coverageEdition, change.metadata.referenceId);
    const baseCoverageInstance = findCoverage(baseCoverageEdition, change.metadata.referenceId);

    const newIsBenefit = getIsBenefit(coverageInstance);
    const oldIsBenefit = getIsBenefit(baseCoverageInstance);

    const newScope = getScope(coverageInstance, product);
    const oldScope = getScope(baseCoverageInstance, product);

    const changes = [];

    if (newIsBenefit.value !== oldIsBenefit.value) {
        changes.push(
            { label: `New ${newIsBenefit.label}`, value: newIsBenefit.value },
            { label: `Old ${oldIsBenefit.label}`, value: oldIsBenefit.value }
        );
    }

    if (newScope.value !== oldScope.value) {
        changes.push(
            { label: `New ${newScope.label}`, value: newScope.value },
            { label: `Old ${oldScope.label}`, value: oldScope.value }
        );
    }

    return (
        <ChangeAccordion
            generalTabData={changes}
            originalRules={null}
            ruleType="coverage"
            title={title ?? `Coverage attributes updated - ${coverageInstance.name}`}
            updatedRules={null}
        />
    );
};
