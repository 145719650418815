import { Button, Flex } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCarousel } from '../../Carousel/CarouselProvider';
import { archiveButton, backButton } from './TopActions.css';
import { useAttachmentsData } from 'components/Attachments/context';
import { hasAction } from 'components/Attachments/utils';

interface Props {
    readonly onClose: () => void;
    readonly setShowArchive: (showArchive: boolean) => void;
}

export const TopActionsPanel: FC<Props> = ({ onClose, setShowArchive }) => {
    const { attachments } = useAttachmentsData();
    const { currentIndex } = useCarousel();
    const navigate = useNavigate();
    const attachment = useMemo(() => attachments[currentIndex], [attachments, currentIndex]);
    const docAssignedToEntity = attachment.assignedToEntity ?? true;
    const hasArchiveAction = hasAction(attachment, 'archive');

    const handleClose = useCallback(() => {
        if (window.location.hash.includes('gallery')) {
            navigate(-1);
        }

        onClose();
    }, [navigate, onClose]);

    const onClickArchive = useCallback(() => {
        setShowArchive(true);
    }, [setShowArchive]);

    return (
        <Flex alignItems="center" justifyContent="space-between">
            <Button
                className={backButton}
                label="esc"
                onClick={handleClose}
                size="sm"
                startIcon="chevron-down"
                variant="secondary"
            />
            {hasArchiveAction && (
                <Button
                    className={archiveButton}
                    disabled={attachment.status === 'archived' || !docAssignedToEntity}
                    label="Archive"
                    onClick={onClickArchive}
                    startIcon="archive"
                    variant="secondary"
                />
            )}
        </Flex>
    );
};
