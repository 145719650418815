import type { SelectOption } from '@lemonade-hq/bluis';
import type { Locale } from '@lemonade-hq/lemonation';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { DialogType } from 'components/LoCo/products/SharedTableConfig';
import { QuoteLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import type { UnderwritingDecisionLifecycleContext } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import type {
    Explanation,
    ExplanationContent,
    ExplanationSummary,
    ExplanationVersion,
    Reason,
    ReasonVersion,
    SpecificExplanationDetails,
} from 'models/LoCo/Insurance/UnderwritingRegistry';

export function compareExplanationsCountryAndState(
    explanation1: SpecificExplanationDetails,
    explanation2: SpecificExplanationDetails
): boolean {
    return explanation1.country === explanation2.country && explanation1.state === explanation2.state;
}

export function isCountryUS(country: string): boolean {
    return country === 'US';
}

export function getDecisionTypeText(reasonType: UnderwritingDecisionLifecycleContext): string {
    return reasonType === QuoteLifecycleContext.NewBusiness ? 'Decline' : 'Non-Renewal';
}

export function isDraftExplanationVersion(explanationVersion: ExplanationVersion): boolean {
    return !isDefined(explanationVersion.version);
}

export enum ActionType {
    CreateOrEditDraft = 'CreateOrEditDraft',
    ViewPublishedVersion = 'ViewPublishedVersion',
    PublishDraft = 'PublishDraft',
    ArchiveDraft = 'ArchiveDraft',
    ManageProductLines = 'ManageProductLines',
    CopyCode = 'CopyCode',
}
export enum UnderwritingEntityType {
    Explanation = 'Explanation',
    Reason = 'Reason',
}

export type ExplanationEntityType = {
    readonly type: UnderwritingEntityType.Explanation;
    readonly entity: Explanation;
    readonly entityVersion: ExplanationVersion;
    readonly isEditable: boolean;
};

export type ReasonEntityType = {
    readonly type: UnderwritingEntityType.Reason;
    readonly entity: Reason;
    readonly entityVersion: ReasonVersion;
    readonly isEditable: boolean;
};
export type UnderwritingEntityTypes = ExplanationEntityType | ReasonEntityType;

export type SpecificExplanationItem = SpecificExplanationDetails & { readonly isValid: boolean | null };
export type LocalizedExplanationItem = ExplanationContent & {
    readonly locale?: Locale;
    readonly isValid: boolean | null;
};

export function isDraftReasonVersion(reasonVersion: ReasonVersion): boolean {
    return !isDefined(reasonVersion.version);
}

export const MIN_INTERNAL_NOTE_CHARS = 10;
export const MIN_EXPLANATION_CHARS = 10;
export const MAX_EXPLANATION_CHARS = 1000;

export function validateExplanationMinChars(value: string): boolean {
    return value.trim().length >= MIN_EXPLANATION_CHARS;
}

export function isExplanationDraftValidForPublishing(explanationVersion: ExplanationVersion): boolean {
    const { explanationDetails, version } = explanationVersion;
    if (isDefined(version)) return false;

    const explanationContentEntries = Object.values(explanationDetails);
    return (
        explanationContentEntries.length > 0 &&
        explanationContentEntries.every(explanationContentEntry => {
            const { chat, email, snail } = explanationContentEntry;
            return [chat, email, snail].every(text => isDefined(text) && text.length > 0);
        })
    );
}

function createExplanationOption(explanationSummary: ExplanationSummary): SelectOption {
    return {
        id: explanationSummary.code,
        value: explanationSummary.code,
        label: explanationSummary.name,
    };
}

export function getExplanationOptions(
    selectedExplanationCode: string | undefined,
    selectedExplanationName: string,
    explanationSummaries: ExplanationSummary[] | undefined
): SelectOption[] {
    // populate available explanations to always include selected, even if not available (when filtered by product lines)
    const availableExplanations = explanationSummaries?.map(createExplanationOption) ?? [];

    if (
        selectedExplanationCode != null &&
        selectedExplanationCode.length > 0 &&
        !availableExplanations.some(option => option.id === selectedExplanationCode)
    ) {
        availableExplanations.push(
            createExplanationOption({
                code: selectedExplanationCode,
                name: selectedExplanationName,
            })
        );
    }

    return availableExplanations;
}

type PublishValidationResult = {
    readonly isValid: boolean;
    readonly errorMessage?: string;
    readonly errorListItems?: string[];
};

export function validateExplanationDraftForPublishing(explanationVersion: ExplanationVersion): PublishValidationResult {
    if (isDefined(explanationVersion.version)) {
        return {
            isValid: false,
            errorMessage: 'This explanation is already published',
        };
    }

    const explanationDetails = Object.entries(explanationVersion.explanationDetails);

    if (explanationDetails.length === 0) {
        return {
            isValid: false,
            errorMessage: 'This explanation has no defined locales',
        };
    }

    const invalidLocales: string[] = explanationDetails
        .filter(([_locale, explanationDetailsEntry]) =>
            [explanationDetailsEntry.chat, explanationDetailsEntry.email, explanationDetailsEntry.snail].includes('')
        )
        .map(([locale]) => locale);

    return invalidLocales.length > 0
        ? {
              isValid: false,
              errorMessage: 'In order to publish this Explanation, please add missing texts for the following locales:',
              errorListItems: invalidLocales,
          }
        : {
              isValid: true,
          };
}

export function getManageEntityDescriptionLabel(dialogType: DialogType, entityVersion: number | undefined): string {
    switch (dialogType) {
        case DialogType.Add:
            return 'Internal Description';
        case DialogType.Edit:
            return 'Description of Change';
        case DialogType.View:
            return entityVersion === 1 ? 'Internal Description' : 'Description of Change';

        default:
            throw new Error('Invalid dialog type');
    }
}
