/* eslint-disable @typescript-eslint/naming-convention */
import { Banner, Button, Flex, Layout, spacing, Text } from '@lemonade-hq/blender-ui';
import type { Status } from '@lemonade-hq/blender-ui';
import { AttachmentType, getTypeFromContentType, toast } from '@lemonade-hq/bluis';
import { EntityTypes } from '@lemonade-hq/bluiza';
import { trackEvent } from '@lemonade-hq/boutique';
import { capitalize } from '@lemonade-hq/ts-helpers';
import { clsx } from 'clsx';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type { AttachmentDTO, Indicator, IndicatorType } from '../../types';
import {
    attachmentHasDetections,
    getAttachmentAnalyticsParam,
    getFraudDetectionScore,
    getIndicators,
    getRaiDetectionResult,
    getTextChanges,
    hasAction,
} from '../../utils';
import { raiLink, statusBanner } from './Carousel.css';
import { useCarousel } from './CarouselProvider';
import type { ScanFraudError, ScanStatusResult } from 'apis/FraudAPI';
import { useScanFraud, useScanStatusQuery } from 'components/Attachments/AttachmentsQueries';
import { useAttachmentsData } from 'components/Attachments/context';

const ALLOWED_ENTITIES = [EntityTypes.HomeClaim];

enum ErrorType {
    ResistantAILimitExceededException = 'ResistantAILimitExceededException',
    Default = 'Default',
}

const errorsMap = {
    [ErrorType.ResistantAILimitExceededException]: {
        title: 'Quota limit reached',
        message:
            'Document cannot be scanned right now due to quota limits. Please discuss next steps with your team lead',
    },

    [ErrorType.Default]: {
        title: 'Error',
        message: 'Oops, something went wrong',
    },
};

const handleError = (nextErrorType: ErrorType | undefined): void => {
    toast.error(errorsMap[nextErrorType ?? ErrorType.Default].message);
};

const getFraudBannerVariant = (attachment: AttachmentDTO, isScanning: boolean): Status => {
    if (isScanning) {
        return 'progress';
    }

    const score = getFraudDetectionScore(attachment);

    if (score != null) {
        switch (score) {
            case 'NORMAL':
                return 'info';
            case 'WARNING':
                return 'attention';
            case 'TRUSTED':
                return 'positive';
            case 'HIGH_RISK':
                return 'negative';
            default:
                return 'info';
        }
    }

    return 'attention';
};

const getFraudBannerTitle = (attachment: AttachmentDTO, isScanning: boolean): JSX.Element | string => {
    const raiDetectionResult = getRaiDetectionResult(attachment);

    if (isScanning) {
        return (
            <Flex justifyContent="space-between" width="100%">
                <Text as="span" type="text-md">
                    <b>Scanning documents.</b>
                    <span>This can take up to 30 sec</span>
                </Text>
            </Flex>
        );
    }

    if (raiDetectionResult?.score != null) {
        let text = '';
        switch (raiDetectionResult.score) {
            case 'NORMAL':
                text = 'This looks normal to us';
                break;
            case 'TRUSTED':
                text = 'Trusted document';
                break;
            case 'HIGH_RISK':
                text = 'Something is fishy here';
                break;
            case 'WARNING':
                text = 'This document may have been modified';
                break;
            default:
                text = '';
        }

        return (
            <Flex alignItems="center" justifyContent="space-between" width="100%">
                <Text>{text}</Text>
                <a className={raiLink} href={raiDetectionResult.externalViewUrl} rel="noreferrer" target="_blank">
                    See more
                </a>
            </Flex>
        );
    }

    return 'This document may have been modified';
};

const getFraudBannerContent = ({ attachment }: { readonly attachment: AttachmentDTO }): JSX.Element | undefined => {
    const textChanges = getTextChanges(attachment) ?? {};
    const indicators = getIndicators(attachment);

    if (Object.keys(textChanges).length > 0) {
        return (
            <Layout pb={spacing.s08}>
                <Text as="p" m="0" type="text-md">
                    <ul>
                        {Object.keys(textChanges).map(key => (
                            <li key={key}>
                                {textChanges[key].map(str => (
                                    <div key={str}>
                                        Page {key}, Text: &quot;{str}&quot;
                                    </div>
                                ))}
                            </li>
                        ))}
                    </ul>
                </Text>
            </Layout>
        );
    }

    if (indicators != null) {
        return (
            <Layout pb={spacing.s08}>
                <Text as="p" m="0" type="text-md">
                    <Flex>
                        <Flex flexDirection="column" padding="0 2px">
                            {Object.keys(indicators).map(key => {
                                const currentIndicators = indicators[key as IndicatorType];
                                if (currentIndicators?.length === 0) return null;

                                return (
                                    <>
                                        <Flex alignItems="center" gap="0 8px" mb={12}>
                                            <b>{capitalize(key.toLocaleLowerCase())} Indicators</b>
                                        </Flex>
                                        <ul>
                                            {currentIndicators?.map((indicator: Indicator) => (
                                                <li key={indicator.indicator_id}>
                                                    <b>{indicator.title}</b> {indicator.description}
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                );
                            })}
                        </Flex>
                    </Flex>
                </Text>
            </Layout>
        );
    }

    return undefined;
};

export const DetectionBanner: FC<{
    readonly isScanning: boolean;
    readonly attachment: AttachmentDTO;
    readonly hideContent?: boolean;
    readonly withBorder?: boolean;
    readonly className?: string;
}> = ({ isScanning, attachment, withBorder, className, hideContent }) => {
    const raiDetectionResult = useMemo(() => getRaiDetectionResult(attachment), [attachment]);
    const scoreIsHigh = useMemo(() => raiDetectionResult?.score === 'HIGH_RISK', [raiDetectionResult?.score]);

    if (!isScanning && (!attachmentHasDetections(attachment) || raiDetectionResult?.score == null)) return null;

    return (
        <Banner
            className={clsx(statusBanner, className)}
            content={isScanning || hideContent ? undefined : getFraudBannerContent({ attachment })}
            contentClassName={statusBanner}
            floatingContent
            isOpenByDefault={scoreIsHigh}
            title={getFraudBannerTitle(attachment, isScanning)}
            variant={getFraudBannerVariant(attachment, isScanning)}
            withBorder={withBorder}
        />
    );
};

export const Detection: FC = () => {
    const { entityPublicId, entityType, attachments } = useAttachmentsData();
    const { currentIndex } = useCarousel();
    const attachment = useMemo(() => attachments[currentIndex], [attachments, currentIndex]);
    const [scanningIds, setScanningIds] = useState<string[]>(
        attachments
            .filter(att => att.detections?.some(detection => detection.detectionStatus === 'processing'))
            .map(({ publicId }) => publicId)
    );

    useScanStatusQuery(
        scanningIds,
        entityPublicId,
        entityType,
        data => {
            if (data != null) {
                const statusesMap = data
                    .filter(status => status.detectionModel === 'resistant_ai')
                    .reduce<Record<string, ScanStatusResult | undefined>>(
                        (acc, curr) => ({
                            ...acc,
                            [curr.attachmentPublicId]: curr,
                        }),
                        {}
                    );

                setScanningIds(currIds => currIds.filter(id => statusesMap[id]?.detectionStatus !== 'completed'));
            }
        },
        () => {
            setScanningIds([]);
            handleError(ErrorType.Default);
        }
    );

    const params = getAttachmentAnalyticsParam({
        attachment,
        entityType,
        entityId: entityPublicId,
    });

    const contentType = useMemo(() => getTypeFromContentType(attachment.contentType ?? ''), [attachment.contentType]);

    const { mutateAsync: scanForFraud } = useScanFraud(entityPublicId, entityType);
    const hasScanAction = hasAction(attachment, 'check_for_modifications');

    const isScanAllowed = useMemo(
        () =>
            ALLOWED_ENTITIES.includes(entityType) &&
            hasScanAction &&
            contentType != null &&
            [AttachmentType.Doc, AttachmentType.Image, AttachmentType.Pdf].includes(contentType),
        [contentType, entityType, hasScanAction]
    );

    const handleScanStart = useCallback((attachmentId: string) => setScanningIds(curr => [...curr, attachmentId]), []);

    const handleScanClick = useCallback(async () => {
        trackEvent('docs.gallery.clicked', { ...params, name: 'scan_for_modifications' });

        if (entityPublicId !== '') {
            try {
                await scanForFraud({
                    attachmentPublicId: attachment.publicId,
                    productLine: entityType.includes('home')
                        ? `home_${entityType.includes('eu') ? 'eu' : 'us'}`
                        : undefined,
                });

                handleScanStart(String(attachment.publicId));
            } catch (err) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                handleError((err?.response?.data as ScanFraudError).data?.type as ErrorType);
            }
        }
    }, [attachment.publicId, entityPublicId, entityType, handleScanStart, params, scanForFraud]);

    const isScanning = scanningIds.includes(String(attachment.publicId));
    const raiDetectionResult = getRaiDetectionResult(attachment);

    return (
        <Flex gap={spacing.s08}>
            {isScanAllowed && raiDetectionResult == null && !isScanning && (
                <div>
                    <Button label="Scan for modifications" onClick={handleScanClick} variant="secondary" />
                </div>
            )}

            <DetectionBanner attachment={attachment} isScanning={isScanning} />
        </Flex>
    );
};
