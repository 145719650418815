import { Button, Flex, Layout, spacing, Text } from '@lemonade-hq/blender-ui';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { useAnalytics } from '@lemonade-hq/boutique';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import { clsx } from 'clsx';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import type { AttachmentDTO } from '../../types';
import { AttachmentType, getAttachmentAnalyticsParam, getAttachmentType, getInlineUrl } from '../../utils';
import { useCarousel } from '../Carousel/CarouselProvider';
import { GalleryImage } from './GalleryImage';
import { GalleryPDF } from './GalleryPDF';
import { GalleryVideo } from './GalleryVideo';
import { archivedBg, mediaWrapper } from './Media.css';
import { useUnArchiveAttachment } from 'components/Attachments/AttachmentsQueries';
import { useAttachmentsData } from 'components/Attachments/context';

export const MediaComp: FC<{
    readonly attachment: AttachmentDTO;
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
    readonly isGallery?: boolean;
}> = ({ attachment, entityPublicId, entityType, isGallery }) => {
    const { fileName, contentType, url } = attachment;
    const type = getAttachmentType(contentType != null && contentType !== '' ? contentType : url);
    const inlineUrl = useMemo(() => getInlineUrl(url), [url]);

    switch (type) {
        case AttachmentType.Pdf:
            return <GalleryPDF fileName={fileName ?? ''} url={inlineUrl} />;
        case AttachmentType.Image:
            return (
                <GalleryImage
                    attachment={attachment}
                    entityPublicId={entityPublicId}
                    entityType={entityType}
                    isGallery={isGallery}
                />
            );
        case AttachmentType.Video:
            return <GalleryVideo fileName={fileName ?? ''} url={inlineUrl} />;
        default:
            return (
                <Flex
                    alignItems="center"
                    flexDirection="column"
                    gap={spacing.s24}
                    height="100%"
                    justifyContent="center"
                    width="100%"
                >
                    <Text>This file cannot be displayed</Text>
                    <Button
                        label="Download"
                        onClick={() => window.open(url.replace('?inline=true', ''), '_blank')}
                        startIcon="download"
                        variant="secondary"
                    />
                </Flex>
            );
    }
};

const CompareMode: FC<{
    readonly attachment: AttachmentDTO;
    readonly entityType: EntityTypes;
    readonly entityPublicId: string;
}> = ({ attachment, entityType, entityPublicId }) => {
    const { detections: attachmentDetections } = attachment;
    const { annotatedSource, annotatedReconstructed } = attachmentDetections?.[0].detectionResult ?? {
        annotatedSource: '',
        annotatedReconstructed: '',
    };

    return (
        <Flex height="100%">
            <Layout width="50%">
                <MediaComp
                    attachment={{ ...attachment, url: annotatedSource ?? attachment.url }}
                    entityPublicId={entityPublicId}
                    entityType={entityType}
                />
            </Layout>
            <Layout width="50%">
                <MediaComp
                    attachment={{ ...attachment, url: annotatedReconstructed ?? attachment.url }}
                    entityPublicId={entityPublicId}
                    entityType={entityType}
                />
            </Layout>
        </Flex>
    );
};

const ArchivedBg: FC<{
    readonly attachment: AttachmentDTO;
    readonly entityType: EntityTypes;
    readonly entityPublicId: string;
    readonly isGallery?: boolean;
}> = ({ attachment, entityType, entityPublicId, isGallery }) => {
    const { mutateAsync: unarchive } = useUnArchiveAttachment({ entityPublicId: entityPublicId, entityType });
    const { archivingReason, publicId } = attachment;
    const { trackEvent } = useAnalytics();
    const params = getAttachmentAnalyticsParam({
        attachment,
        entityType,
        entityId: entityPublicId,
    });

    const handleUnarchive = useCallback(async () => {
        trackEvent('docs.gallery.clicked', {
            ...params,
            name: 'unarchive',
            source: isGallery ? 'gallery' : 'preview',
        });
        await unarchive([{ attachmentPublicId: publicId }]);
    }, [isGallery, params, publicId, trackEvent, unarchive]);

    return (
        <Flex alignItems="center" className={archivedBg} flexDirection="column" justifyContent="center">
            <Text color="light" fontWeight="bold" type="h4">
                This file is archived
            </Text>
            {Boolean(archivingReason) && (
                <Text as="p" color="light" pb={spacing.s16}>
                    Reason: {snakeCaseToReadable(archivingReason ?? '')}
                </Text>
            )}
            <Button label="Unarchive" onClick={handleUnarchive} startIcon="unarchive" variant="secondary" />
        </Flex>
    );
};

export const MediaPreview: FC<{
    readonly attachment: AttachmentDTO;
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
    readonly isGallery?: boolean;
    readonly className?: string;
}> = ({ attachment, entityPublicId, entityType, isGallery, className }) => {
    const { status } = attachment;
    const archived = status === 'archived';
    const isCompareMode = attachment.detections?.some(
        detection =>
            Boolean(detection.detectionResult.annotatedSource) &&
            Boolean(detection.detectionResult.annotatedReconstructed)
    );
    return (
        <div className={clsx(mediaWrapper, className)}>
            {archived && (
                <ArchivedBg
                    attachment={attachment}
                    entityPublicId={entityPublicId}
                    entityType={entityType}
                    isGallery={isGallery}
                />
            )}
            {isCompareMode ? (
                <CompareMode attachment={attachment} entityPublicId={entityPublicId} entityType={entityType} />
            ) : (
                <MediaComp
                    attachment={attachment}
                    entityPublicId={entityPublicId}
                    entityType={entityType}
                    isGallery={isGallery}
                />
            )}
        </div>
    );
};

export const GalleryMedia: FC = () => {
    const { attachments, entityPublicId, entityType } = useAttachmentsData();
    const { currentIndex } = useCarousel();
    const currentAttachment = useMemo(() => attachments[currentIndex], [attachments, currentIndex]);

    return (
        <MediaPreview
            attachment={currentAttachment}
            entityPublicId={entityPublicId}
            entityType={entityType}
            isGallery
        />
    );
};
