import type { TableProps } from '@lemonade-hq/blender-ui';
import { wrap } from '@lemonade-hq/ts-helpers';
import type { RenderData } from './columnRenders';
import { columnRenders } from './columnRenders';
import type { FilterBy, GetGenericQueueOptionsResponse, GetGenericQueueResponse } from './types';

export const getTableColumns = (
    headers: GetGenericQueueOptionsResponse['headers'],
    sort: GetGenericQueueOptionsResponse['sortableBy']
): TableProps['columns'] =>
    headers.map(({ key, label }) => ({
        key,
        name: label,
        sortable: sort?.some(({ columnKey }) => columnKey === key) ?? false,
    }));

export const getTableData = (
    rows: GetGenericQueueResponse['data'],
    headers: GetGenericQueueOptionsResponse['headers']
): TableProps['data'] =>
    rows.map<TableProps['data'][number]>(rowData => {
        return headers.reduce(
            (acc, { key: headerKey, type }) => ({
                ...acc,
                [headerKey]: {
                    value:
                        rowData[headerKey]?.value == null ? '-' : columnRenders[type](rowData[headerKey] as RenderData),
                },
            }),
            {}
        );
    });

export const toFilterBy = (filters: Record<string, string[] | number | string | undefined>): FilterBy[] =>
    Object.entries(filters)
        .filter(([_, values]) => values != null && (!Array.isArray(values) || values.length > 0))
        .map(([datapoint, values]) => ({
            datapoint,
            values: values == null ? undefined : (wrap(values) as unknown as string[]),
        }));
