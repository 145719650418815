import type { DialogAction, SelectOption } from '@lemonade-hq/bluis';
import {
    AlertMode,
    Dialog,
    ErrorSection,
    FormInputWrapper,
    LoadingSection,
    Select,
    SuccessIcon,
} from '@lemonade-hq/bluis';
import { basicRequiredValidation, Flex, useForm } from '@lemonade-hq/cdk';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { GENERAL_ERROR_MSG } from 'commons/Constants';
import { ViolationsErrorMessage } from 'components/LoCo/common/components/ViolationsErrorMessage';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { EditionValidationsResponse } from 'models/LoCo/Insurance/CoveragesEdition';
import { useGetLatestMinors } from 'queries/LoCo/Insurance/BaseEditionQueries';
import { checkCoverageEditionCompatibility } from 'queries/LoCo/Insurance/CoveragesEditionQueries';

const StyledViolationWrapper = styled(Flex)`
    margin-top: 30px;
    gap: 10px;
`;

const StyledTitle = styled(Flex)`
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 40px;
    justify-content: center;
`;

const StyledSelect = styled(Select)`
    height: 38px;
    text-align: left;
`;

const NOTICE_MSG =
    'This action will check if there are any breaking changes in the current version against a selected base version';

const NO_VIOLATIONS_MSG = 'This version has no breaking changes compared to selected base version';

interface CheckCompatibilityDialogProps {
    readonly editionCode: string;
    readonly productCode: string;
    readonly productName: string;
    readonly baseEditionCode: string | undefined;
    readonly onClose: () => void;
}

export const CheckBackwardsCompatibilityDialog: React.FC<CheckCompatibilityDialogProps> = ({
    productCode,
    editionCode,
    productName,
    baseEditionCode,
    onClose,
}) => {
    const [compatibilityStatus, setCompatibilityStatus] = useState<{
        status: EditionValidationsResponse | null;
        isError: boolean;
        isLoading: boolean;
    } | null>(null);

    const { errors, values, setValue, valid } = useForm({
        fields: {
            baseEditionCode: {
                startValue: '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
        },
    });

    const {
        data: baseEditions,
        isLoading: isLoadingBaseEditions,
        isError: isErrorBaseEditions,
    } = useGetLatestMinors(productCode, EditionType.Coverages);

    const coverageEditionVersionOptions = useMemo<SelectOption[]>(
        () =>
            baseEditions?.map(baseEdition => ({
                id: baseEdition.code,
                value: baseEdition.code,
                label: `${baseEdition.friendlyName} (${baseEdition.version || baseEdition.code})`,
            })) ?? [],
        [baseEditions]
    );

    useEffect(() => {
        // preselect if cloned from one of the options
        const baseEditionInLatestMinors = baseEditions?.find(baseEdition => baseEdition.code === baseEditionCode);

        if (baseEditionInLatestMinors != null) {
            setValue('baseEditionCode', baseEditionInLatestMinors.code);
        }
    }, [baseEditionCode, baseEditions, setValue]);

    const checkBreakingChanges = useCallback(async () => {
        setCompatibilityStatus({
            isError: false,
            isLoading: true,
            status: null,
        });

        try {
            const response = await checkCoverageEditionCompatibility(editionCode, values.baseEditionCode);

            setCompatibilityStatus({
                isError: false,
                isLoading: false,
                status: response,
            });
        } catch (error) {
            setCompatibilityStatus({
                isError: true,
                isLoading: false,
                status: null,
            });
        }
    }, [editionCode, values.baseEditionCode]);

    function selectBaseEditionCode(selectedOption: SelectOption): void {
        setValue('baseEditionCode', selectedOption.value);
    }

    const isDisabled = compatibilityStatus?.isLoading ?? (Boolean(isLoadingBaseEditions) || isErrorBaseEditions);

    const breakingChanges = useMemo<string[] | null>(() => {
        return compatibilityStatus?.status?.hasBreakingChanges === true ? compatibilityStatus.status.messages : null;
    }, [compatibilityStatus?.status?.hasBreakingChanges, compatibilityStatus?.status?.messages]);

    const actions: DialogAction[] = useMemo(
        () => [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Check',
                type: 'submit',
                onClick: checkBreakingChanges,
                disabled: Boolean(isDisabled) || !valid,
            },
        ],
        [onClose, checkBreakingChanges, isDisabled, valid]
    );

    const showNotice = useMemo(() => {
        return compatibilityStatus == null || compatibilityStatus.isLoading;
    }, [compatibilityStatus]);

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={
                compatibilityStatus?.isError ? (
                    GENERAL_ERROR_MSG
                ) : breakingChanges ? (
                    <ViolationsErrorMessage
                        editionType={EditionType.Coverages}
                        message={`This version's breaking changes`}
                        violations={breakingChanges}
                    />
                ) : undefined
            }
            loading={Boolean(compatibilityStatus?.isLoading)}
            notice={showNotice ? [{ title: NOTICE_MSG, mode: AlertMode.Info }] : undefined}
            onClose={onClose}
            size="large"
            title="Check Backwards Compatibility"
        >
            {Boolean(isLoadingBaseEditions) && <LoadingSection noBorders noShadow />}
            {Boolean(isErrorBaseEditions) && <ErrorSection noBorders title="Failed fetching base editions  :(" />}
            {Boolean(baseEditions?.length) && (
                <>
                    <StyledTitle>{productName} Coverage Editions</StyledTitle>
                    <FormInputWrapper label="Base Edition" showErrors={!isEmpty(errors.baseEditionCode)}>
                        <StyledSelect
                            disabled={isDisabled}
                            onOptionSelected={selectBaseEditionCode}
                            options={coverageEditionVersionOptions}
                            placeholder="Select"
                            value={values.baseEditionCode}
                        />
                    </FormInputWrapper>
                </>
            )}

            {Boolean(compatibilityStatus?.status) && (
                <StyledViolationWrapper>
                    {breakingChanges == null && (
                        <>
                            <SuccessIcon />
                            {NO_VIOLATIONS_MSG}
                        </>
                    )}
                </StyledViolationWrapper>
            )}
        </Dialog>
    );
};
