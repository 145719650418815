import { ComboBox, Flex, spacing, Text, TextArea } from '@lemonade-hq/blender-ui';
import { trackEvent } from '@lemonade-hq/boutique';
import type { FC } from 'react';
import { AttachmentDialogBase } from './AttachmentDialogBase';
import { useUpdateDetails } from 'components/Attachments/ActionHooks/useUpdateDetails';
import { useAttachmentsData } from 'components/Attachments/context';
import type { AttachmentDTO } from 'components/Attachments/types';
import { getAttachmentAnalyticsParam } from 'components/Attachments/utils';

export const UpdateAttachmentDialog: FC<{
    readonly attachments: AttachmentDTO[];
    readonly onClose: () => void;
}> = ({ attachments, onClose }) => {
    const { entityType, entityPublicId } = useAttachmentsData();
    const {
        types,
        type,
        description,
        isTypesError,
        isTypesLoading,
        isSubmitPending,
        isSubmitError,
        handleSelectionChange,
        setDescription,
        handleSubmit,
        isInTypeList,
    } = useUpdateDetails({
        entityPublicId,
        entityType,
        attachments,
        onClose,
        savedType: attachments.length === 1 ? attachments[0].type : '',
        savedDescription: attachments.length === 1 ? attachments[0].description : '',
    });

    const onSubmit = async (): Promise<void> => {
        trackEvent('docs.gallery.clicked', {
            ...(attachments.length === 1 && {
                ...getAttachmentAnalyticsParam({
                    attachment: attachments[0],
                    entityType,
                    entityId: entityPublicId,
                }),
            }),
            doc_type: type,
            doc_description: description,
            name: 'relabel',
            source: 'button',
            ...(attachments.length > 1 && { is_bulk: 'true' }),
        });
        const attachmentsData = attachments.map(attachment => ({
            attachmentPublicId: attachment.publicId,
            ...(type !== '' && { type }),
            ...(description !== '' && { description }),
        }));
        await handleSubmit({ attachmentsData });
    };

    return (
        <AttachmentDialogBase
            attachments={attachments}
            error={
                isSubmitError
                    ? 'Error updating attachment details'
                    : isTypesError
                      ? 'Error loading attachment types'
                      : undefined
            }
            isLoading={isSubmitPending}
            // to handle legacy types that are not in the list
            isSubmitDisabled={((type === '' || isInTypeList === false) && description === '') || isSubmitPending}
            onClose={onClose}
            onSubmit={onSubmit}
            submitLabel="Done"
            title="Relabel attachments"
        >
            <Flex flexDirection="column" gap={spacing.s12}>
                <Flex flexDirection="column" gap={spacing.s06}>
                    <Text color="primary" type="label-sm">
                        Type
                    </Text>
                    <ComboBox
                        defaultValue={type}
                        disabled={isTypesLoading}
                        items={types}
                        onSelectionChange={handleSelectionChange}
                        placeholder="Select attachment type"
                    />
                </Flex>
                <Flex flexDirection="column" gap={spacing.s06}>
                    <Text color="primary" type="label-sm">
                        Description
                    </Text>
                    <TextArea
                        autoExpanding
                        maxLength={500}
                        onChange={e => setDescription(e.target.value)}
                        rows={5}
                        value={description}
                    />
                </Flex>
            </Flex>
        </AttachmentDialogBase>
    );
};
