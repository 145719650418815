import type { LayoutSprinklesProps } from '../../base/Layout/Layout.css';
import type { TextProps } from '../../base/Text/Text';
import type { TextVariants } from '../../base/Text/Text.css';
import type { TabsSize, TabsVariant } from './types';

export const getTabTextTypeBySize = (tabSize: TabsSize): NonNullable<TextVariants>['type'] => {
  switch (tabSize) {
    case 'sm':
      return 'text-sm';
    case 'md':
    default:
      return 'text-md';
  }
};

export const getCountTextType = (variant: TabsVariant, size: TabsSize): NonNullable<TextVariants>['type'] => {
  switch (variant) {
    case 'fill':
    case 'segmented':
      return 'text-sm';
    default:
      return getTabTextTypeBySize(size);
  }
};

export const getTabTextColor = (isSelected: boolean, disabled: boolean): TextProps<'span'>['color'] =>
  isSelected ? 'primary' : disabled ? 'disabled' : 'secondary';

export const getCounterBgColor = (
  variant: TabsVariant,
  isSelected: boolean,
): LayoutSprinklesProps['backgroundColor'] => {
  switch (variant) {
    case 'fill':
      return isSelected ? '$backgroundPrimary' : '$backgroundQuaternary';
    case 'segmented':
      return '$backgroundQuaternary';
    default:
      return '$backgroundPrimary';
  }
};

export const getTabFontWeightByVariant = (variant: TabsVariant, isSelected: boolean): 'bold' | 'regular' => {
  switch (variant) {
    case 'inline':
      return isSelected ? 'bold' : 'regular';
    default:
      return 'regular';
  }
};
