import type { FC } from 'react';

export const SortIcon: FC = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M4.704 6.414a.525.525 0 0 0 .742 0l2.647-2.648 2.648 2.648a.525.525 0 1 0 .742-.743L8.465 2.652a.525.525 0 0 0-.743 0l-3.018 3.02a.525.525 0 0 0 0 .742Z"
        fill="currentColor"
        fillRule="evenodd"
        id="sort-icon-up"
      />
      <path
        clipRule="evenodd"
        d="M11.396 9.704a.525.525 0 0 0-.742 0L8.006 12.35 5.36 9.704a.525.525 0 0 0-.743.742l3.019 3.019a.525.525 0 0 0 .742 0l3.019-3.019a.525.525 0 0 0 0-.742Z"
        fill="currentColor"
        fillRule="evenodd"
        id="sort-icon-down"
      />
    </svg>
  );
};
