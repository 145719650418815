import type { LabelValueItem } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { ChangeAccordion } from '../ChangeAccordion';
import { assertIsABTestRuleGroup, findFlag } from './common';
import type { FlagABTestEntry } from 'models/LoCo/Insurance/ChangesLog';
import { ChangeType } from 'models/LoCo/Insurance/ChangesLog';
import type { UnderwritingFiltersEdition } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

interface FlagABTestChangesProps {
    readonly change: FlagABTestEntry;
    readonly underwritingFiltersEdition: UnderwritingFiltersEdition;
    readonly baseUnderwritingFiltersEdition: UnderwritingFiltersEdition | null;
}

const TITLE_MAP = {
    [ChangeType.FlagABTestAttached]: 'A/B Test Added',
    [ChangeType.FlagABTestVariantsUpdated]: 'A/B Test Variants Updated',
    [ChangeType.FlagABTestExperimentChanged]: 'A/B Test Experiment Name Changed',
};

export const FlagABTestChanges: FC<FlagABTestChangesProps> = ({
    change,
    underwritingFiltersEdition,
    baseUnderwritingFiltersEdition,
}) => {
    const flag = findFlag(underwritingFiltersEdition, change.metadata.referenceId);
    const name = flag.flagName;
    const rows: LabelValueItem[] = [];

    const oldFlag = baseUnderwritingFiltersEdition?.flagRulesGroup.find(
        f => f.flagCode === change.metadata.referenceId
    );

    assertIsABTestRuleGroup(flag);

    if (change.changeType === ChangeType.FlagABTestExperimentChanged) {
        assertIsABTestRuleGroup(oldFlag);

        rows.push({ label: 'Old Experiment Name', value: oldFlag.experimentName });
        rows.push({ label: 'New Experiment Name', value: flag.experimentName });
    } else {
        rows.push({ label: 'Experiment Name', value: flag.experimentName });
    }

    rows.push({ label: 'Total Variants', value: flag.variants.length });

    return (
        <ChangeAccordion
            generalTabData={rows}
            originalRules={null}
            ruleType="flag"
            title={`${TITLE_MAP[change.changeType]}: ${name}`}
            updatedRules={null}
        />
    );
};
