import { capitalize } from '@lemonade-hq/ts-helpers';
import type { PropsWithChildren } from 'react';
import { OutcomeWrapper } from '../../../Dialog/OutcomeWrapper';
import type { CoveragesTemplateType } from 'models/LoCo/Insurance/CoverageRule';

export interface OutcomeProps {
    readonly coverageOrSettingName: string;
    readonly ruleEntityType: CoveragesTemplateType;
    readonly outcomeTitle: string;
}

export const Outcome: React.FC<PropsWithChildren<OutcomeProps>> = ({
    children,
    coverageOrSettingName,
    ruleEntityType,
    outcomeTitle,
}) => {
    return (
        <OutcomeWrapper subtitle={`${coverageOrSettingName} (${capitalize(ruleEntityType)})`} title={outcomeTitle}>
            {children}
        </OutcomeWrapper>
    );
};
