import { findCoverage, findSetting } from './changes/common';
import type {
    CoverageRelatedSettingsAndRules,
    CoverageRuleDisplayLog,
    SettingsRelatedCoveragesAndRules,
} from './types';
import { CoveragesTemplateType } from 'models/LoCo/Insurance/CoverageRule';
import type { CoveragesEdition, InstanceBase } from 'models/LoCo/Insurance/CoveragesEdition';
import { isCoverageRuleGroupABTest } from 'models/LoCo/Insurance/DigitalAgentEdition';
import type { DigitalAgentEdition, EntityGroups } from 'models/LoCo/Insurance/DigitalAgentEdition';

interface RulesFromCoverageAndDigitalAgentEditionsProps {
    readonly coveragesEdition: CoveragesEdition;
    readonly digitalAgentEdition: DigitalAgentEdition | null;
    readonly code: string;
}

export function getRelatedSettingsAndRulesFromCoverage({
    coveragesEdition,
    digitalAgentEdition,
    code: coverageCode,
}: RulesFromCoverageAndDigitalAgentEditionsProps): CoverageRelatedSettingsAndRules {
    const coverage = findCoverage(coveragesEdition, coverageCode);

    const relatedSettingsIds = coverage.relatedSettings.map(setting => setting.templateCode);
    const relatedSettings = coveragesEdition.settings.filter(setting =>
        relatedSettingsIds.includes(setting.templateCode)
    );

    const rules = getRules(coverage, CoveragesTemplateType.Coverage, digitalAgentEdition);

    return { rules, coverage, relatedSettings };
}

export function getRelatedCoveragesAndRulesFromSetting({
    coveragesEdition,
    digitalAgentEdition,
    code: settingCode,
}: RulesFromCoverageAndDigitalAgentEditionsProps): SettingsRelatedCoveragesAndRules {
    const setting = findSetting(coveragesEdition, settingCode);
    const relatedCoveragesIds = setting.relatedCoverages.map(c => c.templateCode);
    const relatedCoverages = coveragesEdition.coverages.filter(coverage =>
        relatedCoveragesIds.includes(coverage.templateCode)
    );

    const rules = getRules(setting, CoveragesTemplateType.Setting, digitalAgentEdition);

    return { rules, setting, relatedCoverages };
}

function getRules(
    instance: InstanceBase,
    entityType: CoveragesTemplateType,
    digitalAgentEdition: DigitalAgentEdition | null
): CoverageRuleDisplayLog[] {
    const filedRules = instance.relatedRules.map(rule => ({ filed: true, ...rule }));

    const nonFiledRules = getNonFiledRules(entityType, instance.templateCode, digitalAgentEdition?.entityGroups ?? []);
    return filedRules.concat(nonFiledRules);
}

export function getNonFiledRules(
    entityType: CoveragesTemplateType,
    entityCode: string,
    rules: EntityGroups[]
): CoverageRuleDisplayLog[] {
    return rules
        .filter(rule => rule.entityType === entityType && rule.entityCode === entityCode)
        .flatMap(rule =>
            Object.values(rule.rulesGroups).flatMap(r => {
                if (isCoverageRuleGroupABTest(r)) {
                    return r.variants.flatMap(variant =>
                        variant.rules.map(abTestRule => ({
                            ...abTestRule,
                            variantName: variant.variantName,
                            variantType: variant.variantType,
                            experimentName: r.experimentName,
                        }))
                    );
                }

                return r.rules;
            })
        )
        .map(rule => ({ filed: false, ...rule }));
}
