import { ManageSettingDialog } from './ManageSettingDialog2';
import { RemoveSettingDialog } from './RemoveSettingDialog';
import { ManageRuleDialog } from 'components/LoCo/common/components/CoverageRules/ManageRuleDialog';
import type { SettingActionData } from 'components/LoCo/products/SharedTableConfig';
import { DialogType } from 'components/LoCo/products/SharedTableConfig';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import { CoveragesTemplateType } from 'models/LoCo/Insurance/CoverageRule';
import type { SettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import {
    useAddSettingInstancesToEdition,
    useUpdateEditionSettingInstance,
} from 'queries/LoCo/Insurance/CoveragesEditionQueries';

export interface SettingInstancesDialogsProps {
    readonly editionCode: string;
    readonly dialog: SettingActionData | null;
    readonly onClose: () => void;
    readonly editionSettingInstances: SettingInstance[];
}

export const SettingInstancesDialogs: React.FC<SettingInstancesDialogsProps> = ({
    editionCode,
    dialog,
    onClose,
    editionSettingInstances,
}) => {
    const {
        mutateAsync: addSettingInstancesToEdition,
        isError: isAddMutationError,
        isPending: isLoadingAddMutation,
    } = useAddSettingInstancesToEdition(editionCode);

    const {
        mutateAsync: updateEditionSettingInstance,
        isError: isEditMutationError,
        isPending: isLoadingEditMutation,
    } = useUpdateEditionSettingInstance(editionCode);

    switch (dialog?.type) {
        case DialogType.Add:
            return (
                <ManageSettingDialog
                    editionCode={editionCode}
                    editionCoverages={dialog.editionCoverages}
                    editionSettingInstances={editionSettingInstances}
                    isLoadingMutation={isLoadingAddMutation}
                    isMutationError={isAddMutationError}
                    onClose={onClose}
                    onSubmit={async settingInstance =>
                        await addSettingInstancesToEdition({
                            editionCode,
                            settings: [settingInstance],
                        })
                    }
                />
            );
        case DialogType.Edit:
            return (
                <ManageSettingDialog
                    editedSettingInstance={dialog.data}
                    editionCode={editionCode}
                    editionCoverages={dialog.editionCoverages}
                    editionSettingInstances={editionSettingInstances}
                    isLoadingMutation={isLoadingEditMutation}
                    isMutationError={isEditMutationError}
                    onClose={onClose}
                    onSubmit={async settingInstance =>
                        await updateEditionSettingInstance({
                            editionCode,
                            settingTemplateCode: settingInstance.templateCode,
                            setting: settingInstance,
                        })
                    }
                />
            );
        case DialogType.Remove:
            return <RemoveSettingDialog editionCode={editionCode} onClose={onClose} setting={dialog.data} />;
        case DialogType.AddRule:
            return (
                <ManageRuleDialog
                    editionCode={editionCode}
                    editionType={EditionType.Coverages}
                    entity={{
                        code: dialog.data.templateCode,
                        type: CoveragesTemplateType.Setting,
                    }}
                    onClose={onClose}
                />
            );
        default:
            return null;
    }
};
