import { Radio } from '@lemonade-hq/bluis';

import { Step, StyledMultilineInputWrapper, StyledRadioWrapper } from '../ManageRuleCommon';
import { useManageRuleDialogContext } from '../ManageRuleDialogContext';
import { formatRuleType } from 'components/LoCo/common/display-texts/common';
import { QuoteLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import { CoverageRuleType } from 'models/LoCo/Insurance/CoverageRule';

export const RuleTypeSelection: React.FC = () => {
    const {
        values: { ruleType },
        dispatch,
    } = useManageRuleDialogContext();

    return (
        <Step gap="20px" width="530px">
            <StyledMultilineInputWrapper label="Rule Type">
                <StyledRadioWrapper>
                    {Object.values(CoverageRuleType).map(type => (
                        <Radio
                            checked={ruleType === type}
                            id={type}
                            key={type}
                            name="rule-type"
                            onChange={() => {
                                dispatch({ type: 'ruleType', value: type });
                                if (type === CoverageRuleType.Restriction) {
                                    dispatch({ type: 'lifecycleContexts', value: Object.values(QuoteLifecycleContext) });
                                }
                            }}
                        >
                            {formatRuleType(type)}
                        </Radio>
                    ))}
                </StyledRadioWrapper>
            </StyledMultilineInputWrapper>
        </Step>
    );
};
