import type { LabelValueItem } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { ChangeAccordion } from '../ChangeAccordion';
import { findFlag } from './common';
import type { FlagRuleStats } from 'models/LoCo/Insurance/ChangesLog';
import { isABTestRuleGroup } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import type { UnderwritingFiltersEdition } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

interface FlagRulesUpdatedProps {
    readonly baseUnderwritingFiltersEdition: UnderwritingFiltersEdition | null;
    readonly underwritingFiltersEdition: UnderwritingFiltersEdition;
    readonly ruleStats: FlagRuleStats;
}

export const FlagRulesUpdated: FC<FlagRulesUpdatedProps> = ({
    baseUnderwritingFiltersEdition,
    underwritingFiltersEdition,
    ruleStats,
}) => {
    const flag = findFlag(underwritingFiltersEdition, ruleStats.flagCode);
    const originalFlag = baseUnderwritingFiltersEdition?.flagRulesGroup.find(f => f.flagCode === ruleStats.flagCode);
    const originalRules =
        originalFlag != null
            ? isABTestRuleGroup(originalFlag)
                ? originalFlag.variants.flatMap(variant =>
                      variant.rules.map(abTestRule => ({
                          ...abTestRule,
                          variantName: variant.variantName,
                          variantType: variant.variantType,
                          experimentName: originalFlag.experimentName,
                      }))
                  )
                : originalFlag.rules
            : null;
    const updatedRules = isABTestRuleGroup(flag)
        ? flag.variants.flatMap(variant =>
              variant.rules.map(abTestRule => ({
                  ...abTestRule,
                  variantName: variant.variantName,
                  variantType: variant.variantType,
                  experimentName: flag.experimentName,
              }))
          )
        : flag.rules;

    const rows: LabelValueItem[] = [];
    const addRow = (value: number, label: string): void => {
        if (value > 0) rows.push({ label, value });
    };

    addRow(ruleStats.added, 'Added');
    addRow(ruleStats.removed, 'Removed');
    addRow(ruleStats.updated, 'Edited');

    return (
        <ChangeAccordion
            generalTabData={rows}
            originalRules={originalRules}
            ruleType={'flag'}
            title={`Flag rules updated - ${flag.flagName}`}
            updatedRules={updatedRules}
        />
    );
};
