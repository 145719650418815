import { Flex, spacing } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { ArchiveForm } from '../Archive/ArchiveForm';
import { useCarousel } from '../Carousel/CarouselProvider';
import { SidePanel } from '../Gallery.css';
import { GalleryAttachmentDetails } from './AttachmentDetails';
import { AttachmentSources } from './AttachmentSources/AttachmentSources';
import { TopActionsPanel } from './TopActionsPanel/TopActionsPanel';
import { useAttachmentsData } from 'components/Attachments/context';

const TopSidebar: FC<{ readonly onClose: () => void; readonly assignedToEntity: boolean }> = ({
    onClose,
    assignedToEntity,
}) => {
    const { attachments } = useAttachmentsData();
    const { currentIndex } = useCarousel();
    const attachment = useMemo(() => attachments[currentIndex], [attachments, currentIndex]);
    const shouldShowArchive =
        Boolean(attachment.suggestedArchive) && assignedToEntity && attachment.actions?.includes('archive');
    const [showArchive, setShowArchive] = useState(shouldShowArchive);

    return (
        <>
            <TopActionsPanel onClose={onClose} setShowArchive={setShowArchive} />
            {showArchive && <ArchiveForm onClose={() => setShowArchive(false)} />}
        </>
    );
};

export const InfoSidebar: FC<{ readonly onClose: () => void }> = ({ onClose }) => {
    const { attachments } = useAttachmentsData();
    const { currentIndex } = useCarousel();
    const attachment = useMemo(() => attachments[currentIndex], [attachments, currentIndex]);
    const archived = attachment.status === 'archived';
    // unassigned attachments are not editable, by default attachment are assigned to entity (i.e. claim)
    const docAssignedToEntity = attachment.assignedToEntity ?? true;

    return (
        <Flex className={SidePanel} flexDirection="column" gap={spacing.s16}>
            <TopSidebar assignedToEntity={docAssignedToEntity} key={attachment.publicId} onClose={onClose} />
            {docAssignedToEntity && (
                <>
                    {!archived && <GalleryAttachmentDetails />}
                    <AttachmentSources />
                </>
            )}
        </Flex>
    );
};
