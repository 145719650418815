import { Flex, Icon, Layout } from '@lemonade-hq/blender-ui';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { trackEvent } from '@lemonade-hq/boutique';
import { clsx } from 'clsx';
import { useCallback } from 'react';
import type { FC } from 'react';
import { AttachmentPreview } from '../AttachmentPreview';
import type { AttachmentDTO } from '../types';
import { AttachmentType, getAttachmentAnalyticsParam, getAttachmentType, getThumbnailUrl, isFraud } from '../utils';
import {
    dismissAttachment,
    fraudWarning,
    fraudWarningBG,
    hoverOverlay,
    thumbnail,
    thumbnailImage,
} from './AttachmentThumbnail.css';
import { Audio, Doc, Unknown, Video } from './ThumbnailIcons';

const AttachmentIcon: FC<{ readonly attachment: AttachmentDTO }> = ({ attachment }) => {
    const { contentType, url } = attachment;
    const attachmentType = getAttachmentType(contentType != null && contentType !== '' ? contentType : url);

    switch (attachmentType) {
        case AttachmentType.Doc:
        case AttachmentType.Pdf:
            return <Doc />;
        case AttachmentType.Image:
            return <img alt="" className={thumbnailImage} loading="lazy" src={getThumbnailUrl(attachment, 'tiny')} />;
        case AttachmentType.Audio:
            return <Audio />;
        case AttachmentType.Video:
            return <Video />;
        default:
            return <Unknown />;
    }
};

interface ThumbnailProps {
    readonly className?: string;
    readonly onClick?: (attachment: AttachmentDTO) => void;
    readonly openGallery?: (attachment: AttachmentDTO) => void;
    readonly onDismiss?: (attachment: AttachmentDTO) => void;
    readonly attachment: AttachmentDTO;
    readonly showPreview?: boolean;
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
}

export const AttachmentThumbnail: FC<ThumbnailProps> = ({
    className,
    onClick,
    attachment,
    onDismiss,
    showPreview = true,
    entityPublicId = '',
    entityType,
    openGallery,
}) => {
    const handleClick = useCallback(() => {
        if (openGallery != null) {
            openGallery(attachment);
            trackEvent('docs.gallery.clicked', {
                ...getAttachmentAnalyticsParam({
                    attachment,
                    entityType,
                    entityId: entityPublicId,
                }),
                name: 'open_gallery_view',
            });
        }

        onClick?.(attachment);
    }, [attachment, entityPublicId, entityType, onClick, openGallery]);

    const onDismissClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();

            onDismiss?.(attachment);
        },
        [attachment, onDismiss]
    );

    const content = (
        <Flex alignItems="center" className={clsx(className, thumbnail)} justifyContent="center" onClick={handleClick}>
            {showPreview && (
                <Flex alignItems="center" className={hoverOverlay} justifyContent="center">
                    <Icon color="light" name="eye-solid" />
                </Flex>
            )}
            {onDismiss && (
                <button className={dismissAttachment} onClick={onDismissClick} type="button">
                    <Icon color="neutral7" name="x-circle-solid" />
                </button>
            )}
            <AttachmentIcon attachment={attachment} />
            {isFraud(attachment) && (
                <>
                    <Flex alignItems="center" className={fraudWarning} justifyContent="center">
                        <Icon color="brand1" name="alert-circle-solid" />
                    </Flex>
                    <Layout className={clsx(fraudWarning, fraudWarningBG)} />
                </>
            )}
        </Flex>
    );

    if (showPreview) {
        return (
            <AttachmentPreview
                attachment={attachment}
                entityPublicId={entityPublicId}
                entityType={entityType}
                key={attachment.publicId}
                openGallery={openGallery}
            >
                {content}
            </AttachmentPreview>
        );
    }

    return content;
};
