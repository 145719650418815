import type { TooltipContentProps } from '@radix-ui/react-tooltip';
import { Content, Portal, Provider, Root, Trigger } from '@radix-ui/react-tooltip';
import type { FC, ReactNode } from 'react';
import { Text } from '../../base/Text/Text';
import * as styles from './Tooltip.css';

export interface TooltipProps {
  readonly children: ReactNode;
  readonly content: ReactNode;
  /** Whether the tooltip should be disabled, independent from the trigger. */
  readonly disabled?: boolean;
  readonly title?: string;
  readonly side?: TooltipContentProps['side'] | undefined;
  readonly defaultOpen?: boolean;
  readonly maxWidth?: string;
}

/**
 * A tooltip displays a description of an element on hover or focus.
 */
export const Tooltip: FC<TooltipProps> = ({
  children,
  content,
  disabled = false,
  title,
  side,
  defaultOpen,
  maxWidth,
}) => {
  return (
    <Provider delayDuration={200}>
      <Root defaultOpen={defaultOpen} open={disabled ? false : undefined}>
        <Trigger asChild>{typeof children === 'string' ? <span>{children}</span> : children}</Trigger>

        <Portal>
          <Content className={styles.content} side={side} sideOffset={5} style={{ maxWidth }}>
            {title != null && (
              <Text color="light" fontWeight="bold" type="text-md">
                {title}
              </Text>
            )}
            {typeof content === 'string' ? (
              <Text color="light" type="text-md">
                {content}
              </Text>
            ) : (
              content
            )}
          </Content>
        </Portal>
      </Root>
    </Provider>
  );
};
