import pluralize from 'pluralize';
import { EntityTypes, getProductTypeFromUrl } from '@lemonade-hq/bluiza';

/**
 * Retrieves the entity type from a given URL in Blender. will work on policies, claims, and quotes.
 *
 * @example
 * getEntityTypeFromUrl('/backoffice/home/claim/LQ1234')
 * -> EntityTypes.HomeClaim ('home_claim')
 */

export function getEntityTypeFromUrl(pathname: string): EntityTypes | null {
  const productLine = getProductTypeFromUrl(pathname);
  const entity = pluralize(pathname.toLowerCase().split('/')[3] ?? '', 1) as EntityTypes;
  const entityType = `${productLine}_${entity}` as EntityTypes;

  if (Object.values(EntityTypes).includes(entityType)) {
    return entityType;
  }

  return null;
}
