/* eslint-disable @typescript-eslint/naming-convention */
import type { RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import { clsx } from 'clsx';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import { Fragment } from 'react';
import type { SelectionMode } from '../../../../theme/selection';
import { useForm } from '../../FormContext';
import { FormInputGroup } from '../../FormLayout';
import { generateTypedFormComponents } from '../../typeHelpers';
import type { AssertedCheckboxProps } from '../Checkbox/Checkbox';
import type { AssertedComboBoxProps } from '../ComboBox/ComboBox';
import type { ErrorMessageProps } from '../ErrorMessage/ErrorMessage';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import type { AssertedInputProps } from '../Input/Input';
import type { LabelProps } from '../Label/Label';
import { Label } from '../Label/Label';
import type { AssertedRadioProps } from '../Radio/Radio';
import type { AssertedSelectProps } from '../Select/Select';
import type { AssertedTextAreaProps } from '../TextArea/TextArea';
import { horizontalErrorMessage, horizontalWrapper } from './inputGroup.css';

export interface InputComponents<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TMode extends SelectionMode = 'single',
> {
  readonly Select: AssertedSelectProps<TSchema, TSchemaKey, TMode>;
  readonly Checkbox: AssertedCheckboxProps<TSchema, TSchemaKey>;
  readonly Input: AssertedInputProps<TSchema, TSchemaKey>;
  readonly TextArea: AssertedTextAreaProps<TSchema, TSchemaKey>;
  readonly Radio: AssertedRadioProps<TSchema, TSchemaKey>;
  readonly ComboBox: AssertedComboBoxProps<TSchema, TSchemaKey>;
}

export type InputGroupPropsBase<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TComponentType extends keyof InputComponents<TSchema, NoInfer<TSchemaKey>>,
> = Omit<ErrorMessageProps<TSchema, NoInfer<TSchemaKey>>, 'children' | 'schemaKey'> &
  Omit<LabelProps<TSchema, NoInfer<TSchemaKey>>, 'children' | 'schemaKey'> &
  PropsWithChildren<{
    readonly schemaKey: TSchemaKey;
    readonly label?: string;
    readonly customErrorMessage?: string;
    readonly inputComponent?: TComponentType;
    readonly variant?: 'horizontal' | 'vertical';
  }>;

export type InputGroupProps<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TComponentType extends keyof InputComponents<TSchema, NoInfer<TSchemaKey>>,
  TMode extends SelectionMode = 'single',
> = InputGroupPropsBase<TSchema, TSchemaKey, TComponentType> &
  Omit<InputComponents<TSchema, NoInfer<TSchemaKey>, TMode>[NoInfer<TComponentType>], 'schemaKey'>;

const HorizontalWrapper: FC<PropsWithChildren> = ({ children }) => <div className={horizontalWrapper}>{children}</div>;

export const InputGroup = <
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TComponentType extends keyof InputComponents<TSchema, TSchemaKey>,
>(
  props,
): ReactNode => {
  const {
    schemaKey,
    label,
    customErrorMessage,
    inputComponent,
    tooltip,
    variant,
    children,
    requiredOverride,
    showOptional,
    ...rest
  } = props as InputGroupProps<TSchema, TSchemaKey, TComponentType>;

  const Component = (inputComponent ? generateTypedFormComponents()[inputComponent] : 'div') as FC;
  const { config } = useForm();
  const isVisible = config.schemaKeysRules?.[schemaKey]?.visible;

  const Wrapper = variant === 'horizontal' && label !== undefined ? HorizontalWrapper : Fragment;

  return (
    isVisible !== false && (
      <FormInputGroup>
        <Wrapper>
          {label !== undefined && (
            <Label
              requiredOverride={requiredOverride}
              schemaKey={schemaKey}
              showOptional={showOptional}
              tooltip={tooltip}
            >
              {label}
            </Label>
          )}
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {children ?? <Component {...(rest as any)} schemaKey={schemaKey} />}
          <ErrorMessage className={clsx({ [horizontalErrorMessage]: variant === 'horizontal' })} schemaKey={schemaKey}>
            {customErrorMessage}
          </ErrorMessage>
        </Wrapper>
      </FormInputGroup>
    )
  );
};
