import { Tooltip } from '@lemonade-hq/blender-ui';
import type { TableHeaderCellProps } from '@lemonade-hq/bluis';
import { EmptySection, ErrorSection, LoadingSection, Table, TableHeader, TableRow } from '@lemonade-hq/bluis';
import { EditionLink, getFlatEditions } from './EditionsTableShared';
import { getEditionName } from 'components/LoCo/common/editions/editionHelpers';
import { getFormattedDate } from 'components/LoCo/common/helpers/dateHelpers';
import type { Edition, EditionType } from 'models/LoCo/Insurance/BaseEdition';
import { useGetProductEditionsSummary } from 'queries/LoCo/Insurance/ProductQueries';

interface DraftEditionsTableProps {
    readonly productCode: string;
}

const headerFields: TableHeaderCellProps[] = [
    { key: 'type', value: 'Type', width: 150 },
    { key: 'edition', value: 'Edition', width: 200 },
    { key: 'description', value: 'Description', width: 300 },
    { key: 'approved', value: 'Approved', width: 200 },
];

const EditionToRow: React.FC<{
    readonly type: EditionType;
    readonly edition: Edition;
    readonly productCode: string;
}> = ({ type, edition, productCode }) => {
    const publishedAtDisplay = edition.approvedAt != null && getFormattedDate(edition.approvedAt);

    return (
        <TableRow
            key={edition.code}
            row={[
                { key: 'type', value: getEditionName(type) },
                { key: 'edition', value: <EditionLink edition={edition} productCode={productCode} type={type} /> },
                { key: 'description', value: edition.description },
                {
                    key: 'approved',
                    value:
                        edition.approvedAt != null && edition.approvedByName != null ? (
                            <Tooltip content={`By ${edition.approvedByName}`}>{publishedAtDisplay}</Tooltip>
                        ) : (
                            '-'
                        ),
                },
            ]}
        />
    );
};

export const ApprovedEditionsTable: React.FC<React.PropsWithChildren<DraftEditionsTableProps>> = ({ productCode }) => {
    const { data: editionsSummary, isError, isLoading } = useGetProductEditionsSummary(productCode, { approved: true });

    if (isLoading) {
        return <LoadingSection />;
    }

    if (!editionsSummary || isError) {
        return <ErrorSection title="Failed fetching approved editions :(" />;
    }

    const joinedEditions = getFlatEditions(editionsSummary);

    return joinedEditions.length === 0 ? (
        <EmptySection>No approved editions for this product</EmptySection>
    ) : (
        <Table>
            <TableHeader headers={headerFields} />
            {joinedEditions.map(edition => (
                <EditionToRow edition={edition} key={edition.code} productCode={productCode} type={edition.type} />
            ))}
        </Table>
    );
};
