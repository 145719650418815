import type { SelectOption } from '@lemonade-hq/bluis';
import { Select } from '@lemonade-hq/bluis';
import type { SelectOptionBase } from '@lemonade-hq/cdk';
import { Flex } from '@lemonade-hq/cdk';
import { capitalize } from '@lemonade-hq/ts-helpers';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Step } from '../ManageRuleCommon';
import { useManageRuleDialogContext, useRegistryContext } from '../ManageRuleDialogContext';

import { StyledInputWrapper } from 'components/LoCo/LoCoPagesSharedStyles';
import { CoveragesTemplateType } from 'models/LoCo/Insurance/CoverageRule';

const StyledWrapper = styled(Flex)`
    flex-direction: column;
    gap: 24px;
`;

interface EntityTypeMarkupProps {
    readonly entityName: string;
    readonly data: SelectOptionBase[];
    readonly entityCode: string;
    readonly showErrors?: boolean;
    readonly onEntityCodeSelect: (value: string) => void;
}

const EntityTypeMarkup: React.FC<EntityTypeMarkupProps> = ({
    data,
    entityName,
    entityCode,
    showErrors,
    onEntityCodeSelect,
}) => {
    return (
        <StyledInputWrapper label={capitalize(entityName)} showErrors={showErrors}>
            <Select
                onOptionSelected={option => onEntityCodeSelect(option.value)}
                options={data}
                placeholder="Select"
                value={entityCode}
            />
        </StyledInputWrapper>
    );
};

export type HighlightedEntities = Record<CoveragesTemplateType, string[]>;

interface EntitySelectionProps {
    readonly highlightedEntities?: HighlightedEntities;
}

export const EntitySelection: React.FC<EntitySelectionProps> = ({ highlightedEntities }) => {
    const {
        values: { entityType, entityCode },
        dispatch,
    } = useManageRuleDialogContext();
    const onEntityTypeSelect = useCallback(
        (value: CoveragesTemplateType) => dispatch({ type: 'entityType', value }),
        [dispatch]
    );
    const onEntityCodeSelect = useCallback(
        (value: string) => dispatch({ type: 'entityCode', value: value }),
        [dispatch]
    );

    const { coverages, settings } = useRegistryContext();

    const registry = entityType === CoveragesTemplateType.Coverage ? coverages : settings;

    const registryOptions: SelectOption[] = useMemo(() => {
        if (!entityType) return [];

        return registry.map(({ code, name }) => ({
            value: code,
            id: code,
            label: highlightedEntities?.[entityType].includes(code) ? <b>{name}</b> : name,
        }));
    }, [registry, highlightedEntities, entityType]);

    return (
        <Step width="530px">
            <StyledWrapper>
                <StyledInputWrapper label="Entity Type">
                    <Select
                        onOptionSelected={option => {
                            onEntityTypeSelect(option.value);
                            onEntityCodeSelect('');
                        }}
                        options={[
                            { label: 'Coverage', value: CoveragesTemplateType.Coverage, id: CoveragesTemplateType.Coverage },
                            { label: 'Setting', value: CoveragesTemplateType.Setting, id: CoveragesTemplateType.Setting },
                        ]}
                        placeholder="Select"
                        value={entityType}
                    />
                </StyledInputWrapper>

                {entityType && (
                    <EntityTypeMarkup
                        data={registryOptions}
                        entityCode={entityCode ?? ''}
                        entityName={capitalize(entityType)}
                        onEntityCodeSelect={onEntityCodeSelect}
                    />
                )}
            </StyledWrapper>
        </Step>
    );
};
