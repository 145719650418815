import { clsx } from 'clsx';
import type { PropsWithChildren } from 'react';
import { Children, isValidElement, useMemo } from 'react';
import type { LayoutProps } from '../../base/Layout/Layout';
import { Layout } from '../../base/Layout/Layout';
import type { CardProps } from '../Card/Card';
import { Card } from '../Card/Card';
import { commonCardPadding } from '../Card/Card.css';
import * as styles from './CardGrid.css';

type GridAreasDefinition = string[][];

type GridCardProps = CardProps & {
  readonly areas: GridAreasDefinition;
  readonly showSeparators?: boolean;
};

// NOTE: cherry-picking props since the layout sizing should be determined by the container CardGrid grid properties
type CardGridAreaProps = Pick<
  LayoutProps,
  | 'alignItems'
  | 'display'
  | 'flexDirection'
  | 'flexWrap'
  | 'overflow'
  | 'textAlign'
  | `className`
  | `gap`
  | `justifyContent`
  | `padding`
> & {
  readonly areaName: string;
  readonly variant?: 'primary' | 'quaternary' | 'secondary' | 'tertiary';
};

export const CardGridArea: React.FC<PropsWithChildren<CardGridAreaProps>> = ({
  children,
  padding = commonCardPadding,
  className: externalClassName,
  areaName,
  variant = 'primary',
  ...props
}) => {
  return (
    <Layout
      className={clsx(styles.cardGridArea({ variant }), externalClassName)}
      p={padding}
      style={{ gridArea: areaName }}
      {...props}
    >
      {children}
    </Layout>
  );
};

export const CardGrid: React.FC<PropsWithChildren<GridCardProps>> = ({
  children,
  className: externalClassName,
  areas,
  showSeparators,
  style,
  ...props
}) => {
  const flatAreas = useMemo(() => areas.flat(), [areas]);
  Children.forEach(children, child => {
    if (isValidElement(child)) {
      if (child.type !== CardGridArea) {
        throw new Error('CardGrid only accepts CardGridArea as children');
      }

      if (!flatAreas.includes((child.props as Record<string, string>).areaName)) {
        throw new Error('CardGridArea must have a valid area name');
      }
    }
  });

  return (
    <Card
      className={clsx(externalClassName, styles.cardGrid)}
      padding="0"
      style={{
        ...style,
        gap: showSeparators ? '1px' : 0,
        gridTemplateAreas: areas.map(areaRow => `"${areaRow.join(' ')}"`).join('\n'),
      }}
      {...props}
    >
      {children}
    </Card>
  );
};
