import type { GroupedAutocompleteOption, GroupedAutocompleteRoot } from '@lemonade-hq/bluis';
import type { AutocompleteOptionId } from '@lemonade-hq/cdk';
import type { Locale } from '@lemonade-hq/lemonation';
import { getRegion, LOCALES_PER_REGION } from '@lemonade-hq/lemonation';
import { titlize } from '@lemonade-hq/ts-helpers';
import { format } from 'date-fns';
import type {
  CardReference,
  MacrosBoard,
  MacrosBoardGroup,
  MacrosCard,
  MacrosCollection,
  MacrosConfig,
  MacrosParamsObject,
  MacrosParamsPartialObject,
  MacrosSection,
} from '../types';
import { MacrosParam, MacrosSupportedEntity, MacrosSupportedProductType, SupportedProductsForEntities } from '../types';

const isSection = (item: CardReference | MacrosSection): item is MacrosSection =>
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  (item as MacrosSection).cards !== undefined;

const mapCardToOption = (card: CardReference, parentId: string): GroupedAutocompleteOption => ({
  label: card.title,
  id: card.guruId,
  parentId,
});

const mapSectionToOptions = (
  { title, guruId, cards }: MacrosSection,
  parentTitle: string,
  searchByKey: boolean,
): GroupedAutocompleteOption[] =>
  cards.map(card => ({
    parentId: guruId,
    label: card.title,
    id: card.guruId,
    searchKey: searchByKey ? `${parentTitle} ${title} ${card.title}`.toLowerCase() : undefined,
  }));

const mapBoardToOptions = (
  { title: parentTitle, guruId: parentGuruId, items }: MacrosBoard,
  parentId: string,
  searchByKey: boolean,
): GroupedAutocompleteOption => ({
  label: parentTitle,
  id: parentGuruId,
  parentId,
  options: items.map(item => ({
    label: item.title,
    id: item.guruId,
    parentId: parentGuruId,
    options: isSection(item) ? mapSectionToOptions(item, parentTitle, searchByKey) : undefined,
  })),
});

export const mapToGroupedAutocompleteOption = (
  { title, boards, guruId }: MacrosBoardGroup,
  searchHierarchy: boolean,
): GroupedAutocompleteRoot => ({
  label: title,
  id: guruId,
  parentId: null,
  options: boards.map(board =>
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    board.items !== undefined ? mapBoardToOptions(board, guruId, searchHierarchy) : mapCardToOption(board, guruId),
  ),
});

export const mapProductToOptions = ({ entityType, locale }: MacrosCollection): GroupedAutocompleteRoot => {
  const region = getRegion(locale);
  const hasOneLocale = LOCALES_PER_REGION[region].length === 1;

  return {
    label: hasOneLocale ? titlize(entityType) : `${titlize(entityType)} (${locale})`,
    parentId: null,
    id: `${entityType}_${locale}`,
    options: SupportedProductsForEntities[entityType].map(product => ({
      label: titlize(product),
      id: product.toLowerCase(),
      parentId: `${entityType}_${locale}`,
      asyncOptions: true,
    })),
  };
};

export const mapEntityAndLocaleToOptions = (locale: Locale): GroupedAutocompleteOption[] => {
  const options: GroupedAutocompleteOption[] = [];

  Object.keys(MacrosSupportedEntity).forEach(entityType => {
    const region = getRegion(locale);

    const hasOneLocale = LOCALES_PER_REGION[region].length === 1;
    LOCALES_PER_REGION[region].forEach(currentLocale => {
      const label = hasOneLocale ? titlize(entityType) : `${titlize(entityType)} (${currentLocale})`;
      const id = `${entityType.toLowerCase()}_${currentLocale}`;

      options.push({
        label,
        id,
        parentId: null,
        asyncOptions: true,
      });
    });
  });

  return options;
};

export const hasCoreEntityInName = (name: string): boolean =>
  Object.values(MacrosSupportedEntity).findIndex(entity => name.toLowerCase().includes(entity.toLowerCase())) > -1;

export const hasProductInName = (name: string): boolean =>
  Object.values(MacrosSupportedProductType).includes(name.toLowerCase() as MacrosSupportedProductType);

export const getCardsFromOptions = (
  options: GroupedAutocompleteOption[] | GroupedAutocompleteRoot,
  allOptions: GroupedAutocompleteOption[],
): GroupedAutocompleteOption[] => {
  const currentOptions = Array.isArray(options) ? options : options.options;

  const result = currentOptions.reduce((acc, option) => {
    if (option.options !== undefined) {
      acc.concat(getCardsFromOptions(option.options, allOptions));
    } else {
      acc.push(option);
    }

    return acc;
  }, allOptions);

  return result;
};

export const replaceKnownParams = (content: string, params: MacrosParamsPartialObject): string => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (acc.includes(`{${key}}`) && value != null) {
      const toReplace = acc.split(`{${key}}`).join(value);
      return toReplace;
    }

    return acc;
  }, content);
};

export const replaceUnknownParams = (content: string): string => {
  const newContent = content.split('{').join('{👉');
  return newContent.split('}').join('👈}');
};

export const getRelevantOptionsForSearch = (
  pathInOptions: AutocompleteOptionId[],
  currentOptions: GroupedAutocompleteOption[] | GroupedAutocompleteRoot,
): GroupedAutocompleteOption[] | GroupedAutocompleteRoot | null => {
  let optionsForSearch = currentOptions;

  for (const id of pathInOptions) {
    const options = Array.isArray(optionsForSearch) ? optionsForSearch : optionsForSearch.options;
    const newOptions = options.find(option => option.id === id);
    if (newOptions?.options === undefined) {
      return null;
    }

    optionsForSearch = newOptions.options;
  }

  return optionsForSearch;
};

export const getMacrosGeneralParams = (): MacrosParamsPartialObject => ({
  [MacrosParam.TodaysDate]: format(new Date(), 'MMMM dd, yyyy'),
});

const sortOptions = (
  currentOptions: GroupedAutocompleteOption[],
  order: 'asc' | 'desc',
): GroupedAutocompleteOption[] => {
  currentOptions.forEach(({ options = [] }) => sortOptions(options, order));
  currentOptions.sort((one, two) =>
    order === 'desc' ? (one.label > two.label ? -1 : 1) : one.label > two.label ? 1 : -1,
  );

  return currentOptions;
};

export const sortGroupedOptions = (
  currentOptions: GroupedAutocompleteOption[] | GroupedAutocompleteRoot,
  order: 'asc' | 'desc',
): GroupedAutocompleteOption[] | GroupedAutocompleteRoot => {
  if (Array.isArray(currentOptions)) return sortOptions(currentOptions, order);

  return {
    label: currentOptions.label,
    id: currentOptions.id,
    parentId: currentOptions.parentId,
    options: sortOptions(currentOptions.options, order),
  };
};

export const clearNestedAnchorLinks = (content: string): string => {
  if (content.includes('href')) {
    const tempElement = document.createElement('div');

    tempElement.innerHTML = content;

    tempElement.querySelectorAll('a').forEach(link => {
      const linkContent = link.firstElementChild?.lastElementChild;

      Array.from(link.children).forEach(child => {
        child.parentElement?.removeChild(child);
      });

      if (linkContent) link.append(linkContent);
    });

    return tempElement.innerHTML;
  }

  return content;
};

export const formatMacrosContent = (
  card: MacrosCard,
  params: Partial<MacrosParamsObject> | undefined,
  config?: MacrosConfig,
): string => {
  let parsedContent = params ? replaceKnownParams(card.content, params) : card.content;
  parsedContent = replaceUnknownParams(parsedContent);

  const forcedAdditionalSpacing = config?.forcedAdditionalSpacing ?? true;
  if (forcedAdditionalSpacing) {
    parsedContent = parsedContent.split('</p>').join('</p></br>');
  }

  if (config?.convertBrTagToLineBreak) {
    parsedContent = parsedContent.split('<br>').join('\n');
  }

  return clearNestedAnchorLinks(parsedContent);
};
