import React from 'react';
import { ManageRuleDialog } from '../../../../common/components/CoverageRules/ManageRuleDialog';
import { AddCoverageDialog } from './AddCoverageDialog';
import { EditCoverageDialog } from './EditCoverageDialog';
import { RemoveCoverageDialog } from './RemoveCoverageDialog';
import type { CoverageActionData } from 'components/LoCo/products/SharedTableConfig';
import { DialogType } from 'components/LoCo/products/SharedTableConfig';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import { CoveragesTemplateType } from 'models/LoCo/Insurance/CoverageRule';

interface DialogsProps {
    readonly editionCode: string;
    readonly dialog: CoverageActionData | null;
    readonly onClose: () => void;
}

export const CoverageInstancesDialogs: React.FC<DialogsProps> = ({ editionCode, dialog, onClose }) => {
    switch (dialog?.type) {
        case DialogType.Add:
            return <AddCoverageDialog coverageInstances={dialog.data} editionCode={editionCode} onClose={onClose} />;
        case DialogType.Edit:
            return <EditCoverageDialog coverage={dialog.data} editionCode={editionCode} onClose={onClose} />;
        case DialogType.Remove:
            return <RemoveCoverageDialog coverage={dialog.data} editionCode={editionCode} onClose={onClose} />;
        case DialogType.AddRule:
            return (
                <ManageRuleDialog
                    editionCode={editionCode}
                    editionType={EditionType.Coverages}
                    entity={{
                        code: dialog.data.templateCode,
                        type: CoveragesTemplateType.Coverage,
                    }}
                    onClose={onClose}
                />
            );
        default:
            return null;
    }
};
