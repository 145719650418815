import { Flex, Icon, spacing, Tooltip } from '@lemonade-hq/blender-ui';
import { capitalize } from '@lemonade-hq/ts-helpers';
import { getInsuranceScopeTypeDisplayName } from './setting-instance';
import { QuoteLifecycleContext, VersionType } from 'models/LoCo/Insurance/BaseEdition';
import { CoverageRuleType } from 'models/LoCo/Insurance/CoverageRule';
import type {
    CoverageInstance,
    DeductibleSettingInstance,
    LimitSettingInstance,
} from 'models/LoCo/Insurance/CoveragesEdition';
import { EntityScopeValueSelectionMethod, InsuranceScopeType } from 'models/LoCo/Insurance/CoveragesEdition';
import type { InsurableEntity } from 'models/LoCo/Insurance/Product';
import type { SchemaType } from 'models/LoCo/Insurance/Schema';

export function formatRuleType(type: CoverageRuleType): string {
    switch (type) {
        case CoverageRuleType.InitialValue:
            return 'Initial Value';
        case CoverageRuleType.Restriction:
            return 'Restriction';
        default:
            return capitalize(type);
    }
}

export function formatLifeCycleContext(type: QuoteLifecycleContext): string {
    switch (type) {
        case QuoteLifecycleContext.PolicyEdits:
            return 'Policy Edits';
        case QuoteLifecycleContext.Moving:
            return 'Moving';
        case QuoteLifecycleContext.NewBusiness:
            return 'New Business';
        case QuoteLifecycleContext.Renewal:
            return 'Renewal';
        default:
            return capitalize(type);
    }
}

export function getSchemaTypeName(schemaType: SchemaType): string {
    switch (schemaType) {
        case 'product':
            return 'Product';
        case 'platform':
            return 'Platform';
        default:
            throw new Error('Unknown schema type');
    }
}

export function getVersionTypeDisplayName(versionType: VersionType): string {
    switch (versionType) {
        case VersionType.Major:
            return 'New Business & Renewals';
        case VersionType.Minor:
            return 'Product Update';
        case VersionType.BugFix:
            return 'Bug Fix';
        default:
            return '';
    }
}

export function displayBoolean(value: boolean): string {
    return value ? 'Yes' : 'No';
}

export function getScopeEntityName(
    instance: CoverageInstance | DeductibleSettingInstance | LimitSettingInstance,
    insuredEntityName?: string
): string {
    if (instance.scope.type === InsuranceScopeType.ExternalEntity) {
        return `Per ${instance.scope.name}`;
    }

    if (instance.scope.type === InsuranceScopeType.InsuredEntity) {
        return `Per ${insuredEntityName ?? 'Entity'}`;
    }

    return getInsuranceScopeTypeDisplayName(instance.scope.type);
}

export function getScopeEntityNameWithInfo(
    instance: CoverageInstance | DeductibleSettingInstance | LimitSettingInstance,
    insurableEntities: InsurableEntity[]
): JSX.Element {
    const { scope } = instance;
    let insuredEntityName;
    let scopeInfo;

    if (scope.type === InsuranceScopeType.InsuredEntity) {
        insuredEntityName = insurableEntities.find(e => e.code === scope.insuredEntityCode)?.name;

        if (scope.valueSelectionMethod === EntityScopeValueSelectionMethod.PerEntity) {
            scopeInfo = `Values selected per-${insuredEntityName?.toLocaleLowerCase() ?? 'entity'}`;
        } else if (scope.valueSelectionMethod === EntityScopeValueSelectionMethod.Stacked) {
            scopeInfo = 'Stacked (one value selected per policy)';
        }
    }

    const scopeText = getScopeEntityName(instance, insuredEntityName);

    return (
        <Flex alignItems="center" gap={spacing.s06}>
            {scopeText}
            {scopeInfo != null && (
                <Tooltip content={scopeInfo}>
                    <Icon color="textTertiary" name="info-circle-solid" />
                </Tooltip>
            )}
        </Flex>
    );
}
