import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { blenderGeneral } from '../../../apiClients';

const BASE_URL = '/api/v1/ai-playground';

interface GenerateDescriptionResponse {
    readonly generated: string;
}

interface GenerateDescriptionPayload {
    readonly toolsRevisionPublicId: string;
    readonly toolName: string;
}

export function useGenerateToolDescription(): UseMutationResult<string, unknown, GenerateDescriptionPayload> {
    return useMutation({
        mutationFn: async ({ toolsRevisionPublicId, toolName }: GenerateDescriptionPayload) => {
            const url = `${BASE_URL}/generate/description`;

            const response = await blenderGeneral.post<{ readonly data: GenerateDescriptionResponse }>(url, {
                toolsRevisionPublicId,
                toolName,
            });
            return response.data.data.generated;
        },
    });
}
