import type { CoverageRuleType, CoveragesTemplateType } from './CoverageRule';
import type { CoverageRuleGroupType } from './DigitalAgentEdition';
import type { UnderwritingDecisionLifecycleContext } from './UnderwritingFiltersEdition';

interface InstanceBinding {
    readonly settingTemplateCode: string;
    readonly coverageTemplateCode: string;
}

export enum ChangeType {
    CoverageAdded = 'coverage_added',
    CoverageRemoved = 'coverage_removed',
    CoverageAttributesUpdated = 'coverage_attributes_updated',
    CoverageSetToRequired = 'coverage_set_to_required',
    CoverageSetToOptional = 'coverage_set_to_optional',
    SettingAdded = 'setting_added',
    SettingRemoved = 'setting_removed',
    SettingAttributesUpdated = 'setting_attributes_updated',
    SettingValuesUpdated = 'setting_values_updated',
    CoverageBindingAdded = 'coverage_binding_added',
    CoverageBindingRemoved = 'coverage_binding_removed',
    CoupledSettingsAdded = 'coupled_settings_added',
    CoupledSettingsRemoved = 'coupled_settings_removed',
    ProductSchemaUpdated = 'product_schema_updated',
    PlatformSchemaUpdated = 'platform_schema_updated',
    CoverageRulesUpdated = 'coverage_rules_updated',
    DeclineRulesUpdated = 'decline_rules_updated',
    FlagRulesUpdated = 'flag_rules_updated',
    FlagChanges = 'flag_changes',
    // AB Test
    CoverageRuleABTestVariantsUpdated = 'coverage_rule_ab_test_variants_updated',
    CoverageRuleABTestAttached = 'coverage_rule_ab_test_attached',
    CoverageRuleABTestDetached = 'coverage_rule_ab_test_detached',
    CoverageRuleABTestExperimentChanged = 'coverage_rule_ab_test_experiment_changed',
    FlagABTestVariantsUpdated = 'flag_ab_test_variants_updated',
    FlagABTestAttached = 'flag_ab_test_attached',
    FlagABTestDetached = 'flag_ab_test_detached',
    FlagABTestExperimentChanged = 'flag_ab_test_experiment_changed',
}

interface BaseChangesLogEntry<TChangeType extends ChangeType, TChangesLogMetadata extends object = object> {
    readonly changeType: TChangeType;
    readonly metadata: TChangesLogMetadata;
}

type ReferenceIdType =
    | ChangeType.CoverageAdded
    | ChangeType.CoverageRemoved
    | ChangeType.CoverageSetToOptional
    | ChangeType.CoverageSetToRequired
    | ChangeType.SettingAdded
    | ChangeType.SettingRemoved
    | ChangeType.SettingValuesUpdated;

export type ReferenceIdEntry = BaseChangesLogEntry<ReferenceIdType, ReferenceIdMetadata>;
export type AttributesUpdatedEntry = BaseChangesLogEntry<
    ChangeType.CoverageAttributesUpdated | ChangeType.SettingAttributesUpdated,
    AttributesUpdatedMetadata
>;
export type BindingEntry = BaseChangesLogEntry<
    ChangeType.CoverageBindingAdded | ChangeType.CoverageBindingRemoved,
    InstanceBinding
>;
export type SchemaUpdatedEntry = BaseChangesLogEntry<
    ChangeType.PlatformSchemaUpdated | ChangeType.ProductSchemaUpdated
>;
export type CoverageRulesUpdatedEntry = BaseChangesLogEntry<
    ChangeType.CoverageRulesUpdated,
    CoveragesRulesUpdatedMetadata
>;
export type DeclineRulesUpdatedEntry = BaseChangesLogEntry<ChangeType.DeclineRulesUpdated, DeclineRuleStats>;
export type FlagRulesUpdatedEntry = BaseChangesLogEntry<ChangeType.FlagRulesUpdated, FlagRuleStats>;
export type FlagChangesEntry = BaseChangesLogEntry<ChangeType.FlagChanges, FlagChanges>;
export type CoupledSettingsEntry = BaseChangesLogEntry<
    ChangeType.CoupledSettingsAdded | ChangeType.CoupledSettingsRemoved,
    { readonly coupledSettingCodes: [string, string] }
>;
export type CoverageRuleABTestEntry = BaseChangesLogEntry<
    | ChangeType.CoverageRuleABTestAttached
    | ChangeType.CoverageRuleABTestExperimentChanged
    | ChangeType.CoverageRuleABTestVariantsUpdated,
    EntityABTestMetadata
>;

export type CoverageRuleABTestDetachedEntry = BaseChangesLogEntry<
    ChangeType.CoverageRuleABTestDetached,
    EntityABTestMetadata
>;

export type FlagABTestEntry = BaseChangesLogEntry<
    ChangeType.FlagABTestAttached | ChangeType.FlagABTestExperimentChanged | ChangeType.FlagABTestVariantsUpdated,
    ReferenceIdMetadata
>;

export type FlagABTestDetachedEntry = BaseChangesLogEntry<ChangeType.FlagABTestDetached, ReferenceIdMetadata>;

export type EntityABTestMetadata = RuleGroupIdentifier;

export interface RuleGroupIdentifier {
    readonly entityType: CoveragesTemplateType;
    readonly entityCode: string;
    readonly groupType: CoverageRuleGroupType;
}

interface ReferenceIdMetadata {
    readonly referenceId: string;
}

type AttributesUpdatedMetadata = ReferenceIdMetadata & {
    readonly changedAttributes: string[];
};

export interface RuleStats {
    readonly added: number;
    readonly removed: number;
    readonly updated: number;
}

interface DeclineRuleStats extends RuleStats {
    readonly lifecycleContext: UnderwritingDecisionLifecycleContext;
    readonly reordered: boolean;
}

interface CoverageRuleStats {
    readonly [CoverageRuleType.Restriction]: RuleStats;
    readonly [CoverageRuleType.InitialValue]: RuleStats;
}

interface CoveragesRulesUpdatedMetadata {
    readonly entityCode: string;
    readonly entityType: CoveragesTemplateType;
    readonly filed: CoverageRuleStats;
    readonly nonFiled: CoverageRuleStats;
    readonly nonFiledRulesReordered: boolean;
}

export interface FlagRuleStats extends RuleStats {
    readonly flagCode: string;
}

interface FlagChanges {
    readonly added: string[];
    readonly removed: string[];
}

export type ChangesLogEntry =
    | AttributesUpdatedEntry
    | BindingEntry
    | CoupledSettingsEntry
    | CoverageRuleABTestDetachedEntry
    | CoverageRuleABTestEntry
    | CoverageRulesUpdatedEntry
    | DeclineRulesUpdatedEntry
    | FlagABTestDetachedEntry
    | FlagABTestEntry
    | FlagChangesEntry
    | FlagRulesUpdatedEntry
    | ReferenceIdEntry
    | SchemaUpdatedEntry;

export interface ChangesLogResponse {
    readonly entries: ChangesLogEntry[];
}
