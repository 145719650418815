import type { LabelValueItem } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { ChangeAccordion } from '../ChangeAccordion';
import { assertDigitalAgentEditionIsNotNull, assertIsCoverageRuleGroupABTest } from './common';
import { titlize, toReadable } from 'commons/StringUtils';
import type { CoverageRuleABTestEntry } from 'models/LoCo/Insurance/ChangesLog';
import { ChangeType } from 'models/LoCo/Insurance/ChangesLog';
import type { DigitalAgentEdition } from 'models/LoCo/Insurance/DigitalAgentEdition';

interface CoverageRuleABTestChangesProps {
    readonly change: CoverageRuleABTestEntry;
    readonly digitalAgentEdition: DigitalAgentEdition;
    readonly baseDigitalAgentEdition: DigitalAgentEdition | null;
}

const TITLE_MAP = {
    [ChangeType.CoverageRuleABTestAttached]: 'A/B Test Added',
    [ChangeType.CoverageRuleABTestVariantsUpdated]: 'A/B Test Variants Updated',
    [ChangeType.CoverageRuleABTestExperimentChanged]: 'A/B Test Experiment Name Changed',
};

export const CoverageRuleABTestChanges: FC<CoverageRuleABTestChangesProps> = ({
    change,
    digitalAgentEdition,
    baseDigitalAgentEdition,
}) => {
    assertDigitalAgentEditionIsNotNull(digitalAgentEdition);
    assertDigitalAgentEditionIsNotNull(baseDigitalAgentEdition);

    const entityGroup = digitalAgentEdition.entityGroups.find(group => group.entityCode === change.metadata.entityCode);
    const rulesGroup = entityGroup?.rulesGroups[change.metadata.groupType];
    const name = `${entityGroup?.entityName} - ${titlize(toReadable(change.metadata.groupType))}`;
    const rows: LabelValueItem[] = [];

    const oldEntityGroup = baseDigitalAgentEdition.entityGroups.find(
        group => group.entityCode === change.metadata.entityCode
    );
    const oldRulesGroup = oldEntityGroup?.rulesGroups[change.metadata.groupType];

    assertIsCoverageRuleGroupABTest(rulesGroup);

    if (change.changeType === ChangeType.CoverageRuleABTestExperimentChanged) {
        assertIsCoverageRuleGroupABTest(oldRulesGroup);

        rows.push({ label: 'Old Experiment Name', value: oldRulesGroup.experimentName });
        rows.push({ label: 'New Experiment Name', value: rulesGroup.experimentName });
    } else {
        rows.push({ label: 'Experiment Name', value: rulesGroup.experimentName });
    }

    rows.push({ label: 'Total Variants', value: rulesGroup.variants.length });

    return (
        <ChangeAccordion
            generalTabData={rows}
            originalRules={null}
            ruleType="coverage"
            title={`${TITLE_MAP[change.changeType]}: ${name}`}
            updatedRules={null}
        />
    );
};
