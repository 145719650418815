import type { FC } from 'react';
import { ChangeAccordion } from '../ChangeAccordion';
import { assertIsCoverageRuleGroupABTest } from './common';
import { titlize, toReadable } from 'commons/StringUtils';
import type { CoverageRuleABTestDetachedEntry } from 'models/LoCo/Insurance/ChangesLog';
import type { DigitalAgentEdition } from 'models/LoCo/Insurance/DigitalAgentEdition';

interface CoverageRuleABTestDetachedProps {
    readonly change: CoverageRuleABTestDetachedEntry;
    readonly digitalAgentEdition: DigitalAgentEdition;
    readonly baseDigitalAgentEdition: DigitalAgentEdition;
}

export const CoverageRuleABTestDetached: FC<CoverageRuleABTestDetachedProps> = ({
    change,
    digitalAgentEdition,
    baseDigitalAgentEdition,
}) => {
    const entityGroup = digitalAgentEdition.entityGroups.find(group => group.entityCode === change.metadata.entityCode);
    const name = `${entityGroup?.entityName} - ${titlize(toReadable(change.metadata.groupType))}`;

    const oldEntityGroup = baseDigitalAgentEdition.entityGroups.find(
        group => group.entityCode === change.metadata.entityCode
    );
    const oldRulesGroup = oldEntityGroup?.rulesGroups[change.metadata.groupType];

    assertIsCoverageRuleGroupABTest(oldRulesGroup);

    const rows = [{ label: 'Experiment Name', value: oldRulesGroup.experimentName }];

    return (
        <ChangeAccordion
            generalTabData={rows}
            originalRules={null}
            ruleType="coverage"
            title={`A/B Test Removed: ${name}`}
            updatedRules={null}
        />
    );
};
