import { IconButton } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import * as styles from '../../PlaygroundPage.css';

export const PlaygroundMinimizedChat: FC<{ readonly open: () => void }> = ({ open }) => {
    return (
        <div className={styles.minimizedChat}>
            <IconButton color="neutral7" icon="chat-message" iconSize="xl" onClick={open} size="lg" variant="inline" />
        </div>
    );
};
