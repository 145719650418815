import { Spinner } from '@lemonade-hq/blender-ui';
import { InlineButton } from '@lemonade-hq/bluis';
import { Flex, themedColor } from '@lemonade-hq/cdk';
import * as monacoEditor from '@monaco-editor/react';
import type { editor } from 'monaco-editor/esm/vs/editor/editor.api';
import type { MutableRefObject } from 'react';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useManageRuleDialogContext } from '../CoverageRules/ManageRuleDialogContext';
import type { InputFunction } from '../ExpressionSimpleEditor/operators';
import { generateTypesLib } from './generateTypesLib';

import { CoveragesTemplateType } from 'models/LoCo/Insurance/CoverageRule';
import type { SchemaResponse } from 'models/LoCo/Insurance/Schema';
import { useGetCoveragesEdition } from 'queries/LoCo/Insurance/CoveragesEditionQueries';

interface ExpressionFreeTextEditorProps {
    readonly productSchema: SchemaResponse;
    readonly editorValueRef: MutableRefObject<string | null>;
    readonly expression: string;
    readonly onChange?: (value: string) => void;
    readonly onValidation?: (isValid: boolean) => void;
    readonly additionalFunctions?: Record<string, InputFunction>;
}

const StyledButtonWrapper = styled(Flex)`
    gap: 6px;
    align-items: baseline;
`;

const StyledIcon = styled(Flex)`
    color: ${themedColor('primary')};
`;

const Wrapper = styled(Flex)`
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
`;

const StyledEditor = styled(monacoEditor.Editor)`
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #d1d1d1;
`;

export const ExpressionFreeTextEditor: React.FC<ExpressionFreeTextEditorProps> = ({
    productSchema,
    editorValueRef,
    expression,
    onValidation,
    onChange,
    additionalFunctions = {},
}) => {
    const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
    const {
        values: { entityCode, entityType },
    } = useManageRuleDialogContext();
    const { coveragesEditionCode } = useParams<{
        coveragesEditionCode: string;
    }>();

    const { data, isPending } = useGetCoveragesEdition(coveragesEditionCode);

    if (isPending) return <Spinner size="sm" />;

    const instance = (entityType === CoveragesTemplateType.Coverage ? data?.coverages : data?.settings)?.find(
        ({ templateCode }) => templateCode === entityCode
    );

    const scope = typeof instance === 'object' && 'scope' in instance ? instance.scope : undefined;

    return (
        <Wrapper>
            <StyledEditor
                beforeMount={monaco => {
                    monaco.languages.typescript.typescriptDefaults.addExtraLib(
                        generateTypesLib(
                            productSchema,
                            additionalFunctions,
                            data?.coverages.map(c => c.templateCode) ?? [],
                            data?.settings.map(c => c.templateCode) ?? [],
                            scope
                        )
                    );

                    monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
                        ...monaco.languages.typescript.typescriptDefaults.getCompilerOptions(),
                    });

                    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
                        ...monaco.languages.typescript.javascriptDefaults.getCompilerOptions(),
                        noLib: true,
                    });

                    onValidation?.(true);
                }}
                height="80px"
                language="typescript"
                onChange={value => {
                    if (editorValueRef.current === null) {
                        return;
                    }

                    editorValueRef.current = value ?? '';
                    onChange?.(value ?? '');
                    onValidation?.(true);
                }}
                onMount={editor => {
                    editorRef.current = editor;
                    editorRef.current.setValue(expression);
                    editorValueRef.current = expression;
                }}
                onValidate={markers => {
                    if (editorValueRef.current === null) {
                        return;
                    }

                    onValidation?.(markers.length === 0);
                }}
                options={{
                    wordBasedSuggestions: 'off',
                    minimap: { enabled: false },
                    suggest: {
                        showKeywords: false,
                        showModules: false,
                        showVariables: false,
                        showOperators: true,
                    },
                    fontSize: 14,
                    scrollBeyondLastLine: false,
                    scrollBeyondLastColumn: 0,
                    contextmenu: false,
                    fixedOverflowWidgets: true,
                }}
            />
            <StyledButtonWrapper>
                <StyledIcon>&#x2139;</StyledIcon>
                <InlineButton
                    onClick={() => {
                        editorRef.current?.trigger('keyboard', 'editor.action.triggerSuggest', {});
                    }}
                >
                    Show suggestions
                </InlineButton>
            </StyledButtonWrapper>
        </Wrapper>
    );
};
