import type { ComboBoxItem, ListItemProps } from '@lemonade-hq/blender-ui';
import { Avatar, Card, ComboBox, Flex, listItem, spacing, Table, Text } from '@lemonade-hq/blender-ui';
import { ErrorSection } from '@lemonade-hq/bluis';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AssigneeItem } from './AssigneeItem';
import { assigneeComboBox, container } from './GenericQueue.css';
import { GenericQueuePagination } from './GenericQueuePagination';
import { useGetGenericQueue, useGetGenericQueueAssignees, useGetGenericQueueOptions } from './GenericQueueQueries';
import { GenericQueueTableHeader } from './GenericQueueTableHeader';
import { getTableColumns, getTableData, toFilterBy } from './utils';

interface StaticFilters extends Record<string, string[] | number | string | null | undefined> {
    readonly assignee?: string;
    readonly selectedTab?: string;
    readonly page: number;
    readonly size?: number;
    readonly sortingBy?: string;
    readonly sortDirection?: 'asc' | 'desc';
}

const STATIC_URL_PARAMS = [
    'assignee',
    'selectedTab',
    'page',
    'size',
    'sortingBy',
    'sortDirection',
] as (keyof StaticFilters)[];

const getInitialStaticFiltersFromSearch = (search: string): StaticFilters => {
    const params = new URLSearchParams(search);

    return Object.fromEntries(
        STATIC_URL_PARAMS.map(paramKey => [paramKey, params.get(paramKey as string)]).filter(
            ([, paramValue]) => paramValue != null
        )
    ) as StaticFilters;
};

const getInitialDynamicFiltersFromSearch = (search: string): Record<string, string[]> => {
    const params = new URLSearchParams(search);

    return Object.fromEntries(
        Array.from(params.entries())
            .filter(([paramKey]) => !STATIC_URL_PARAMS.includes(paramKey))
            .map(([paramKey, paramValue]) => [paramKey, paramValue.split(',')])
    );
};

const useUrlParams = <T extends Record<string, string[] | number | string | null | undefined>>(state: T): void => {
    const navigate = useNavigate();
    const { search } = useLocation();

    useEffect(
        () => {
            const params = new URLSearchParams(search);

            Object.entries(state).forEach(([key, value]) => {
                if (value == null || value === '' || (Array.isArray(value) && value.length === 0)) {
                    params.delete(key);
                } else {
                    params.set(key, String(value));
                }
            });

            navigate({ search: params.toString() });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state]
    );
};

export const GenericQueue: FC<{ readonly queueName: string }> = ({ queueName }) => {
    const {
        data: availableAssignees,
        isPending: isAvailableAssigneesLoading,
        isError: isAvailableAssigneesError,
    } = useGetGenericQueueAssignees(queueName);

    const assigneeToOperatorMap = useMemo(
        () =>
            Object.fromEntries(
                availableAssignees
                    ?.filter(({ operatorId }) => operatorId != null)
                    .map(({ publicId, operatorId }) => [publicId, operatorId]) ?? []
            ) as Record<string, number>,
        [availableAssignees]
    );

    const { search } = useLocation();

    const [staticFiltersState, updateStaticFiltersState] = useState<StaticFilters>({
        // @ts-expect-error we expect page to be overwritten if it appears in url search
        page: 1,
        assignee: 'ALL',
        ...getInitialStaticFiltersFromSearch(search),
    });

    const { assignee, selectedTab, page, size, sortingBy, sortDirection } = staticFiltersState;

    const {
        data: genericQueueOptions,
        isPending: isOptionsLoading,
        isError: isOptionsError,
    } = useGetGenericQueueOptions(
        queueName,
        {
            selectedTab,
            filterBy: toFilterBy({
                assignee,
                operator: assignee == null ? undefined : assigneeToOperatorMap[assignee],
            }),
        },
        availableAssignees != null
    );
    const sortMap = useMemo(
        () =>
            Object.fromEntries(
                genericQueueOptions?.sortableBy?.flatMap(({ columnKey, datapoint }) => [
                    [columnKey, datapoint],
                    [datapoint, columnKey],
                ]) ?? []
            ),
        [genericQueueOptions?.sortableBy]
    );

    const nonEmptyTabs = useMemo(
        () => genericQueueOptions?.tabs?.filter(({ count }) => count > 0) ?? [],
        [genericQueueOptions?.tabs]
    );

    useEffect(() => {
        if (genericQueueOptions != null) {
            if (genericQueueOptions.sortableBy != null) {
                if (
                    sortingBy == null
                        ? genericQueueOptions.defaults?.sortedBy != null
                        : !genericQueueOptions.sortableBy.some(({ datapoint }) => datapoint === sortingBy)
                ) {
                    updateStaticFiltersState(currStaticFilters => ({
                        ...currStaticFilters,
                        sortingBy: genericQueueOptions.defaults?.sortedBy?.datapoint,
                        sortDirection:
                            genericQueueOptions.defaults?.sortedBy?.value == null
                                ? undefined
                                : (genericQueueOptions.defaults.sortedBy.value.toLowerCase() as 'asc' | 'desc'),
                    }));
                }
            }

            if (nonEmptyTabs.length > 0) {
                if (
                    selectedTab == null
                        ? genericQueueOptions.defaults?.selectedTab != null
                        : !nonEmptyTabs.some(({ key }) => key === selectedTab)
                ) {
                    if (nonEmptyTabs.some(({ key }) => key === genericQueueOptions.defaults?.selectedTab)) {
                        updateStaticFiltersState(currStaticFilters => ({
                            ...currStaticFilters,
                            selectedTab: genericQueueOptions.defaults?.selectedTab,
                        }));
                    } else {
                        updateStaticFiltersState(currStaticFilters => ({
                            ...currStaticFilters,
                            selectedTab: nonEmptyTabs[0].key,
                        }));
                    }
                }
            }
        }
    }, [genericQueueOptions, nonEmptyTabs, selectedTab, sortingBy, updateStaticFiltersState]);

    const defaultFilters = useMemo(
        () =>
            Object.fromEntries(
                genericQueueOptions?.defaults?.filteredBy?.map(({ datapoint, values }) => [datapoint, values]) ?? []
            ),
        [genericQueueOptions?.defaults?.filteredBy]
    );

    const [dynamicFiltersState, updateDynamicFiltersState] = useState<Record<string, string[] | undefined>>(
        getInitialDynamicFiltersFromSearch(search)
    );

    const allFilters = useMemo(
        () => ({ ...dynamicFiltersState, ...staticFiltersState }),
        [dynamicFiltersState, staticFiltersState]
    );

    useUrlParams(allFilters);

    useEffect(() => {
        if (genericQueueOptions?.defaults?.filteredBy != null) {
            updateDynamicFiltersState(currDynamicFilters => ({ ...defaultFilters, ...currDynamicFilters }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultFilters]);

    const {
        data: genericQueueData,
        isPending: isDataLoading,
        isError: isDataError,
        isPlaceholderData,
    } = useGetGenericQueue(
        queueName,
        {
            selectedTab: assignee == null ? undefined : selectedTab,
            filterBy: [
                ...toFilterBy({ assignee, operator: assignee == null ? undefined : assigneeToOperatorMap[assignee] }),
                ...toFilterBy(dynamicFiltersState),
            ],
            sortBy: sortingBy == null ? undefined : { datapoint: sortingBy, value: sortDirection?.toUpperCase() },
            page,
            size,
        },
        availableAssignees != null && !isOptionsLoading
    );

    const availableAssigneesOptions = useMemo<ComboBoxItem[]>(
        () =>
            availableAssignees?.map(({ fullName, publicId, photoUrl }) => ({
                value: publicId ?? '',
                label: fullName,
                customIcon:
                    publicId == null || ['ALL', 'UNASSIGNED'].includes(publicId) ? undefined : (
                        <Avatar name={fullName} size="xxs" src={photoUrl} />
                    ),
                render:
                    publicId == null || ['ALL', 'UNASSIGNED'].includes(publicId)
                        ? undefined
                        : forwardRef<HTMLDivElement, ListItemProps>((props, ref) => (
                              <AssigneeItem
                                  avatarSize="xxs"
                                  className={listItem}
                                  fullName={fullName}
                                  photoUrl={photoUrl}
                                  ref={ref}
                                  {...props}
                              />
                          )),
            })) ?? [],
        [availableAssignees]
    );

    const clearFilters = useCallback((): void => {
        updateDynamicFiltersState(
            Object.fromEntries(genericQueueOptions?.filterableBy?.map(({ datapoint }) => [datapoint, undefined]) ?? [])
        );
    }, [genericQueueOptions?.filterableBy]);

    const setAssigneeFilter = useCallback(
        (item: ComboBoxItem | null): void => {
            const reset = {
                sortingBy: undefined,
                sortDirection: undefined,
                page: 1,
                size: undefined,
                selectedTab: undefined,
            };

            updateStaticFiltersState({ assignee: item?.value, ...reset });

            clearFilters();
        },
        [clearFilters]
    );

    const handleSort = useCallback(
        (columnKey: string): void => {
            updateStaticFiltersState(currStaticFilters => ({
                ...currStaticFilters,
                sortingBy: sortMap[columnKey],
                sortDirection: sortDirection === 'asc' ? 'desc' : 'asc',
            }));
        },
        [sortDirection, sortMap]
    );

    const handleTabChange = useCallback(
        (nextTab: string): void => {
            updateStaticFiltersState(currStaticFilters => ({
                ...currStaticFilters,
                selectedTab: nextTab,
                sortingBy: undefined,
                sortDirection: undefined,
                size: undefined,
                page: 1,
            }));

            clearFilters();
        },
        [clearFilters]
    );

    const handleUpdateFilters = useCallback((nextFilters: Record<string, string[] | undefined>): void => {
        updateDynamicFiltersState(currDynamicFilterState => ({
            ...currDynamicFilterState,
            ...nextFilters,
        }));
    }, []);

    const tableData = useMemo(
        () =>
            genericQueueData != null && genericQueueOptions != null
                ? getTableData(genericQueueData.data, genericQueueOptions.headers)
                : [],
        [genericQueueData, genericQueueOptions]
    );

    if (isDataError || isOptionsError || isAvailableAssigneesError) {
        return <ErrorSection />;
    }

    return (
        <Card>
            <Flex className={container({ disabled: isPlaceholderData })} flexDirection="column" gap={spacing.s24}>
                <Flex alignItems="center" gap={spacing.s16}>
                    <Text as="span" type="text-md">
                        Assigned to
                    </Text>
                    <ComboBox
                        className={assigneeComboBox}
                        defaultValue="ALL"
                        items={availableAssigneesOptions}
                        onSelectionChange={setAssigneeFilter}
                        placeholder="Select assignee"
                        value={assignee}
                    />
                </Flex>

                <Table
                    columns={
                        genericQueueOptions != null
                            ? getTableColumns(genericQueueOptions.headers, genericQueueOptions.sortableBy)
                            : []
                    }
                    data={tableData}
                    emptyMessage="No results found"
                    footer={
                        genericQueueData?.stats != null ? (
                            <GenericQueuePagination
                                setPage={nextPage => updateStaticFiltersState({ page: nextPage })}
                                stats={genericQueueData.stats}
                            />
                        ) : undefined
                    }
                    header={
                        genericQueueOptions != null && genericQueueData != null ? (
                            <GenericQueueTableHeader
                                filterableBy={genericQueueOptions.filterableBy}
                                filters={dynamicFiltersState}
                                onClearFilters={clearFilters}
                                onTabClick={handleTabChange}
                                selectedTab={selectedTab}
                                stats={genericQueueData.stats}
                                tabs={nonEmptyTabs}
                                title={genericQueueOptions.title}
                                updateFilters={handleUpdateFilters}
                            />
                        ) : undefined
                    }
                    isLoading={
                        (isDataLoading || isOptionsLoading || isAvailableAssigneesLoading) &&
                        (genericQueueData == null || genericQueueOptions == null)
                    }
                    sortableSet={
                        sortingBy == null
                            ? undefined
                            : {
                                  sortDirection: sortDirection ?? 'asc',
                                  sortingBy: sortMap[sortingBy],
                                  sort: handleSort,
                              }
                    }
                />
            </Flex>
        </Card>
    );
};
