import type { DialogAction } from '@lemonade-hq/bluis';
import { Alert, AlertMode, Checkbox, Dialog, Input, Select, YesNoSwitch } from '@lemonade-hq/bluis';
import { basicRequiredValidation, Flex, useForm } from '@lemonade-hq/cdk';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { getInsuranceScopeTypeDisplayName } from '../../../../common/display-texts/setting-instance';
import { InsurableEntityLabel } from '../../Settings/Dialogs/FormItems/Attributes/SharedLabels';
import { GENERAL_ERROR_MSG } from 'commons/Constants';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import { StyledInputWrapper, StyledInputWrapperNoStretch } from 'components/LoCo/LoCoPagesSharedStyles';
import type {
    CoverageInstance,
    CoverageInsuranceScope,
    CoverageScopeType,
} from 'models/LoCo/Insurance/CoveragesEdition';
import { InsuranceScopeType } from 'models/LoCo/Insurance/CoveragesEdition';
import { useUpdateEditionCoverageInstance } from 'queries/LoCo/Insurance/CoveragesEditionQueries';

const StyledSelect = styled(Select)`
    height: 38px;
    text-align: left;
`;

interface EditCoverageDialogProps {
    readonly editionCode: string;
    readonly coverage: CoverageInstance & { readonly name: string };
    readonly onClose: () => void;
}

export const EditCoverageDialog: React.FC<EditCoverageDialogProps> = ({ editionCode, coverage, onClose }) => {
    const product = useGetProductData();

    const { errors, values, setValue, valid } = useForm({
        fields: {
            required: {
                startValue: coverage.required,
                validations: {
                    required: basicRequiredValidation,
                },
            },
            isBenefit: {
                startValue: coverage.isBenefit,
                validations: {
                    required: basicRequiredValidation,
                },
            },
            scope: {
                startValue: coverage.scope.type,
                validations: {
                    required: basicRequiredValidation,
                },
                skipValidationsCondition: ({ required }) => required === true,
            },
            insuredEntityCode: {
                startValue:
                    coverage.scope.type === InsuranceScopeType.InsuredEntity
                        ? coverage.scope.insuredEntityCode
                        : undefined,
                validations: {
                    required: basicRequiredValidation,
                },
                skipValidationsCondition: ({ scope }) => scope !== InsuranceScopeType.InsuredEntity,
            },
        },
    });

    const [showAlert, setShowAlert] = useState<boolean>(false);
    const {
        mutateAsync: updateEditionSettingInstance,
        isPending: isLoading,
        isError,
    } = useUpdateEditionCoverageInstance(editionCode);

    const submit = useCallback(async () => {
        await updateEditionSettingInstance({
            editionCode,
            coverage: {
                templateCode: coverage.templateCode,
                required: values.required,
                isBenefit: values.isBenefit,
                scope: {
                    type: values.scope,
                    insuredEntityCode: values.insuredEntityCode,
                } as CoverageInsuranceScope,
            },
        });
        onClose();
    }, [updateEditionSettingInstance, editionCode, coverage.templateCode, values, onClose]);

    const onSwitchClick = (): void => {
        setValue('required', !values.required);
        setShowAlert(!values.required);
    };

    const onBenefitCheckBoxClick = (): void => {
        setValue('isBenefit', !values.isBenefit);
    };

    const actions: DialogAction[] = useMemo(
        () => [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Save',
                type: 'submit',
                onClick: submit,
                disabled: isLoading || !valid,
            },
        ],
        [isLoading, onClose, submit, valid]
    );

    const notices = [
        ...(showAlert
            ? [
                  <Alert
                      key="1"
                      mode={AlertMode.Info}
                      title={<span>Any filed rules for this coverage will be deleted</span>}
                  />,
              ]
            : []),
    ];

    const scopeOptions = [InsuranceScopeType.Policy, InsuranceScopeType.InsuredEntity].map(scope => ({
        id: scope,
        value: scope,
        label: getInsuranceScopeTypeDisplayName(scope),
    }));

    const insurableEntityOptions = product.insurableEntities.map(entity => ({
        id: entity.code,
        value: entity.code,
        label: entity.name,
    }));

    const showScope = product.insurableEntities.length > 0 && !values.required;

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={isError ? GENERAL_ERROR_MSG : undefined}
            loading={isLoading}
            notice={notices}
            onClose={onClose}
            size="large"
            title="Edit Coverage"
        >
            <StyledInputWrapper label="Coverage">
                <Input disabled value={coverage.name} />
            </StyledInputWrapper>
            <StyledInputWrapperNoStretch label="Required" showErrors={!isEmpty(errors.required)}>
                <YesNoSwitch name="coverageRequired" onSwitch={onSwitchClick} selected={values.required ? 0 : 1} />
            </StyledInputWrapperNoStretch>

            {showScope && (
                <>
                    <StyledInputWrapper label="Scope">
                        <StyledSelect
                            onOptionSelected={({ value }) => setValue('scope', value as CoverageScopeType)}
                            options={scopeOptions}
                            placeholder="Coverage Selection"
                            value={values.scope}
                        />
                    </StyledInputWrapper>

                    {values.scope === InsuranceScopeType.InsuredEntity && (
                        <StyledInputWrapper label={<InsurableEntityLabel />}>
                            <StyledSelect
                                onOptionSelected={({ value }) => setValue('insuredEntityCode', value)}
                                options={insurableEntityOptions}
                                placeholder="Entity"
                                value={values.insuredEntityCode ?? ''}
                            />
                        </StyledInputWrapper>
                    )}
                </>
            )}

            <StyledInputWrapperNoStretch label="Non-Insurance Benefit">
                <Flex>
                    <Checkbox checked={values.isBenefit} onChange={onBenefitCheckBoxClick} />
                </Flex>
            </StyledInputWrapperNoStretch>
        </Dialog>
    );
};
