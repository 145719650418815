import type { LabelValueItem } from '@lemonade-hq/blender-ui';
import { ChangeAccordion } from '../ChangeAccordion';
import { displayBoolean } from 'components/LoCo/common/display-texts/common';
import { getOutcomeText } from 'components/LoCo/common/display-texts/underwriting-rules';
import { QuoteLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import type { DeclineRulesUpdatedEntry } from 'models/LoCo/Insurance/ChangesLog';
import type { UnderwritingFiltersEdition } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

interface UnderwritingDecisionUpdatedProps {
    readonly change: DeclineRulesUpdatedEntry;
    readonly underwritingFiltersEdition: UnderwritingFiltersEdition;
    readonly baseUnderwritingFiltersEdition: UnderwritingFiltersEdition | null;
}

export const UnderwritingDecisionUpdated: React.FC<UnderwritingDecisionUpdatedProps> = ({
    change: {
        metadata: { added, removed, reordered, updated, lifecycleContext },
    },
    underwritingFiltersEdition,
    baseUnderwritingFiltersEdition,
}) => {
    const updatedRules =
        lifecycleContext === QuoteLifecycleContext.NewBusiness
            ? underwritingFiltersEdition.declineRules.newBusinessDeclineRules
            : underwritingFiltersEdition.declineRules.renewalDeclineRules;

    const originalRules =
        (lifecycleContext === QuoteLifecycleContext.NewBusiness
            ? baseUnderwritingFiltersEdition?.declineRules.newBusinessDeclineRules
            : baseUnderwritingFiltersEdition?.declineRules.renewalDeclineRules) ?? null;

    const rows: LabelValueItem[] = [];

    const addRow = (value: number, label: string): void => {
        if (value > 0) rows.push({ label, value });
    };

    addRow(added, 'Filed restriction rules added');
    addRow(removed, 'Filed restriction rules removed');
    addRow(updated, 'Filed default rules edited');

    if (reordered) {
        rows.push({ label: 'Rules Reordered', value: displayBoolean(reordered) });
    }

    return (
        <ChangeAccordion
            generalTabData={rows}
            originalRules={originalRules}
            ruleType={'decline'}
            title={`Automatic ${getOutcomeText(lifecycleContext)} rules updated`}
            updatedRules={updatedRules}
        />
    );
};
