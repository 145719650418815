import {
    ActionsMenu,
    Button,
    Card,
    ComboBox,
    darkThemeClass,
    Flex,
    LabelValueTable,
    spacing,
    Text,
    TextArea,
    Tooltip,
} from '@lemonade-hq/blender-ui';
import { useAnalytics } from '@lemonade-hq/boutique';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useCarousel } from '../../Carousel/CarouselProvider';
import { filenameEllipsis } from './details.css';
import { useUpdateDetails } from 'components/Attachments/ActionHooks/useUpdateDetails';
import { useAttachmentsData } from 'components/Attachments/context';
import { getAttachmentAnalyticsParam, hasAction } from 'components/Attachments/utils';

interface GalleryAttachmentDetailsProps {
    readonly onSubmit?: () => void;
}

const EditMode: FC<{
    readonly onSubmit: GalleryAttachmentDetailsProps['onSubmit'];
    readonly setEditMode: (value: boolean) => void;
}> = ({ onSubmit, setEditMode }) => {
    const { attachments, entityType, entityPublicId } = useAttachmentsData();
    const { currentIndex } = useCarousel();
    const attachment = useMemo(() => attachments[currentIndex], [attachments, currentIndex]);
    const { fileName, type, description = '', publicId } = attachment;
    const {
        types,
        type: selectedType,
        description: selectedDescription,
        isTypesError,
        isTypesLoading,
        isSubmitPending,
        isSubmitError,
        handleSelectionChange,
        setDescription,
        handleSubmit: submitAttachmentDetails,
    } = useUpdateDetails({
        entityPublicId,
        entityType,
        attachments: [attachment],
        savedType: type,
        savedDescription: description,
    });

    const { trackEvent } = useAnalytics();
    const params = getAttachmentAnalyticsParam({
        attachment,
        entityType,
        entityId: entityPublicId,
    });

    const handleSubmit = useCallback(async () => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        trackEvent('docs.gallery.clicked', {
            ...params,
            doc_type: selectedType,
            doc_description: selectedDescription,
            name: 'relabel',
            source: 'gallery',
        });
        setEditMode(false);
        onSubmit?.();
        const attachmentsData = [
            {
                attachmentPublicId: publicId,
                ...(selectedType !== '' && { type: selectedType }),
                ...(selectedDescription !== '' && { description: selectedDescription }),
            },
        ];
        await submitAttachmentDetails({
            attachmentsData,
        });
    }, [
        onSubmit,
        params,
        publicId,
        setEditMode,
        submitAttachmentDetails,
        trackEvent,
        selectedDescription,
        selectedType,
    ]);

    const disabled = selectedType === '' || selectedDescription === '';

    return (
        <Card p={spacing.s16}>
            <Flex flexDirection="column" gap={spacing.s20}>
                <Flex flexDirection="column" gap={spacing.s12}>
                    <Text fontWeight="semi-bold" type="text-lg">
                        File information
                    </Text>
                    <LabelValueTable
                        columnCount={1}
                        data={[
                            {
                                label: 'File name',
                                value: (
                                    <Tooltip content={fileName}>
                                        <Text className={filenameEllipsis}>{fileName}</Text>
                                    </Tooltip>
                                ),
                            },
                        ]}
                        labelWidth="100px"
                    />
                    <Flex flexDirection="column" gap={spacing.s06}>
                        <Text color="primary" type="label-sm">
                            Type
                            <Text as="span" color="error">
                                &nbsp;*
                            </Text>
                        </Text>
                        <Flex flexDirection="column" gap={spacing.s06}>
                            <ComboBox
                                defaultValue={selectedType}
                                disabled={isTypesLoading}
                                items={types}
                                onSelectionChange={handleSelectionChange}
                                optionsClassName={darkThemeClass}
                                placeholder="Select attachment type"
                            />
                            {isTypesError && (
                                <Text color="error" type="label-sm">
                                    Error loading attachment types
                                </Text>
                            )}
                        </Flex>
                    </Flex>
                    <Flex flexDirection="column" gap={spacing.s06}>
                        <Text color="primary" type="label-sm">
                            Description
                            <Text as="span" color="error">
                                &nbsp;*
                            </Text>
                        </Text>
                        <TextArea
                            autoExpanding
                            maxLength={500}
                            onChange={e => setDescription(e.target.value)}
                            rows={5}
                            value={selectedDescription}
                        />
                    </Flex>
                </Flex>
                {isSubmitError && (
                    <Text color="error" type="label-sm">
                        Error updating attachment details
                    </Text>
                )}

                <Button
                    disabled={disabled}
                    label="Save"
                    loading={isSubmitPending}
                    onClick={handleSubmit}
                    size="md"
                    variant="primary"
                />
            </Flex>
        </Card>
    );
};

const LabelValueMode: FC<{
    readonly fileName: string;
    readonly type: string;
    readonly description: string;
    readonly setEditMode?: (value: boolean) => void;
}> = ({ fileName = '', type = '', description = '', setEditMode }) => {
    const data = [
        {
            label: 'File name',
            value: (
                <Tooltip content={fileName}>
                    <Text className={filenameEllipsis}>{fileName}</Text>
                </Tooltip>
            ),
        },
        { label: 'Type', value: snakeCaseToReadable(type) },
        { label: 'Description', value: description },
    ];

    return (
        <Card p={spacing.s16}>
            <Flex alignItems="center" justifyContent="space-between" mb={spacing.s12}>
                <Text fontWeight="semi-bold" type="text-lg">
                    File information
                </Text>
                {setEditMode && (
                    <ActionsMenu
                        className={darkThemeClass}
                        items={[{ label: 'Edit', id: 'edit', onClick: () => setEditMode(true) }]}
                    />
                )}
            </Flex>
            <LabelValueTable columnCount={1} data={data} labelWidth="100px" />
        </Card>
    );
};

export const GalleryAttachmentDetails: FC<GalleryAttachmentDetailsProps> = ({ onSubmit }) => {
    const { attachments } = useAttachmentsData();
    const { currentIndex } = useCarousel();
    const attachment = useMemo(() => attachments[currentIndex], [attachments, currentIndex]);
    const { fileName, type, description = '' } = attachment;
    const hasEditAction = hasAction(attachment, 'relabel');
    const [isEditMode, setEditMode] = useState((description === '' || description == null) && hasEditAction);

    if (isEditMode) {
        return <EditMode key={attachment.publicId} onSubmit={onSubmit} setEditMode={setEditMode} />;
    } else {
        return (
            <LabelValueMode
                description={description ?? ''}
                fileName={fileName ?? ''}
                key={attachment.publicId}
                setEditMode={hasEditAction ? setEditMode : undefined}
                type={type ?? ''}
            />
        );
    }
};
