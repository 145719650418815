import { isDefined } from '@lemonade-hq/ts-helpers';
import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import qs from 'qs';
import { insuranceBlender } from '../../../apiClients';
import { CoveragesEditionQueryKey } from './CoveragesEditionQueries';
import { DigitalAgentEditionQueryKey } from './DigitalAgentEditionQueries';
import { ProductsQueryKey } from './ProductQueries';
import { UnderwritingFiltersEditionQueryKey } from './UnderwritingFiltersEditionQueries';
import type { ApprovedEdition, Edition, VersionType } from 'models/LoCo/Insurance/BaseEdition';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { CoverageRule } from 'models/LoCo/Insurance/CoverageRule';
import type { UnderwritingEditionViolations } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import { usePessimisticMutation } from 'queries/MutationHooks';

const COVERAGES_EDITIONS_BASE_PATH = '/api/v1/coverages-editions';
const DIGITAL_AGENT_EDITIONS_BASE_PATH = '/api/v1/digital-agent-editions';
const UNDERWRITING_FILTERS_EDITIONS_BASE_PATH = '/api/v1/underwriting-filters-editions';
const RATING_EDITIONS_BASE_PATH = '/api/v1/rating-editions';

export interface CreateCoverageRuleParams {
    readonly editionType: EditionType;
    readonly editionCode: string;
    readonly rule: Omit<CoverageRule, 'publicId'>;
    readonly variants?: string[];
}

export type CreateRuleArgs = Omit<CoverageRule, 'publicId'>;

interface EditEditionCoverageRuleParams {
    readonly editionType: EditionType;
    readonly editionCode: string;
    readonly rule: CoverageRule;
    readonly variants?: string[];
}

export enum EditionQueryKey {
    GetEditionViolations = 'GET_EDITION_VIOLATIONS',
    GetEditionApproveEligibility = 'GET_EDITION_APPROVE_ELIGIBILITY',
    GetEditionSummary = 'GET_EDITION_SUMMARY',
    GetLatestMinors = 'GET_LATEST_MINORS',
}

const getBasePath = (editionType: EditionType): string => {
    switch (editionType) {
        case EditionType.Coverages:
            return COVERAGES_EDITIONS_BASE_PATH;
        case EditionType.DigitalAgent:
            return DIGITAL_AGENT_EDITIONS_BASE_PATH;
        case EditionType.UnderwritingFilters:
            return UNDERWRITING_FILTERS_EDITIONS_BASE_PATH;
        case EditionType.Rating:
            return RATING_EDITIONS_BASE_PATH;
        default:
            throw new Error('Invalid edition type');
    }
};

async function approveEdition({
    editionCode,
    versionType,
    editionType,
}: {
    readonly editionType: EditionType;
    readonly editionCode: string;
    readonly versionType: VersionType;
}): Promise<void> {
    const path = getBasePath(editionType);
    await insuranceBlender.post(`${path}/${editionCode}/approve`, { versionType });
}

export function useApproveEdition(
    productCode: string,
    editionCode: string,
    editionType: EditionType
): UseMutationResult<
    void,
    unknown,
    { readonly editionType: EditionType; readonly editionCode: string; readonly versionType: VersionType },
    null
> {
    const { invalidateKey, mutationKey } = getApproveKeys(editionType);

    return usePessimisticMutation({
        mutationFn: approveEdition,
        invalidateKeys: [
            [invalidateKey, editionCode],
            [ProductsQueryKey.GetLatestMinorVersions, productCode],
            [EditionQueryKey.GetLatestMinors, productCode, editionType],
        ],
        mutationKey: [mutationKey],
    });
}

async function archiveEdition({
    editionType,
    editionCode,
}: {
    readonly editionType: EditionType;
    readonly editionCode: string;
}): Promise<void> {
    const path = getBasePath(editionType);
    await insuranceBlender.put(`${path}/${editionCode}/archive`);
}

export function useArchiveEdition(
    editionType: string,
    productCode?: string
): UseMutationResult<
    void,
    unknown,
    {
        readonly editionType: EditionType;
        readonly editionCode: string;
    },
    null
> {
    const { invalidateKey, mutationKey } = getArchiveKeys(editionType as EditionType);
    return usePessimisticMutation({
        mutationFn: archiveEdition,
        invalidateKeys: [[invalidateKey, productCode]],
        mutationKey: [mutationKey],
    });
}

// Coverages rules related queries
async function createEditionRule({
    editionType,
    editionCode,
    rule,
    variants,
}: CreateCoverageRuleParams): Promise<void> {
    const path = getBasePath(editionType);

    await insuranceBlender.post(`${path}/${editionCode}/rules`, { rule, variants });
}

export function useCreateEditionRule(
    editionType: EditionType,
    editionCode: string
): UseMutationResult<
    void,
    unknown,
    {
        readonly rule: CreateCoverageRuleParams['rule'];
        readonly variants?: string[];
    },
    null
> {
    const getKey =
        editionType === EditionType.Coverages
            ? CoveragesEditionQueryKey.GetCoveragesEdition
            : DigitalAgentEditionQueryKey.GetDigitalAgentEdition;

    const createRuleKey =
        editionType === EditionType.Coverages
            ? CoveragesEditionQueryKey.CreateCoveragesEditionRule
            : DigitalAgentEditionQueryKey.CreateDigitalAgentEditionRule;

    return usePessimisticMutation({
        mutationFn: async ({ rule, variants }: { rule: CreateCoverageRuleParams['rule']; variants?: string[] }) =>
            await createEditionRule({ editionType, editionCode, rule, variants }),
        invalidateKeys: [
            [getKey, editionCode],
            [DigitalAgentEditionQueryKey.GetExtendedPreview, editionCode],
        ],
        mutationKey: [createRuleKey],
    });
}

async function editEditionRule({
    editionType,
    editionCode,
    rule,
    variants,
}: EditEditionCoverageRuleParams): Promise<void> {
    const path = getBasePath(editionType);

    await insuranceBlender.put(`${path}/${editionCode}/rules/${rule.publicId}`, { rule, variants });
}

export function useEditEditionRule(
    editionType: EditionType,
    editionCode: string
): UseMutationResult<
    void,
    unknown,
    {
        readonly rule: CoverageRule;
        readonly variants?: string[];
    },
    null
> {
    const getKey =
        editionType === EditionType.Coverages
            ? CoveragesEditionQueryKey.GetCoveragesEdition
            : DigitalAgentEditionQueryKey.GetDigitalAgentEdition;

    const editRuleKey =
        editionType === EditionType.Coverages
            ? CoveragesEditionQueryKey.EditCoveragesEditionRule
            : DigitalAgentEditionQueryKey.EditDigitalAgentEditionRule;

    return usePessimisticMutation({
        mutationFn: async ({ rule, variants }: { rule: CoverageRule; variants?: string[] }) =>
            await editEditionRule({ editionType, editionCode, rule, variants }),
        invalidateKeys: [
            [getKey, editionCode],
            [DigitalAgentEditionQueryKey.GetExtendedPreview, editionCode],
        ],
        mutationKey: [editRuleKey],
    });
}

// Key helper functions
type QueryKeys = { readonly invalidateKey: string; readonly mutationKey: string };

function getApproveKeys(editionType: EditionType): QueryKeys {
    switch (editionType) {
        case EditionType.Coverages:
            return {
                invalidateKey: CoveragesEditionQueryKey.GetCoveragesEdition,
                mutationKey: CoveragesEditionQueryKey.ApproveCoveragesEdition,
            };
        case EditionType.DigitalAgent:
            return {
                invalidateKey: DigitalAgentEditionQueryKey.GetDigitalAgentEdition,
                mutationKey: DigitalAgentEditionQueryKey.ApproveDigitalAgentEdition,
            };
        case EditionType.UnderwritingFilters:
            return {
                invalidateKey: UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition,
                mutationKey: UnderwritingFiltersEditionQueryKey.ApproveUnderwritingFiltersEdition,
            };
        default:
            throw new Error(`Invalid edition type: ${editionType}`);
    }
}

function getArchiveKeys(editionType: EditionType): QueryKeys {
    switch (editionType) {
        case EditionType.Coverages:
            return {
                invalidateKey: CoveragesEditionQueryKey.GetProductCoveragesEdition,
                mutationKey: CoveragesEditionQueryKey.ArchiveCoveragesEdition,
            };
        case EditionType.DigitalAgent:
            return {
                invalidateKey: '',
                mutationKey: DigitalAgentEditionQueryKey.ArchiveDigitalAgentEdition,
            };
        case EditionType.UnderwritingFilters:
            return {
                invalidateKey: UnderwritingFiltersEditionQueryKey.GetProductUnderwritingFiltersEditions,
                mutationKey: UnderwritingFiltersEditionQueryKey.ArchiveUnderwritingFiltersEdition,
            };
        default:
            throw new Error(`Invalid edition type: ${editionType}`);
    }
}

export type EditionApprovalViolations = UnderwritingEditionViolations;

export async function getEditionViolations(
    editionCode: string,
    editionType: EditionType
): Promise<EditionApprovalViolations> {
    switch (editionType) {
        case EditionType.UnderwritingFilters:
        case EditionType.Coverages:
            return await insuranceBlender
                .get<{ data: EditionApprovalViolations }>(`${getBasePath(editionType)}/${editionCode}/violations`)
                .then(response => response.data.data);
        default:
            return {
                hasViolation: false,
                messages: [],
            };
    }
}

export function useGetEditionViolations(
    editionCode: string,
    editionType: EditionType
): UseQueryResult<EditionApprovalViolations> {
    return useQuery({
        queryKey: [EditionQueryKey.GetEditionViolations, editionCode],
        queryFn: async () => await getEditionViolations(editionCode, editionType),
        gcTime: 0,
    });
}

export async function getEditionSummary(
    editionCode: string | undefined,
    editionType: EditionType
): Promise<Edition | null> {
    return await insuranceBlender
        .get<{ data: Edition }>(`${getBasePath(editionType)}/${editionCode}/summary`)
        .then(response => response.data.data);
}

export function useGetEditionSummary(
    editionCode: string,
    editionType: EditionType | null
): UseQueryResult<Edition | null> {
    return useQuery({
        queryKey: [EditionQueryKey.GetEditionSummary, editionCode],
        queryFn: async () => await getEditionSummary(editionCode, editionType!),
        enabled: Boolean(editionCode) && Boolean(editionType),
    });
}

async function getLatestMinors(productCode: string, editionType: EditionType): Promise<ApprovedEdition[]> {
    const query = qs.stringify({ productCode });
    const response = await insuranceBlender.get<{ data: ApprovedEdition[] }>(
        `${getBasePath(editionType)}/latest-minors?${query}`
    );

    return response.data.data;
}

export function useGetLatestMinors(
    productCode: string,
    editionType: EditionType | undefined,
    onSuccess?: (latestMinors: ApprovedEdition[]) => void
): UseQueryResult<ApprovedEdition[]> {
    return useQuery({
        queryFn: async () => {
            if (!isDefined(editionType)) return null;

            const result = await getLatestMinors(productCode, editionType);

            onSuccess?.(result);
            return result;
        },
        queryKey: [EditionQueryKey.GetLatestMinors, productCode, editionType],
        enabled: Boolean(productCode) && Boolean(editionType),
    });
}
