import { Card, Flex, pageWrapper, spacing } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import * as styles from './PlaygroundPage.css';
import { PlaygroundChat } from './simulation/PlaygroundChat';
import { PlaygroundMinimizedChat } from './toolEditor/components/PlaygroundMinimizedChat';
import { PlaygroundToolEditor } from './toolEditor/PlaygroundToolEditor';
import { usePlaygroundChatResize } from './usePlaygroundChatResize';
import { useForceBlenderUI } from 'hooks/useForceBlenderUI';
import { useFullscreen } from 'hooks/useFullScreen';

export const PlaygroundPage: FC = () => {
    useFullscreen();
    useForceBlenderUI();
    const { containerRef, handleRef, open, isCollapsed, elRef } = usePlaygroundChatResize();

    return (
        <Flex className={pageWrapper} gap={spacing.s04} height="100%" padding={spacing.s24} ref={containerRef}>
            <PlaygroundToolEditor className={styles.playgroundEditorMainArea} />
            <div className={styles.resizeHandle} ref={handleRef} />
            <Card padding={0} ref={elRef}>
                {isCollapsed ? (
                    <PlaygroundMinimizedChat open={open} />
                ) : (
                    <PlaygroundChat className={styles.playgroundEditorChatArea} />
                )}
            </Card>
        </Flex>
    );
};
