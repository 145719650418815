import { clsx } from 'clsx';
import type { ButtonHTMLAttributes, Ref } from 'react';
import { forwardRef } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Text } from '../../base/Text/Text';
import { spacing } from '../../theme/spacing.css';
import { Icon } from '../Icon/Icon';
import type { IconName } from '../Icon/types';
import { Spinner } from '../Spinner/Spinner';
import { buttonSpinner, buttonStyles, buttonText } from './Button.css';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly loading?: boolean;
  readonly startIcon?: IconName;
  readonly endIcon?: IconName;
  readonly variant: 'inline' | 'primary' | 'secondary' | 'text';
  readonly label: JSX.Element | string;
  readonly size?: 'lg' | 'md' | 'sm';
}

export const Button = forwardRef(
  (
    {
      variant = 'primary',
      loading,
      size = 'md',
      disabled,
      label,
      className: externalClassName,
      startIcon,
      endIcon,
      ...rest
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>,
  ): JSX.Element => {
    return (
      <button
        aria-busy={loading}
        className={clsx(buttonStyles({ variant, size }), externalClassName)}
        disabled={disabled}
        type="button"
        {...rest}
        ref={ref}
      >
        <Flex alignItems="center" className={buttonText({ loading })} gap={spacing.s06} justifyContent="center">
          {startIcon && <Icon data-testid="start-icon" name={startIcon} size={size} />}
          <Text className={buttonText({ size })}>{label}</Text>
          {endIcon && <Icon data-testid="end-icon" name={endIcon} size={size} />}
        </Flex>
        {loading && (
          <div className={buttonSpinner}>
            <Spinner color={variant === 'primary' ? 'white' : 'pink'} size={size} />
          </div>
        )}
      </button>
    );
  },
);
