import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Layout } from '../../base/Layout/Layout';
import { Text } from '../../base/Text/Text';
import { borderRadius } from '../../theme/radius.css';
import { spacing } from '../../theme/spacing.css';
import { Icon } from '../Icon/Icon';
import type { TooltipProps } from '../Tooltip/Tooltip';
import { Tooltip } from '../Tooltip/Tooltip';
import { counter, highlightSlider, tab, tabList } from './Tabs.css';
import {
  getCounterBgColor,
  getCountTextType,
  getTabFontWeightByVariant,
  getTabTextColor,
  getTabTextTypeBySize,
} from './utils';

export interface TabButton {
  readonly label: string;
  readonly value: string;
  readonly tooltip?: Omit<TooltipProps, 'children'>;
  readonly onClick?: () => void;
  readonly count?: number;
  readonly disabled?: boolean;
}

export interface TabButtonsProps {
  readonly buttons: TabButton[];
  readonly variant?: 'fill' | 'inline' | 'segmented';
  readonly size?: 'md' | 'sm';
  readonly startAtTabIndex?: number;
  readonly className?: string;
}

export const TabButtons: FC<TabButtonsProps> = ({
  buttons,
  variant = 'fill',
  size = 'md',
  startAtTabIndex = 0,
  className,
}) => {
  const [selected, setSelected] = useState(startAtTabIndex);
  const refs = useRef<HTMLButtonElement[]>([]);
  const [sliderStyle, setSliderStyle] = useState({
    left: refs.current[selected]?.offsetLeft,
    width: refs.current[selected]?.offsetWidth,
    height: refs.current[selected]?.offsetHeight,
  });

  useEffect(() => {
    setSliderStyle({
      left: refs.current[selected]?.offsetLeft,
      width: refs.current[selected]?.offsetWidth,
      height: refs.current[selected]?.offsetHeight,
    });
  }, [refs, selected]);

  const onTabClick = (index: number): void => {
    if (buttons[index].disabled) return;

    setSelected(index);
    buttons[index].onClick?.();
  };

  return (
    <Flex className={clsx(tabList({ variant, size }), className)}>
      {buttons.map(({ label, value, tooltip, count, disabled = false }, index) => (
        <button
          aria-selected={selected === index}
          className={clsx(
            tab({
              variant,
              isSelected: String(selected === index),
              size,
            }),
            borderRadius({ borderRadius: variant === 'inline' ? undefined : 'sm' }),
          )}
          disabled={disabled}
          key={value}
          onClick={() => onTabClick(index)}
          ref={(node: HTMLButtonElement) => {
            refs.current[index] = node;
          }}
          type="button"
        >
          <Text
            as="span"
            color={getTabTextColor(selected === index, Boolean(disabled))}
            fontWeight={getTabFontWeightByVariant(variant, selected === index)}
            padding={`0 ${spacing.s04}`}
            type={getTabTextTypeBySize(size)}
          >
            {label}
          </Text>
          {count != null && (
            <Flex
              alignItems="center"
              backgroundColor={getCounterBgColor(variant, selected === index)}
              className={clsx(counter({ variant }), borderRadius({ borderRadius: 'xs' }))}
              justifyContent="center"
              ml={spacing.s04}
              padding={`0 ${spacing.s02}`}
            >
              <Text
                as="span"
                color={variant === 'inline' ? getTabTextColor(selected === index, disabled) : 'secondary'}
                type={getCountTextType(variant, size)}
              >
                {variant === 'inline' ? (
                  <Layout as="span" padding={`0 ${spacing.s04} 0 0`}>
                    ·
                  </Layout>
                ) : (
                  ''
                )}
                {count}
              </Text>
            </Flex>
          )}
          {tooltip != null && (
            <Tooltip content={tooltip.content} disabled={disabled}>
              <Flex px={spacing.s04}>
                <Icon color={disabled ? 'textDisabled' : 'neutral7'} name="info-circle" />
              </Flex>
            </Tooltip>
          )}
        </button>
      ))}
      <Layout
        className={clsx(
          highlightSlider({ variant, size }),
          borderRadius({ borderRadius: variant === 'inline' ? undefined : 'sm' }),
        )}
        height={`${sliderStyle.height}px`}
        left={`${sliderStyle.left}px`}
        width={`${sliderStyle.width}px`}
      />
    </Flex>
  );
};
