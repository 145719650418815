import type { UseMutationResult, UseQueryResult, UseSuspenseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { insuranceBlender } from '../../../apiClients';
import { useSuspenseQueryWithErrorHandling } from 'components/LoCo/common/hooks/useSuspenseQueryWithErrorHandling';
import type { CreateEditionParams } from 'models/LoCo/Insurance/BaseEdition';
import type { CoveragesTemplateType } from 'models/LoCo/Insurance/CoverageRule';
import type {
    CoverageRuleGroupType,
    DigitalAgentEdition,
    DigitalAgentEditionExtendedPreview,
} from 'models/LoCo/Insurance/DigitalAgentEdition';
import { usePessimisticMutation } from 'queries/MutationHooks';

const BASE_PATH = '/api/v1/digital-agent-editions';

export interface UpdateDigitalAgentEditionViewPreferenceParams {
    readonly digitalAgentEditionCode: string;
    readonly coveragesEditionCode: string;
}

interface ABTestRequestBase {
    readonly entityType: CoveragesTemplateType;
    readonly entityCode: string;
    readonly groupType: CoverageRuleGroupType;
}

export interface CreateABTestRequest extends ABTestRequestBase {
    readonly experimentName: string;
    readonly control: string;
    readonly variants: string[];
}

export interface UnbindABTestRequest extends ABTestRequestBase {
    readonly variantToKeep: string;
}

export interface AddRemoveVariantsRequest extends ABTestRequestBase {
    readonly variants: string[];
}

export interface RenameVariantsRequest extends ABTestRequestBase {
    readonly experimentName: string;
    readonly variants?: {
        readonly oldName: string;
        readonly newName: string;
    }[];
}

export interface AttachABTestParams {
    readonly digitalAgentEditionCode: string;
    readonly payload: CreateABTestRequest;
}

export interface UnbindABTestParams {
    readonly digitalAgentEditionCode: string;
    readonly payload: UnbindABTestRequest;
}

export interface AddRemoveVariantsParams {
    readonly digitalAgentEditionCode: string;
    readonly payload: AddRemoveVariantsRequest;
}

export interface RenameVariantsParams {
    readonly digitalAgentEditionCode: string;
    readonly payload: RenameVariantsRequest;
}

export interface GetDigitalAgentEditionViewPreferenceResponse {
    readonly coveragesEditionCode: string;
}

export enum DigitalAgentEditionQueryKey {
    GetDigitalAgentEdition = 'GET_DIGITAL_AGENT_EDITION',
    CreateDigitalAgentEdition = 'CREATE_DIGITAL_AGENT_EDITION',
    ApproveDigitalAgentEdition = 'APPROVE_DIGITAL_AGENT_EDITION',
    ArchiveDigitalAgentEdition = 'ARCHIVE_DIGITAL_AGENT_EDITION',
    CreateDigitalAgentEditionRule = 'CREATE_DIGITAL_AGENT_EDITION_RULE',
    EditDigitalAgentEditionRule = 'EDIT_DIGITAL_AGENT_EDITION_RULE',
    DeleteDigitalAgentEditionRule = 'DELETE_DIGITAL_AGENT_EDITION_RULE',
    ReorderDigitalAgentEditionRule = 'REORDER_DIGITAL_AGENT_EDITION_RULE',
    GetDigitalAgentEditionViewPreference = 'GET_DIGITAL_AGENT_EDITION_VIEW_PREFERENCE',
    UpdateDigitalAgentEditionViewPreference = 'UPDATE_DIGITAL_AGENT_EDITION_VIEW_PREFERENCE',
    GetExtendedPreview = 'GET_EXTENDED_PREVIEW',
    AttachABTest = 'ATTACH_AB_TEST',
    AddVariants = 'ADD_VARIANTS',
    RemoveVariants = 'REMOVE_VARIANTS',
    RenameVariants = 'RENAME_VARIANTS',
    UnbindABTest = 'UNBIND_AB_TEST',
}

// === Editions === //

export async function getDigitalAgentEdition(editionCode: string): Promise<DigitalAgentEdition> {
    const response = await insuranceBlender.get<{ data: DigitalAgentEdition }>(`${BASE_PATH}/${editionCode}`);

    return response.data.data;
}

export async function getDigitalAgentEditionExtendedPreview(
    editionCode?: string
): Promise<DigitalAgentEditionExtendedPreview> {
    const response = await insuranceBlender.get<{ data: DigitalAgentEditionExtendedPreview }>(
        `${BASE_PATH}/${editionCode}/extended-preview`
    );

    return response.data.data;
}

export function useSuspenseGetDigitalAgentEditionExtendedPreview(
    editionCode: string
): UseSuspenseQueryResult<DigitalAgentEditionExtendedPreview> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [DigitalAgentEditionQueryKey.GetExtendedPreview, editionCode],
        queryFn: async () => await getDigitalAgentEditionExtendedPreview(editionCode),
    });
}

async function createDigitalAgentEdition({
    productCode,
    description,
    baseEditionCode,
}: CreateEditionParams): Promise<DigitalAgentEdition> {
    const response = await insuranceBlender.post<{ data: DigitalAgentEdition }>(BASE_PATH, {
        productCode,
        description,
        baseEditionCode,
    });

    return response.data.data;
}

export function useCreateDigitalAgentEdition(): UseMutationResult<
    DigitalAgentEdition,
    unknown,
    CreateEditionParams,
    null
> {
    return usePessimisticMutation({
        mutationFn: createDigitalAgentEdition,
        invalidateKeys: [],
        mutationKey: [DigitalAgentEditionQueryKey.CreateDigitalAgentEdition],
    });
}

interface DeleteDigitalAgentRulesParams {
    readonly editionCode: string;
    readonly rules: { readonly publicId: string; readonly variants?: string[] }[];
}

async function deleteDigitalAgentEditionRules({ editionCode, rules }: DeleteDigitalAgentRulesParams): Promise<void> {
    await insuranceBlender.post(`${BASE_PATH}/${editionCode}/rules/remove`, { rules });
}

export function useDeleteDigitalAgentEditionRules(editionCode: string): UseMutationResult<
    void,
    unknown,
    {
        readonly rules: { readonly publicId: string; readonly variants?: string[] }[];
    },
    null
> {
    return usePessimisticMutation({
        mutationFn: async ({
            rules,
        }: {
            readonly rules: { readonly publicId: string; readonly variants?: string[] }[];
        }) => await deleteDigitalAgentEditionRules({ editionCode, rules }),
        invalidateKeys: [
            [DigitalAgentEditionQueryKey.GetDigitalAgentEdition, editionCode],
            [DigitalAgentEditionQueryKey.GetExtendedPreview, editionCode],
        ],
        mutationKey: [DigitalAgentEditionQueryKey.DeleteDigitalAgentEditionRule],
    });
}

async function reorderDigitalAgentEditionRules(
    editionCode: string,
    publicId: string,
    destination: number,
    variant?: string
): Promise<void> {
    await insuranceBlender.put(`${BASE_PATH}/${editionCode}/rules/order`, {
        publicId,
        destination,
        variant,
    });
}

export function useReorderDigitalAgentEditionRules(editionCode: string): UseMutationResult<
    void,
    unknown,
    {
        readonly publicId: string;
        readonly destination: number;
        readonly variant?: string;
    },
    null
> {
    return usePessimisticMutation({
        mutationFn: async ({
            publicId,
            destination,
            variant,
        }: {
            readonly publicId: string;
            readonly destination: number;
            readonly variant?: string;
        }) => await reorderDigitalAgentEditionRules(editionCode, publicId, destination, variant),
        invalidateKeys: [
            [DigitalAgentEditionQueryKey.GetDigitalAgentEdition, editionCode],
            [DigitalAgentEditionQueryKey.GetExtendedPreview, editionCode],
        ],
        mutationKey: [DigitalAgentEditionQueryKey.ReorderDigitalAgentEditionRule],
    });
}

async function updateDigitalAgentViewPreference({
    coveragesEditionCode,
    digitalAgentEditionCode,
}: UpdateDigitalAgentEditionViewPreferenceParams): Promise<void> {
    await insuranceBlender.put(`${BASE_PATH}/${digitalAgentEditionCode}/view-preference`, {
        coveragesEditionCode,
    });
}

export function useUpdateDigitalAgentViewPreference(
    digitalAgentEditionCode: string
): UseMutationResult<void, unknown, UpdateDigitalAgentEditionViewPreferenceParams, null> {
    return usePessimisticMutation({
        mutationFn: updateDigitalAgentViewPreference,
        invalidateKeys: [
            [DigitalAgentEditionQueryKey.GetDigitalAgentEditionViewPreference, digitalAgentEditionCode],
            [DigitalAgentEditionQueryKey.GetExtendedPreview, digitalAgentEditionCode],
        ],
        mutationKey: [DigitalAgentEditionQueryKey.UpdateDigitalAgentEditionViewPreference],
    });
}

async function getDigitalAgentViewPreference(
    digitalAgentEditionCode: string
): Promise<GetDigitalAgentEditionViewPreferenceResponse> {
    const response = await insuranceBlender.get<{ data: GetDigitalAgentEditionViewPreferenceResponse }>(
        `${BASE_PATH}/${digitalAgentEditionCode}/view-preference`
    );

    return response.data.data;
}

export function useGetDigitalAgentViewPreference(
    digitalAgentEditionCode: string
): UseQueryResult<GetDigitalAgentEditionViewPreferenceResponse> {
    return useQuery({
        queryKey: [DigitalAgentEditionQueryKey.GetDigitalAgentEditionViewPreference, digitalAgentEditionCode],
        queryFn: async () => await getDigitalAgentViewPreference(digitalAgentEditionCode),
    });
}

async function attachABTest({ payload, digitalAgentEditionCode }: AttachABTestParams): Promise<void> {
    await insuranceBlender.post(`${BASE_PATH}/${digitalAgentEditionCode}/ab-test/attach`, payload);
}

export function useAttachABTest(
    digitalAgentEditionCode: string
): UseMutationResult<void, unknown, AttachABTestParams, null> {
    return usePessimisticMutation({
        mutationFn: attachABTest,
        invalidateKeys: [[DigitalAgentEditionQueryKey.GetExtendedPreview, digitalAgentEditionCode]],
        mutationKey: [DigitalAgentEditionQueryKey.AttachABTest],
    });
}

async function unbindABTest({ payload, digitalAgentEditionCode }: UnbindABTestParams): Promise<void> {
    await insuranceBlender.post(`${BASE_PATH}/${digitalAgentEditionCode}/ab-test/detach`, payload);
}

export function useUnbindABTest(
    digitalAgentEditionCode: string
): UseMutationResult<void, unknown, UnbindABTestParams, null> {
    return usePessimisticMutation({
        mutationFn: unbindABTest,
        invalidateKeys: [[DigitalAgentEditionQueryKey.GetExtendedPreview, digitalAgentEditionCode]],
        mutationKey: [DigitalAgentEditionQueryKey.UnbindABTest],
    });
}

async function addVariants({ payload, digitalAgentEditionCode }: AddRemoveVariantsParams): Promise<void> {
    await insuranceBlender.post(`${BASE_PATH}/${digitalAgentEditionCode}/ab-test/variants/add`, payload);
}

export function useAddVariants(
    digitalAgentEditionCode: string
): UseMutationResult<void, unknown, AddRemoveVariantsParams, null> {
    return usePessimisticMutation({
        mutationFn: addVariants,
        invalidateKeys: [[DigitalAgentEditionQueryKey.GetExtendedPreview, digitalAgentEditionCode]],
        mutationKey: [DigitalAgentEditionQueryKey.AddVariants],
    });
}

async function removeVariants({ payload, digitalAgentEditionCode }: AddRemoveVariantsParams): Promise<void> {
    await insuranceBlender.post(`${BASE_PATH}/${digitalAgentEditionCode}/ab-test/variants/remove`, payload);
}

export function useRemoveVariants(
    digitalAgentEditionCode: string
): UseMutationResult<void, unknown, AddRemoveVariantsParams, null> {
    return usePessimisticMutation({
        mutationFn: removeVariants,
        invalidateKeys: [[DigitalAgentEditionQueryKey.GetExtendedPreview, digitalAgentEditionCode]],
        mutationKey: [DigitalAgentEditionQueryKey.RemoveVariants],
    });
}

async function renameVariants({ payload, digitalAgentEditionCode }: RenameVariantsParams): Promise<void> {
    await insuranceBlender.post(`${BASE_PATH}/${digitalAgentEditionCode}/ab-test/rename`, payload);
}

export function useRenameVariants(
    digitalAgentEditionCode: string
): UseMutationResult<void, unknown, RenameVariantsParams, null> {
    return usePessimisticMutation({
        mutationFn: renameVariants,
        invalidateKeys: [[DigitalAgentEditionQueryKey.GetExtendedPreview, digitalAgentEditionCode]],
        mutationKey: [DigitalAgentEditionQueryKey.RenameVariants],
    });
}
