export enum EditionType {
    Rating = 'rating',
    Coverages = 'coverages',
    DigitalAgent = 'digitalAgent',
    UnderwritingFilters = 'underwritingFilters',
}

export enum EditionStatus {
    Draft = 'draft',
    Archived = 'archived',
    Approved = 'approved',
    Published = 'published',
}

export interface Edition {
    readonly code: string;
    readonly status: EditionStatus;
    readonly productCode: string;
    readonly description: string;
    readonly friendlyName: string;
    readonly baseEditionCode?: string;

    readonly addedAt: string;
    readonly addedBy: string;
    readonly addedByName: string;

    readonly approvedAt?: string;
    readonly approvedBy?: string;
    readonly approvedByName?: string;

    readonly archivedAt?: string;
    readonly archivedBy?: string;
    readonly archivedByName?: string;

    readonly publishedAt?: string;
    readonly publishedBy?: string;
    readonly publishedByName?: string;

    readonly earliestNewBusinessEffectiveDate?: string;
    readonly earliestRenewalEffectiveDate?: string;

    readonly version?: string;
    readonly versionMajor?: number;
    readonly versionMinor?: number;
}

export interface ApprovedEdition extends Edition {
    readonly approvedBy: string;
    readonly approvedAt: string;
    readonly approvedByName: string;

    readonly version: string;
    readonly versionMajor: number;
    readonly versionMinor: number;
}

export interface PublishedEdition extends ApprovedEdition {
    readonly publishedAt: string;
    readonly publishedBy: string;
    readonly publishedByName: string;

    readonly earliestNewBusinessEffectiveDate: string;
    readonly earliestRenewalEffectiveDate: string;

    readonly version: string;
    readonly versionMajor: number;
    readonly versionMinor: number;
}

export interface BaseEditionFilter {
    readonly approved?: boolean;
    readonly archived?: boolean;
    readonly maxDaysSinceArchived?: number;
}

export interface CreateEditionParams {
    readonly productCode: string;
    readonly description: string;
    readonly baseEditionCode?: string;
}

export type EditionWithType = Edition & { readonly type: EditionType };

export interface ArchiveEditionParams {
    readonly editionCode: string;
}

export enum VersionType {
    Major = 'major',
    Minor = 'minor',
    BugFix = 'bugfix',
}

export enum QuoteLifecycleContext {
    NewBusiness = 'new_business',
    Renewal = 'renewal',
    PolicyEdits = 'policy_edits',
    Moving = 'moving',
}

export enum VersionTypeBlockReason {
    EditionApproved = 'edition_approved',
    BaseEditionNotApproved = 'base_edition_not_approved',
    BaseNotLatestVersion = 'base_not_latest_version',
    BaseNotLatestVersionForMajor = 'base_not_latest_version_for_major',
    MissingBaseEdition = 'missing_base_edition',
    MissingPriorMajor = 'missing_prior_major',
}

export interface VersionTypeInfo {
    readonly type: VersionType;
    readonly allowed: boolean;
    readonly blockReasons: VersionTypeBlockReason[];
}

export enum EditionAlertLevel {
    Warning = 'warning',
    Info = 'info',
}
