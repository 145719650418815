import {
    SummaryInnerSectionItemKey as Key,
    SummarySectionContent,
    SummarySectionTitle,
    SummaryInnerSectionItemValue as Value,
} from '@lemonade-hq/bluis';
import { LinkComp } from '@lemonade-hq/bluiza';
import { titlize } from '@lemonade-hq/ts-helpers';
import { CopyableText } from 'components/LoCo/common/components/Copyable/CopyButton';
import { getProductsPageUrl } from 'components/LoCo/common/urlBuilders';
import { StyledSection } from 'components/LoCo/LoCoPagesSharedStyles';
import type { Product } from 'models/LoCo/Insurance/Product';

export const ProductDetails: React.FC<{ readonly product: Product }> = ({ product }) => (
    <StyledSection>
        <SummarySectionTitle>Product Details</SummarySectionTitle>
        <SummarySectionContent>
            <Key>Product Line</Key>
            <Value>
                <LinkComp url={getProductsPageUrl()}>{titlize(product.productLineCode.replace(/[_-]/g, ' '))}</LinkComp>
            </Value>
            <Key>Description</Key>
            <Value>{product.description}</Value>
            <Key>Code</Key>
            <Value>
                <CopyableText textToCopy={product.code} textToDisplay={product.code} visibleOnHover />
            </Value>{' '}
            <Key>Currency</Key>
            <Value>{product.currency}</Value>
            <Key>Locales</Key>
            <Value>{product.locales.join(', ')}</Value>
            <Key>Insurable Entities</Key>
            <Value>{product.insurableEntities.map(e => e.name).join(', ')}</Value>
        </SummarySectionContent>
    </StyledSection>
);
