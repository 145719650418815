import { isDefined } from '@lemonade-hq/ts-helpers';
import type { Edition } from 'models/LoCo/Insurance/BaseEdition';
import { EditionStatus, EditionType } from 'models/LoCo/Insurance/BaseEdition';

export function isEditionApproved(edition?: Edition): boolean {
    return edition != null && isDefined(edition.approvedAt) && isDefined(edition.approvedBy);
}

export function isEditionArchived(edition?: Edition): boolean {
    return edition != null && isDefined(edition.archivedAt) && isDefined(edition.archivedBy);
}

const EDITION_TYPE_TO_DISPLAY_NAME: Record<EditionType, string> = {
    [EditionType.Rating]: 'Rating',
    [EditionType.Coverages]: 'Coverages',
    [EditionType.DigitalAgent]: 'Digital Agent',
    [EditionType.UnderwritingFilters]: 'Underwriting Filters',
};

export function getEditionName(editionType: EditionType): string {
    return EDITION_TYPE_TO_DISPLAY_NAME[editionType];
}

export function getEditionStatus(edition: Edition): EditionStatus {
    if (isEditionArchived(edition)) {
        return EditionStatus.Archived;
    }

    if (isEditionApproved(edition)) {
        return EditionStatus.Approved;
    }

    return EditionStatus.Draft;
}
