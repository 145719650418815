import type { AvatarProps } from '@lemonade-hq/blender-ui';
import { Avatar, Flex, spacing, Text } from '@lemonade-hq/blender-ui';
import { forwardRef } from 'react';

export const AssigneeItem = forwardRef<
    HTMLDivElement,
    {
        readonly fullName: string;
        readonly photoUrl?: string;
        readonly className?: string;
        readonly avatarSize?: AvatarProps['size'];
    }
>(({ fullName, photoUrl, className, avatarSize = 'xs', ...props }, ref) => (
    <Flex alignItems="center" className={className} gap={spacing.s06} ref={ref} {...props}>
        <Avatar name={fullName} size={avatarSize} src={photoUrl} />
        <Text as="span" type="text-md">
            {fullName}
        </Text>
    </Flex>
));
