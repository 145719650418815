import type { QuoteLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import type { CoverageRuleType, CoveragesTemplateType } from 'models/LoCo/Insurance/CoverageRule';

export enum DialogType {
    AddRule = 'addRule',
}

export type ActionData = {
    readonly type: DialogType;
    readonly entity?: { readonly type: CoveragesTemplateType; readonly code?: string };
    readonly lifecycleContexts?: QuoteLifecycleContext[];
    readonly ruleType?: CoverageRuleType;
    readonly variantName?: string;
    readonly allVariants?: Record<string, Record<string, string[]>>;
};

export type Actions = DialogType.AddRule;
