import type { TableItem } from '@lemonade-hq/blender-ui';
import { Flex, Hr, spacing, Table, Text } from '@lemonade-hq/blender-ui';
import { useMemo } from 'react';
import type { FC } from 'react';
import * as styles from './ChangesSummary.css';
import type { CoverageRuleDisplayLog } from './types';
import type { RuleContext } from './useCoveragesRulesTableData';
import { useCoveragesRulesTableData } from './useCoveragesRulesTableData';
import { formatLifeCycleContext } from 'components/LoCo/common/display-texts/common';
import { CoverageRuleType } from 'models/LoCo/Insurance/CoverageRule';

const contextTitle = (context: RuleContext): string =>
    context === CoverageRuleType.Restriction ? 'Restrictions' : `${formatLifeCycleContext(context)} Defaults`;

const typeColumnValue = (ruleType: CoverageRuleType): string =>
    ruleType === CoverageRuleType.Restriction ? 'Restriction' : 'Default';

const CoveragesRulesTable: FC<{ readonly rules: CoverageRuleDisplayLog[] }> = ({ rules }) => {
    const showContext = rules[0].ruleType === CoverageRuleType.Restriction;
    const columns = useMemo(() => {
        const cols = [
            { key: 'type', name: 'type' },
            { key: 'segment', name: 'segment' },
            { key: 'outcome', name: 'outcome' },
        ];

        if (showContext) {
            cols.splice(1, 0, { key: 'context', name: 'context' });
        }

        return cols;
    }, [showContext]);

    const columnsData = useMemo(
        () =>
            rules.map(rule => {
                const col: Record<string, TableItem> = {
                    type: {
                        value: `${typeColumnValue(rule.ruleType)} (${rule.filed ? 'Filed' : 'Not Filed'})`,
                    },
                };

                if (showContext) {
                    col.context = {
                        value: (
                            <Flex flexDirection="column">
                                {rule.lifecycleContexts.map(lifecycleContext => (
                                    <div key={lifecycleContext}>{formatLifeCycleContext(lifecycleContext)}</div>
                                ))}
                            </Flex>
                        ),
                    };
                }

                col.segment = { value: rule.expression !== '' ? rule.expression : 'All' };
                col.outcome = { value: rule.outcomeDisplay };

                return col;
            }),
        [rules, showContext]
    );

    return <Table className={styles.table} columns={columns} data={columnsData} />;
};

export const CoveragesRulesTables: FC<{ readonly rules: CoverageRuleDisplayLog[] }> = ({ rules }) => {
    const { groupedRules, groupedRulesABTest } = useCoveragesRulesTableData(rules);

    return (
        <Flex flexDirection="column" pt={spacing.s16}>
            {Object.entries(groupedRulesABTest).map(([lifecycleContext, rulesByVariant], index) => (
                <>
                    {index > 0 && <Hr />}
                    <Flex flexDirection="column" key={lifecycleContext} px={spacing.s08}>
                        <h5>{contextTitle(lifecycleContext as RuleContext)}</h5>
                        {Object.entries(rulesByVariant).map(([variantTitle, variantRules]) => (
                            <Flex
                                flexDirection="column"
                                key={variantTitle}
                                pb={spacing.s08}
                                pl={spacing.s08}
                                pt={spacing.s12}
                            >
                                <Text color="secondary">{variantTitle}</Text>
                                {variantRules && <CoveragesRulesTable rules={variantRules} />}
                            </Flex>
                        ))}
                    </Flex>
                </>
            ))}
            {Object.entries(groupedRules).map(([lifecycleContext, rulesByContext], index) => (
                <>
                    {(Object.keys(groupedRulesABTest).length > 0 || index > 0) && <Hr />}
                    <Flex flexDirection="column" key={lifecycleContext} px={spacing.s08}>
                        <h5>{contextTitle(lifecycleContext as RuleContext)}</h5>
                        <CoveragesRulesTable rules={rulesByContext} />
                    </Flex>
                </>
            ))}
        </Flex>
    );
};
