import { Select } from '@lemonade-hq/bluis';
import { isDefined } from '@lemonade-hq/ts-helpers';
import type { schema } from 'avsc';
import type { SelectOptionBase } from 'libs/blender-ui/src/components/Select/Select';
import styled from 'styled-components';
import { isEnumField, isPrimitiveField } from '../../helpers/schemaHelpers';
import type { Argument, ExpressionTree } from './expressionTypes';
import { ArgumentType, ExpressionType } from './expressionTypes';
import type { InputFunction } from './operators';
import { BOOLEAN_VALUES, COMPARE_OPERATORS } from './operators';
import type { AvroFieldWithType, FieldEntry } from 'models/LoCo/Insurance/Schema';

const avroTypeMap = {
    string: ArgumentType.String,
    int: ArgumentType.Number,
    float: ArgumentType.Number,
    double: ArgumentType.Number,
    boolean: ArgumentType.Boolean,
    null: ArgumentType.Unknown,
    long: ArgumentType.Number,
    bytes: ArgumentType.Unknown,
} satisfies Record<schema.PrimitiveType, ArgumentType>;

export function getTypeFromSchema(schema: Record<string, FieldEntry>, key: string): Argument {
    if (!isDefined(schema[key])) return { type: ArgumentType.Unknown };
    if (isPrimitiveField(schema[key])) {
        const schemaType = schema[key].type as schema.PrimitiveType;
        return { type: avroTypeMap[schemaType] };
    }

    if (isEnumField(schema[key]))
        return {
            type: ArgumentType.Enum,
            symbols: (schema[key] as AvroFieldWithType<schema.EnumType>).type.symbols.map(symbol => ({
                label: symbol,
                value: symbol,
            })),
            enumName: key,
        };

    return { type: ArgumentType.Unknown };
}

/**
 * For a given node in the expression, which represents a function like "input" or "isContainedIn"
 * return the type of the argument that the function expects.
 *
 * @param expression - The expression tree containing all of the nodes.
 * @param key - The key of the node in the expression tree to evaluate. (Should represent a function)
 * @param schema - The schema of the product, which contains the field definitions.
 * @param functions - An optional record of input functions that may influence the return type.
 * @returns An object representing the argument type, which can include types such as
 *          Unknown, Number, String, Boolean, or Enum, along with any relevant symbols
 *          for enum types.
 */
export function getInputType(
    expression: ExpressionTree,
    key: string | undefined,
    schema: Record<string, FieldEntry>,
    functions: Record<string, InputFunction> = {}
): Argument {
    if (key == null) return { type: ArgumentType.Unknown };
    if (!isDefined(expression[key])) return { type: ArgumentType.Unknown };

    const node = expression[key];

    switch (node.type) {
        case ExpressionType.Literal: {
            if (node.value === '') return { type: ArgumentType.Unknown };
            if (isNaN(node.value as unknown as number)) {
                if (BOOLEAN_VALUES.includes(node.value)) {
                    return { type: ArgumentType.Boolean };
                }

                return { type: ArgumentType.String };
            }

            return { type: ArgumentType.Number };
        }

        case ExpressionType.InputExpression: {
            // TODO for an input expression, isn't the callee always 'input'?
            // Turns out that input express isn't always an input, it can be a general function
            // we should rename accordingly
            if (node.callee === 'input') return getTypeFromSchema(schema, node.value);
            else return functions[node.callee].returnType;
        }

        case ExpressionType.CallExpression:
        case ExpressionType.BinaryExpression:
            return getInputType(expression, node.children[0], schema, functions);
        case ExpressionType.ArrayExpression:
            return getInputType(expression, node.parent, schema, functions);
        default:
            return { type: ArgumentType.Unknown };
    }
}

export interface ExpressionProps {
    readonly id: string;
    readonly payload?: {
        readonly placeholder?: string;
    };
}

export function getCompareOperators(type: ArgumentType): SelectOptionBase[] {
    return Object.keys(COMPARE_OPERATORS[type]).map(key => ({
        value: key,
        label: COMPARE_OPERATORS[type][key].display,
    }));
}

export const FloatError = styled.div`
    position: absolute;
    bottom: -20px;
    color: red;
`;

export const StyledSelect = styled(Select)`
    > button {
        width: 150px !important;
    }
`;

export const ExpressionContainer = styled.div`
    display: flex;
    column-gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const StyledWhere = styled.span`
    width: 80px;
    height: 100%;
    padding-top: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    justify-self: flex-start;
`;

export const placeholderMapper: Record<ArgumentType, string> = {
    [ArgumentType.Number]: 'Number',
    [ArgumentType.String]: 'String',
    [ArgumentType.Unknown]: '',
    [ArgumentType.Enum]: 'String',
    [ArgumentType.Boolean]: 'Boolean',
};

export function randomExpressionId(): string {
    return Math.random().toString(36).substring(2);
}
