import type { UseMutationResult, UseQueryOptions, UseQueryResult, UseSuspenseQueryResult } from '@tanstack/react-query';
import { keepPreviousData, useQuery, useSuspenseQueries } from '@tanstack/react-query';
import { insuranceBlender } from '../../../apiClients';
import type { Pagination } from './QueriesShared';
import type { PaginationResult } from 'models/CarShared';
import { CoveragesTemplateType } from 'models/LoCo/Insurance/CoverageRule';
import type { CoverageTemplate, SettingTemplate } from 'models/LoCo/Insurance/Registry';
import { RegistryType } from 'models/LoCo/Insurance/Registry';
import type { SettingType } from 'models/LoCo/Insurance/SettingType';
import { usePessimisticMutation } from 'queries/MutationHooks';

export interface CreateTemplateAttributes {
    readonly name: string;
    readonly description: string;
    readonly productLines: string[];
}

export type CreateCoverageTemplateAttributes = CreateTemplateAttributes;

export interface CreateSettingTemplateAttributes extends CreateTemplateAttributes {
    readonly type: SettingType;
}

export interface UpdateTemplateAttributes {
    readonly description?: string;
    readonly productLines?: string[];
}

type RegistryTemplateByType<T extends RegistryType> = T extends RegistryType.Coverage
    ? CoverageTemplate
    : SettingTemplate;

const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_PAGE = 1;

const DEFAULT_PAGINATION: Pagination = {
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
};

export enum RegistryQueryKey {
    CreateRegistrySettings = 'CREATE_REGISTRY_SETTINGS',
    CreateRegistryCoverage = 'CREATE_REGISTRY_COVERAGE',
    UpdateRegistrySettings = 'UPDATE_REGISTRY_SETTINGS',
    UpdateRegistryCoverage = 'UPDATE_REGISTRY_COVERAGE',
    GetRegistryCoverages = 'GET_REGISTRY_COVERAGES',
    GetRegistrySettings = 'GET_REGISTRY_SETTINGS',
}

async function getRegistryCoverages(page: number, size: number): Promise<PaginationResult<CoverageTemplate>> {
    return await insuranceBlender
        .get<PaginationResult<CoverageTemplate>>(`api/v1/registry/coverages?page=${page}&size=${size}`)
        .then(response => response.data);
}

export function useGetRegistryCoverages(
    { page = DEFAULT_PAGE, size = DEFAULT_PAGE_SIZE }: Pagination = DEFAULT_PAGINATION,
    enabled = true
): UseQueryResult<PaginationResult<CoverageTemplate>, unknown> {
    return useQuery({
        queryKey: [RegistryQueryKey.GetRegistryCoverages, page, size],
        queryFn: async () => await getRegistryCoverages(page, size),
        enabled,
        placeholderData: keepPreviousData,
    });
}

async function createRegistryCoverageTemplate(data: CreateCoverageTemplateAttributes): Promise<void> {
    await insuranceBlender.post('api/v1/registry/coverages', data);
}

export function useCreateRegistryCoverageTemplate(): UseMutationResult<
    void,
    unknown,
    CreateCoverageTemplateAttributes,
    null
> {
    return usePessimisticMutation({
        mutationFn: createRegistryCoverageTemplate,
        invalidateKeys: [[RegistryQueryKey.GetRegistryCoverages]],
        mutationKey: [RegistryQueryKey.CreateRegistryCoverage],
    });
}

export async function getRegistrySettings(page: number, size: number): Promise<PaginationResult<SettingTemplate>> {
    return await insuranceBlender
        .get<PaginationResult<SettingTemplate>>(`api/v1/registry/settings?page=${page}&size=${size}`)
        .then(response => response.data);
}

export async function createRegistrySettingTemplate(data: CreateSettingTemplateAttributes): Promise<void> {
    await insuranceBlender.post('api/v1/registry/settings', data);
}

export function useCreateRegistrySettingTemplate(): UseMutationResult<
    void,
    unknown,
    CreateSettingTemplateAttributes,
    null
> {
    return usePessimisticMutation({
        mutationFn: createRegistrySettingTemplate,
        invalidateKeys: [[RegistryQueryKey.GetRegistrySettings]],
        mutationKey: [RegistryQueryKey.CreateRegistrySettings],
    });
}

export function useGetRegistrySettings(
    { page = DEFAULT_PAGE, size = DEFAULT_PAGE_SIZE }: Pagination = DEFAULT_PAGINATION,
    enabled = true
): UseQueryResult<PaginationResult<SettingTemplate>, unknown> {
    return useQuery({
        queryKey: [RegistryQueryKey.GetRegistrySettings, page, size],
        queryFn: async () => await getRegistrySettings(page, size),
        enabled,
    });
}

export function useGetCoveragesOrSettingsFromRegistry<T extends CoveragesTemplateType>(
    entityType: T
): UseQueryResult<PaginationResult<T extends CoveragesTemplateType.Coverage ? CoverageTemplate : SettingTemplate>, unknown> {
    const queryType =
        entityType === CoveragesTemplateType.Coverage
            ? RegistryQueryKey.GetRegistryCoverages
            : RegistryQueryKey.GetRegistrySettings;

    const queryFunc = entityType === CoveragesTemplateType.Coverage ? getRegistryCoverages : getRegistrySettings;
    return useQuery({
        queryKey: [queryType, DEFAULT_PAGE, DEFAULT_PAGE_SIZE],
        queryFn: async () => await queryFunc(DEFAULT_PAGE, DEFAULT_PAGE_SIZE),
        placeholderData: keepPreviousData,
    });
}

export async function getRegistryByProductLine<T extends RegistryType>(
    productLineCode: string,
    type: T
): Promise<RegistryTemplateByType<T>[]> {
    return await insuranceBlender
        .get<{ data: RegistryTemplateByType<T>[] }>(`api/v1/registry/product-lines/${productLineCode}/${type}`)
        .then(response => response.data.data);
}

function buildRegistryQueries<T extends RegistryType>(
    productLineCode: string,
    type: T[]
): UseQueryOptions<RegistryTemplateByType<T>[]>[] {
    const keys: Record<RegistryType, string> = {
        [RegistryType.Coverage]: RegistryQueryKey.GetRegistryCoverages,
        [RegistryType.Setting]: RegistryQueryKey.GetRegistrySettings,
    };

    return type.map(t => ({
        queryFn: async () => await getRegistryByProductLine(productLineCode, t),
        queryKey: [keys[t], productLineCode, t],
    }));
}

export function useGetRegistryByProductLine<T extends RegistryType>(
    productLineCode: string,
    type: T[]
): UseSuspenseQueryResult<RegistryTemplateByType<T>[]>[] {
    const queries = buildRegistryQueries(productLineCode, type);

    return useSuspenseQueries({ queries });
}

export async function updateRegistryTemplate<T extends RegistryType>(
    type: T,
    templateCode: string,
    data: UpdateTemplateAttributes
): Promise<void> {
    await insuranceBlender.put(`api/v1/registry/${type}/${templateCode}`, data);
}

type UseUpdateAttrs = { readonly templateCode: string; readonly data: UpdateTemplateAttributes };

export function useUpdateRegistryTemplate(type: RegistryType): UseMutationResult<void, unknown, UseUpdateAttrs, null> {
    const invalidateKeys =
        type === RegistryType.Coverage
            ? [[RegistryQueryKey.GetRegistryCoverages]]
            : [[RegistryQueryKey.GetRegistrySettings]];

    const mutationKey =
        type === RegistryType.Coverage
            ? RegistryQueryKey.UpdateRegistryCoverage
            : RegistryQueryKey.UpdateRegistrySettings;

    return usePessimisticMutation({
        mutationFn: async ({ templateCode, data }: UseUpdateAttrs) =>
            await updateRegistryTemplate(type, templateCode, data),
        invalidateKeys,
        mutationKey: [mutationKey],
    });
}
