import { LoadingSection } from '@lemonade-hq/bluis';
import { Step } from '../ManageRuleCommon';
import { useManageRuleDialogContext } from '../ManageRuleDialogContext';
import { CoverageDefault } from './Outcomes/CoverageDefault';
import { CoverageInitialValue } from './Outcomes/CoverageInitialRenewalValue';
import { CoverageRestriction } from './Outcomes/CoverageRestriction';
import { SettingDefault } from './Outcomes/SettingDefault';
import { SettingInitialValue } from './Outcomes/SettingInitialValue';
import { SettingRestriction } from './Outcomes/SettingRestriction';
import { QuoteLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import { CoverageRuleType, CoveragesTemplateType } from 'models/LoCo/Insurance/CoverageRule';
import { useGetCoveragesOrSettingsFromRegistry } from 'queries/LoCo/Insurance/RegistryQueries';

const OutcomeContent: React.FC = () => {
    const {
        values: { entityCode, entityType, ruleType, lifecycleContexts },
    } = useManageRuleDialogContext();

    if (entityType == null) throw new Error('Entity type is required');

    const { data: coveragesOrSettings, isLoading } = useGetCoveragesOrSettingsFromRegistry(entityType);

    if (isLoading) return <LoadingSection noBorders noShadow />;

    const entity = coveragesOrSettings?.data.find(({ code }) => code === entityCode);

    if (!entity) throw new Error('Coverage or setting not found');
    if (entityType === CoveragesTemplateType.Coverage) {
        if (ruleType === CoverageRuleType.InitialValue) {
            const lifecycleContext = (lifecycleContexts ?? [])[0];
            if (lifecycleContext === QuoteLifecycleContext.Moving || lifecycleContext === QuoteLifecycleContext.Renewal) {
                return <CoverageInitialValue coverageName={entity.name} lifecycle={lifecycleContext} />;
            } else {
                return <CoverageDefault coverageName={entity.name} />;
            }
        } else if (ruleType === CoverageRuleType.Restriction) {
            return <CoverageRestriction coverageName={entity.name} />;
        }
    }

    if (entityType === CoveragesTemplateType.Setting) {
        if (ruleType === CoverageRuleType.InitialValue) {
            if (lifecycleContexts?.includes(QuoteLifecycleContext.Renewal))
                return <SettingInitialValue label="Match Previous Policy Term" settingName={entity.name} />;
            else if (lifecycleContexts?.includes(QuoteLifecycleContext.Moving)) {
                return <SettingInitialValue label="Match Pre-Moving Policy Term" settingName={entity.name} />;
            } else {
                return <SettingDefault settingName={entity.name} />;
            }
        } else if (ruleType === CoverageRuleType.Restriction) {
            return <SettingRestriction settingName={entity.name} />;
        }
    }

    return null;
};

export const OutcomeSelection: React.FC = () => {
    return (
        <Step>
            <OutcomeContent />
        </Step>
    );
};
