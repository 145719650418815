import type { ProductTypes } from '../ProductTypes';
import { CoreEntityType } from '../ProductTypes';

export function createClaimLink(product: ProductTypes, entityPublicId?: string): string {
  return entityPublicId !== undefined
    ? `/backoffice/${product}/claims/${entityPublicId}`
    : `/backoffice/${product}/claims`;
}

export function createTaskLink(product: ProductTypes, claimPublicId: string, taskPublicId: string): string {
  return `/backoffice/${product}/claims/${claimPublicId}/workflows/#${taskPublicId}`;
}

export function createPolicyLink(product: ProductTypes, entityPublicId?: string, isMetromileEntity?: boolean): string {
  if (entityPublicId === undefined) {
    return `/backoffice/${product}/policies`;
  }

  if (isMetromileEntity) {
    return createMetromilePolicyLink(entityPublicId);
  }

  return `/backoffice/${product}/policies/${entityPublicId}`;
}

export function createQuoteLink(product: ProductTypes, entityPublicId?: string, isMetromileEntity?: boolean): string {
  if (entityPublicId === undefined) {
    return `/backoffice/${product}/quotes`;
  }

  if (isMetromileEntity) {
    return createMetromileQuoteLink(entityPublicId);
  }

  return `/backoffice/${product}/quotes/${entityPublicId}`;
}

export function createUserLink(id?: string): string {
  return id !== undefined ? `/backoffice/users/${id}` : `/backoffice/users`;
}

export function createEditPolicyCoveragesLink(product: ProductTypes, policyPublicId: string): string {
  return `/backoffice/${product}/policies/${policyPublicId}/edit_coverages`;
}

export function createMetromileClaimLink(claimId: string): string {
  return `/backoffice/car/history_claims/${claimId}`;
}

export function createMetromilePolicyLink(policyId: string): string {
  return `/backoffice/car/history_policies/${policyId}`;
}

export function createMetromileQuoteLink(quoteId: string): string {
  return `/backoffice/car/history_quotes/${quoteId}`;
}

export function getLinkByEntity({
  product,
  entity,
}: {
  readonly product: ProductTypes;
  readonly entity: CoreEntityType;
}): string {
  switch (entity) {
    case CoreEntityType.Policy:
      return createPolicyLink(product);
    case CoreEntityType.Quote:
      return createQuoteLink(product);
    case CoreEntityType.Claim:
      return createClaimLink(product);
    case CoreEntityType.User:
      return createUserLink();
    default:
      return '';
  }
}
