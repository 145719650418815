import type { LabelValueItem } from '@lemonade-hq/blender-ui';
import pluralize from 'pluralize';
import { ChangeAccordion } from '../ChangeAccordion';
import { findSetting } from './common';
import { getAttributesForSetting } from './setting-attributes';
import { getSettingTypeDisplayName } from 'components/LoCo/common/display-texts/setting-instance';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import type { AttributesUpdatedEntry } from 'models/LoCo/Insurance/ChangesLog';
import type { CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';

const zipOldWithNewAttributes = (
    newAttributes: LabelValueItem[],
    oldAttributes: LabelValueItem[]
): LabelValueItem[] => {
    const filteredNewAttributes: LabelValueItem[] = [];
    const filteredOldAttributes: LabelValueItem[] = [];

    const oldAttributesMap: Record<string, LabelValueItem> = {};
    for (let i = 0; i < oldAttributes.length; i++) {
        oldAttributesMap[oldAttributes[i].label] = oldAttributes[i];
    }

    for (let i = 0; i < newAttributes.length; i++) {
        const oldAttribute = oldAttributesMap[newAttributes[i].label];

        if ((oldAttribute as LabelValueItem | undefined) === undefined) {
            filteredNewAttributes.push({ label: `New ${newAttributes[i].label}`, value: newAttributes[i].value });
            filteredOldAttributes.push({ label: `Old ${newAttributes[i].label}`, value: '-' });
        } else if (oldAttribute.value !== newAttributes[i].value) {
            filteredNewAttributes.push({ label: `New ${newAttributes[i].label}`, value: newAttributes[i].value });
            filteredOldAttributes.push({ label: `Old ${oldAttribute.label}`, value: oldAttribute.value });
        }
    }

    return filteredNewAttributes.flatMap((newAttribute, i) => [newAttribute, filteredOldAttributes[i]]);
};

interface SettingAttributesUpdatedProps {
    readonly change: AttributesUpdatedEntry;
    readonly coveragesEdition: CoveragesEdition;
    readonly baseCoveragesEdition: CoveragesEdition;
}

export const SettingAttributesUpdated: React.FC<SettingAttributesUpdatedProps> = ({
    change,
    coveragesEdition,
    baseCoveragesEdition,
}) => {
    const product = useGetProductData();
    const baseSetting = findSetting(baseCoveragesEdition, change.metadata.referenceId);
    const setting = findSetting(coveragesEdition, change.metadata.referenceId);
    const attributes = getAttributesForSetting(setting, product, coveragesEdition.settings);
    const baseAttributes = getAttributesForSetting(baseSetting, product, baseCoveragesEdition.settings);
    const items = zipOldWithNewAttributes(attributes, baseAttributes);

    return (
        <ChangeAccordion
            generalTabData={items}
            originalRules={null}
            ruleType="coverage"
            title={`${getSettingTypeDisplayName(setting.type)} ${pluralize('attribute', change.metadata.changedAttributes.length)} updated - ${setting.name}`}
            updatedRules={null}
        />
    );
};
