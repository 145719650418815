import { IconButton } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { useCallback } from 'react';
import { getInlineUrl } from 'components/Attachments/utils';

export const OpenInNewTab: FC<{ readonly url: string }> = ({ url }) => {
    const openInNewTab = useCallback(() => {
        const inlineUrl = getInlineUrl(url);

        window.open(inlineUrl, '_blank');
    }, [url]);

    return (
        <IconButton
            color="neutral7"
            icon="export"
            iconSize="xl"
            onClick={openInNewTab}
            size="lg"
            title="Open in new tab"
            variant="inline"
        />
    );
};
