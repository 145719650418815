import type { FC } from 'react';
import { ChangeAccordion } from '../ChangeAccordion';
import { assertIsABTestRuleGroup, findFlag } from './common';
import type { FlagABTestDetachedEntry } from 'models/LoCo/Insurance/ChangesLog';
import type { UnderwritingFiltersEdition } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

interface FlagABTestDetachedProps {
    readonly change: FlagABTestDetachedEntry;
    readonly underwritingFiltersEdition: UnderwritingFiltersEdition;
    readonly baseUnderwritingFiltersEdition: UnderwritingFiltersEdition;
}

export const FlagABTestDetached: FC<FlagABTestDetachedProps> = ({
    change,
    underwritingFiltersEdition,
    baseUnderwritingFiltersEdition,
}) => {
    const flag = findFlag(underwritingFiltersEdition, change.metadata.referenceId);
    const name = flag.flagName;

    const oldFlag = baseUnderwritingFiltersEdition.flagRulesGroup.find(f => f.flagCode === change.metadata.referenceId);

    assertIsABTestRuleGroup(oldFlag);

    const rows = [{ label: 'Experiment Name', value: oldFlag.experimentName }];

    return (
        <ChangeAccordion
            generalTabData={rows}
            originalRules={null}
            ruleType="flag"
            title={`A/B Test Removed: ${name}`}
            updatedRules={null}
        />
    );
};
