import { ErrorSection, LoadingSection } from '@lemonade-hq/bluis';
import { ErrorBoundary } from '@sentry/react';
import { Suspense, useMemo, useRef, useState } from 'react';
import { ManageRuleStep, UnderwritingDialogType } from '../UnderwritingFiltersShared';
import { ReasonSelection } from './ReasonSelection';
import type { DialogData } from './UnderwritingRulesDialogs';
import type { DeepNullable } from 'apps/blender/src/shared/utils/types';
import type { RuleBuilderRefMethods } from 'components/LoCo/common/components/CoverageRules/ManageRuleDialogContext';
import { RegistryProvider } from 'components/LoCo/common/components/CoverageRules/ManageRuleDialogContext';
import { SegmentFilterSelection } from 'components/LoCo/common/components/CoverageRules/Steps/SegmentSelection';
import { StyledDialog } from 'components/LoCo/common/components/Dialog/Dialog';
import { useMiniFlowFormDialog } from 'components/LoCo/common/components/Dialog/useMiniFlowFormDialog';
import { StyledMiniFlow } from 'components/LoCo/common/components/MiniFlow';
import { SuspendableContent } from 'components/LoCo/common/components/SuspendableContent';
import { getOutcomeText } from 'components/LoCo/common/display-texts/underwriting-rules';
import { RuleDialogDimensions } from 'components/LoCo/LoCoPagesSharedStyles';
import type { DeclineRuleParamsRequest } from 'queries/LoCo/Insurance/UnderwritingFiltersEditionQueries';
import {
    useCreateUnderwritingDeclineFiltersRules,
    useUpdateUnderwritingDeclineFiltersRules,
} from 'queries/LoCo/Insurance/UnderwritingFiltersEditionQueries';

const stepIds = [ManageRuleStep.Segment, ManageRuleStep.Outcome];

type DeclineRuleDialogData = Extract<
    DialogData,
    { readonly type: UnderwritingDialogType.AddDeclineRule | UnderwritingDialogType.EditDeclineRule }
>;

export const ManageDeclineRuleDialog: React.FC<{
    readonly dialogData: DeclineRuleDialogData;
    readonly onClose: () => void;
}> = ({ dialogData, onClose }) => {
    const isEditMode = dialogData.type === UnderwritingDialogType.EditDeclineRule;

    const [isExpressionInvalid, setIsExpressionInvalid] = useState(
        dialogData.type === UnderwritingDialogType.AddDeclineRule
    );
    const renderExpressionRef = useRef<RuleBuilderRefMethods | null>(null);
    const { mutateAsync: createRule, isPending: isLoadingCreateRule } = useCreateUnderwritingDeclineFiltersRules(
        dialogData.editionCode
    );
    const { mutateAsync: updateRule, isPending: isLoadingUpdateRule } = useUpdateUnderwritingDeclineFiltersRules(
        dialogData.editionCode,
        dialogData.type === UnderwritingDialogType.EditDeclineRule ? dialogData.data.publicId : ''
    );
    const lifecycleContext =
        dialogData.type === UnderwritingDialogType.EditDeclineRule
            ? dialogData.data.lifecycleContext
            : dialogData.lifecycleContext;

    const { actions, currentStep, dispatch, state } = useMiniFlowFormDialog<
        ManageRuleStep,
        DeepNullable<DeclineRuleParamsRequest>
    >({
        steps: stepIds,
        onClose,
        onSubmit: async data => {
            if (data.expression === null || data.reasonCode === null || data.editionCode === null)
                throw new Error('Expression is not set');

            const request = {
                expression: data.expression,
                lifecycleContext,
                reasonCode: data.reasonCode,
                editionCode: data.editionCode,
            };
            if (dialogData.type === UnderwritingDialogType.EditDeclineRule) {
                await updateRule(request);
            } else {
                await createRule(request);
            }

            onClose();
        },
        isNextDisabled: (_, step) => {
            if (step === ManageRuleStep.Segment) {
                return isExpressionInvalid;
            }

            return false;
        },
        clear: () => undefined,
        onNext: (step, injectedDispatch) => {
            if (step === ManageRuleStep.Segment) {
                if (renderExpressionRef.current == null) {
                    throw new Error('Expression renderer is not set');
                }

                injectedDispatch({ type: 'expression', value: renderExpressionRef.current.get() });
            }
        },
        initialData: isEditMode
            ? { ...dialogData.data, editionCode: dialogData.editionCode }
            : {
                  expression: null,
                  editionCode: dialogData.editionCode,
                  reasonCode: null,
                  lifecycleContext,
              },
    });

    const steps = useMemo(
        () => [
            {
                title: 'Segment',
                body: (
                    <SegmentFilterSelection
                        allowEmpty={false}
                        expression={state.expression}
                        lifecycleContexts={[lifecycleContext]}
                        renderExpressionRef={renderExpressionRef}
                        setExpression={(expression: string) => dispatch({ type: 'expression', value: expression })}
                        setSegmentFilterIsInvalid={setIsExpressionInvalid}
                        showSelectedSettingsAndCoverages={false}
                    />
                ),
                id: ManageRuleStep.Segment,
            },
            {
                title: 'Outcome',
                body: (
                    <SuspendableContent>
                        <ReasonSelection
                            lifecycleContext={lifecycleContext}
                            onChange={reason => {
                                dispatch({ type: 'reasonCode', value: reason as string });
                            }}
                            reasonCode={state.reasonCode}
                        />
                    </SuspendableContent>
                ),
                id: ManageRuleStep.Outcome,
            },
        ],
        [dispatch, lifecycleContext, state.expression, state.reasonCode]
    );

    const mode = isEditMode ? 'Edit' : 'Add';
    const title = `${mode} ${getOutcomeText(lifecycleContext)} Rule`;

    return (
        <StyledDialog
            actions={actions}
            className="add-rule-dialog"
            closeOnOutsideClick={false}
            loading={isLoadingCreateRule || isLoadingUpdateRule}
            minHeight={RuleDialogDimensions.minHeight}
            minWidth={RuleDialogDimensions.minWidth}
            onClose={onClose}
            size="x-large"
            title={title}
        >
            <ErrorBoundary fallback={<ErrorSection noBorders />}>
                <Suspense fallback={<LoadingSection noBorders />}>
                    <RegistryProvider>
                        <StyledMiniFlow
                            activeStepIndex={steps.findIndex(step => step.id === currentStep)}
                            steps={steps}
                        />
                    </RegistryProvider>
                </Suspense>
            </ErrorBoundary>
        </StyledDialog>
    );
};
