import { Button, Card, Flex, spacing, Tab, TabList, TabPanel, TabPanels, Tabs } from '@lemonade-hq/blender-ui';
import { EmptySection } from '@lemonade-hq/bluis';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { UnderwritingRulesDialogs } from './dialogs/UnderwritingRulesDialogs';
import type { RuleActionData } from './dialogs/UnderwritingRulesDialogs';
import { PreviewEntityAccordion } from './PreviewEntityAccordion';
import { UnderwritingDialogType } from './UnderwritingFiltersShared';
import { EditionHeader } from 'components/LoCo/common/editions/EditionHeader';
import { isEditionApproved } from 'components/LoCo/common/editions/editionHelpers';
import { EditionActionsDialogs } from 'components/LoCo/common/editions/editionSharedActions';
import type { EditionActionData } from 'components/LoCo/common/editions/editionSharedActions';
import { EditionSummarySection } from 'components/LoCo/common/editions/EditionSummarySection';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import { EditionType, QuoteLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import { useSuspenseGetUnderwritingFiltersEdition } from 'queries/LoCo/Insurance/UnderwritingFiltersEditionQueries';

export type DialogData = RuleActionData & { readonly editionCode: string; readonly existingFlagCodes?: string[] };

export const UnderwritingFiltersEdition: React.FC = () => {
    const product = useGetProductData();
    const [dialogData, setDialogData] = useState<EditionActionData | null>(null);
    const [dialog, setDialog] = useState<DialogData | null>(null);
    const { underwritingFiltersEditionCode = '' } = useParams<{
        productCode: string;
        underwritingFiltersEditionCode: string;
    }>();
    const { data: underwritingFiltersEditionData } =
        useSuspenseGetUnderwritingFiltersEdition(underwritingFiltersEditionCode);

    const readonly = isEditionApproved(underwritingFiltersEditionData);

    return (
        <>
            <EditionHeader
                edition={underwritingFiltersEditionData}
                product={product}
                setDialogData={setDialogData}
                type={EditionType.UnderwritingFilters}
            />
            <Flex flexDirection="column" gap={spacing.s24} maxWidth={1480} py={spacing.s20}>
                <EditionSummarySection
                    edition={underwritingFiltersEditionData}
                    product={product}
                    type={EditionType.UnderwritingFilters}
                />
                <Card style={{ position: 'relative' }}>
                    <Tabs variant="inline">
                        <TabList>
                            <Tab key="decline">Declines & Non-Renewals</Tab>
                            <Tab key="flags">Flags</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Flex flexDirection="column" gap={spacing.s12} margin={`${spacing.s16} 0`}>
                                    <PreviewEntityAccordion
                                        description="Rules which will cause a new business quote to be automatically declined (each for a specific decline reason). A quote may be declined for multiple reasons if multiple rules are matched - the order of the configured rules will determine which explanation is provided to the customer."
                                        editionCode={underwritingFiltersEditionCode}
                                        hideActions={readonly}
                                        lifecycle={QuoteLifecycleContext.NewBusiness}
                                        onAction={actionData => {
                                            setDialog({
                                                ...actionData,
                                                editionCode: underwritingFiltersEditionCode,
                                            });
                                        }}
                                        readonly={readonly}
                                        rulesGroup={underwritingFiltersEditionData.declineRules.newBusinessDeclineRules}
                                        title="Automatic Decline: New Business"
                                        type="decline"
                                    />
                                    <PreviewEntityAccordion
                                        description="Rules which will cause a policy to be automatically non-renewed (each for a specific non-renewal reason). A policy may be non-renewed for multiple reasons if multiple rules are matched - the order of the configured rules will determine which explanation is provided to the customer."
                                        editionCode={underwritingFiltersEditionCode}
                                        hideActions={readonly}
                                        lifecycle={QuoteLifecycleContext.Renewal}
                                        onAction={actionData => {
                                            setDialog({
                                                ...actionData,
                                                editionCode: underwritingFiltersEditionCode,
                                            });
                                        }}
                                        readonly={readonly}
                                        rulesGroup={underwritingFiltersEditionData.declineRules.renewalDeclineRules}
                                        title="Automatic Non-Renewal"
                                        type="decline"
                                    />
                                </Flex>
                            </TabPanel>
                            <TabPanel>
                                {!readonly && (
                                    <Button
                                        label="+ Add Flag"
                                        onClick={() =>
                                            setDialog({
                                                type: UnderwritingDialogType.AddFlagRule,
                                                editionCode: underwritingFiltersEditionCode,
                                                existingFlagCodes: underwritingFiltersEditionData.flagRulesGroup.map(
                                                    flag => flag.flagCode
                                                ),
                                            })
                                        }
                                        style={{ position: 'absolute', right: spacing.s24, top: spacing.s20 }}
                                        variant="secondary"
                                    />
                                )}
                                <Flex flexDirection="column" gap={spacing.s12} margin={`${spacing.s16} 0`}>
                                    {underwritingFiltersEditionData.flagRulesGroup.length === 0 ? (
                                        <EmptySection>No Rules Configured</EmptySection>
                                    ) : (
                                        underwritingFiltersEditionData.flagRulesGroup.map(rulesGroup => (
                                            <PreviewEntityAccordion
                                                description={rulesGroup.flagDescription}
                                                editionCode={underwritingFiltersEditionCode}
                                                hideActions={readonly}
                                                key={rulesGroup.flagCode}
                                                onAction={actionData => {
                                                    setDialog({
                                                        ...actionData,
                                                        editionCode: underwritingFiltersEditionCode,
                                                    });
                                                }}
                                                readonly={readonly}
                                                rulesGroup={rulesGroup}
                                                title={rulesGroup.flagName}
                                                type="flag"
                                            />
                                        ))
                                    )}
                                </Flex>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Card>
            </Flex>
            {dialog && <UnderwritingRulesDialogs dialogData={dialog} onClose={() => setDialog(null)} />}
            {dialogData != null && (
                <EditionActionsDialogs
                    dialogData={dialogData}
                    onClose={() => setDialogData(null)}
                    productCode={product.code}
                />
            )}
        </>
    );
};
