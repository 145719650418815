import { useCallback, useMemo, useState } from 'react';

interface UseResizableStoredStateProps {
  readonly initialWidth: number;
  readonly minWidth?: number;
  readonly maxWidth?: number;
  readonly storageKey?: string;
  readonly collapsedWidth?: number;
}

interface UseResizableStoredStateReturn {
  readonly width: number;
  readonly setWidth: (newWidth: number) => void;
  readonly resetWidth: () => void;
}

export const useResizableStoredState = ({
  initialWidth,
  minWidth = 0,
  maxWidth = Infinity,
  storageKey,
  collapsedWidth,
}: UseResizableStoredStateProps): UseResizableStoredStateReturn => {
  // Get stored width or use initial width
  const initialStoredWidth = useMemo(() => {
    if (storageKey != null) {
      const storedWidth = localStorage.getItem(storageKey);
      if (storedWidth != null) {
        const parsedWidth = Number(storedWidth);
        if (collapsedWidth != null && parsedWidth === collapsedWidth) {
          return collapsedWidth;
        }

        return Math.min(Math.max(parsedWidth, minWidth), maxWidth);
      }
    }

    return initialWidth;
  }, [storageKey, initialWidth, minWidth, maxWidth, collapsedWidth]);

  // Width state
  const [width, setWidth] = useState(initialStoredWidth);

  // Update width with optional storage
  const updateWidth = useCallback(
    (newWidth: number) => {
      setWidth(newWidth);

      // Optionally persist to local storage
      if (storageKey != null) {
        localStorage.setItem(storageKey, newWidth.toString());
      }
    },
    [storageKey],
  );

  // Reset to initial width
  const resetWidth = useCallback(() => {
    setWidth(initialWidth);

    if (storageKey != null) {
      localStorage.setItem(storageKey, initialWidth.toString());
    }
  }, [initialWidth, storageKey]);

  return {
    width,
    setWidth: updateWidth,
    resetWidth,
  };
};
