import type { CommonFormChangePayload } from '@lemonade-hq/blender-ui';
import { Button, Card, FormInputGroup, generateTypedFormComponents, Tooltip, useForm } from '@lemonade-hq/blender-ui';
import { toast } from '@lemonade-hq/bluis';
import type { clientToolSchema, Tool } from '@lemonade-hq/persisted-tools';
import { useGenerateToolDescription } from '../copilot.queries';
import { prettifyField } from '../shared/tool.helpers';
import * as styles from './PlaygroundDescription.css';

interface PlaygroundDescriptionProps {
    readonly toolsRevisionId?: string;
    readonly tool: Tool;
    readonly value?: string;
    readonly isReadonly?: boolean;
}

const { TextArea, Accordion, ErrorMessage } = generateTypedFormComponents<typeof clientToolSchema>();

const MIN_QUESTIONS_FOR_DESCRIPTION_GENERATION = 10;
export const PlaygroundDescription: React.FC<PlaygroundDescriptionProps> = ({
    toolsRevisionId,
    tool,
    value,
    isReadonly = false,
}) => {
    const { dispatch } = useForm<typeof clientToolSchema, CommonFormChangePayload>();

    const { mutateAsync: generateDescription, isPending: isLoadingGeneration } = useGenerateToolDescription();

    async function handleGenerateDescription(): Promise<void> {
        if (toolsRevisionId == null) return;

        try {
            const generatedDescription = await generateDescription({
                toolsRevisionPublicId: toolsRevisionId,
                toolName: tool.name,
            });

            const generatedValue =
                value != null && value !== '' ? `${value}\n###\n${generatedDescription}` : generatedDescription;

            dispatch({ type: 'setValue', key: 'description', value: generatedValue });
        } catch (error) {
            toast.error('Failed to generate description');
        }
    }

    const notEnoughQuestions =
        tool.questions == null || tool.questions.length < MIN_QUESTIONS_FOR_DESCRIPTION_GENERATION;
    const disabled = notEnoughQuestions || isLoadingGeneration;

    const tooltipContent = notEnoughQuestions
        ? `Use AI to generate a stellar description. Add at least ${MIN_QUESTIONS_FOR_DESCRIPTION_GENERATION} questions to enable.`
        : 'Use AI to generate a stellar description. For the best results, ensure the following inputs are filled out: Questions, sub-classifiers, instructions, and user groups.';

    return (
        <Accordion
            isOpenByDefault
            schemaKey="description"
            title={prettifyField('description')}
            toolbar={
                isReadonly ? undefined : (
                    <Tooltip content={tooltipContent} maxWidth="30rem" side="bottom">
                        <Button
                            className={styles.generateButton}
                            disabled={disabled}
                            label="Generate"
                            loading={isLoadingGeneration}
                            onClick={handleGenerateDescription}
                            startIcon="spark-solid"
                            variant="secondary"
                        />
                    </Tooltip>
                )
            }
        >
            <Card borderRadius="sm" padding="1.2rem" variant="primary">
                <FormInputGroup>
                    <TextArea aria-label="description" autoExpanding schemaKey="description" />
                    <ErrorMessage schemaKey="description" />
                </FormInputGroup>
            </Card>
        </Accordion>
    );
};
