import { Flex, Icon, spacing, Text, Tooltip } from '@lemonade-hq/blender-ui';
import type { CoverageInstance, SettingInstanceBase } from 'models/LoCo/Insurance/CoveragesEdition';

export const CoverageEditionName: React.FC<{
    readonly instance: CoverageInstance | SettingInstanceBase;
}> = ({ instance: { name, alerts } }) => {
    return (
        <Flex alignItems="center" gap={spacing.s04}>
            <Text>{name}</Text>
            {alerts !== undefined && alerts.length > 0 && (
                <Tooltip content={alerts.map(a => a.message).join('/n')}>
                    <Icon color="negative1" name="info-circle-solid" />
                </Tooltip>
            )}
        </Flex>
    );
};
