import type { TabButton, TabButtonsProps } from '@lemonade-hq/blender-ui';
import { Button, ComboBox, Flex, spacing, TabButtons, Text } from '@lemonade-hq/blender-ui';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import type { FC } from 'react';
import type { RequiredDeep } from 'type-fest';
import { filterCount, filtersButton, separator } from './GenericQueue.css';
import type { FilterByOption, GetGenericQueueOptionsResponse, PaginationStats } from './types';
import useToggle from 'apps/blender/src/shared/hooks/useToggle';

const getResultsRange = ({ currentPage, pageSize, totalRecords }: PaginationStats): string => {
    const start = (currentPage - 1) * pageSize + 1;
    const end = totalRecords / pageSize < currentPage ? totalRecords : currentPage * pageSize;
    return `${start}-${end}`;
};

const getOptionLabelByType = (datapoint: string, option: string): string => {
    switch (datapoint) {
        case 'status':
            return snakeCaseToReadable(option);
        default:
            return option;
    }
};

export const GenericQueueTableHeader: FC<{
    readonly tabs?: RequiredDeep<GetGenericQueueOptionsResponse['tabs']>;
    readonly onTabClick: (tabKey: string) => void;
    readonly selectedTab?: string;
    readonly title: string;
    readonly stats: PaginationStats;
    readonly filterableBy?: FilterByOption[];
    readonly filters?: Record<string, string[] | undefined>;
    readonly updateFilters: (nextFilters: Record<string, string[]>) => void;
    readonly onClearFilters: () => void;
}> = ({ tabs, onTabClick, selectedTab, title, stats, filterableBy, filters, updateFilters, onClearFilters }) => {
    const { isOpen: isFiltersOpen, toggle } = useToggle(false);
    const tabProps: TabButtonsProps = {
        buttons:
            tabs
                ?.filter(({ count }) => count > 0)
                .map<TabButton>(({ key, header, count }) => ({
                    label: header,
                    value: key,
                    onClick: () => onTabClick(key),
                    count,
                })) ?? [],
        startAtTabIndex: selectedTab == null ? 0 : tabs?.findIndex(({ key }) => key === selectedTab),
    };

    const handleFilterClick = (): void => {
        toggle();
    };

    const handleFilterChange = (datapoint: string, selectedValues: string[]): void => {
        updateFilters({ [datapoint]: selectedValues });
    };

    const activeFiltersCount = Object.entries(filters ?? {}).filter(
        ([, filterValue]) => filterValue != null && (!Array.isArray(filterValue) || filterValue.length !== 0)
    ).length;

    return (
        <Flex flexDirection="column" gap={spacing.s12} padding={`${spacing.s12} ${spacing.s16}`}>
            <Flex alignItems="center" justifyContent="space-between">
                <Flex alignItems="center" gap={spacing.s16}>
                    {tabs != null && tabs.length > 0 && (
                        <>
                            <TabButtons {...tabProps} />
                            <div className={separator} />
                        </>
                    )}
                    <Flex gap={spacing.s08}>
                        <Text as="span" fontWeight="bold" type="text-lg">
                            {title}
                        </Text>
                        {stats.totalRecords > 0 && (
                            <Text as="span" type="text-md">
                                {getResultsRange(stats)} of {stats.totalRecords} Results
                            </Text>
                        )}
                    </Flex>
                </Flex>
                {filterableBy != null && (
                    <Button
                        className={filtersButton({ active: isFiltersOpen })}
                        label={
                            <Flex gap={spacing.s04}>
                                Filters
                                {activeFiltersCount > 0 && (
                                    <Flex
                                        alignItems="center"
                                        className={filterCount({ isFiltersOpen })}
                                        justifyContent="center"
                                    >
                                        {activeFiltersCount}
                                    </Flex>
                                )}
                            </Flex>
                        }
                        onClick={handleFilterClick}
                        startIcon="filter-lines"
                        variant="secondary"
                    />
                )}
            </Flex>
            {filterableBy != null && isFiltersOpen && (
                <Flex justifyContent="space-between">
                    <Flex gap={spacing.s08}>
                        {filterableBy.map(({ datapoint, label, values }) => (
                            <ComboBox
                                cancelable
                                disabled={values.length === 0}
                                items={values.map(option => ({
                                    value: option,
                                    label: getOptionLabelByType(datapoint, option),
                                }))}
                                key={label}
                                maxAmountOfChips={2}
                                mode="multiple"
                                onSelectionChange={items =>
                                    handleFilterChange(datapoint, items?.map(({ value }) => value) ?? [])
                                }
                                placeholder={label}
                                value={filters?.[datapoint] ?? []}
                                variant="brand"
                            />
                        ))}
                    </Flex>
                    <Button label="Clear all" onClick={onClearFilters} variant="inline" />
                </Flex>
            )}
        </Flex>
    );
};
