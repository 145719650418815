import type { DialogAction, SelectOption } from '@lemonade-hq/bluis';
import { Checkbox, Dialog, FormInputWrapper, Select, YesNoSwitch } from '@lemonade-hq/bluis';
import { basicRequiredValidation, Flex, useForm } from '@lemonade-hq/cdk';
import differenceWith from 'lodash/differenceWith';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { getInsuranceScopeTypeDisplayName } from '../../../../common/display-texts/setting-instance';
import { InsurableEntityLabel, LabelWithTooltip } from '../../Settings/Dialogs/FormItems/Attributes/SharedLabels';
import { GENERAL_ERROR_MSG } from 'commons/Constants';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import { StyledFormInputWrapper } from 'components/LoCo/LoCoPagesSharedStyles';
import type {
    CoverageInstance,
    CoverageScopeType,
    InsuredEntityScope,
    PolicyScope,
} from 'models/LoCo/Insurance/CoveragesEdition';
import { InsuranceScopeType } from 'models/LoCo/Insurance/CoveragesEdition';
import { RegistryType } from 'models/LoCo/Insurance/Registry';
import { useAddCoverageInstancesToEdition } from 'queries/LoCo/Insurance/CoveragesEditionQueries';
import { useGetRegistryByProductLine } from 'queries/LoCo/Insurance/RegistryQueries';

const StyledSelect = styled(Select)`
    height: 38px;
    text-align: left;
`;

const BenefitLabel: React.FC = () => (
    <LabelWithTooltip
        label="Non-Insurance Benefit"
        tooltipContent={
            <Flex alignItems="flex-start" flexDirection="column">
                <span>An additional benefit/service provided with the product,</span>
                <span>which is not considered Insurance</span>
            </Flex>
        }
        type="info"
    />
);

interface AddCoverageDialogProps {
    readonly editionCode: string;
    readonly coverageInstances: CoverageInstance[];
    readonly onClose: () => void;
}

export const AddCoverageDialog: React.FC<AddCoverageDialogProps> = ({ editionCode, coverageInstances, onClose }) => {
    const product = useGetProductData();

    const hasInsurableEntities = product.insurableEntities.length > 0;

    const { errors, values, setValue, valid } = useForm({
        fields: {
            coverage: {
                startValue: '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            required: {
                startValue: true as boolean,
                validations: {
                    required: basicRequiredValidation,
                },
            },
            isBenefit: {
                startValue: false as boolean,
                validations: {
                    required: basicRequiredValidation,
                },
            },
            scope: {
                startValue: InsuranceScopeType.Policy,
                validations: {
                    required: basicRequiredValidation,
                },
                skipValidationsCondition: ({ required }) => required === true || !hasInsurableEntities,
            },
            insuredEntityCode: {
                startValue: '',
                validations: {
                    required: basicRequiredValidation,
                },
                skipValidationsCondition: ({ scope, required }) =>
                    required === true || scope !== InsuranceScopeType.InsuredEntity || !hasInsurableEntities,
            },
        },
    });
    const {
        mutateAsync: addCoverageInstancesToEdition,
        isPending: isLoadingMutations,
        isError: isErrorMutations,
    } = useAddCoverageInstancesToEdition(editionCode);

    const [{ data: coveragesRegistry }] = useGetRegistryByProductLine(product.productLineCode, [RegistryType.Coverage]);

    const coverageOptions: SelectOption[] = useMemo(
        () =>
            // filter out already existing coverages, in order to show only selectable coverages
            differenceWith(
                coveragesRegistry,
                coverageInstances,
                (registryCoverage, coverageInstance) => registryCoverage.code === coverageInstance.templateCode
            ).map(({ code, name }) => ({ value: code, id: code, label: name })),
        [coverageInstances, coveragesRegistry]
    );

    const submit = useCallback(async () => {
        await addCoverageInstancesToEdition({
            editionCode,
            coverages: [
                {
                    templateCode: values.coverage,
                    required: values.required,
                    isBenefit: values.isBenefit,
                    scope: {
                        type: values.scope,
                        insuredEntityCode: values.insuredEntityCode,
                    } as InsuredEntityScope | PolicyScope,
                },
            ],
        });
        onClose();
    }, [addCoverageInstancesToEdition, editionCode, values, onClose]);

    function selectCoverage(selectedOption: SelectOption): void {
        setValue('coverage', selectedOption.value);
    }

    const actions: DialogAction[] = useMemo(
        () => [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Save',
                type: 'submit',
                onClick: submit,
                disabled: isLoadingMutations || !valid,
            },
        ],
        [isLoadingMutations, onClose, submit, valid]
    );

    const scopeOptions = [InsuranceScopeType.Policy, InsuranceScopeType.InsuredEntity].map(scope => ({
        id: scope,
        value: scope,
        label: getInsuranceScopeTypeDisplayName(scope),
    }));

    const insurableEntityOptions = product.insurableEntities.map(entity => ({
        id: entity.code,
        value: entity.code,
        label: entity.name,
    }));

    const disableCoverageSelect = isLoadingMutations || coveragesRegistry.length === 0;

    const showScope = hasInsurableEntities && !values.required;

    return (
        <Dialog
            actions={actions}
            closeOnOutsideClick
            error={isErrorMutations ? GENERAL_ERROR_MSG : undefined}
            loading={isLoadingMutations}
            onClose={onClose}
            size="large"
            title="Add Coverage"
        >
            {
                <>
                    <FormInputWrapper label="Coverage" showErrors={!isEmpty(errors.coverage)}>
                        <StyledSelect
                            disabled={disableCoverageSelect}
                            onOptionSelected={selectCoverage}
                            options={coverageOptions}
                            placeholder="Select"
                            value={values.coverage}
                            width={250}
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="Required" showErrors={!isEmpty(errors.required)}>
                        <YesNoSwitch
                            name="coverageRequired"
                            onSwitch={() => setValue('required', !values.required)}
                            selected={values.required ? 0 : 1}
                        />
                    </FormInputWrapper>

                    {showScope && (
                        <>
                            <FormInputWrapper label="Scope">
                                <StyledSelect
                                    onOptionSelected={({ value }) => {
                                        setValue('scope', value as CoverageScopeType);
                                    }}
                                    options={scopeOptions}
                                    placeholder="Coverage Selection"
                                    value={values.scope}
                                    width={250}
                                />
                            </FormInputWrapper>

                            {values.scope === InsuranceScopeType.InsuredEntity && (
                                <FormInputWrapper label={<InsurableEntityLabel />}>
                                    <StyledSelect
                                        onOptionSelected={({ value }) =>
                                            setValue('insuredEntityCode', value as CoverageScopeType)
                                        }
                                        options={insurableEntityOptions}
                                        placeholder="Entity"
                                        value={values.insuredEntityCode}
                                        width={250}
                                    />
                                </FormInputWrapper>
                            )}
                        </>
                    )}

                    <StyledFormInputWrapper label={<BenefitLabel />}>
                        <Checkbox
                            checked={values.isBenefit}
                            onChange={() => setValue('isBenefit', !values.isBenefit)}
                        />
                    </StyledFormInputWrapper>
                </>
            }
        </Dialog>
    );
};
