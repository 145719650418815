import { Button, Flex, spacing, Tooltip } from '@lemonade-hq/blender-ui';
import { useMemo } from 'react';
import type { FC } from 'react';
import type { AttachmentActionType, AttachmentDTO } from '../types';
import { hasAction } from '../utils';

function getTooltipText(selectedAttachments: AttachmentDTO[], shouldDisable: boolean): string {
    if (selectedAttachments.length === 0) return 'Select at least one attachment to perform this action';
    if (shouldDisable) return 'Action is not possible for one or more of the selected attachments';

    return '';
}

export const BulkActionButtons: FC<{
    readonly setActionProps: (props: {
        readonly action: AttachmentActionType;
        readonly attachments: AttachmentDTO[];
    }) => void;
    readonly selectedRows?: Set<string>;
    readonly attachments?: AttachmentDTO[];
    readonly isArchivedTab?: boolean;
}> = ({ setActionProps, selectedRows, attachments, isArchivedTab }) => {
    const selectedAttachments = useMemo(
        () => attachments?.filter(attachment => selectedRows?.has(attachment.publicId)) ?? [],
        [attachments, selectedRows]
    );
    const disableRelabel = useMemo(
        () => selectedAttachments.some(attachment => !hasAction(attachment, 'relabel')),
        [selectedAttachments]
    );
    const disableArchive = useMemo(
        () => selectedAttachments.some(attachment => !hasAction(attachment, 'archive')),
        [selectedAttachments]
    );

    const tooltip = useMemo(
        () => getTooltipText(selectedAttachments, disableRelabel),
        [disableRelabel, selectedAttachments]
    );

    if (attachments?.length === 0) return null;

    return (
        <Flex gap={spacing.s08}>
            {!isArchivedTab && (
                <>
                    <Tooltip content={tooltip} disabled={tooltip === ''}>
                        <Button
                            disabled={selectedAttachments.length === 0 || disableRelabel}
                            label="Relabel"
                            onClick={() =>
                                setActionProps({
                                    action: 'relabel',
                                    attachments: selectedAttachments,
                                })
                            }
                            startIcon="labels"
                            variant="secondary"
                        />
                    </Tooltip>
                    <Tooltip content={tooltip} disabled={tooltip === ''}>
                        <Button
                            disabled={selectedAttachments.length === 0 || disableArchive}
                            label="Archive"
                            onClick={() =>
                                setActionProps({
                                    action: 'archive',
                                    attachments: selectedAttachments,
                                })
                            }
                            startIcon="archive"
                            variant="secondary"
                        />
                    </Tooltip>
                </>
            )}
            {isArchivedTab && (
                <Tooltip
                    content={'Select at least one attachment to unarchive'}
                    disabled={selectedAttachments.length === 0}
                >
                    <Button
                        disabled={selectedAttachments.length === 0}
                        label="Unarchive"
                        onClick={() => setActionProps({ action: 'unarchive', attachments: selectedAttachments })}
                        startIcon="unarchive"
                        variant="secondary"
                    />
                </Tooltip>
            )}
        </Flex>
    );
};
