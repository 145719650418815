import { ErrorSection, LoadingSection } from '@lemonade-hq/bluis';
import { ErrorBoundary } from '@sentry/react';
import { Suspense, useMemo, useRef, useState } from 'react';
import { ManageRuleStep, UnderwritingDialogType } from '../UnderwritingFiltersShared';
import { FlagSelection } from './FlagSelection';
import type { DialogData } from './UnderwritingRulesDialogs';
import type { DeepNullable } from 'apps/blender/src/shared/utils/types';
import type { RuleBuilderRefMethods } from 'components/LoCo/common/components/CoverageRules/ManageRuleDialogContext';
import { RegistryProvider } from 'components/LoCo/common/components/CoverageRules/ManageRuleDialogContext';
import { SegmentFilterSelection } from 'components/LoCo/common/components/CoverageRules/Steps/SegmentSelection';
import { StyledDialog } from 'components/LoCo/common/components/Dialog/Dialog';
import { useMiniFlowFormDialog } from 'components/LoCo/common/components/Dialog/useMiniFlowFormDialog';
import { StyledMiniFlow } from 'components/LoCo/common/components/MiniFlow';
import { RuleDialogDimensions } from 'components/LoCo/LoCoPagesSharedStyles';
import type { CreateFlagRuleParams } from 'queries/LoCo/Insurance/UnderwritingFiltersEditionQueries';
import {
    useCreateUnderwritingFlagsFiltersRules,
    useUpdateUnderwritingFlagsFiltersRules,
} from 'queries/LoCo/Insurance/UnderwritingFiltersEditionQueries';

const stepIds = [ManageRuleStep.Outcome, ManageRuleStep.Segment];

type FlagRuleDialogData = Extract<
    DialogData,
    { readonly type: UnderwritingDialogType.AddFlagRule | UnderwritingDialogType.EditFlagRule }
>;

export const ManageFlagRuleDialog: React.FC<{
    readonly dialogData: FlagRuleDialogData;
    readonly onClose: () => void;
}> = ({ dialogData, onClose }) => {
    const [isExpressionInvalid, setIsExpressionInvalid] = useState(
        UnderwritingDialogType.AddFlagRule === dialogData.type
    );
    const [flagCode, setFlagCode] = useState(dialogData.data?.flagCode);

    const renderExpressionRef = useRef<RuleBuilderRefMethods | null>(null);
    const { mutateAsync: createRule, isPending: isLoading } = useCreateUnderwritingFlagsFiltersRules(
        dialogData.editionCode
    );
    const { mutateAsync: updateRule } = useUpdateUnderwritingFlagsFiltersRules(dialogData.editionCode);
    const isEditMode = dialogData.type === UnderwritingDialogType.EditFlagRule;

    const { actions, currentStep, state, dispatch } = useMiniFlowFormDialog<
        ManageRuleStep,
        DeepNullable<CreateFlagRuleParams>
    >({
        steps: stepIds,
        initialStep: dialogData.data?.flagCode != null ? ManageRuleStep.Segment : ManageRuleStep.Outcome,
        onClose,
        onSubmit: async data => {
            if (renderExpressionRef.current == null) {
                throw new Error('Expression renderer is not set');
            }

            const expression = renderExpressionRef.current.get();
            if (expression === '' || data.flagCode === null || data.editionCode === null)
                throw new Error('Expression is not set');
            if (dialogData.type === UnderwritingDialogType.AddFlagRule) {
                await createRule({
                    expression,
                    flagCode: data.flagCode,
                    editionCode: data.editionCode,
                    variants: dialogData.data?.variantName != null ? [dialogData.data.variantName] : undefined,
                });
            } else {
                await updateRule({
                    expression,
                    flagCode: data.flagCode,
                    editionCode: data.editionCode,
                    ruleId: dialogData.data.publicId,
                    variants: dialogData.data.variantName != null ? [dialogData.data.variantName] : undefined,
                });
            }

            onClose();
        },
        isNextDisabled: (_, step) => {
            if (step === ManageRuleStep.Outcome) {
                return flagCode == null;
            }

            return isExpressionInvalid;
        },
        clear: () => undefined,
        onNext: (step, injectedDispatch) => {
            if (step === ManageRuleStep.Segment) {
                if (renderExpressionRef.current == null) {
                    throw new Error('Expression renderer is not set');
                }

                injectedDispatch({ type: 'expression', value: renderExpressionRef.current.get() });
            }
        },
        initialData: isEditMode
            ? { ...dialogData.data, editionCode: dialogData.editionCode }
            : {
                  expression: null,
                  editionCode: dialogData.editionCode,
                  flagCode: dialogData.data?.flagCode ?? null,
              },
    });

    const mode = isEditMode ? 'Edit' : 'Add';
    const title = `${mode}  Flag Rule`;

    const steps = useMemo(
        () => [
            {
                title: 'Outcome',
                body: (
                    <FlagSelection
                        disabledFlags={dialogData.existingFlagCodes}
                        flagCode={state.flagCode}
                        onChange={flag => {
                            dispatch({ type: 'flagCode', value: flag as string });
                            setFlagCode(flag as string);
                        }}
                    />
                ),
                id: ManageRuleStep.Outcome,
            },
            {
                title: 'Segment',
                body: (
                    <SegmentFilterSelection
                        allowEmpty={false}
                        expression={state.expression}
                        renderExpressionRef={renderExpressionRef}
                        setExpression={(expression: string) => dispatch({ type: 'expression', value: expression })}
                        setSegmentFilterIsInvalid={setIsExpressionInvalid}
                    />
                ),
                id: ManageRuleStep.Segment,
            },
        ],
        [dialogData, dispatch, state.expression, state.flagCode]
    );

    return (
        <StyledDialog
            actions={actions}
            closeOnOutsideClick={false}
            loading={isLoading}
            minHeight={RuleDialogDimensions.minHeight}
            minWidth={RuleDialogDimensions.minWidth}
            onClose={onClose}
            size="x-large"
            title={title}
        >
            <ErrorBoundary fallback={<ErrorSection noBorders />}>
                <Suspense fallback={<LoadingSection noBorders />}>
                    <RegistryProvider>
                        <StyledMiniFlow
                            activeStepIndex={steps.findIndex(step => step.id === currentStep)}
                            steps={steps}
                        />
                    </RegistryProvider>
                </Suspense>
            </ErrorBoundary>
        </StyledDialog>
    );
};
