import {
    borderRadius,
    Button,
    darkThemeClass,
    ellipsis,
    Flex,
    Icon,
    IconButton,
    Layout,
    spacing,
    Text,
} from '@lemonade-hq/blender-ui';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { trackEvent } from '@lemonade-hq/boutique';
import * as HoverCard from '@radix-ui/react-hover-card';
import * as Popover from '@radix-ui/react-popover';
import { clsx } from 'clsx';
import type { FC, PropsWithChildren } from 'react';
import { useState } from 'react';
import { DetectionBanner } from '../AttachmentGallery/Carousel/Detection';
import { imageActionBg } from '../AttachmentGallery/MediaComponents/Media.css';
import {
    attachmentPreviewWrapper,
    popoverActionContent,
    previewBottomButtons,
    previewContent,
    previewDetectionBanner,
    previewMedia,
} from './Preview.css';
import { PreviewActionContent } from './PreviewActionsContent';
import { OpenInNewTab } from 'components/Attachments/AttachmentGallery/Carousel/OpenInNewTab';
import { MediaPreview } from 'components/Attachments/AttachmentGallery/MediaComponents/GalleryMedia';
import type { AttachmentActionType, AttachmentDTO } from 'components/Attachments/types';
import { attachmentHasDetections, getActionLabel, getAttachmentAnalyticsParam } from 'components/Attachments/utils';

interface PreviewProps {
    readonly attachment: AttachmentDTO;
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
    readonly openGallery?: (attachment: AttachmentDTO) => void;
}

const PreviewActions: FC<PreviewProps> = ({ attachment, entityPublicId, entityType }) => {
    const { actions } = attachment;
    const [currentAction, setCurrentAction] = useState<AttachmentActionType | undefined>();

    if (actions == null || actions.length === 0) return null;

    const filteredActions = actions.filter(action => action !== 'check_for_modifications' && action !== 'unarchive');

    return (
        <Flex gap={spacing.s08} position="relative" zIndex={3}>
            {filteredActions.map(action => (
                <Popover.Root key={action}>
                    <Popover.Trigger asChild>
                        <Button
                            label={getActionLabel(action)}
                            onClick={() => setCurrentAction(action)}
                            variant="secondary"
                        />
                    </Popover.Trigger>
                    <Popover.Content className={popoverActionContent} sideOffset={8}>
                        <Flex justifyContent="flex-end">
                            <Popover.Close asChild>
                                <Icon color="neutral7" name="x" size="sm" />
                            </Popover.Close>
                        </Flex>
                        <PreviewActionContent
                            action={currentAction}
                            attachment={attachment}
                            entityPublicId={entityPublicId}
                            entityType={entityType}
                        />
                    </Popover.Content>
                </Popover.Root>
            ))}
        </Flex>
    );
};

const PreviewData: FC<PreviewProps> = ({ attachment, entityPublicId, entityType }) => {
    return (
        <Flex gap={spacing.s08} justifyContent="space-between">
            <Flex alignItems="center" gap={spacing.s08}>
                <Text className={ellipsis} maxWidth="200px" pl={spacing.s04} type="text-md">
                    {attachment.fileName}
                </Text>
            </Flex>
            <PreviewActions attachment={attachment} entityPublicId={entityPublicId} entityType={entityType} />
        </Flex>
    );
};

const HoverCardPortal: FC<PreviewProps> = ({ attachment, entityPublicId, entityType, openGallery }) => {
    const showBanner = attachmentHasDetections(attachment);

    return (
        <HoverCard.Portal>
            <HoverCard.Content align="start" className={previewContent} side="right" sideOffset={8}>
                <Flex
                    className={clsx(darkThemeClass, attachmentPreviewWrapper, borderRadius({ borderRadius: 'sm' }))}
                    flexDirection="column"
                    gap={spacing.s08}
                >
                    <PreviewData attachment={attachment} entityPublicId={entityPublicId} entityType={entityType} />
                    <Layout height="100%" position="relative" width="100%">
                        <MediaPreview
                            attachment={attachment}
                            className={previewMedia}
                            entityPublicId={entityPublicId}
                            entityType={entityType}
                        />
                        <Flex className={previewBottomButtons} gap={spacing.s08} justifyContent="flex-end">
                            <Layout className={imageActionBg}>
                                <OpenInNewTab url={attachment.url} />
                            </Layout>
                            {openGallery && (
                                <IconButton
                                    className={imageActionBg}
                                    color="neutral7"
                                    icon="full-screen"
                                    iconSize="xl"
                                    onClick={() => openGallery(attachment)}
                                    size="lg"
                                    title="Open gallery"
                                    variant="inline"
                                />
                            )}
                        </Flex>
                    </Layout>
                    {showBanner && (
                        <DetectionBanner
                            attachment={attachment}
                            className={previewDetectionBanner}
                            hideContent
                            isScanning={false}
                            withBorder={false}
                        />
                    )}
                </Flex>
            </HoverCard.Content>
        </HoverCard.Portal>
    );
};

export const AttachmentPreview: FC<PropsWithChildren<PreviewProps>> = ({
    children,
    attachment,
    entityPublicId,
    entityType,
    openGallery,
}) => {
    const onOpen = (open: boolean): void => {
        if (open) {
            trackEvent('docs.gallery.hover', {
                ...getAttachmentAnalyticsParam({
                    attachment,
                    entityType,
                    entityId: entityPublicId,
                }),
                name: 'preview',
            });
        }
    };

    return (
        <HoverCard.Root onOpenChange={onOpen} openDelay={500}>
            <HoverCard.Trigger asChild>{children}</HoverCard.Trigger>
            <HoverCardPortal
                attachment={attachment}
                entityPublicId={entityPublicId}
                entityType={entityType}
                openGallery={openGallery}
            />
        </HoverCard.Root>
    );
};
