import { Flex, spacing, Text } from '@lemonade-hq/blender-ui';
import { Image } from '@lemonade-hq/bluis';
import { noResults } from '../Attachments.css';

export const NoResults: React.FC<React.PropsWithChildren<unknown>> = () => (
    <Flex alignItems="center" flexDirection="column" gap={spacing.s12} justifyContent="center">
        <Flex alignItems="center" className={noResults} justifyContent="center">
            <Image
                alt="no results found"
                img={{
                    lightSrc: '/blender_assets/backoffice/no_results_light.svg',
                    darkSrc: '/blender_assets/backoffice/no_results_dark.svg',
                }}
            />
        </Flex>
        <Text as="p" fontWeight="bold" type="text-lg">
            No results found
        </Text>
    </Flex>
);
