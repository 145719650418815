import { getIsDevKubernetes } from './kubernetes';

export enum ServiceName {
  Blender = 'blender',
  BlenderGeneral = 'blender-general',
  CarBlender = 'car-blender',
  CryptoBlender = 'crypto-blender',
  HomeBlender = 'home-blender',
  HomeEuBlender = 'home-eu-blender',
  LifeBlender = 'life-blender',
  Monolith = 'monolith',
  PetBlender = 'pet-blender',
  Llmnd = 'llmnd',
  Files = 'files',
  Cxllm = 'cxllm',
  RatingBlender = 'rating-blender',
  InsuranceBlender = 'insurance-blender',
}

export enum EnvName {
  Development = 'development',
  Production = 'production',
  Staging = 'staging',
  Sandbox = 'sandbox',
}

export const serviceUrls = (envName: EnvName): Record<ServiceName, string> => {
  switch (envName) {
    case EnvName.Development:
      return {
        [ServiceName.Blender]: getIsDevKubernetes()
          ? 'https://blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmnddev.com'
          : 'http://lmnd-dev.com',
        [ServiceName.BlenderGeneral]: getIsDevKubernetes()
          ? 'https://blender-general-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmnddev.com'
          : 'http://lmnd-dev.com:3110',
        [ServiceName.CarBlender]: getIsDevKubernetes()
          ? 'https://car-blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmnddev.com'
          : 'http://lmnd-dev.com:3150',
        [ServiceName.CryptoBlender]: getIsDevKubernetes()
          ? 'https://crypto-blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmnddev.com'
          : 'http://lmnd-dev.com:3170',
        [ServiceName.Cxllm]: getIsDevKubernetes()
          ? 'https://cxllm-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmnddev.com'
          : 'http://lmnd-dev.com:9900',
        [ServiceName.Files]: getIsDevKubernetes()
          ? 'https://files-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmnddev.com'
          : 'http://lmnd-dev.com:3400',
        [ServiceName.HomeBlender]: getIsDevKubernetes()
          ? 'https://home-blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmnddev.com'
          : 'http://lmnd-dev.com:3120',
        [ServiceName.HomeEuBlender]: getIsDevKubernetes()
          ? 'https://home-eu-blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmnddev.com'
          : 'http://lmnd-dev.com:3160',
        [ServiceName.LifeBlender]: getIsDevKubernetes()
          ? 'https://life-blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmnddev.com'
          : 'http://lmnd-dev.com:3140',
        [ServiceName.Llmnd]: getIsDevKubernetes()
          ? 'https://llmnd-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmnddev.com'
          : 'http://lmnd-dev.com:9400',
        [ServiceName.Monolith]: getIsDevKubernetes()
          ? 'https://monolith-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmnddev.com'
          : 'https://lmnd-dev.com:3000',
        [ServiceName.PetBlender]: getIsDevKubernetes()
          ? 'https://pet-blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmnddev.com'
          : 'http://lmnd-dev.com:3130',
        [ServiceName.RatingBlender]: getIsDevKubernetes()
          ? 'https://rating-blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmnddev.com'
          : 'http://lmnd-dev.com:3170',
        [ServiceName.InsuranceBlender]: getIsDevKubernetes()
          ? 'https://insurance-blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmnddev.com'
          : 'http://lmnd-dev.com:3190',
      };
    case EnvName.Staging:
      return {
        [ServiceName.Blender]: 'https://blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmndstaging.com',
        [ServiceName.BlenderGeneral]: 'https://blender-general-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmndstaging.com',
        [ServiceName.CarBlender]: 'https://car-blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmndstaging.com',
        [ServiceName.CryptoBlender]: 'https://crypto-blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmndstaging.com',
        [ServiceName.Cxllm]: 'https://cxllm-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmndstaging.com',
        [ServiceName.Files]: 'https://files-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmndstaging.com',
        [ServiceName.HomeBlender]: 'https://home-blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmndstaging.com',
        [ServiceName.HomeEuBlender]: 'https://home-eu-blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmndstaging.com',
        [ServiceName.LifeBlender]: 'https://life-blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmndstaging.com',
        [ServiceName.Llmnd]: 'https://llmnd-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmndstaging.com',
        [ServiceName.Monolith]: 'https://monolith-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmndstaging.com',
        [ServiceName.PetBlender]: 'https://pet-blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmndstaging.com',
        [ServiceName.RatingBlender]: 'https://rating-blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmndstaging.com',
        [ServiceName.InsuranceBlender]: 'https://insurance-blender-{{ENV_ID}}{{BLENDER_DOMAIN}}.lmndstaging.com',
      };
    case EnvName.Sandbox:
      return {
        [ServiceName.Blender]: 'https://blender-sandbox{{BLENDER_DOMAIN}}.lmndsandbox.com',
        [ServiceName.BlenderGeneral]: 'https://blender-general-sandbox{{BLENDER_DOMAIN}}.lmndsandbox.com',
        [ServiceName.CarBlender]: 'https://car-blender-sandbox{{BLENDER_DOMAIN}}.lmndsandbox.com',
        [ServiceName.CryptoBlender]: 'https://crypto-blender-sandbox{{BLENDER_DOMAIN}}.lmndsandbox.com',
        [ServiceName.Cxllm]: 'https://cxllm-sandbox{{BLENDER_DOMAIN}}.lmndsandbox.com',
        [ServiceName.Files]: 'https://files-sandbox{{BLENDER_DOMAIN}}.lmndsandbox.com',
        [ServiceName.HomeBlender]: 'https://home-blender-sandbox{{BLENDER_DOMAIN}}.lmndsandbox.com',
        [ServiceName.HomeEuBlender]: 'https://home-eu-blender-sandbox{{BLENDER_DOMAIN}}.lmndsandbox.com',
        [ServiceName.LifeBlender]: 'https://life-blender-sandbox{{BLENDER_DOMAIN}}.lmndsandbox.com',
        [ServiceName.Llmnd]: 'https://llmnd-sandbox{{BLENDER_DOMAIN}}.lmndsandbox.com',
        [ServiceName.Monolith]: 'https://monolith-sandbox{{BLENDER_DOMAIN}}.lmndsandbox.com',
        [ServiceName.PetBlender]: 'https://pet-blender-sandbox{{BLENDER_DOMAIN}}.lmndsandbox.com',
        [ServiceName.RatingBlender]: 'https://rating-blender-sandbox{{BLENDER_DOMAIN}}.lmndsandbox.com',
        [ServiceName.InsuranceBlender]: 'https://insurance-blender-sandbox{{BLENDER_DOMAIN}}.lmndsandbox.com',
      };
    case EnvName.Production: {
      return {
        [ServiceName.Blender]: 'https://blender{{BLENDER_DOMAIN}}.lemonade.com',
        [ServiceName.BlenderGeneral]: 'https://blender-general{{BLENDER_DOMAIN}}.lemonade.com',
        [ServiceName.CarBlender]: 'https://car-blender{{BLENDER_DOMAIN}}.lemonade.com',
        [ServiceName.CryptoBlender]: 'https://crypto-blender{{BLENDER_DOMAIN}}.lemonade.com',
        [ServiceName.Cxllm]: 'https://cxllm-production.lmndprod.com',
        [ServiceName.Files]: 'https://icebox.lemonade.com',
        [ServiceName.HomeBlender]: 'https://home-blender{{BLENDER_DOMAIN}}.lemonade.com',
        [ServiceName.HomeEuBlender]: 'https://home-eu-blender{{BLENDER_DOMAIN}}.lemonade.com',
        [ServiceName.LifeBlender]: 'https://life-blender{{BLENDER_DOMAIN}}.lemonade.com',
        [ServiceName.Llmnd]: 'https://llmnd-production.lmndprod.com',
        [ServiceName.Monolith]: 'https://lemonade.com',
        [ServiceName.PetBlender]: 'https://pet-blender{{BLENDER_DOMAIN}}.lemonade.com',
        [ServiceName.RatingBlender]: 'https://rating-blender{{BLENDER_DOMAIN}}.lemonade.com',
        [ServiceName.InsuranceBlender]: 'https://insurance-blender{{BLENDER_DOMAIN}}.lemonade.com',
      };
    }

    default:
      throw new Error('Invalid EnvName');
  }
};

export function getEnv(): EnvName {
  try {
    return (process.env.ENV_NAME as EnvName | undefined) ?? EnvName.Development;
  } catch {
    return EnvName.Development;
  }
}

// eslint-disable-next-line @lemonade-hq/base/favor-singular-enums
export enum ParamKeys {
  BaseMonolithUrl = 'BASE_MONOLITH_URL',
  BaseBlenderUrl = 'BASE_BLENDER_URL',
  FilesServiceUrl = 'FILES_SERVICE_URL',
  BlenderGeneralUrl = 'BLENDER_GENERAL_URL',
  HomeBlenderUrl = 'HOME_BLENDER_URL',
  PetBlenderUrl = 'PET_BLENDER_URL',
  LifeBlenderUrl = 'LIFE_BLENDER_URL',
  CarBlenderUrl = 'CAR_BLENDER_URL',
  HomeEuBlenderUrl = 'HOME_EU_BLENDER_URL',
  CryptoBlenderUrl = 'CRYPTO_BLENDER_URL',
  InsuranceBlenderUrl = 'INSURANCE_BLENDER_URL',
}
