import React, { useCallback } from 'react';
import { ManageABTestDialog } from '../../common/components/ABTestDialogs/ManageABTestDialog';
import { RemoveABTestDialog } from '../../common/components/ABTestDialogs/RemoveABTestDialog';
import { RenameVariantsDialog } from '../../common/components/ABTestDialogs/RenameVariantsDialog';
import type { RenameVariantsValues } from '../../common/components/ABTestDialogs/utils';
import { DialogType } from '../../common/components/ABTestDialogs/utils';
import type { CoveragesTemplateType } from 'models/LoCo/Insurance/CoverageRule';
import type { CoverageRuleGroupType, CoverageRulesGroup } from 'models/LoCo/Insurance/DigitalAgentEdition';
import { isCoverageRuleGroupABTest } from 'models/LoCo/Insurance/DigitalAgentEdition';
import {
    useAddVariants,
    useAttachABTest,
    useRemoveVariants,
    useRenameVariants,
    useUnbindABTest,
} from 'queries/LoCo/Insurance/DigitalAgentEditionQueries';

interface ABTestDialogsProps {
    readonly dialogType: DialogType;
    readonly editionCode: string;
    readonly rulesGroup: CoverageRulesGroup;
    readonly groupedRulesType: CoverageRuleGroupType;
    readonly type: CoveragesTemplateType;
    readonly entityCode: string;
    readonly onClose: () => void;
}

export const ABTestDialogs: React.FC<ABTestDialogsProps> = ({
    dialogType,
    editionCode,
    rulesGroup,
    groupedRulesType,
    type,
    entityCode,
    onClose,
}) => {
    const { mutateAsync: attachABTest, isPending: isAttachPending } = useAttachABTest(editionCode);
    const { mutateAsync: addVariants, isPending: isAddPending } = useAddVariants(editionCode);
    const { mutateAsync: removeVariants, isPending: isRemovePending } = useRemoveVariants(editionCode);
    const { mutateAsync: renameVariants, isPending: isRenamePending } = useRenameVariants(editionCode);
    const { mutateAsync: unbindABTest, isPending: isUnbindPending } = useUnbindABTest(editionCode);

    const isPending = isAttachPending || isAddPending || isRemovePending || isRenamePending || isUnbindPending;

    const submitABTest = useCallback(
        async (values: Record<string, string[] | string>) => {
            const payload = {
                entityType: type,
                entityCode,
                groupType: groupedRulesType,
            };

            let variantsToAdd: string[] = [],
                variantsToRemove: string[] = [];
            if (isCoverageRuleGroupABTest(rulesGroup)) {
                variantsToAdd = (values.variants as string[]).filter(
                    variant => !rulesGroup.variants.some(v => v.variantName === variant)
                );
                variantsToRemove = rulesGroup.variants
                    .filter(v => !values.variants.includes(v.variantName))
                    .map(v => v.variantName);
            }

            switch (dialogType) {
                case DialogType.AddABTest:
                    await attachABTest({
                        digitalAgentEditionCode: editionCode,
                        payload: {
                            experimentName: values.experimentName as string,
                            control: values.variants[0],
                            variants: (values.variants as string[]).slice(1),
                            ...payload,
                        },
                    });
                    break;
                case DialogType.AddTestGroups:
                    await addVariants({
                        digitalAgentEditionCode: editionCode,
                        payload: {
                            variants: variantsToAdd,
                            ...payload,
                        },
                    });
                    break;
                case DialogType.RemoveTestGroups:
                    await removeVariants({
                        digitalAgentEditionCode: editionCode,
                        payload: {
                            variants: variantsToRemove,
                            ...payload,
                        },
                    });
                    break;
                default:
            }
        },
        [
            addVariants,
            attachABTest,
            dialogType,
            editionCode,
            entityCode,
            groupedRulesType,
            removeVariants,
            rulesGroup,
            type,
        ]
    );

    const submitRenameVariants = useCallback(
        async (values: RenameVariantsValues) => {
            if (isCoverageRuleGroupABTest(rulesGroup)) {
                const payload = {
                    entityType: type,
                    entityCode,
                    groupType: groupedRulesType,
                    experimentName: values.experimentName,
                    variants: values.variants,
                };

                await renameVariants({
                    digitalAgentEditionCode: editionCode,
                    payload,
                });
            }
        },
        [editionCode, entityCode, groupedRulesType, renameVariants, rulesGroup, type]
    );

    const removeABTest = useCallback(
        async ({ variantToKeep }: { variantToKeep: string }) => {
            await unbindABTest({
                digitalAgentEditionCode: editionCode,
                payload: { variantToKeep, entityType: type, entityCode: entityCode, groupType: groupedRulesType },
            });
        },
        [editionCode, entityCode, groupedRulesType, type, unbindABTest]
    );

    switch (dialogType) {
        case DialogType.RenameVariants:
            return (
                <RenameVariantsDialog
                    isLoading={isPending}
                    item={isCoverageRuleGroupABTest(rulesGroup) ? rulesGroup : undefined}
                    onClose={onClose}
                    onSubmit={submitRenameVariants}
                />
            );
        case DialogType.RemoveABTest:
            return (
                <RemoveABTestDialog
                    isLoading={isPending}
                    item={isCoverageRuleGroupABTest(rulesGroup) ? rulesGroup : undefined}
                    onClose={onClose}
                    onSubmit={removeABTest}
                />
            );
        default:
            return (
                <ManageABTestDialog
                    isLoading={isPending}
                    item={isCoverageRuleGroupABTest(rulesGroup) ? rulesGroup : undefined}
                    onClose={onClose}
                    onSubmit={submitABTest}
                    type={dialogType}
                />
            );
    }
};
