import type { Edition, QuoteLifecycleContext } from './BaseEdition';

export type UnderwritingDecisionLifecycleContext = QuoteLifecycleContext.NewBusiness | QuoteLifecycleContext.Renewal;

export type UnderwritingRuleBase = { readonly publicId: string; readonly expression: string };

export type UnderwritingFlagRule = UnderwritingRuleBase & {
    readonly flagCode: string;
};

export type UnderwritingDeclineRule = UnderwritingRuleBase & {
    readonly reasonCode: string;
    readonly lifecycleContext: UnderwritingDecisionLifecycleContext;
    readonly reasonDisplay: string;
};

export enum VariantType {
    Control = 'control',
    Test = 'test',
}

export interface VariantRuleSet {
    readonly variantName: string;
    readonly rules: UnderwritingFlagRule[];
    readonly variantType: VariantType;
}

export enum Mode {
    ABTest = 'ab_test',
    Plain = 'plain',
}

export interface RuleGroupBase {
    readonly flagCode: string;
    readonly flagName: string;
    readonly flagDescription: string;
    readonly mode: Mode;
}

export interface ABTestRuleGroup extends RuleGroupBase {
    readonly mode: Mode.ABTest;
    readonly variants: VariantRuleSet[];
    readonly experimentName: string;
}

interface PlainRuleGroup extends RuleGroupBase {
    readonly mode: Mode.Plain;
    readonly rules: UnderwritingFlagRule[];
}

export const isABTestRuleGroup = (group: RuleGroupBase): group is ABTestRuleGroup => group.mode === Mode.ABTest;

export type UnderwritingFlagRulesGroup = ABTestRuleGroup | PlainRuleGroup;

export interface LifecycleContextDeclineRules {
    readonly newBusinessDeclineRules: UnderwritingDeclineRule[];
    readonly renewalDeclineRules: UnderwritingDeclineRule[];
}

export interface UnderwritingFiltersEdition extends Edition {
    readonly declineRules: LifecycleContextDeclineRules;
    readonly flagRulesGroup: UnderwritingFlagRulesGroup[];
}

export interface UnderwritingEditionViolations {
    readonly hasViolation: boolean;
    readonly messages: string[];
}

export type UnderwritingFiltersEditionViolationsPayload = {
    readonly explanationName: string;
    readonly locales: string[];
};
