import type { UseQueryResult } from '@tanstack/react-query';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { blenderGeneral } from '../../apiClients';
import type {
    GetGenericQueueAssigneesResponse,
    GetGenericQueueOptionsParams,
    GetGenericQueueOptionsResponse,
    GetGenericQueueParams,
    GetGenericQueueResponse,
} from './types';

const getMainQueryParamsString = (params: GetGenericQueueOptionsParams | GetGenericQueueParams): string => {
    const filterParams =
        params.filterBy == null || params.filterBy.length === 0 ? '' : `&filterBy=${JSON.stringify(params.filterBy)}`;
    const selectedTabParam = params.selectedTab == null ? '' : `&selectedTab=${params.selectedTab}`;

    return `?${filterParams}${selectedTabParam}`;
};

const getQueryParamsString = (params: GetGenericQueueParams): string => {
    const paginationParams = `&size=${params.size ?? 12}&page=${params.page}`;
    const sortParams = params.sortBy == null ? '' : `&sortBy=${JSON.stringify(params.sortBy)}`;

    return `${getMainQueryParamsString(params)}${paginationParams}${sortParams}`;
};

enum GenericQueueKey {
    GetGenericQueue = 'GET_GENERIC_QUEUE',
    GetGenericQueueOptions = 'GET_GENERIC_QUEUE_OPTIONS',
    GetGenericQueueAssignees = 'GET_GENERIC_QUEUE_ASSIGNEES',
}

export const useGetGenericQueue = (
    queueName: string,
    params: GetGenericQueueParams,
    enabled: boolean
): UseQueryResult<GetGenericQueueResponse> => {
    const queryParamsString = getQueryParamsString(params);

    return useQuery({
        queryKey: [GenericQueueKey.GetGenericQueue, queueName, queryParamsString],
        queryFn: async () =>
            await blenderGeneral.get(`/api/v1/generic_queues/${queueName}${queryParamsString}`).then(res => res.data),
        placeholderData: keepPreviousData,
        enabled,
    });
};

export const useGetGenericQueueOptions = (
    queueName: string,
    params: GetGenericQueueOptionsParams,
    enabled: boolean
): UseQueryResult<GetGenericQueueOptionsResponse> => {
    const queryParamsString = getMainQueryParamsString(params);

    return useQuery({
        queryKey: [GenericQueueKey.GetGenericQueueOptions, queueName, queryParamsString],
        queryFn: async () =>
            await blenderGeneral
                .get(`/api/v1/generic_queues/options/${queueName}${queryParamsString}`)
                .then(res => res.data.data),
        enabled,
    });
};

export const useGetGenericQueueAssignees = (queueName: string): UseQueryResult<GetGenericQueueAssigneesResponse> => {
    return useQuery({
        queryKey: [GenericQueueKey.GetGenericQueueAssignees, queueName],
        queryFn: async () =>
            await blenderGeneral.get(`/api/v1/generic_queues/assignees/${queueName}`).then(res => res.data.data),
        placeholderData: keepPreviousData,
    });
};
