import { ChangeAccordion } from '../ChangeAccordion';
import { findFlag } from './common';
import { isABTestRuleGroup } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import type { UnderwritingFiltersEdition } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

interface FlagAddedProps {
    readonly flagId: string;
    readonly underwritingFiltersEdition: UnderwritingFiltersEdition;
}

export const FlagAdded: React.FC<FlagAddedProps> = ({ flagId, underwritingFiltersEdition }) => {
    const flag = findFlag(underwritingFiltersEdition, flagId);
    const updatedRules = isABTestRuleGroup(flag) ? flag.variants.flatMap(variant => variant.rules) : flag.rules;

    return (
        <ChangeAccordion
            generalTabData={null}
            originalRules={null}
            ruleType="flag"
            title={`Flag added - ${flag.flagName}`}
            updatedRules={updatedRules}
        />
    );
};
