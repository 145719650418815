import { BaseHttpCode } from '@lemonade-hq/ts-helpers';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { getIsDevKubernetes } from '../../config';
import type { ServiceName } from '../../config/envs';
import { EnvName, getEnv, serviceUrls } from '../../config/envs';
import { getBlenderDomain, getEnvId } from '../getEnvParameters';

const URL_SERVICE_ENV_ID_REGEX = /^https:\/\/(.*)-t[[0-9]+/;
const ENV_ID_REGEX = /t[[0-9]+/g;

export type Path<T extends string = string> = '' | `?${T}` | `/${T}`;

interface UrlConfig {
  // eslint-disable-next-line functional/prefer-readonly-type
  url: string;
}

interface UrlError {
  readonly config: UrlConfig;
}

type FallbackError<T> = T extends AxiosError ? AxiosError : UrlError;
type FallbackReturnType = AxiosRequestConfig | UrlConfig;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function instanceOfAxiosError(error: any): error is AxiosError {
  return axios.isAxiosError(error);
}

export function isMasterEnv(): boolean {
  return getEnvId() === 'master';
}

export function shouldFallbackToMaster<T>(error: FallbackError<T>, envName: EnvName): boolean {
  if (envName !== 'staging') return false;
  if (
    instanceOfAxiosError(error) &&
    typeof error.response !== 'undefined' &&
    error.response.status < BaseHttpCode.ServerError
  )
    return false;

  return error.config.url != null && /t[[0-9]+/.test(error.config.url);
}

export function fallback<T>(
  error: FallbackError<T>,
  fallbackMap: Set<string>,
): { readonly config: FallbackReturnType; readonly fallbackMap: Set<string> } {
  const { config } = error;

  if (config.url == null || !URL_SERVICE_ENV_ID_REGEX.test(config.url)) {
    throw new Error('failed falling back to master');
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [, service] = URL_SERVICE_ENV_ID_REGEX.exec(config.url)!;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  fallbackMap.add(service!);

  config.url = changeEnvToMaster(config.url);

  // eslint-disable-next-line no-console
  console.log(`****** using ${service}'s master ******`);

  return { config, fallbackMap };
}

export function axiosFallback(error: AxiosError): {
  readonly config: Promise<AxiosResponse<unknown>>;
  readonly fallbackMap: Set<string>;
} {
  const { config, fallbackMap } = fallback<AxiosError>(error, new Set<string>());

  return { config: axios.request(config), fallbackMap };
}

export function changeEnvToMaster(url: string): string {
  return url.replace(ENV_ID_REGEX, 'master');
}

export interface GetUrlResolverParams {
  readonly envName?: EnvName;
  readonly service: ServiceName;
  readonly fallbackMap: Set<string>;
  readonly basePath?: Path | '';
}

export function getUrlResolver({
  envName = getEnv(),
  service,
  fallbackMap,
  basePath = '',
}: GetUrlResolverParams): (path: Path) => string {
  let baseUrl: string | undefined = serviceUrls(envName)[service];

  baseUrl = `${baseUrl}${basePath}`;

  return function getEnvUrl(path: Path = '') {
    const url = `${baseUrl}${path}`.replace('{{BLENDER_DOMAIN}}', getBlenderDomain(envName));

    if (envName !== EnvName.Staging && !getIsDevKubernetes()) return url;

    const currentEnv = getEnvId(envName);

    if (currentEnv == null) {
      // eslint-disable-next-line no-console
      console.error('Failed to resolve current env');
      return url;
    }

    const resolvedUrl = url.replace('{{ENV_ID}}', currentEnv);

    if (!URL_SERVICE_ENV_ID_REGEX.test(resolvedUrl)) return resolvedUrl;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [, fallbackService] = URL_SERVICE_ENV_ID_REGEX.exec(resolvedUrl)!;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (fallbackMap.has(fallbackService!)) {
      // eslint-disable-next-line no-console
      console.log(`****** using ${service}'s master ******`);

      return changeEnvToMaster(resolvedUrl);
    }

    return resolvedUrl;
  };
}
