import { DamagePoint } from '@lemonade-hq/bluis';
import type { UsState } from '@lemonade-hq/lemonation';
import type { SnakeCaseToCamelCase } from '@lemonade-hq/ts-helpers';
import type { CarDiscoveredVehicle } from './CarDiscoveredVehicle';
import type { PricingItemCode } from './CarQuote';
import type { CarVendorDataVehicleHistory } from './CarVehicleHistory';
import type { TypeWithExcluded } from './Driver';
import type { InvolvedVehicle } from './InvolvedParty';
import type { AttachmentToAdd } from 'components/Bluis/ClaimAttachments/AddAttachments';
import type { Address } from 'models/Address';
import type { Attachment } from 'models/Attachment';
import type { CarClaimLossTypes, CarClaimStatus, ReportedByType } from 'models/CarClaim';

export interface CarAttachmentToAdd extends AttachmentToAdd {
    readonly involvedPartyId?: string;
}

export interface QuoteAddress {
    readonly addressExternalId: string;
    readonly street: string;
    readonly streetNumber?: string;
    readonly city: string;
    readonly unit?: string;
    readonly state: UsState;
    readonly country: string;
    readonly county?: string;
    readonly postalCode: string;
    readonly timezone: string;
}

export interface ShippingAddress {
    readonly streetName: string;
    readonly streetNumber?: string;
    readonly city: string;
    readonly unit?: string;
    readonly state: string;
    readonly country: string;
    readonly county?: string;
    readonly postalCode: string;
}

export enum CarListItemType {
    Fixed = 'Fixed',
    Ppm = 'PPM',
}

export enum UWEntityType {
    Quote = 'quote',
    Policy = 'policy',
}

export interface VehicleDescriptor {
    readonly year: number;
    readonly make: string;
    readonly model: string;
    readonly body_style: string;
}

export enum AttachmentRelationEntityType {
    InvolvedPerson = 'involved_person',
    InvolvedVehicle = 'involved_vehicle',
    Lien = 'lien',
}

export interface GroupedAttachmentRelations {
    readonly [involvedPartyId: string]:
        | { readonly entityType: AttachmentRelationEntityType; readonly attachments: string[] }
        | undefined;
}

export type ReportedByTypeString = 'Attorney' | 'Claimant' | 'Insured' | 'Other Carrier' | 'Other';
export interface ReportedBy {
    readonly name?: string;
    readonly type?: ReportedByTypeString;
}

export enum InvolvedVehicleDamagePoints {
    DamagedRightFrontCorner = 'damaged_right_front_corner',
    DamagedRightFrontSide = 'damaged_right_front_side',
    DamagedRightRearCorner = 'damaged_right_rear_corner',
    DamagedRightRearSide = 'damaged_right_rear_side',
    DamagedLeftFrontCorner = 'damaged_left_front_corner',
    DamagedLeftFrontSide = 'damaged_left_front_side',
    DamagedLeftRearCorner = 'damaged_left_rear_corner',
    DamagedLeftRearSide = 'damaged_left_rear_side',
    DamagedFrontCenter = 'damaged_front_center',
    DamagedRearCenter = 'damaged_rear_center',
    DamagedRoof = 'damaged_roof',
    DamagedHood = 'damaged_hood',
    DamagedFrontWindshield = 'damaged_front_windshield',
    DamagedRearWindshield = 'damaged_rear_windshield',
    DamagedTrunkLid = 'damaged_trunk_lid',
}

export const DamagePointsMap: Record<InvolvedVehicleDamagePoints, DamagePoint> = {
    [InvolvedVehicleDamagePoints.DamagedRightFrontCorner]: DamagePoint.RightFrontCorner,
    [InvolvedVehicleDamagePoints.DamagedRightFrontSide]: DamagePoint.RightFrontSide,
    [InvolvedVehicleDamagePoints.DamagedRightRearCorner]: DamagePoint.RightRearCorner,
    [InvolvedVehicleDamagePoints.DamagedRightRearSide]: DamagePoint.RightRearSide,
    [InvolvedVehicleDamagePoints.DamagedLeftFrontCorner]: DamagePoint.LeftFrontCorner,
    [InvolvedVehicleDamagePoints.DamagedLeftFrontSide]: DamagePoint.LeftFrontSide,
    [InvolvedVehicleDamagePoints.DamagedLeftRearCorner]: DamagePoint.LeftRearCorner,
    [InvolvedVehicleDamagePoints.DamagedLeftRearSide]: DamagePoint.LeftRearSide,
    [InvolvedVehicleDamagePoints.DamagedFrontCenter]: DamagePoint.FrontCenter,
    [InvolvedVehicleDamagePoints.DamagedRearCenter]: DamagePoint.RearCenter,
    [InvolvedVehicleDamagePoints.DamagedRoof]: DamagePoint.Roof,
    [InvolvedVehicleDamagePoints.DamagedHood]: DamagePoint.Hood,
    [InvolvedVehicleDamagePoints.DamagedFrontWindshield]: DamagePoint.FrontWindshield,
    [InvolvedVehicleDamagePoints.DamagedRearWindshield]: DamagePoint.RearWindshield,
    [InvolvedVehicleDamagePoints.DamagedTrunkLid]: DamagePoint.TrunkLid,
};

export interface CarClaimReporterBase {
    readonly type: ReportedByType.Attorney | ReportedByType.Carrier | ReportedByType.Claimant | ReportedByType.Other;
    readonly name: string;
}

export interface CarClaimReporterPrimaryNamedInsured {
    readonly type: ReportedByType.PrimaryNamedInsured;
    readonly name: string;
    readonly userPublicId: string;
}

export interface CarClaimReporterSecondaryNamedInsured {
    readonly type: ReportedByType.SecondaryNamedInsured;
    readonly name: string;
    readonly userPublicId: string;
}

export interface CarClaimReporterAdditionalDriver {
    readonly type: ReportedByType.AdditionalDriver;
    readonly name: string;
    readonly driverPublicId: string;
}

export type CarClaimReporter =
    | CarClaimReporterAdditionalDriver
    | CarClaimReporterBase
    | CarClaimReporterPrimaryNamedInsured
    | CarClaimReporterSecondaryNamedInsured;

interface ClaimPayment {
    readonly type: string;
    readonly amount: number;
    readonly paidTo?: string;
    readonly status?: string;
}

export type ClaimHistory = {
    readonly claimId: string;
    readonly archived?: boolean;
    readonly involvedVehicle?: InvolvedVehicle;
    readonly lossType?: CarClaimLossTypes;
    readonly reportedBy?: ReportedBy;
    readonly dateOfLoss?: string;
    readonly paidAmount: number;
    readonly damages?: boolean;
    readonly injuries?: boolean;
    readonly status: CarClaimStatus;
    readonly policyRole?: TypeWithExcluded;
    readonly lossAddress?: Address | null;
    readonly insuredName: string;
    readonly carrier?: string;
    readonly carrierOperator?: string;
    readonly policyId?: string;
    readonly payments: ClaimPayment[];
    readonly incidentDescription?: string;
    readonly insuredAtFault?: boolean;
    readonly policyType?: string;
    readonly migrated?: boolean;
};

enum AdditionalAddressComponents {
    Intersection = 'intersection',
}

export const additionalAddressComponentTypes = [AdditionalAddressComponents.Intersection];

export type AutocompleteAddress = Address & { readonly [AdditionalAddressComponents.Intersection]?: string };

export enum InjuryFlag {
    FirstPartyDriverInjured = 'firstPartyDriverInjured',
    FirstPartyPassengerInjured = 'firstPartyPassengerInjured',
    ThirdPartyDriverOrPassengerInjured = 'thirdPartyDriverOrPassengerInjured',
    ThirdPartyCyclistOrPedestrianInjured = 'thirdPartyCyclistOrPedestrianInjured',
}

export interface PageResult {
    readonly attachments: Attachment[];
    readonly paginationStats: {
        readonly currentPage: number;
        readonly pageSize: number;
        readonly totalRecords: number;
        readonly totalPages: number;
    };
}

export interface PaginatedResult {
    readonly pages: PageResult[];
    readonly pageParams: unknown[];
}

export interface PaginationResult<T> {
    readonly data: T[];
    readonly stats: {
        readonly currentPage: number;
        readonly pageSize: number;
        readonly totalRecords: number;
        readonly totalPages: number;
    };
}

export interface CarVendorData {
    readonly claims: unknown;
    readonly insuranceScore: unknown;
    readonly subjects: unknown;
    readonly vehicleDetails: unknown;
    readonly vehicleHistory: CarVendorDataVehicleHistory[];
    readonly vehicles: CarDiscoveredVehicle[];
    readonly violations: unknown;
}

export enum CarAttachmentType {
    AttorneyCorrespondence = 'attorney_correspondence',
    CarTitle = 'car_title',
    ClaimantDemand = 'claimant_demand',
    ClaimVideo = 'claim_video',
    ClaimVideoPreview = 'claim_video_preview',
    DamageDocuments = 'damage_documents',
    InvoiceReceipt = 'invoice_receipt',
    LitigationDocs = 'litigation_docs',
    InjuryDocuments = 'injury_documents',
    PoliceReport = 'police_report',
    RepairAppraisalAssessment = 'repair_appraisal_assessment',
    RepairReceipt = 'repair_receipt',
    SubrogationDocs = 'subrogation_docs',
    SalvageDocs = 'salvage_docs',
    ThirdPartyInsuranceID = 'third_party_insurance_id',
    UserSignature = 'user_signature',
    Email = 'email',
    TotalLossValuation = 'total_loss_valuation',
    SortedDemand = 'sorted_demand',
    ThirdPartyBills = 'third_party_bill',
    LetterOfRepresentation = 'letter_of_representation',
    SignedRelease = 'signed_release',
    PaymentReports = 'payment_reports',
    PhotoCollection = 'photo_collection',
    Photos360 = '360_photos',
    Lien = 'lien',
    Other = 'other',
    RSAReceipt = 'rsa_receipt',
}

export const previewableAttachments = Object.values(CarAttachmentType).filter(
    att => att !== CarAttachmentType.ClaimVideoPreview
);

export interface CarRatingData {
    readonly calculation: Record<string, unknown>;
    readonly request: Record<string, unknown>;
}

export type Location = SnakeCaseToCamelCase<Address> & {
    readonly description?: string | null;
};

export interface BiCoverageTypes {
    readonly firstParty: string[];
    readonly thirdParty: string[];
}

export interface FeeSummary {
    readonly code: PricingItemCode;
    readonly costPerItem: number;
    readonly totalCost?: number;
}

export interface FeesBreakdown {
    readonly feesTotalCost: number;
    readonly feesSummary: FeeSummary[] | null;
}

export enum ExportEntityType {
    CarPolicy = 'car_policy',
    CarClaim = 'car_claim',
}
