import type { TableColumn, TableItem } from '@lemonade-hq/blender-ui';
import { Flex, spacing, Table, Text } from '@lemonade-hq/blender-ui';
import * as styles from './ChangesSummary.css';
import { toReadable } from 'commons/StringUtils';
import type { UWRuleType } from 'components/LoCo/common/display-texts/underwriting-rules';
import type {
    UnderwritingDeclineRule,
    UnderwritingFlagRule,
    VariantType,
} from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

export type UnderwritingRules<TRuleType extends UWRuleType> = TRuleType extends 'decline'
    ? UnderwritingDeclineRule[]
    : UnderwritingFlagRule[];

type UnderwritingFlagRuleDisplayLog = UnderwritingFlagRule & {
    readonly variantName?: string;
    readonly variantType?: VariantType;
    readonly experimentName?: string;
};

const isUnderwritingDeclineRules = (
    rules: UnderwritingRules<UWRuleType>,
    ruleType: UWRuleType
): rules is UnderwritingDeclineRule[] => ruleType === 'decline';

export type UnderwritingRulesDisplayLog<TRuleType extends UWRuleType> = TRuleType extends 'decline'
    ? UnderwritingDeclineRule[]
    : UnderwritingFlagRuleDisplayLog[];

interface UnderwritingRulesTableProps<TRuleType extends UWRuleType> {
    readonly ruleType: TRuleType;
    readonly rules: UnderwritingRulesDisplayLog<TRuleType>;
}

export const UnderwritingRulesTable = <TRuleType extends UWRuleType>({
    ruleType,
    rules,
}: UnderwritingRulesTableProps<TRuleType>): JSX.Element => {
    const columns: TableColumn[] = [{ key: 'segment', name: 'segment' }];
    const rows: Record<string, TableItem>[] = rules.map(rule => ({ segment: { value: rule.expression } }));

    if (ruleType === 'decline') {
        const declineRules = rules as UnderwritingDeclineRule[];
        columns.push({ key: 'reason', name: 'Reason' });

        rows.forEach((row, index) => {
            row.outcome = { value: declineRules[index].reasonDisplay };
        });
    }

    return <Table className={styles.table} columns={columns} data={rows} />;
};

export const UnderwritingRulesTables = <TRuleType extends UWRuleType>({
    ruleType,
    rules,
}: UnderwritingRulesTableProps<TRuleType>): JSX.Element => {
    const groupedRules: UnderwritingFlagRuleDisplayLog[] = [];
    const groupedRulesABTest: Partial<Record<string, UnderwritingFlagRuleDisplayLog[]>> = {};

    if (isUnderwritingDeclineRules(rules, ruleType)) {
        return <UnderwritingRulesTable ruleType={ruleType} rules={rules} />;
    }

    rules.forEach(rule => {
        const variantTitle =
            rule.variantName != null
                ? `${rule.experimentName} - ${toReadable(rule.variantType)}: ${rule.variantName}`
                : null;

        if (variantTitle == null) {
            groupedRules.push(rule);
        } else {
            if (groupedRulesABTest[variantTitle] == null) {
                groupedRulesABTest[variantTitle] = [];
            }

            groupedRulesABTest[variantTitle]?.push(rule);
        }
    });

    return (
        <Flex flexDirection="column">
            {Object.entries(groupedRulesABTest).map(([variantTitle, variantRules]) => (
                <Flex flexDirection="column" key={variantTitle} pl={spacing.s08} pt={spacing.s20}>
                    <Text color="secondary">{variantTitle}</Text>
                    {variantRules && (
                        <UnderwritingRulesTable
                            ruleType={ruleType}
                            rules={variantRules as UnderwritingRulesDisplayLog<TRuleType>}
                        />
                    )}
                </Flex>
            ))}
            {groupedRules.length > 0 && (
                <UnderwritingRulesTable
                    ruleType={ruleType}
                    rules={groupedRules as UnderwritingRulesDisplayLog<TRuleType>}
                />
            )}
        </Flex>
    );
};
