import { Card, Flex, spacing, Text } from '@lemonade-hq/blender-ui';
import type { DialogProps } from '@lemonade-hq/bluis';
import { Dialog } from '@lemonade-hq/bluis';
import type { FC } from 'react';
import * as styles from '../../../shared/debugLogs.css';
import type { ArbitraryStringDebugLog, DebugLogs, PromptDebugLog, SlackMessageDebugLog } from '../simulations.types';
import { PromptRenderer, RawOutputRenderer } from 'components/Llmnd/shared/components/PromptRenderer';

interface DebugLogsDialogProps {
    readonly onClose: () => void;
    readonly debugLogs: DebugLogs;
}

export function createLogList(
    logs: ArbitraryStringDebugLog[] | PromptDebugLog[] | SlackMessageDebugLog[]
): JSX.Element[] {
    return logs.map(log => (
        <Card key={'prompt' in log ? log.prompt.title : log.id} padding={spacing.s12} variant="tertiary">
            <Flex flexDirection="column" gap={spacing.s12}>
                <Text type="h4">{log.id.replace('_', ' ')}</Text>
                <div className={styles.debugLogContainer}>
                    {'text' in log && log.text}
                    {'prompt' in log && (
                        <>
                            <p>{`prompt:`}</p>
                            <PromptRenderer prompt={log.prompt} />
                            <ul>
                                <li>
                                    output:&nbsp;<code>{JSON.stringify(log.output)}</code>
                                </li>
                                {log.rawOutput != null && (
                                    <li>
                                        rawOutput:&nbsp;
                                        <RawOutputRenderer rawOutput={log.rawOutput} />
                                    </li>
                                )}
                                {log.metadata && (
                                    <li>
                                        metadata:
                                        <ul>
                                            {Object.entries(log.metadata).map(([key, value]) => (
                                                <li key={key}>{`${key}: ${JSON.stringify(value)}`}</li>
                                            ))}
                                        </ul>
                                    </li>
                                )}
                            </ul>
                        </>
                    )}
                </div>
            </Flex>
        </Card>
    ));
}

export const DebugLogsDialog: FC<DebugLogsDialogProps> = ({ onClose, debugLogs }) => {
    const dialogProps: DialogProps = {
        title: 'Debug Log',
        textAlign: 'left',
        size: 'x-large',
        actions: [
            {
                text: 'Close',
                type: 'close',
                onClick: onClose,
            },
        ],
        closeOnOutsideClick: true,
    };

    return (
        <Dialog {...dialogProps}>
            <Flex flexDirection="column" gap="12px">
                {Object.entries(debugLogs).map(
                    ([logType, logs]: [
                        string,
                        ArbitraryStringDebugLog[] | PromptDebugLog[] | SlackMessageDebugLog[],
                    ]) => (
                        <>
                            <Text type="h3">{logType}</Text>
                            {createLogList(logs)}
                        </>
                    )
                )}
            </Flex>
        </Dialog>
    );
};
