import { ServiceName, getUrlResolver } from '@lemonade-hq/bluiza';
import axios from 'axios';
import { stringifyUrl } from 'query-string';
import { HomePolicyData } from 'models/HomePolicy';
import { UserData } from 'models/User';
import { HomeClaimDataResponse } from 'apis/HomeClaimsAPI';
import { HomesiteQuoteDataResponse } from 'models/HomesiteQuote';
import { HomesitePolicyDataResponse } from 'models/HomesitePolicy';

const homeBlenderUrlResolver = getUrlResolver({ service: ServiceName.HomeBlender, fallbackMap: new Set() });

export type DataAggregatorResponse<T, K extends keyof T = keyof T> = {
    data: Pick<T, K>;
    error: { [key in K]: string | undefined };
};

export type Fetcher<TData> = (
    id: string,
    serializers: (keyof TData)[]
) => Promise<DataAggregatorResponse<TData, keyof TData>>;

export function getFetcher<TData>(basePath: string): Fetcher<TData> {
    return async (id, serializers) => {
        const url = stringifyUrl(
            {
                url: `${basePath}${id}`,
                query: {
                    include: serializers as string[],
                },
            },
            { arrayFormat: 'comma' }
        );

        const response = await axios.get(url);

        return response.data.data;
    };
}

export const fetchers = {
    homePolicy: getFetcher<HomePolicyData>(homeBlenderUrlResolver('/api/v1/policies/')),
    homesitePolicy: getFetcher<HomesitePolicyDataResponse>(homeBlenderUrlResolver('/api/v1/homesite/policies/')),
    homesiteQuote: getFetcher<HomesiteQuoteDataResponse>(homeBlenderUrlResolver('/api/v1/homesite/quotes/')),
    homeUser: getFetcher<UserData>(homeBlenderUrlResolver('/api/v1/users/')),
    homeClaim: getFetcher<HomeClaimDataResponse>(homeBlenderUrlResolver('/api/v1/claims/')),
};

export type Fetchers = typeof fetchers;
export type EntityType = keyof Fetchers;

export type InferDataSchemaFromEntityType<TEntityType extends EntityType> = Fetchers[TEntityType] extends Fetcher<
    infer TData
>
    ? TData
    : never;

export type InferSerializersFromEntityType<
    TEntityType extends EntityType
> = keyof InferDataSchemaFromEntityType<TEntityType>;

type SerializersStaleTime = {
    [K in EntityType]: Partial<{ [P in InferSerializersFromEntityType<K>]: number }>;
};

// To override specific serializers stale time, add them in this config
export const serializersStaleTime: SerializersStaleTime = {
    homePolicy: {
        page_layout: Infinity,
    },
    homeUser: {},
    homeClaim: {
        page_layout: Infinity,
    },
    homesitePolicy: {},
    homesiteQuote: {},
};
