import type { CoverageRelatedSettingsAndRules, SettingsRelatedCoveragesAndRules } from '../types';
import type { CoverageInstance, CoveragesEdition, SettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import { isCoverageRuleGroupABTest } from 'models/LoCo/Insurance/DigitalAgentEdition';
import type {
    CoverageRuleGroupABTest,
    CoverageRuleGroupBase,
    DigitalAgentEdition,
} from 'models/LoCo/Insurance/DigitalAgentEdition';
import { isABTestRuleGroup } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import type {
    ABTestRuleGroup,
    RuleGroupBase,
    UnderwritingFiltersEdition,
    UnderwritingFlagRulesGroup,
} from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

export function findSetting(coveragesEdition: CoveragesEdition, settingCode: string): SettingInstance {
    const setting = coveragesEdition.settings.find(s => s.templateCode === settingCode);
    if (setting === undefined) {
        throw new Error(`Setting ${settingCode} not found in coverage edition ${coveragesEdition.code}`);
    }

    return setting;
}

export function findCoverage(coveragesEdition: CoveragesEdition, coverageCode: string): CoverageInstance {
    const coverage = coveragesEdition.coverages.find(c => c.templateCode === coverageCode);
    if (coverage === undefined) {
        throw new Error(`Coverage ${coverageCode} not found in coverage edition ${coveragesEdition.code}`);
    }

    return coverage;
}

export function findFlag(
    underwritingFiltersEdition: UnderwritingFiltersEdition,
    flagCode: string
): UnderwritingFlagRulesGroup {
    const flag = underwritingFiltersEdition.flagRulesGroup.find(f => f.flagCode === flagCode);
    if (flag === undefined) {
        throw new Error(
            `Flag ${flagCode} not found in underwriting filters edition ${underwritingFiltersEdition.code}`
        );
    }

    return flag;
}

export function assertDigitalAgentEditionIsNotNull(
    digitalAgentEdition: DigitalAgentEdition | null
): asserts digitalAgentEdition is DigitalAgentEdition {
    if (digitalAgentEdition === null) {
        throw new Error('Digital agent edition is null');
    }
}

export function isCoverageRelatedSettingsAndRules(
    value: CoverageRelatedSettingsAndRules | SettingsRelatedCoveragesAndRules
): value is CoverageRelatedSettingsAndRules {
    return 'coverage' in value;
}

export function assertIsCoverageRuleGroupABTest(
    group: CoverageRuleGroupBase | undefined
): asserts group is CoverageRuleGroupABTest {
    if (group == null || !isCoverageRuleGroupABTest(group)) {
        throw new Error('Coverage rule group is not an A/B test');
    }
}

export function assertIsABTestRuleGroup(group: RuleGroupBase | undefined): asserts group is ABTestRuleGroup {
    if (group == null || !isABTestRuleGroup(group)) {
        throw new Error('Flag rule group is not an A/B test');
    }
}
