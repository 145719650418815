import { Button, Text } from '@lemonade-hq/blender-ui';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { useMemo } from 'react';
import { ArrayExpression, ArrayItem } from './ArrayExpression';
import { BinaryExpression } from './BinaryExpression';
import { CallExpression } from './CallExpression';
import { EnumExpression } from './EnumExpression';
import type { ExpressionSimpleEditorProps } from './ExpressionSimpleEditorContext';
import {
    ExpressionActionType,
    ExpressionSimpleEditorProvider,
    useExpressionSimpleEditorContext,
} from './ExpressionSimpleEditorContext';
import type { ExpressionProps } from './ExpressionSimpleEditorShared';
import { getInputType } from './ExpressionSimpleEditorShared';
import type { UnknownArgument } from './expressionTypes';
import { ArgumentType, ExpressionType, isLogicalExpression, nodeHasChildren } from './expressionTypes';
import { InputExpression } from './InputExpression';
import { LogicalExpression } from './LogicalExpression';
import { BOOLEAN_VALUES, COMPARE_OPERATORS } from './operators';

import * as rulesBuilderStyles from './RulesBuilder.css';

// TODO probably rename to node
export const Expression: React.FC<ExpressionProps> = ({ id, payload }) => {
    const { state, productSchema, functions } = useExpressionSimpleEditorContext();

    const node = state.expressionTree[id];

    const argumentType = useMemo(() => {
        if (!isDefined(node)) return { type: ArgumentType.Unknown } as UnknownArgument;

        const searchId = nodeHasChildren(node) ? id : node.parent;

        return getInputType(state.expressionTree, searchId, productSchema, functions);
    }, [id, node, productSchema, state.expressionTree, functions]);

    if (state.root === '') {
        return null;
    }

    switch (node.type) {
        /**
         * An expression that has 2 parameters (hence binary) and a logical operator between them
         * This kind of node has exactly 2 children.
         *
         * e.g. age > 18, name === 'John', etc.
         */
        case ExpressionType.BinaryExpression:
            return (
                <BinaryExpression
                    argumentType={argumentType}
                    id={id}
                    left={<Expression id={node.children[0]} />}
                    right={<Expression id={node.children[1]} />}
                />
            );

        /**
         * An expression that has a logical operator between two expressions, like AND or OR.
         */
        case ExpressionType.LogicalExpression: {
            const secondChild = state.expressionTree[node.children[1]];
            const isRightLogical = isLogicalExpression(secondChild);

            const rightId = isRightLogical ? secondChild.children[0] : node.children[1];
            const left = state.root === id ? <Expression id={node.children[0]} /> : undefined;

            return (
                <>
                    <LogicalExpression id={id} left={left} right={<Expression id={rightId} />} />
                    {isRightLogical && <Expression id={node.children[1]} />}
                </>
            );
        }

        /**
         * A literal expression is a simple expression that has a value.
         */
        case ExpressionType.Literal: {
            if (argumentType.type === ArgumentType.Enum) {
                return <EnumExpression argumentType={argumentType} id={id} />;
            }

            if (argumentType.type === ArgumentType.Boolean) {
                return (
                    <EnumExpression
                        argumentType={{
                            type: ArgumentType.Enum,
                            symbols: BOOLEAN_VALUES.map(o => ({ label: o, value: o })),
                            enumName: 'BooleanValues',
                        }}
                        id={id}
                    />
                );
            }

            // for some reason we wrap literals like strings or numbers with the input function
            // maybe it's for casting strings to numbers?
            return <InputExpression argumentType={argumentType} id={id} placeholder={payload?.placeholder} />;
        }

        /**
         * An expression that has a function call. Each of the children of this node
         * is an an argument to the function.
         *
         * e.g. contains(name, 'John'), startsWith(name, 'J'), etc.
         */
        case ExpressionType.CallExpression: {
            const operatorArgs = COMPARE_OPERATORS[argumentType.type][node.callee].arguments;

            const children = node.children.map((child, index) => (
                <Expression
                    id={child}
                    key={child}
                    payload={{ placeholder: index === 0 ? undefined : operatorArgs[index - 1].placeholder }}
                />
            ));

            return (
                <CallExpression argumentType={argumentType} id={id}>
                    {children}
                </CallExpression>
            );
        }

        /**
         * An input is a special type of built in function that receives a key from the product's
         * schema and returns the value of that key.
         *
         * e.g. input('name'), input('age'), represents the value of the key 'name' and 'age' respectively.
         */
        case ExpressionType.InputExpression: {
            return <InputExpression argumentType={argumentType} id={id} />;
        }

        case ExpressionType.ArrayExpression: {
            return (
                <ArrayExpression argumentType={argumentType} id={id}>
                    {node.children.map(child => (
                        <ArrayItem id={child} key={child} removable={node.children.length > 1}>
                            <Expression id={child} payload={payload} />
                        </ArrayItem>
                    ))}
                </ArrayExpression>
            );
        }

        default:
            return null;
    }
};

export const RuleBuilderInner: React.FC = () => {
    const { state, dispatch, allowEmpty } = useExpressionSimpleEditorContext();
    const addLogicalOperator = (): void => dispatch({ type: ExpressionActionType.AddLogicalOperator });

    const isEmpty = state.root.length === 0;

    return (
        <div className={rulesBuilderStyles.ruleBuilderContainer}>
            {!isEmpty && <Expression id={state.root} />}
            {isEmpty && allowEmpty && (
                <Text type="text-md">If no filters are added, this rule will always be applied</Text>
            )}

            <Button label={'+ Add Filter'} onClick={addLogicalOperator} variant={'secondary'}></Button>
        </div>
    );
};

export const ExpressionSimpleEditor: React.FC<ExpressionSimpleEditorProps> = ({
    productSchema,
    onValidation,
    ruleBuilderRef,
    expression,
    additionalFunctions,
    allowEmpty = true,
}) => {
    return (
        <ExpressionSimpleEditorProvider
            additionalFunctions={additionalFunctions}
            allowEmpty={allowEmpty}
            expression={expression}
            onValidation={onValidation}
            productSchema={productSchema}
            ruleBuilderRef={ruleBuilderRef}
        >
            <RuleBuilderInner />
        </ExpressionSimpleEditorProvider>
    );
};
