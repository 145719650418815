import { UnderwritingDialogType } from '../UnderwritingFiltersShared';
import { ManageDeclineRuleDialog } from './ManageDeclineRuleDialog';
import { ManageFlagRuleDialog } from './ManageFlagsRuleDialog';
import { RemoveRule } from './RemoveRule';
import type {
    UnderwritingDecisionLifecycleContext,
    UnderwritingDeclineRule,
    UnderwritingFlagRule,
} from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

interface RemoveRuleDialogData {
    readonly ruleId: string;
    readonly editionCode: string;
}

export type RuleActionData =
    | {
          readonly type: UnderwritingDialogType.AddDeclineRule;
          readonly lifecycleContext: UnderwritingDecisionLifecycleContext;
      }
    | {
          readonly type: UnderwritingDialogType.AddFlagRule;
          readonly data?: { readonly flagCode: string; readonly variantName?: string };
          readonly existingFlagCodes?: string[];
      }
    | {
          readonly type: UnderwritingDialogType.EditDeclineRule;
          readonly data: UnderwritingDeclineRule;
      }
    | {
          readonly type: UnderwritingDialogType.EditFlagRule;
          readonly data: UnderwritingFlagRule & { readonly variantName?: string };
      }
    | {
          readonly type: UnderwritingDialogType.RemoveDeclineRule;
          readonly data: RemoveRuleDialogData;
      }
    | {
          readonly type: UnderwritingDialogType.RemoveFlagRule;
          readonly data: RemoveRuleDialogData & { readonly variantName?: string };
      };

export type DialogData = RuleActionData & { readonly editionCode: string; readonly existingFlagCodes?: string[] };

export interface UnderwritingRulesDialogsProps {
    readonly dialogData: DialogData;
    readonly onClose: () => void;
}

export const UnderwritingRulesDialogs: React.FC<UnderwritingRulesDialogsProps> = ({ dialogData, onClose }) => {
    if (
        dialogData.type === UnderwritingDialogType.RemoveDeclineRule ||
        dialogData.type === UnderwritingDialogType.RemoveFlagRule
    ) {
        return (
            <RemoveRule
                editionCode={dialogData.editionCode}
                onClose={onClose}
                ruleId={dialogData.data.ruleId}
                ruleType={dialogData.type === UnderwritingDialogType.RemoveDeclineRule ? 'decline' : 'flag'}
                variantName={
                    dialogData.type === UnderwritingDialogType.RemoveFlagRule ? dialogData.data.variantName : undefined
                }
            />
        );
    }

    if (
        dialogData.type === UnderwritingDialogType.AddFlagRule ||
        dialogData.type === UnderwritingDialogType.EditFlagRule
    ) {
        return <ManageFlagRuleDialog dialogData={dialogData} onClose={onClose} />;
    } else {
        return <ManageDeclineRuleDialog dialogData={dialogData} onClose={onClose} />;
    }
};
