import { isCoverageRuleDisplayLogABTest } from './types';
import type { CoverageRuleDisplayLog } from './types';
import { toReadable } from 'commons/StringUtils';
import type { QuoteLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import { CoverageRuleType } from 'models/LoCo/Insurance/CoverageRule';

export type RuleContext = CoverageRuleType.Restriction | QuoteLifecycleContext;
type GroupedRules = Partial<Record<RuleContext, CoverageRuleDisplayLog[]>>;
type GroupedRulesABTest = Partial<Record<RuleContext, Partial<Record<string, CoverageRuleDisplayLog[]>>>>;

export const useCoveragesRulesTableData = (
    rules: CoverageRuleDisplayLog[]
): {
    readonly groupedRules: GroupedRules;
    readonly groupedRulesABTest: GroupedRulesABTest;
} => {
    const groupedRules: GroupedRules = {};
    const groupedRulesABTest: GroupedRulesABTest = {};

    rules.forEach(rule => {
        if (!isCoverageRuleDisplayLogABTest(rule)) {
            if (rule.ruleType === CoverageRuleType.Restriction) {
                if (groupedRules[CoverageRuleType.Restriction] == null) {
                    groupedRules[CoverageRuleType.Restriction] = [];
                }

                groupedRules[CoverageRuleType.Restriction].push(rule);
            } else {
                rule.lifecycleContexts.forEach(lifecycleContext => {
                    if (groupedRules[lifecycleContext] == null) {
                        groupedRules[lifecycleContext] = [];
                    }

                    groupedRules[lifecycleContext]?.push(rule);
                });
            }
        } else {
            const variantTitle = `${rule.experimentName} - ${toReadable(rule.variantType)}: ${rule.variantName}`;

            if (rule.ruleType === CoverageRuleType.Restriction) {
                if (groupedRulesABTest[CoverageRuleType.Restriction] == null) {
                    groupedRulesABTest[CoverageRuleType.Restriction] = {};
                }

                if (groupedRulesABTest[CoverageRuleType.Restriction][variantTitle] == null) {
                    groupedRulesABTest[CoverageRuleType.Restriction][variantTitle] = [];
                }

                groupedRulesABTest[CoverageRuleType.Restriction][variantTitle]?.push(rule);
            } else {
                rule.lifecycleContexts.forEach(lifecycleContext => {
                    if (groupedRulesABTest[lifecycleContext] == null) {
                        groupedRulesABTest[lifecycleContext] = {};
                    }

                    const rulesByContext = groupedRulesABTest[lifecycleContext];

                    if (rulesByContext != null && rulesByContext[variantTitle] == null) {
                        rulesByContext[variantTitle] = [];
                    }

                    rulesByContext?.[variantTitle]?.push(rule);
                });
            }
        }
    });

    return { groupedRules, groupedRulesABTest };
};
