import type { ComboBoxItem } from '@lemonade-hq/blender-ui';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type { AttachmentDetailsData } from '../AttachmentsQueries';
import { useGetAttachmentTypes, useSubmitAttachmentDetails } from '../AttachmentsQueries';
import type { AttachmentDTO } from '../types';

// A hook to manage the update attachment details - in dialog, preview or gallery

interface ReturnType {
    readonly types: ComboBoxItem[];
    readonly type: string;
    readonly description: string;
    readonly isTypesError: boolean;
    readonly isTypesLoading: boolean;
    readonly isSubmitPending: boolean;
    readonly isSubmitError: boolean;
    readonly handleSelectionChange: (item: ComboBoxItem | null) => void;
    readonly setDescription: Dispatch<SetStateAction<string>>;
    readonly handleSubmit: ({
        attachmentsData,
    }: {
        readonly attachmentsData: AttachmentDetailsData[];
    }) => Promise<void>;
    readonly isInTypeList?: boolean;
}

export const useUpdateDetails = ({
    entityPublicId,
    entityType,
    attachments,
    onClose,
    savedType,
    savedDescription,
}: {
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
    readonly attachments: AttachmentDTO[];
    readonly onClose?: () => void;
    readonly savedType?: string | null;
    readonly savedDescription?: string | null;
}): ReturnType => {
    const [type, setType] = useState(savedType ?? '');
    const [description, setDescription] = useState(savedDescription ?? '');
    const {
        data: attachmentTypes,
        isError: isTypesError,
        isLoading: isTypesLoading,
    } = useGetAttachmentTypes({ entityType });
    const {
        mutateAsync: updateAttachmentDetails,
        isPending: isSubmitPending,
        isError: isSubmitError,
    } = useSubmitAttachmentDetails({
        entityPublicId,
        entityType,
    });

    const handleSelectionChange = useCallback((item: ComboBoxItem | null) => {
        setType(item?.value ?? '');
    }, []);

    const isInTypeList = attachmentTypes?.some(it => it === type);

    const handleSubmit = useCallback(async () => {
        const attachmentsData = attachments.map(it => ({
            attachmentPublicId: it.publicId,
            ...(type !== '' && isInTypeList && { type }),
            ...(description !== '' && { description }),
        }));

        await updateAttachmentDetails({ attachmentsData });

        onClose?.();
    }, [attachments, description, isInTypeList, onClose, type, updateAttachmentDetails]);

    const types = useMemo(
        () =>
            attachmentTypes?.map(attachmentType => ({
                value: attachmentType,
                label: snakeCaseToReadable(attachmentType),
            })) ?? [],
        [attachmentTypes]
    );

    return {
        types,
        type,
        description,
        isTypesError,
        isTypesLoading,
        isSubmitPending,
        isSubmitError,
        handleSelectionChange,
        setDescription,
        handleSubmit,
        isInTypeList,
    };
};
