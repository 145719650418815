import { useResizableWithCollapse } from 'libs/blender-ui/src/hooks/useResizableWithCollapse/useResizableWithCollapse';
import type { RefObject } from 'react';
import { useRef } from 'react';

const CHAT_MIN_WIDTH = 330;
const CHAT_COLLAPSED_WIDTH = 48;
const PERSIST_SIZE_KEY = 'playground_chat_width';

export const usePlaygroundChatResize = (): {
    readonly containerRef: RefObject<HTMLDivElement>;
    readonly elRef: RefObject<HTMLDivElement>;
    readonly handleRef: RefObject<HTMLDivElement>;
    readonly isCollapsed: boolean;
    readonly open: () => void;
} => {
    const containerRef = useRef<HTMLDivElement>(null);
    const elementRef = useRef<HTMLDivElement>(null);
    const handleRef = useRef<HTMLDivElement>(null);

    const { isCollapsed, open } = useResizableWithCollapse({
        initialWidth: CHAT_MIN_WIDTH,
        minWidth: CHAT_MIN_WIDTH,
        maxWidth: CHAT_MIN_WIDTH * 2,
        collapseThreshold: CHAT_MIN_WIDTH / 2,
        collapsedWidth: CHAT_COLLAPSED_WIDTH,
        containerRef,
        elementRef,
        handleRef,
        storageKey: PERSIST_SIZE_KEY,
    });

    return {
        containerRef,
        open,
        elRef: elementRef,
        handleRef,
        isCollapsed,
    };
};
