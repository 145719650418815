/* eslint-disable react/no-array-index-key */
import type { CommonFormChangePayload, OnChangeSubscription, OnResetSubscription } from '@lemonade-hq/blender-ui';
import { Badge, Card, generateTypedFormComponents, Text, useForm } from '@lemonade-hq/blender-ui';
import type { Infer } from '@lemonade-hq/maschema-schema';
import type {
    clientToolSchema,
    CustomerCardSectionName,
    SupportedToolRegion,
    SerializableTool as Tool,
    ToolSchemaValues,
    SerializableToolsRevision as ToolsRevision,
} from '@lemonade-hq/persisted-tools';
import {
    instructionToString,
    subclassifierToString,
    SUPPORTED_TOOL_REGIONS,
    ToolInstructionType,
    ToolMode,
    ToolParamName,
    ToolProduct,
    ToolSubclassifierActionType,
    ToolSubclassifierType,
    ToolType,
    ToolUserGroup,
} from '@lemonade-hq/persisted-tools';
import isEmpty from 'lodash/isEmpty';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { PlaygroundToolStructuredContainer } from '../shared/PlaygroundToolStructuredContainer';
import {
    prettifyField,
    prettifyString,
    prettifyToolMode,
    prettifyToolParam,
    prettifyToolProduct,
    prettifyToolRegion,
    prettifyToolType,
    safeDisplayAs,
    transformSchemaValueChange,
} from '../shared/tool.helpers';
import { CustomerCardSectionAccordion } from './components/CustomerCardSectionAccordion';
import { ToolEditorInstruction } from './components/ToolEditorInstruction';
import { ToolEditorQuestion } from './components/ToolEditorQuestion';
import { ToolEditorSubclassifier } from './components/ToolEditorSubclassifier';
import { PlaygroundDescription } from './PlaygroundDescription';
import { PlaygroundToolEditorMainTopBar } from './PlaygroundToolEditorMainTopBar';

interface PlaygroundToolEditorMainProps {
    readonly tool: Tool;
    readonly toolsRevision: ToolsRevision | undefined;
    readonly toolSchemaValues?: ToolSchemaValues;
    readonly onDeleteTool: (toolPublicId: string) => void;
    readonly onDiscardToolChanges: (toolPublicId: string) => void;
    readonly onUpdateTool: (toolPublicId: string, data: Partial<Tool>, options?: { readonly local?: boolean }) => void;
    readonly onCloneTool: (toolPublicId: string) => void;
}

const { Accordion, Select, DynamicList, InputGroup } = generateTypedFormComponents<typeof clientToolSchema>();

export const PlaygroundToolEditorMain: FC<PlaygroundToolEditorMainProps> = ({
    tool,
    toolsRevision,
    toolSchemaValues,
    onDeleteTool: handleDeleteTool,
    onDiscardToolChanges: handleDiscardToolChanges,
    onUpdateTool: handleUpdateTool,
    onCloneTool: handleCloneTool,
}) => {
    const {
        dispatch,
        values,
        config: { globallyDisabled: isReadonly },
    } = useForm<typeof clientToolSchema, CommonFormChangePayload>();
    const onChange: OnChangeSubscription<typeof clientToolSchema, CommonFormChangePayload> = useCallback(
        ({ key, value, allValues, changePayload }): void => {
            const dynamicItemAdded = changePayload?.changeType === 'addItem';
            handleUpdateTool(
                tool.publicId,
                transformSchemaValueChange(key, value, allValues as Infer<typeof clientToolSchema>),
                {
                    local: dynamicItemAdded,
                }
            );

            if (key === 'topic') {
                dispatch({
                    type: 'setValue',
                    key: 'subtopic',
                    value: toolSchemaValues?.topics[value as string]?.[0],
                });
            }
        },
        [tool, handleUpdateTool, dispatch, toolSchemaValues?.topics]
    );

    const onReset: OnResetSubscription<typeof clientToolSchema> = useCallback((): void => {
        handleDiscardToolChanges(tool.publicId);
    }, [tool, handleDiscardToolChanges]);

    useEffect(() => {
        dispatch({ type: 'subscribe', key: 'tool-editor', subscriptions: { onChange, onReset } });

        return () => {
            dispatch({ type: 'unsubscribe', key: 'tool-editor', subscriptions: { onChange, onReset } });
        };
    }, [onChange, onReset, dispatch]);

    return (
        <PlaygroundToolStructuredContainer
            renderBadges={{
                type: values.type != null ? <Badge label={prettifyToolType(values.type)} /> : null,
                topic: values.topic != null ? <Badge icon="tags" label={prettifyString(values.topic)} /> : null,
                userGroups:
                    values.userGroups != null && values.userGroups.length > 0 ? (
                        <Badge icon="user" label={values.userGroups.map(ug => prettifyString(ug)).join(', ')} />
                    ) : null,
            }}
            renderCustomerCardSections={
                <CustomerCardSectionAccordion
                    customerCardSections={
                        values.customerCardSections as Record<CustomerCardSectionName, boolean> | undefined
                    }
                    toolSchemaValues={toolSchemaValues}
                />
            }
            renderDescription={
                <PlaygroundDescription
                    isReadonly={isReadonly}
                    tool={tool}
                    toolsRevisionId={toolsRevision?.publicId}
                    value={values.description}
                />
            }
            renderInstructions={
                !isReadonly || !isEmpty(values.instructions) ? (
                    <Accordion
                        badges={[{ label: values.instructions?.length ?? 0 }]}
                        isOpenByDefault
                        schemaKey="instructions"
                        title={prettifyField('instructions')}
                    >
                        <Card borderRadius="sm" padding="1.2rem" variant="primary">
                            <DynamicList
                                displayAs={safeDisplayAs(instructionToString)}
                                newItemTemplate={{ type: ToolInstructionType.Rule, content: '' }}
                                renderFormAs={() => (
                                    <ToolEditorInstruction
                                        interpolations={
                                            values.type === ToolType.DIY
                                                ? toolSchemaValues?.pvps.map(v => `{%${v}%}`)
                                                : undefined
                                        }
                                    />
                                )}
                                schemaKey="instructions"
                                sortable={!isReadonly}
                                variant="ordered"
                            />
                        </Card>
                    </Accordion>
                ) : null
            }
            renderMacros={
                <InputGroup
                    inputComponent="ComboBox"
                    label={prettifyField('macros')}
                    mode="multiple"
                    placeholder="Search..."
                    schemaKey="macros"
                />
            }
            renderMode={
                <Select
                    aria-label="mode"
                    disabled={isReadonly}
                    labels={Object.values(ToolMode).reduce(
                        (acc, mode) => ({ ...acc, [mode]: prettifyToolMode(mode) }),
                        {} as Record<ToolMode, string>
                    )}
                    placeholder="Select"
                    schemaKey="mode"
                    selectedValueTemplate={value => (
                        <span>
                            <Text as="span" color="secondary" fontWeight="regular" type="text-sm">
                                {prettifyField('mode')}:
                            </Text>
                            &nbsp;
                            <Text as="span" fontWeight="bold" type="text-sm">
                                {value}
                            </Text>
                        </span>
                    )}
                    size="sm"
                />
            }
            renderNotes={
                <InputGroup
                    autoExpanding
                    inputComponent="TextArea"
                    label={prettifyField('notes')}
                    placeholder="Type here..."
                    schemaKey="notes"
                />
            }
            renderParams={
                <InputGroup
                    inputComponent="Select"
                    label={prettifyField('params')}
                    labels={Object.values(ToolParamName).reduce(
                        (acc, param) => ({ ...acc, [param]: prettifyToolParam(param) }),
                        {} as Record<ToolParamName, string>
                    )}
                    placeholder="Select"
                    schemaKey="params"
                />
            }
            renderProducts={
                <InputGroup
                    inputComponent="Select"
                    label={prettifyField('products')}
                    labels={Object.values(ToolProduct).reduce(
                        (acc, product) => ({ ...acc, [product]: prettifyToolProduct(product) }),
                        {} as Record<ToolProduct, string>
                    )}
                    mode="multiple"
                    placeholder="Select"
                    schemaKey="products"
                />
            }
            renderQuestions={
                !isReadonly || !isEmpty(values.questions) ? (
                    <Accordion
                        badges={[{ label: values.questions?.length ?? 0 }]}
                        isOpenByDefault
                        schemaKey="questions"
                        title={prettifyField('questions')}
                    >
                        <Card borderRadius="sm" padding="1.2rem" variant="primary">
                            <DynamicList
                                displayAs={safeDisplayAs((question: { content: string }) => question.content)}
                                newItemTemplate={{ content: '' }}
                                renderFormAs={() => <ToolEditorQuestion />}
                                schemaKey="questions"
                                sortable={!isReadonly}
                                variant="ordered"
                            />
                        </Card>
                    </Accordion>
                ) : null
            }
            renderRegion={
                <InputGroup
                    aria-label="region"
                    inputComponent="Select"
                    label={prettifyField('region')}
                    labels={SUPPORTED_TOOL_REGIONS.reduce(
                        (acc, region) => ({ ...acc, [region]: prettifyToolRegion(region) }),
                        {} as Record<SupportedToolRegion, string>
                    )}
                    placeholder="Select"
                    schemaKey="region"
                />
            }
            renderSubClassifiers={
                !isReadonly || !isEmpty(values.subClassifiers) ? (
                    <Accordion
                        badges={[{ label: values.subClassifiers?.length ?? 0 }]}
                        isOpenByDefault
                        schemaKey="subClassifiers"
                        title={prettifyField('subClassifiers')}
                    >
                        <Card borderRadius="sm" padding="1.2rem" variant="primary">
                            <DynamicList
                                // mode is required in order to resolve conditional validations inside subclassifiers reroute tool names
                                additionalItemContext={{ mode: values.mode }}
                                displayAs={safeDisplayAs(subclassifierToString)}
                                newItemTemplate={{
                                    type: ToolSubclassifierType.Standard,
                                    ifContent: '',
                                    thenAction: { type: ToolSubclassifierActionType.Escalate, reason: '' },
                                }}
                                renderFormAs={() => <ToolEditorSubclassifier />}
                                schemaKey="subClassifiers"
                                sortable={!isReadonly}
                                variant="ordered"
                            />
                        </Card>
                    </Accordion>
                ) : null
            }
            renderSubtopic={
                <InputGroup
                    inputComponent="ComboBox"
                    label={prettifyField('subtopic')}
                    placeholder="Select"
                    schemaKey="subtopic"
                />
            }
            renderTicketsIdsExamples={
                <InputGroup
                    inputComponent="Input"
                    label={prettifyField('ticketsIdsExamples')}
                    placeholder="e.g. 10000, 20001"
                    schemaKey="ticketsIdsExamples"
                />
            }
            renderTopBar={
                <PlaygroundToolEditorMainTopBar
                    handleCloneTool={handleCloneTool}
                    handleDeleteTool={handleDeleteTool}
                    handleDiscardToolChanges={() => dispatch({ type: 'reset' })}
                    handleUpdateTool={handleUpdateTool}
                    isReadonly={isReadonly}
                    tool={tool}
                    toolsRevision={toolsRevision}
                />
            }
            renderTopic={
                <InputGroup
                    inputComponent="ComboBox"
                    label={prettifyField('topic')}
                    placeholder="Select"
                    schemaKey="topic"
                />
            }
            renderType={
                <InputGroup
                    inputComponent="Select"
                    label={prettifyField('type')}
                    labels={Object.values(ToolType).reduce(
                        (acc, type) => ({ ...acc, [type]: prettifyToolType(type) }),
                        {} as Record<ToolType, string>
                    )}
                    placeholder="Select"
                    schemaKey="type"
                />
            }
            renderUserGroups={
                <InputGroup
                    inputComponent="Select"
                    label={prettifyField('userGroups')}
                    labels={Object.values(ToolUserGroup).reduce(
                        (acc, group) => ({ ...acc, [group]: prettifyString(group) }),
                        {} as Record<ToolUserGroup, string>
                    )}
                    mode="multiple"
                    placeholder="Select"
                    schemaKey="userGroups"
                />
            }
        />
    );
};
