import type { UseMutationResult, UseSuspenseQueryResult } from '@tanstack/react-query';
import { insuranceBlender } from '../../../apiClients';
import type { UWRuleType } from 'components/LoCo/common/display-texts/underwriting-rules';
import { useSuspenseQueryWithErrorHandling } from 'components/LoCo/common/hooks/useSuspenseQueryWithErrorHandling';
import type { CreateEditionParams } from 'models/LoCo/Insurance/BaseEdition';
import type {
    UnderwritingDeclineRule,
    UnderwritingFiltersEdition,
    UnderwritingFlagRule,
} from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

import { usePessimisticMutation } from 'queries/MutationHooks';

const BASE_PATH = '/api/v1/underwriting-filters-editions';

export interface CreateABTestRequest {
    readonly experimentName: string;
    readonly control: string;
    readonly variants: string[];
}

export interface UnbindABTestRequest {
    readonly variantToKeep: string;
}

export interface AddRemoveVariantsRequest {
    readonly variantNames: string[];
}

export interface RenameVariantsRequest {
    readonly experimentName: string;
    readonly variants?: {
        readonly oldName: string;
        readonly newName: string;
    }[];
}

interface ABTestParamsBase {
    readonly uwFiltersEditionCode: string;
    readonly flagCode: string;
}

export interface AttachABTestParams extends ABTestParamsBase {
    readonly payload: CreateABTestRequest;
}

export interface UnbindABTestParams extends ABTestParamsBase {
    readonly payload: UnbindABTestRequest;
}

export interface AddRemoveVariantsParams extends ABTestParamsBase {
    readonly payload: AddRemoveVariantsRequest;
}

export interface RenameVariantsParams extends ABTestParamsBase {
    readonly payload: RenameVariantsRequest;
}

export enum UnderwritingFiltersEditionQueryKey {
    GetUnderwritingFiltersEdition = 'GET_UNDERWRITING_FILTERS_EDITION',
    GetProductUnderwritingFiltersEditions = 'GET_PRODUCT_UNDERWRITING_FILTERS_EDITIONS',
    CreateUnderwritingFiltersEdition = 'CREATE_UNDERWRITING_FILTERS_EDITION',
    ApproveUnderwritingFiltersEdition = 'APPROVE_UNDERWRITING_FILTERS_EDITION',
    ArchiveUnderwritingFiltersEdition = 'ARCHIVE_UNDERWRITING_FILTERS_EDITION',
    CreateUnderwritingFiltersEditionDeclineRule = 'CREATE_UNDERWRITING_FILTERS_EDITION_DECLINE_RULE',
    UpdateUnderwritingFiltersEditionDeclineRule = 'UPDATE_UNDERWRITING_FILTERS_EDITION_DECLINE_RULE',
    CreateUnderwritingFiltersEditionFlagsRule = 'CREATE_UNDERWRITING_FILTERS_EDITION_FLAG_RULE',
    EditUnderwritingFiltersEditionRule = 'EDIT_UNDERWRITING_FILTERS_EDITION_RULE',
    DeleteUnderwritingFiltersEditionRule = 'DELETE_UNDERWRITING_FILTERS_EDITION_RULE',
    AttachABTest = 'ATTACH_AB_TEST',
    UnbindABTest = 'UNBIND_AB_TEST',
    AddVariants = 'ADD_VARIANTS',
    RemoveVariants = 'REMOVE_VARIANTS',
    RenameVariants = 'RENAME_VARIANTS',
}

export async function getUnderwritingFiltersEdition(editionCode: string): Promise<UnderwritingFiltersEdition> {
    const response = await insuranceBlender.get<{ data: UnderwritingFiltersEdition }>(`${BASE_PATH}/${editionCode}`);

    return response.data.data;
}

export function useSuspenseGetUnderwritingFiltersEdition(
    editionCode: string
): UseSuspenseQueryResult<UnderwritingFiltersEdition> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, editionCode],
        queryFn: async () => await getUnderwritingFiltersEdition(editionCode),
    });
}

async function createUnderwritingFiltersEdition({
    productCode,
    description,
    baseEditionCode,
}: CreateEditionParams): Promise<UnderwritingFiltersEdition> {
    const response = await insuranceBlender.post<{ data: UnderwritingFiltersEdition }>(BASE_PATH, {
        productCode,
        description,
        baseEditionCode,
    });

    return response.data.data;
}

export type DeclineRuleParamsRequest = Pick<
    UnderwritingDeclineRule,
    'expression' | 'lifecycleContext' | 'reasonCode'
> & { readonly editionCode: string };

async function createUnderwritingDeclineFiltersRules(params: DeclineRuleParamsRequest): Promise<void> {
    const { editionCode, ...rest } = params;
    await insuranceBlender.post<{ data: UnderwritingFiltersEdition }>(
        `${BASE_PATH}/${editionCode}/decline-rules`,
        rest
    );
}

export function useCreateUnderwritingDeclineFiltersRules(
    editionCode: string
): UseMutationResult<void, unknown, DeclineRuleParamsRequest, null> {
    return usePessimisticMutation({
        mutationFn: createUnderwritingDeclineFiltersRules,
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, editionCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.CreateUnderwritingFiltersEditionDeclineRule],
    });
}

async function updateUnderwritingDeclineFiltersRules(ruleId: string, params: DeclineRuleParamsRequest): Promise<void> {
    const { editionCode, ...rest } = params;
    await insuranceBlender.put<{ data: UnderwritingFiltersEdition }>(
        `${BASE_PATH}/${editionCode}/decline-rules/${ruleId}`,
        rest
    );
}

export function useUpdateUnderwritingDeclineFiltersRules(
    editionCode: string,
    ruleId: string
): UseMutationResult<void, unknown, DeclineRuleParamsRequest, null> {
    return usePessimisticMutation({
        mutationFn: async (params: DeclineRuleParamsRequest) =>
            await updateUnderwritingDeclineFiltersRules(ruleId, params),
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, editionCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.UpdateUnderwritingFiltersEditionDeclineRule],
    });
}

export type CreateFlagRuleParams = Pick<UnderwritingFlagRule, 'expression' | 'flagCode'> & {
    readonly editionCode: string;
    readonly variants?: string[];
};

async function createUnderwritingFlagsFiltersRules(params: CreateFlagRuleParams): Promise<void> {
    const { editionCode, ...rest } = params;
    await insuranceBlender.post<{ data: UnderwritingFiltersEdition }>(`${BASE_PATH}/${editionCode}/flag-rules`, rest);
}

export function useCreateUnderwritingFlagsFiltersRules(
    editionCode: string
): UseMutationResult<void, unknown, CreateFlagRuleParams, null> {
    return usePessimisticMutation({
        mutationFn: createUnderwritingFlagsFiltersRules,
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, editionCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.CreateUnderwritingFiltersEditionFlagsRule],
    });
}

export type UpdateFlagRuleParams = Pick<UnderwritingFlagRule, 'expression' | 'flagCode'> & {
    readonly editionCode: string;
    readonly ruleId: string;
    readonly variants?: string[];
};

async function updateUnderwritingFlagsFiltersRules(params: UpdateFlagRuleParams): Promise<void> {
    const { editionCode, ruleId, ...rest } = params;
    await insuranceBlender.put<{ data: UnderwritingFiltersEdition }>(
        `${BASE_PATH}/${editionCode}/flag-rules/${ruleId}`,
        rest
    );
}

export function useUpdateUnderwritingFlagsFiltersRules(
    editionCode: string
): UseMutationResult<void, unknown, UpdateFlagRuleParams, null> {
    return usePessimisticMutation({
        mutationFn: updateUnderwritingFlagsFiltersRules,
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, editionCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.CreateUnderwritingFiltersEditionFlagsRule],
    });
}

export function useCreateUnderwritingFiltersEdition(
    productCode: string
): UseMutationResult<UnderwritingFiltersEdition, unknown, CreateEditionParams, null> {
    return usePessimisticMutation({
        mutationFn: createUnderwritingFiltersEdition,
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetProductUnderwritingFiltersEditions, productCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.CreateUnderwritingFiltersEdition],
    });
}

interface EditUnderwritingFiltersEditionRuleParams {
    readonly editionCode: string;
    readonly ruleId: string;
    readonly ruleType: UWRuleType;
    readonly variants?: string[];
}

export async function removeUnderwritingFiltersEditionRule({
    editionCode,
    ruleId,
    ruleType,
    variants,
}: EditUnderwritingFiltersEditionRuleParams): Promise<void> {
    if (ruleType === 'flag') {
        await insuranceBlender.post(`${BASE_PATH}/${editionCode}/flag-rules/remove`, {
            rules: [{ publicId: ruleId, variants }],
        });
    } else {
        await insuranceBlender.delete(`${BASE_PATH}/${editionCode}/decline-rules/${ruleId}`);
    }
}

export function useRemoveUnderwritingFiltersEditionRule(
    editionCode: string
): UseMutationResult<void, unknown, EditUnderwritingFiltersEditionRuleParams, null> {
    return usePessimisticMutation({
        mutationFn: removeUnderwritingFiltersEditionRule,
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, editionCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.DeleteUnderwritingFiltersEditionRule],
    });
}

interface ReorderUnderwritingFiltersEditionRule {
    readonly editionCode: string;
    readonly rulePublicId: string;
    readonly destination: number;
    readonly ruleType: UWRuleType;
}

async function reorderUnderwritingFiltersEditionRule({
    editionCode,
    rulePublicId,
    destination,
    ruleType,
}: ReorderUnderwritingFiltersEditionRule): Promise<void> {
    const destinationPath = ruleType === 'flag' ? 'flag-rules' : 'decline-rules';
    await insuranceBlender.put(`${BASE_PATH}/${editionCode}/${destinationPath}/${rulePublicId}/order`, { destination });
}

type ReorderMutationArgs = Pick<ReorderUnderwritingFiltersEditionRule, 'destination' | 'rulePublicId'>;

export function useReorderUnderwritingFiltersEditionRule(
    editionCode: string,
    ruleType: UWRuleType
): UseMutationResult<void, unknown, ReorderMutationArgs, null> {
    return usePessimisticMutation({
        mutationFn: async args => await reorderUnderwritingFiltersEditionRule({ editionCode, ruleType, ...args }),
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, editionCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.EditUnderwritingFiltersEditionRule],
    });
}

async function attachABTest({ payload, uwFiltersEditionCode, flagCode }: AttachABTestParams): Promise<void> {
    await insuranceBlender.post(`${BASE_PATH}/${uwFiltersEditionCode}/flags/${flagCode}/ab-test/attach`, payload);
}

export function useAttachABTest(
    uwFiltersEditionCode: string
): UseMutationResult<void, unknown, AttachABTestParams, null> {
    return usePessimisticMutation({
        mutationFn: attachABTest,
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, uwFiltersEditionCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.AttachABTest],
    });
}

async function unbindABTest({ payload, uwFiltersEditionCode, flagCode }: UnbindABTestParams): Promise<void> {
    await insuranceBlender.post(`${BASE_PATH}/${uwFiltersEditionCode}/flags/${flagCode}/ab-test/detach`, payload);
}

export function useUnbindABTest(
    uwFiltersEditionCode: string
): UseMutationResult<void, unknown, UnbindABTestParams, null> {
    return usePessimisticMutation({
        mutationFn: unbindABTest,
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, uwFiltersEditionCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.UnbindABTest],
    });
}

async function addVariants({ payload, uwFiltersEditionCode, flagCode }: AddRemoveVariantsParams): Promise<void> {
    await insuranceBlender.post(`${BASE_PATH}/${uwFiltersEditionCode}/flags/${flagCode}/ab-test/add`, payload);
}

export function useAddVariants(
    uwFiltersEditionCode: string
): UseMutationResult<void, unknown, AddRemoveVariantsParams, null> {
    return usePessimisticMutation({
        mutationFn: addVariants,
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, uwFiltersEditionCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.AddVariants],
    });
}

async function removeVariants({ payload, uwFiltersEditionCode, flagCode }: AddRemoveVariantsParams): Promise<void> {
    await insuranceBlender.post(`${BASE_PATH}/${uwFiltersEditionCode}/flags/${flagCode}/ab-test/remove`, payload);
}

export function useRemoveVariants(
    uwFiltersEditionCode: string
): UseMutationResult<void, unknown, AddRemoveVariantsParams, null> {
    return usePessimisticMutation({
        mutationFn: removeVariants,
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, uwFiltersEditionCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.RemoveVariants],
    });
}

async function renameVariants({ payload, uwFiltersEditionCode, flagCode }: RenameVariantsParams): Promise<void> {
    await insuranceBlender.post(`${BASE_PATH}/${uwFiltersEditionCode}/flags/${flagCode}/ab-test/rename`, payload);
}

export function useRenameVariants(
    uwFiltersEditionCode: string
): UseMutationResult<void, unknown, RenameVariantsParams, null> {
    return usePessimisticMutation({
        mutationFn: renameVariants,
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, uwFiltersEditionCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.RenameVariants],
    });
}
