import type { MultiSwitchOption } from '@lemonade-hq/bluis';
import { ToolRegion } from '@lemonade-hq/persisted-tools';
import { AiResponseType } from '../shared/types';
import type { SupervisorMetadata, SupervisorTicketFilters } from '../shared/types';

export const SIDEBAR_WIDTH = '248px';

export const defaultFilters: SupervisorTicketFilters = {
    products: [],
    intents: [],
    mediums: [],
    languages: [],
    regions: [ToolRegion.US],
};

export const defaultMetadata: SupervisorMetadata = {
    ...defaultFilters,
    intents: {
        tools: {},
        nonTools: {},
    },
    pendingTicketsCount: { draft: -1, public: -1 },
    reviewedTodayCount: 0,
    regions: [ToolRegion.US, ToolRegion.FR, ToolRegion.GB],
};

export const aiModeTypes: MultiSwitchOption[] = [
    {
        label: 'Draft',
        value: AiResponseType.Draft,
    },
    {
        label: 'Public',
        value: AiResponseType.Public,
    },
];
